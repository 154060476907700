import {
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import axios from "axios";

import baseURL from "../../app/utils.js";

import { uuidv4 } from "../../app/utils";
import { fetchClient } from "../clients/clientsSlice.js";
import { resetOrder } from "../orders/ordersSlice.js";
import { resetProduct } from "../products/productsSlice";
import { resetSnack } from "../snackbar/snackbarSlice";
import { fetchMyself, resetUserAccount } from "../users/usersSlice";

// export const logoutUser = createAction("auth/logoutUser");
export const loggedIn = createAction("auth/loggedIn");

axios.interceptors.request.use(
  (req) => {
    req.headers.common["X-Request-ID"] = uuidv4();
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export const fetchAuthToken = createAsyncThunk(
  "auth/fetchAuthToken",
  async (args, thunkAPI) => {
    const config = {
      headers: { "X-Client-Host": args.host },
    };
    try {
      const response = await axios.post(
        `${baseURL}/auth/token`,
        args.creds,
        config
      );
      const token = response.data.token;
      thunkAPI.dispatch(fetchMyself({ token: token }));
      thunkAPI.dispatch(fetchClient({ token: token }));
      thunkAPI.dispatch(resetSnack());
      thunkAPI.dispatch(resetProduct());
      localStorage.setItem("clientToken", token);
      localStorage.setItem("clientUser", args.creds.username);
      return await response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.message.includes("401")
          ? { message: "Inavlid Username or Password" }
          : { message: error.message }
      );
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(resetSnack());
    thunkAPI.dispatch(resetProduct());
    thunkAPI.dispatch(resetOrder());
    thunkAPI.dispatch(resetUserAccount());
    localStorage.removeItem("clientToken");
    localStorage.removeItem("clientUser");

    args.history.push("/signin");
    // if (args.token) {
    //   try {
    //     const response = await axios.delete(`${baseURL}/v1/admin/logout`);

    //     return await response.data;
    //   } catch (error) {
    //     return
    //     // return thunkAPI.rejectWithValue(
    //     //   error.message.includes('401')
    //     //     ? {message: 'Inavlid Username or Password'}
    //     //     : {message: error.message}
    //     // );
    //   }
    // }
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { "X-Client-Host": args.host },
      };
      const response = await axios.get(
        `${baseURL}/v1/users/forgotpassword/${args.username}`,
        // {},
        config
      ); //where you want to fetch data
      return await response;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue({ error_description: error.message });
      }
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { "X-Client-Host": args.host },
      };
      const response = await axios.patch(
        `${baseURL}/v1/users/resetpassword`,
        args.userDetails,
        config
      ); //where you want to fetch data
      return await response;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue({ error_description: error.message });
      }
    }
  }
);

export const logoutUser = createAsyncThunk(
  "auth/logoutUser",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.delete(`${baseURL}/v1/admin/logout`, config); //where you want to fetch data
      return await response;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue({ error_description: error.message });
      }
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    signedIn: false,
    loading: "idle",
    error: "",
    success: false,
    logout: false,
  },
  reducers: {
    // logoutUser(state, action) {
    //   state.signedIn = false;
    //   state.logout = false;
    // },
    loggedIn(state, action) {
      state.signedIn = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAuthToken.pending, (state) => {
      // state.signedIn = false;
      state.loading = "loading";
      state.error = "";
    });
    builder.addCase(fetchAuthToken.fulfilled, (state, { payload }) => {
      state.signedIn = true;
      state.loading = "loaded";
    });
    builder.addCase(fetchAuthToken.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.payload;
    });
    builder.addCase(logoutUser.fulfilled, (state, { payload }) => {
      state.signedIn = false;
      state.logout = false;
      state.loading = "loaded";
    });
    builder.addCase(logoutUser.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.payload;
    });
    builder.addCase(resetPassword.pending, (state) => {
      state.loading = "loading";
      state.error = "";
    });
    builder.addCase(resetPassword.fulfilled, (state, { payload }) => {
      state.loading = "loaded";
      state.success = true;
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.payload;
      state.success = false;
    });
    builder.addCase(forgotPassword.pending, (state) => {
      state.loading = "loading";
      state.error = "";
      state.success = false;
    });
    builder.addCase(forgotPassword.fulfilled, (state, { payload }) => {
      state.error = "";
      state.loading = "loaded";
      state.success = true;
    });
    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.payload;
    });
    builder.addCase(fetchMyself.fulfilled, (state, { payload }) => {
      state.signedIn = true;
    });
    builder.addCase(fetchMyself.rejected, (state, { payload }) => {
      state.error = "";
      state.logout = true;
    });
    builder.addCase(logout.rejected, (state, { payload }) => {
      state.error = "";
    });
  },
});

export const selectAuth = createSelector(
  (state) => ({
    auth: state.auth,
    signedIn: state.auth.signedIn,
    loading: state.auth.loading,
    error: state.auth.error,
    success: state.auth.success,
    logout: state.auth.logout,
  }),
  (state) => state
);
export default authSlice.reducer;
