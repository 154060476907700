import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Dropdown from "../../components/Dropdown";
import { fetchUserClaims, selectClaims } from "../claims/claimsSlice";
import { fetchUserOrders, selectOrders, setOrder } from "../orders/ordersSlice";
import {
  fetchUserPointsHistory,
  selectUserPoints,
} from "../userPoints/userPointsSlice";
import { fetchMyself, selectUsers } from "../users/usersSlice";
import UpdatePassword from "./UpdatePassword";

var sortBy = require("lodash/sortBy");

const ColorButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.primaryButton.main,
    color: theme.palette.primaryButtonFont.main,
    borderRadius: "24px",
    // borderRadius: '24px',
    padding: 4,
    paddingLeft: 12,
    paddingRight: 12,
    "&:hover": {
      backgroundColor: theme.palette.primaryButton.main,
      color: theme.palette.primaryButtonFont.main,
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  accountContainer: {
    width: "100%",
    height: "100%",
    color: theme.palette.primaryFont.main,
  },
  bottomBackground: { display: "flex", flexGrow: 1 },
  userDetailsContainer: {
    padding: 24,
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-between",
  },
  userDetails: {
    color: theme.palette.primaryFont.main,
    fontSize: 40,
  },
  editButtonContainer: {},
  userCardsContainer: {
    display: "flex",
    flexFlow: "row wrap",
    padding: 24,
    boxSizing: "border-box",
    justifyContent: "space-between",
    flexGrow: 1,
  },
  historyCardContainer: {
    display: "flex",
    flexGrow: 1,
    width: "59%",
  },
  historyCard: {
    flexGrow: 1,
    padding: 24,
    borderRadius: 16,
  },
  headerRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 48,
  },
  cardHeader: {
    color: theme.palette.primaryFont.main,
    fontWeight: "bold",
    fontSize: 40,
  },
  viewAll: {
    color: theme.palette.secondaryFont.main,
    cursor: "pointer",
    fontSize: 24,
    display: "none",
  },
  cardSortContainer: {
    display: "flex",
  },
  cardlistContainer: {
    marginBottom: 32,
    marginTop: 32,
    borderBottom: "3px solid #DDD",
    borderTop: "3px solid #DDD",
    overflowX: "scroll",
  },
  noList: {
    color: "#DDD",
    paddingBottom: 48,
    paddingTop: 48,
    fontSize: 24,
    fontWieght: "bold",
  },
  infoContainer: {
    display: "flex",
    flexFlow: "column wrap",
  },
  avatarCardContainer: {
    display: "flex",
    maxHeight: 550,
  },
  avatarCard: {
    borderRadius: 16,
    padding: 24,
    textAlign: "center",
  },
  pointsBalanceCardContainer: {
    display: "flex",
    marginBottom: 80,
  },
  pointsBalanceCard: {
    borderRadius: 16,
    padding: 24,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  pointsCardContainer: {
    display: "flex",
    flexGrow: 1,
    padding: 24,
  },
  pointsCard: {
    flexGrow: 1,
    padding: 24,
    borderRadius: 16,
  },

  header: { fontWeight: "bold", marginBottom: 24 },
  title: {
    color: theme.palette.primaryFont.main,
    fontWeight: "bold",
    marginBottom: 16,
    paddingTop: 8,
  },
  subTitle: {
    fontWeight: "bold",
    marginBottom: 8,
  },
  text: {
    marginBottom: 16,
  },

  productListItem: {
    display: "flex",
    paddingBottom: 36,
    justifyContent: "space-between",
  },

  textLabel: {
    color: theme.palette.secondaryFont.main,
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 24,
    marginTop: 32,
  },

  historyItem: {
    color: theme.palette.primaryFont.main,
    fontSize: 16,
    marginBottom: 8,
  },
  pointsColor: { color: theme.palette.highlight.main },
  accountColumn: {
    width: "fit-content",
    marginRight: 20,
  },
}));

export default function Account() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const matches = useMediaQuery("(min-width:1450px)");
  const mobile = useMediaQuery("(min-width:850px)");

  const { users, userAccount } = useSelector(selectUsers);
  const { claimsList } = useSelector(selectClaims);
  const { ordersList } = useSelector(selectOrders);
  const { pointsHistory } = useSelector(selectUserPoints);

  const [pointsSort, setPointsSort] = useState("date");
  const [pointsSortAsc, setPointsSortAsc] = useState(true);
  const [orderSort, setOrderSort] = useState("date");
  const [orderSortAsc, setOrderSortAsc] = useState(true);
  let jwtToken = localStorage.getItem("clientToken");

  const [uA, setUA] = useState("");

  useEffect(() => {
    if (users.loading === "loaded") {
      setUA(userAccount);
    }
  }, [users.loading]);

  useEffect(() => {
    if (uA.id != undefined) {
      dispatch(
        fetchUserClaims({
          token: jwtToken,
          userID: uA.id,
        })
      );
      dispatch(
        fetchUserPointsHistory({
          token: jwtToken,
          userID: uA.id,
        })
      );
      dispatch(
        fetchUserOrders({
          token: jwtToken,
          userID: uA.id,
        })
      );
      dispatch(
        fetchMyself({
          token: jwtToken,
        })
      );
    }
  }, [dispatch, uA.id]);

  const sortArr = (arr, sortCategory, asc) => {
    let sort = sortCategory;
    let sortedProds = sortBy(arr, [
      function (o) {
        return o[sort];
      },
    ]);
    return asc ? sortedProds : sortedProds.reverse();
  };

  function dateReverse(s) {
    return s.split("-").reverse().join("-");
  }

  const formatPTS = (str) => {
    return str.toLocaleString();
  };

  const handleOrderClick = (e, order) => {
    dispatch(setOrder(order));

    history.push(`/orders/${order.id}`);
  };

  return (
    <div className={classes.accountContainer}>
      <div id="account-background" className={classes.topBackground}>
        <div
          className={classes.userDetailsContainer}
          style={{ flexDirection: mobile ? "row" : "column" }}
        >
          <div className={classes.userDetails}>
            <div style={{ fontWeight: "bold", marginBottom: 24 }}>
              {process.env.REACT_APP_ACC_COMPANY_NAME == "true"
                ? uA.company && uA.company + " Account"
                : uA.displayName && uA.displayName + "'s Account"}
            </div>
            {process.env.REACT_APP_ENABLE_REPUTATION == "true" && (
              <div style={{ fontSize: 24, fontWeight: "bold" }}>
                <div className={classes.pointsText}>
                  Tier Level:{" "}
                  {userAccount.reputationName && userAccount.reputationName}
                </div>
              </div>
            )}
            <div style={{ fontSize: 24, fontWeight: "bold" }}>
              <div className={classes.pointsText}>
                Points Balance:{" "}
                {uA.redeemablePoints ? formatPTS(uA.redeemablePoints) : "0"}
              </div>
            </div>
            <div style={{ fontSize: 24, fontWeight: "bold" }}>
              Member Since:{" "}
              {uA.createdAt &&
                uA.createdAt.substring(0, 10).split("-").reverse().join("/")}
            </div>
          </div>

          <div
            style={{ display: "flex", marginTop: mobile ? 0 : 10 }}
            className={classes.editButtonContainer}
          >
            <div>
              <UpdatePassword />
            </div>
            <Link to={"/account/edit"}>
              <ColorButton>Edit Account Info</ColorButton>
            </Link>
          </div>
        </div>

        <div className={classes.userCardsContainer}>
          <div className={classes.historyCardContainer}>
            <Paper
              elevation={6}
              className={classes.historyCard}
              style={{ width: !matches && "85%" }}
            >
              {/* order history */}
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ fontSize: 40 }} />}
                >
                  <div className={classes.headerRow}>
                    <div
                      style={{ fontSize: matches ? 40 : 24 }}
                      className={classes.cardHeader}
                    >
                      Order History
                    </div>
                    <div className={classes.viewAll}>View All</div>
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    overflowX: matches ? "default" : "scroll",
                  }}
                >
                  <div
                    style={{ display: matches ? "flex" : "none" }}
                    className={classes.cardSortContainer}
                  >
                    {" "}
                    <Dropdown
                      setSortCategory={setOrderSort}
                      setAsc={setOrderSortAsc}
                      placeholder={"Date"}
                    />
                    <Dropdown
                      setSortCategory={setOrderSort}
                      setAsc={setOrderSortAsc}
                      placeholder={"Order #"}
                    />
                    <Dropdown
                      setSortCategory={setOrderSort}
                      setAsc={setOrderSortAsc}
                      placeholder={"Status"}
                    />
                    <Dropdown
                      setSortCategory={setOrderSort}
                      setAsc={setOrderSortAsc}
                      placeholder={"Products"}
                    />
                    <Dropdown
                      setSortCategory={setOrderSort}
                      setAsc={setOrderSortAsc}
                      placeholder={"Total"}
                    />
                  </div>
                  <div className={classes.cardlistContainer}>
                    {!ordersList.length ? (
                      <div className={classes.noList}>
                        No Orders History Available
                      </div>
                    ) : (
                      <div
                        className={classes.productListItem}
                        style={{
                          justifyContent: "space-around",
                          maxHeight: 500,
                          width: !matches && "fit-content",
                        }}
                      >
                        <div className={classes.accountColumn}>
                          <div
                            style={{ fontSize: matches ? 20 : 12 }}
                            className={classes.textLabel}
                          >
                            Date
                          </div>
                          {sortArr(ordersList, orderSort, orderSortAsc).map(
                            (order, index) => (
                              <div
                                style={{
                                  cursor: "pointer",
                                  fontSize: matches ? 16 : 12,
                                  whiteSpace: "nowrap",
                                }}
                                className={classes.historyItem}
                                key={order.id}
                                onClick={(event) =>
                                  handleOrderClick(event, order)
                                }
                              >
                                {order.updatedAt &&
                                  dateReverse(order.updatedAt.split("T")[0])}
                              </div>
                            )
                          )}
                        </div>
                        <div className={classes.accountColumn}>
                          <div
                            style={{
                              fontSize: matches ? 20 : 12,
                              whiteSpace: "nowrap",
                            }}
                            className={classes.textLabel}
                          >
                            Order #
                          </div>
                          {sortArr(ordersList, orderSort, orderSortAsc).map(
                            (order, index) => (
                              <div
                                style={{
                                  cursor: "pointer",
                                  fontSize: matches ? 16 : 12,
                                  whiteSpace: "nowrap",
                                }}
                                className={classes.historyItem}
                                key={order.id}
                                onClick={(event) =>
                                  handleOrderClick(event, order)
                                }
                              >
                                {order.id}
                              </div>
                            )
                          )}
                        </div>
                        <div className={classes.accountColumn}>
                          <div
                            style={{ fontSize: matches ? 20 : 12 }}
                            className={classes.textLabel}
                          >
                            Status
                          </div>
                          {sortArr(ordersList, orderSort, orderSortAsc).map(
                            (order, index) => (
                              <div
                                style={{
                                  cursor: "pointer",
                                  fontSize: matches ? 16 : 12,
                                }}
                                className={classes.historyItem}
                                key={order.id}
                                onClick={(event) =>
                                  handleOrderClick(event, order)
                                }
                              >
                                {order.statusName}
                              </div>
                            )
                          )}
                        </div>
                        <div className={classes.accountColumn}>
                          <div
                            style={{ fontSize: matches ? 20 : 12 }}
                            className={classes.textLabel}
                          >
                            Products
                          </div>
                          {sortArr(ordersList, orderSort, orderSortAsc).map(
                            (order, index) => (
                              <div
                                style={{
                                  cursor: "pointer",
                                  fontSize: matches ? 16 : 12,
                                }}
                                className={classes.historyItem}
                                key={order.id}
                                onClick={(event) =>
                                  handleOrderClick(event, order)
                                }
                              >
                                {order.totalProducts}
                              </div>
                            )
                          )}
                        </div>
                        <div className={classes.accountColumn}>
                          <div
                            style={{ fontSize: matches ? 20 : 12 }}
                            className={classes.textLabel}
                          >
                            Notes
                          </div>
                          {sortArr(ordersList, orderSort, orderSortAsc).map(
                            (order, index) => (
                              <div
                                style={{
                                  cursor: "pointer",
                                  fontSize: matches ? 16 : 12,
                                }}
                                className={classes.historyItem}
                                key={order.id}
                                onClick={(event) =>
                                  handleOrderClick(event, order)
                                }
                              >
                                {order.notes}
                              </div>
                            )
                          )}
                        </div>
                        <div className={classes.accountColumn}>
                          <div
                            style={{ fontSize: matches ? 20 : 12 }}
                            className={classes.textLabel}
                          >
                            Total
                          </div>
                          {sortArr(ordersList, orderSort, orderSortAsc).map(
                            (order, index) => (
                              <div
                                style={{
                                  cursor: "pointer",
                                  fontSize: matches ? 16 : 12,
                                }}
                                className={classes.historyItem}
                                key={order.id}
                                onClick={(event) =>
                                  handleOrderClick(event, order)
                                }
                              >
                                {order.totalPoints}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>

              {/* claims history */}
              {process.env.REACT_APP_ENABLE_CLAIMS == "true" && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ fontSize: 40 }} />}
                  >
                    <div className={classes.headerRow}>
                      <div
                        style={{ fontSize: matches ? 40 : 24 }}
                        className={classes.cardHeader}
                      >
                        Claims History
                      </div>
                      <div className={classes.viewAll}>View All</div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div
                      style={{ display: matches ? "flex" : "none" }}
                      className={classes.cardSortContainer}
                    >
                      <Dropdown
                        setSortCategory={setOrderSort}
                        setAsc={setOrderSortAsc}
                        placeholder={"Date"}
                      />
                      <Dropdown
                        setSortCategory={setOrderSort}
                        setAsc={setOrderSortAsc}
                        placeholder={"Invoice #"}
                      />
                      <Dropdown
                        setSortCategory={setOrderSort}
                        setAsc={setOrderSortAsc}
                        placeholder={"Status"}
                      />
                    </div>
                    <div className={classes.cardlistContainer}>
                      {!claimsList.length ? (
                        <div className={classes.noList}>
                          No Claims History Available
                        </div>
                      ) : (
                        <div
                          className={classes.productListItem}
                          style={{
                            justifyContent: "space-around",
                            maxHeight: 500,
                            width: !matches && "fit-content",
                          }}
                        >
                          <div className={classes.accountColumn}>
                            <div
                              style={{ fontSize: matches ? 20 : 12 }}
                              className={classes.textLabel}
                            >
                              Date
                            </div>
                            {sortArr(claimsList, orderSort, orderSortAsc).map(
                              (claim, index) => (
                                <div
                                  style={{
                                    fontSize: matches ? 16 : 12,
                                    whiteSpace: "nowrap",
                                  }}
                                  className={classes.historyItem}
                                  key={claim.id}
                                >
                                  {claim.updatedAt &&
                                    dateReverse(claim.updatedAt.split("T")[0])}
                                </div>
                              )
                            )}
                          </div>
                          <div className={classes.accountColumn}>
                            <div
                              style={{
                                fontSize: matches ? 20 : 12,
                                whiteSpace: "nowrap",
                              }}
                              className={classes.textLabel}
                            >
                              Claim #
                            </div>
                            {sortArr(claimsList, orderSort, orderSortAsc).map(
                              (claim, index) => (
                                <div
                                  style={{ fontSize: matches ? 16 : 12 }}
                                  className={classes.historyItem}
                                  key={claim.id}
                                >
                                  {claim.id}
                                </div>
                              )
                            )}
                          </div>
                          <div className={classes.accountColumn}>
                            <div
                              style={{ fontSize: matches ? 20 : 12 }}
                              className={classes.textLabel}
                            >
                              Status
                            </div>
                            {sortArr(claimsList, orderSort, orderSortAsc).map(
                              (claim, index) => (
                                <div
                                  style={{ fontSize: matches ? 16 : 12 }}
                                  className={classes.historyItem}
                                  key={claim.id}
                                >
                                  {claim.statusName}
                                </div>
                              )
                            )}
                          </div>
                          <div className={classes.accountColumn}>
                            <div
                              style={{ fontSize: matches ? 20 : 12 }}
                              className={classes.textLabel}
                            >
                              Notes
                            </div>
                            {sortArr(claimsList, orderSort, orderSortAsc).map(
                              (claim, index) => (
                                <div
                                  style={{
                                    fontSize: matches ? 16 : 12,
                                    whiteSpace: "nowrap",
                                  }}
                                  className={classes.historyItem}
                                  key={claim.id}
                                >
                                  {claim.points.notes && claim.points.notes}
                                </div>
                              )
                            )}
                          </div>
                          <div className={classes.accountColumn}>
                            <div
                              style={{
                                fontSize: matches ? 20 : 12,
                                whiteSpace: "nowrap",
                              }}
                              className={classes.textLabel}
                            >
                              Points Total
                            </div>
                            {sortArr(claimsList, orderSort, orderSortAsc).map(
                              (claim, index) => (
                                <div
                                  style={{ fontSize: matches ? 16 : 12 }}
                                  className={classes.historyItem}
                                  key={claim.id}
                                >
                                  {claim.points && claim.points.points}
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </AccordionDetails>
                </Accordion>
              )}
              {pointsHistory && pointsHistory.data && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ fontSize: 40 }} />}
                  >
                    <div className={classes.headerRow}>
                      <div
                        style={{ fontSize: matches ? 40 : 24 }}
                        className={classes.cardHeader}
                      >
                        Points History
                      </div>
                      <div className={classes.viewAll}>View All</div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div
                      style={{ display: matches ? "flex" : "none" }}
                      className={classes.cardSortContainer}
                    >
                      {" "}
                      <Dropdown
                        placeholder={"Date"}
                        setSortCategory={setPointsSort}
                        setAsc={setPointsSortAsc}
                      />
                      <Dropdown
                        placeholder={"Notes"}
                        setSortCategory={setPointsSort}
                        setAsc={setPointsSortAsc}
                      />
                      <Dropdown
                        placeholder={"Points"}
                        setSortCategory={setPointsSort}
                        setAsc={setPointsSortAsc}
                      />
                    </div>
                    <div className={classes.cardlistContainer}>
                      {/* <div className={classes.noList}>No Points History Available</div> */}
                      {!pointsHistory.data.filter(
                        (points) =>
                          points.statusID !== 21 && points.statusID !== 5
                      ).length ? (
                        <div className={classes.noList}>
                          No Points History Available
                        </div>
                      ) : (
                        <div
                          className={classes.productListItem}
                          style={{
                            justifyContent: "space-around",
                            maxHeight: 500,
                            width: !matches && "fit-content",
                          }}
                        >
                          <div className={classes.accountColumn}>
                            <div
                              style={{
                                fontSize: matches ? 20 : 12,
                                minWidth: matches ? 80 : 100,
                              }}
                              className={classes.textLabel}
                            >
                              Date
                            </div>
                            {pointsHistory.data &&
                              sortArr(
                                pointsHistory.data.filter(
                                  (points) =>
                                    points.statusID !== 21 &&
                                    points.statusID !== 5
                                ),
                                // ? pointsHistory.data.filter(
                                //     (point) => point.description !== null
                                //   )
                                // : [],
                                pointsSort,
                                pointsSortAsc
                              ).map((point, index) => (
                                <div
                                  style={{
                                    fontSize: matches ? 16 : 12,
                                    minHeight: 30,
                                  }}
                                  className={classes.historyItem}
                                  key={point.id}
                                >
                                  {point.updatedAt &&
                                    dateReverse(point.updatedAt.split("T")[0])}
                                </div>
                              ))}
                          </div>

                          <div className={classes.accountColumn}>
                            <div
                              style={{
                                fontSize: matches ? 20 : 12,
                                minWidth: matches ? 80 : 100,
                              }}
                              className={classes.textLabel}
                            >
                              Points
                            </div>
                            {pointsHistory.data &&
                              sortArr(
                                pointsHistory.data.filter(
                                  (points) =>
                                    points.statusID !== 21 &&
                                    points.statusID !== 5
                                ),
                                // ? pointsHistory.data.filter(
                                //     (point) => point.description !== null
                                //   )
                                // : [],
                                pointsSort,
                                pointsSortAsc
                              ).map((point, index) => (
                                <div
                                  style={{
                                    fontSize: matches ? 16 : 12,
                                    minHeight: 30,
                                  }}
                                  className={classes.historyItem}
                                  key={point.id}
                                >
                                  {point.statusID === 20 && "-"}
                                  {point.points}
                                </div>
                              ))}
                          </div>
                          <div className={classes.accountColumn}>
                            <div
                              style={{
                                fontSize: matches ? 20 : 12,
                                maxWidth: matches && "70%",
                                minWidth: matches ? 150 : 100,
                              }}
                              className={classes.textLabel}
                            >
                              Notes
                            </div>
                            {pointsHistory.data &&
                              sortArr(
                                pointsHistory.data.filter(
                                  (points) =>
                                    points.statusID !== 21 &&
                                    points.statusID !== 5
                                ),
                                // ? pointsHistory.data.filter(
                                //     (point) => point.description !== null
                                //   )
                                // : [],
                                pointsSort,
                                pointsSortAsc
                              ).map((point, index) => (
                                <div
                                  style={{
                                    fontSize: matches ? 16 : 12,
                                    maxWidth: matches && "100%",
                                    minHeight: 30,
                                    whiteSpace: "nowrap",
                                  }}
                                  className={classes.historyItem}
                                  key={point.id}
                                >
                                  {point.notes === '""' ? null : point.notes}
                                </div>
                              ))}
                          </div>
                          <div className={classes.accountColumn}>
                            <div
                              style={{
                                fontSize: matches ? 20 : 12,
                                maxWidth: matches && "100%",
                                whiteSpace: "nowrap",
                              }}
                              className={classes.textLabel}
                            >
                              Expiry Date
                            </div>
                            {pointsHistory.data &&
                              sortArr(
                                pointsHistory.data
                                  ? pointsHistory.data
                                      .filter(
                                        (points) =>
                                          points.statusID !== 21 &&
                                          points.statusID !== 5
                                      )
                                      .filter((point) => point.status !== null)
                                  : [],
                                pointsSort,
                                pointsSortAsc
                              ).map((point, index) => (
                                <div
                                  style={{
                                    fontSize: matches ? 16 : 12,
                                    maxWidth: matches && "100%",
                                    minHeight: 30,
                                    whiteSpace: "nowrap",
                                  }}
                                  className={classes.historyItem}
                                  key={point.expiryDate}
                                >
                                  {point.expiryDate}
                                </div>
                              ))}
                          </div>
                          <div className={classes.accountColumn}>
                            <div
                              style={{
                                fontSize: matches ? 20 : 12,
                                maxWidth: matches && "100%",
                                whiteSpace: "nowrap",
                              }}
                              className={classes.textLabel}
                            >
                              Order ID
                            </div>
                            {pointsHistory.data &&
                              sortArr(
                                pointsHistory.data
                                  ? pointsHistory.data
                                      .filter(
                                        (points) =>
                                          points.statusID !== 21 &&
                                          points.statusID !== 5
                                      )
                                      .filter((point) => point.status !== null)
                                  : [],
                                pointsSort,
                                pointsSortAsc
                              ).map((point, index) => (
                                <div
                                  style={{
                                    fontSize: matches ? 16 : 12,
                                    maxWidth: matches && "100%",
                                    minHeight: 30,
                                  }}
                                  className={classes.historyItem}
                                  key={point.orderID}
                                >
                                  {
                                    <Link to={`/orders/${point.orderID}`}>
                                      {point.orderID}
                                    </Link>
                                  }
                                </div>
                              ))}
                          </div>
                          <div className={classes.accountColumn}>
                            <div
                              style={{
                                fontSize: matches ? 20 : 12,
                                maxWidth: matches && "70%",
                              }}
                              className={classes.textLabel}
                            >
                              Status
                            </div>
                            {pointsHistory.data &&
                              sortArr(
                                pointsHistory.data
                                  ? pointsHistory.data
                                      .filter(
                                        (points) =>
                                          points.statusID !== 21 &&
                                          points.statusID !== 5
                                      )
                                      .filter((point) => point.status !== null)
                                  : [],
                                pointsSort,
                                pointsSortAsc
                              ).map((point, index) => (
                                <div
                                  style={{
                                    fontSize: matches ? 16 : 12,
                                    maxWidth: matches && "100%",
                                    minHeight: 30,
                                  }}
                                  className={classes.historyItem}
                                  key={point.id}
                                >
                                  {point.statusName.charAt(0).toUpperCase() +
                                    point.statusName.slice(1)}
                                </div>
                              ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </AccordionDetails>
                </Accordion>
              )}
              {process.env.REACT_APP_ENABLE_SALES_REP == "true" && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ fontSize: 40 }} />}
                  >
                    <div className={classes.headerRow}>
                      <div
                        style={{ fontSize: matches ? 40 : 24 }}
                        className={classes.cardHeader}
                      >
                        Sales Representative Contact Details
                      </div>
                      <div className={classes.viewAll}>View All</div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{ display: "flex", flexDirection: "column" }}
                    className={classes.textLabel}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: 10,
                      }}
                    >
                      <div>Name: </div>
                      <div style={{ marginLeft: 10 }}>
                        {userAccount.managerName
                          ? userAccount.managerName
                          : "N/A"}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: 10,
                      }}
                    >
                      <div>Email: </div>
                      <div style={{ marginLeft: 10 }}>
                        {userAccount.managerEmail
                          ? userAccount.managerEmail
                          : "N/A"}
                      </div>
                    </div>
                    {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: 10,
                    }}
                  >
                    <div>Phone: </div>
                    <div style={{ marginLeft: 10 }}>
                      {userAccount.managerPhone
                        ? userAccount.managerPhone
                        : "N/A"}
                    </div>
                  </div> */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: 10,
                      }}
                    >
                      <div>Mobile: </div>
                      <div style={{ marginLeft: 10 }}>
                        {userAccount.managerMobile
                          ? userAccount.managerMobile
                          : "N/A"}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              )}
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
}
