import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import { errorSnack } from '../snackbar/snackbarSlice';

import axios from 'axios';

import { uuidv4 } from '../../app/utils';
import baseURL from '../../app/utils.js';
axios.interceptors.request.use(
   (req) => {
      req.headers.common['X-Request-ID'] = uuidv4();
      return req;
   },
   (err) => {
      return Promise.reject(err);
   }
);

export const fetchPointsLeaderboardByProgram = createAsyncThunk(
  'leaderboards/fetchPointsLeaderboardByProgram',
  async (args, thunkAPI) => {
    const config = {
      headers: {Authorization: `Bearer ${args.token}`},
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/admin/programs/${args.programID}/points/leaderboards/pointsbased`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.message.includes('401')) {
        localStorage.removeItem('clientUser');
        localStorage.removeItem('clientToken');
      }
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              'Error fetching leaderboard list'
          )
        );
      }
      return thunkAPI.rejectWithValue({error: error.message});
    }
  }
);

export const fetchPointsLeaderboardByClient = createAsyncThunk(
  'leaderboards/fetchPointsLeaderboardByClient',
  async (args, thunkAPI) => {
    const config = {
      headers: {Authorization: `Bearer ${args.token}`},
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/admin/clients/${args.clientID}/points/leaderboards/pointsbased`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.message.includes('401')) {
        localStorage.removeItem('clientUser');
        localStorage.removeItem('clientToken');
      }
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              'Error fetching leaderboard list'
          )
        );
      }
      return thunkAPI.rejectWithValue({error: error.message});
    }
  }
);

export const fetchPointsLeaderboardByStore = createAsyncThunk(
  'leaderboards/fetchPointsLeaderboardByStore',
  async (args, thunkAPI) => {
    const config = {
      headers: {Authorization: `Bearer ${args.token}`},
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/admin/clients/${args.clientID}/points/leaderboards/pointsbased?store_id=${args.storeID}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.message.includes('401')) {
        localStorage.removeItem('clientUser');
        localStorage.removeItem('clientToken');
      }
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              'Error fetching leaderboard list'
          )
        );
      }
      return thunkAPI.rejectWithValue({error: error.message});
    }
  }
);

export const fetchReputationLeaderboardByProgram = createAsyncThunk(
  'leaderboards/fetchReputationLeaderboardByProgram',
  async (args, thunkAPI) => {
    const config = {
      headers: {Authorization: `Bearer ${args.token}`},
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/admin/programs/${args.programID}/points/leaderboards/repbased`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.message.includes('401')) {
        localStorage.removeItem('clientUser');
        localStorage.removeItem('clientToken');
      }
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              'Error fetching leaderboard list'
          )
        );
      }
      return thunkAPI.rejectWithValue({error: error.message});
    }
  }
);

export const fetchReputationLeaderboardByClient = createAsyncThunk(
  'leaderboards/fetchReputationLeaderboardByClient',
  async (args, thunkAPI) => {
    const config = {
      headers: {Authorization: `Bearer ${args.token}`},
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/admin/clients/${args.clientID}/points/leaderboards/repbased`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.message.includes('401')) {
        localStorage.removeItem('clientUser');
        localStorage.removeItem('clientToken');
      }
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              'Error fetching leaderboard list'
          )
        );
      }
      return thunkAPI.rejectWithValue({error: error.message});
    }
  }
);

export const fetchKPILeaderboardByProgram = createAsyncThunk(
  'leaderboards/fetchKPILeaderboardByProgram',
  async (args, thunkAPI) => {
    const config = {
      headers: {Authorization: `Bearer ${args.token}`},
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/admin/programs/${args.programID}/points/leaderboards/kpibased`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.message.includes('401')) {
        localStorage.removeItem('clientUser');
        localStorage.removeItem('clientToken');
      }
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              'Error fetching leaderboard list'
          )
        );
      }
      return thunkAPI.rejectWithValue({error: error.message});
    }
  }
);

export const fetchKPILeaderboardByClient = createAsyncThunk(
  'leaderboards/fetchKPILeaderboardByClient',
  async (args, thunkAPI) => {
    const config = {
      headers: {Authorization: `Bearer ${args.token}`},
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/admin/clients/${args.clientID}/points/leaderboards/kpibased`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.message.includes('401')) {
        localStorage.removeItem('clientUser');
        localStorage.removeItem('clientToken');
      }
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              'Error fetching leaderboard list'
          )
        );
      }
      return thunkAPI.rejectWithValue({error: error.message});
    }
  }
);

const leaderboardSlice = createSlice({
  name: 'leaderboards',
  initialState: {
    clientLeaderboard: [],
    programLeaderboard: [],
    loading: 'idle',
    message: '',
    error: '',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPointsLeaderboardByProgram.pending, (state) => {
      state.programLeaderboard = [];
      state.loading = 'loading';
    });
    builder.addCase(
      fetchPointsLeaderboardByProgram.fulfilled,
      (state, {payload}) => {
        state.programLeaderboard = payload;
        state.loading = 'loaded';
      }
    );
    builder.addCase(
      fetchPointsLeaderboardByProgram.rejected,
      (state, action) => {
        state.loading = 'error';
        state.error = action.error.message;
      }
    );
    builder.addCase(fetchPointsLeaderboardByClient.pending, (state) => {
      state.clientLeaderboard = [];
      state.loading = 'loading';
    });
    builder.addCase(
      fetchPointsLeaderboardByClient.fulfilled,
      (state, {payload}) => {
        state.clientLeaderboard = payload;
        state.loading = 'loaded';
      }
    );
    builder.addCase(
      fetchPointsLeaderboardByClient.rejected,
      (state, action) => {
        state.loading = 'error';
        state.error = action.error.message;
      }
    );
    builder.addCase(fetchPointsLeaderboardByStore.pending, (state) => {
      state.clientLeaderboard = [];
      state.loading = 'loading';
    });
    builder.addCase(
      fetchPointsLeaderboardByStore.fulfilled,
      (state, {payload}) => {
        state.clientLeaderboard = payload;
        state.loading = 'loaded';
      }
    );
    builder.addCase(
      fetchPointsLeaderboardByStore.rejected,
      (state, action) => {
        state.loading = 'error';
        state.error = action.error.message;
      }
    );

    builder.addCase(fetchReputationLeaderboardByProgram.pending, (state) => {
      state.programLeaderboard = [];
      state.loading = 'loading';
    });
    builder.addCase(
      fetchReputationLeaderboardByProgram.fulfilled,
      (state, {payload}) => {
        state.programLeaderboard = payload;
        state.loading = 'loaded';
      }
    );
    builder.addCase(
      fetchReputationLeaderboardByProgram.rejected,
      (state, action) => {
        state.loading = 'error';
        state.error = action.error.message;
      }
    );
    builder.addCase(fetchReputationLeaderboardByClient.pending, (state) => {
      state.clientLeaderboard = [];
      state.loading = 'loading';
    });
    builder.addCase(
      fetchReputationLeaderboardByClient.fulfilled,
      (state, {payload}) => {
        state.clientLeaderboard = payload;
        state.loading = 'loaded';
      }
    );
    builder.addCase(
      fetchReputationLeaderboardByClient.rejected,
      (state, action) => {
        state.loading = 'error';
        state.error = action.error.message;
      }
    );

    builder.addCase(fetchKPILeaderboardByProgram.pending, (state) => {
      state.programLeaderboard = [];
      state.loading = 'loading';
    });
    builder.addCase(
      fetchKPILeaderboardByProgram.fulfilled,
      (state, {payload}) => {
        state.programLeaderboard = payload;
        state.loading = 'loaded';
      }
    );
    builder.addCase(fetchKPILeaderboardByProgram.rejected, (state, action) => {
      state.loading = 'error';
      state.error = action.error.message;
    });
    builder.addCase(fetchKPILeaderboardByClient.pending, (state) => {
      state.clientLeaderboard = [];
      state.loading = 'loading';
    });
    builder.addCase(
      fetchKPILeaderboardByClient.fulfilled,
      (state, {payload}) => {
        state.clientLeaderboard = payload;
        state.loading = 'loaded';
      }
    );
    builder.addCase(fetchKPILeaderboardByClient.rejected, (state, action) => {
      state.loading = 'error';
      state.error = action.error.message;
    });
  },
});

export const selectLeaderboard = createSelector(
  (state) => ({
    leaderboards: state.leaderboards,
    loading: state.leaderboards.loading,
    error: state.leaderboards.error,
    programLeaderboard: state.leaderboards.programLeaderboard,
    clientLeaderboard: state.leaderboards.clientLeaderboard,
  }),
  (state) => state
);
export default leaderboardSlice.reducer;
