import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

import { errorSnack, successSnack } from "../snackbar/snackbarSlice";

import axios from "axios";

import { uuidv4 } from "../../app/utils";
import baseURL from "../../app/utils.js";
axios.interceptors.request.use(
  (req) => {
    req.headers.common["X-Request-ID"] = uuidv4();
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export const fetchClaims = createAsyncThunk(
  "claims/fetchClaims",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/claims${args.query}`,
        config
      );
      thunkAPI.dispatch(setClaimsAmount(response.headers["x-total-count"]));
      thunkAPI.dispatch(setLastQuery(args.query));

      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching claims list"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchMoreClaims = createAsyncThunk(
  "claims/fetchMoreClaims",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/claims${args.query}&offset=${args.offset}`,
        config
      );
      // thunkAPI.dispatch(setClaimsAmount(response.headers["x-total-count"]));
      return await response.data;
    } catch (error) {
      if (error.message.includes("401")) {
        localStorage.removeItem("clientUser");
        localStorage.removeItem("clientToken");
      }
      if (error.response && error.response.data) {
        // thunkAPI.dispatch(
        //   errorSnack(
        //     error.response.data.error_description ||
        //       'Error fetching products list'
        //   )
        // );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const filterClaims = createAsyncThunk(
  "claims/filterClaims",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/claims${args.query}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching claims list"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchUserClaims = createAsyncThunk(
  "claims/fetchUserClaims",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/admin/users/${args.userID}/claims?limit=100`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error fetching claim data"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchUserClaimByID = createAsyncThunk(
  "claims/fetchUserClaimByID",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/admin/claims/${args.id}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error fetching claim data"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const deleteClaim = createAsyncThunk(
  "claims/deleteClaim",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //full options
      const response = await axios.delete(
        `${baseURL}/v1/admin/claims/${args.id}`,
        config
      );
      thunkAPI.dispatch(successSnack("claim deleted"));
      return await response;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error deleting claim"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const createClaim = createAsyncThunk(
  "claims/createClaim",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //full options
      const response = await axios.post(
        `${baseURL}/v1/admin/users/${args.id}/claims`,
        args.claim,
        config
      );
      thunkAPI.dispatch(
        successSnack(
          `Claim for invoice no: ${args.claim.invoiceNumber} submitted successfully`
        )
      );
      thunkAPI.dispatch(
        fetchUserClaims({
          token: args.token,
          userID: args.id,
        })
      );
      return await response;
    } catch (error) {
      if (error.response && error.response.data) {
        if (
          error.response.data.error_description ==
          "Invalid data. Error: unique violation in invoice_number"
        ) {
          thunkAPI.dispatch(
            errorSnack(
              `A claim with this invoice no: ${args.claim.invoiceNumber} already exists.`
            )
          );
        } else {
          thunkAPI.dispatch(
            errorSnack(
              error.response.data.error_description ||
                `Error creating claim for invoice no: ${args.claim.invoiceNumber}`
            )
          );
        }
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const updateClaim = createAsyncThunk(
  "claims/createClaim",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //full options
      const response = await axios.patch(
        `${baseURL}/v1/admin/users/${args.id}/claims/${args.claimID}`,
        args.claim,
        config
      );
      thunkAPI.dispatch(
        successSnack(
          `Claim for invoice no: ${args.claim.invoiceNumber} submitted successfully`
        )
      );
      thunkAPI.dispatch(
        fetchUserClaims({
          token: args.token,
          userID: args.id,
        })
      );
      return await response;
    } catch (error) {
      if (error.response && error.response.data) {
        if (
          error.response.data.error_description ==
          "Invalid data. Error: unique violation in invoice_number"
        ) {
          thunkAPI.dispatch(
            errorSnack(
              `A claim with this invoice no: ${args.claim.invoiceNumber} already exists.`
            )
          );
        } else {
          thunkAPI.dispatch(
            errorSnack(
              error.response.data.error_description ||
                `Error creating claim for invoice no: ${args.claim.invoiceNumber}`
            )
          );
        }
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const preSignedURLClaims = createAsyncThunk(
  "claims/preSignedURL",
  async (args, thunkAPI) => {
    const config = {
      headers: {
        Authorization: `Bearer ${args.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      const response = await axios.post(
        `${baseURL}/v1/admin/pre_signed_url`,

        {
          objectName: `users/${args.id}/claims/attachments/${args.objectName}`,
          objectType: "asset",
          // objectType: args.objectType,
          objectContentType: args.contentType,
        },
        config
      );
      return new Promise((resolve) => {
        //full options

        if (response.data.preSignedURL) {
          thunkAPI
            .dispatch(
              uploadInvoice({
                token: args.token,
                url: response.data.preSignedURL,
                contentType: args.contentType,
                file: args.file,
              })
            )
            .then(() => {
              resolve();
            });
        }
      });
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error creating pre-signed url for AWS upload"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const uploadInvoice = createAsyncThunk(
  "claims/uploadInvoice",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: {
          // Authorization: `Bearer ${args.token}`,
          "Content-Type": args.contentType,
          "Access-Control-Allow-Origin": "*",
        },
        //
      };
      const response = await axios.put(`${args.url}`, args.file, config);
      if (response.status === 200) {
      }
      // thunkAPI.dispatch(successSnack(`File uploaded to s3`));

      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error uploading image to AWS"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getFullList = createAsyncThunk(
  "claims/getFullList",
  async (args, thunkAPI) => {
    let objToMap = [];
    for (let i = 0; i < (args.targetLength - args.offset) / 100; i++) {
      objToMap.push({
        token: args.token,
        query: args.query,
        offset: args.offset + 100 * i,
      });
    }

    Promise.all(
      objToMap.map((requestData) =>
        thunkAPI.dispatch(fetchMoreClaims(requestData))
      )
    )
      .then((results) => {})
      .catch((err) => {
        return thunkAPI.rejectWithValue("Error fetching full claims list");
      });
  }
);

const claimsSlice = createSlice({
  name: "claims",
  initialState: {
    claim: {},
    claimsList: [],
    loading: "idle",
    invoiceLoading: false,
    invoiceError: false,
    message: "",
    error: "",
    claimsAmount: "",
    lastQuery: "",
  },
  reducers: {
    setClaim(state, action) {
      state.claim = action.payload;
    },
    setLastQuery: (state, action) => {
      state.lastQuery = action.payload;
    },
    setClaimsAmount: (state, action) => {
      state.claimsAmount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClaims.pending, (state) => {
      state.claimsList = [];
      state.claimsFilter = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(fetchClaims.fulfilled, (state, { payload }) => {
      state.claimsList = payload;
      state.claimsFilter = payload;
      state.loading = "loaded";
    });
    builder.addCase(fetchClaims.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchMoreClaims.fulfilled, (state, { payload }) => {
      state.error = "";
      state.claimsList = state.claimsList.concat(payload);
      state.loading = "loaded";
    });
    builder.addCase(fetchMoreClaims.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(getFullList.fulfilled, (state, { payload }) => {
      state.error = "";
      state.claimsList = state.claimsList.sort(function (a, b) {
        return a.id - b.id;
      });
      state.loading = "loaded";
    });
    builder.addCase(getFullList.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchUserClaims.pending, (state) => {
      state.claimsList = [];
      state.loading = "loading";
    });
    builder.addCase(fetchUserClaims.fulfilled, (state, { payload }) => {
      state.claimsList = payload;
      state.loading = "loaded";
    });
    builder.addCase(fetchUserClaimByID.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchUserClaimByID.pending, (state) => {
      state.claim = {};
      state.loading = "loading";
    });
    builder.addCase(fetchUserClaimByID.fulfilled, (state, { payload }) => {
      state.claim = payload;
      state.loading = "loaded";
    });
    builder.addCase(fetchUserClaims.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(deleteClaim.pending, (state) => {
      state.message = "";
      state.loading = "loading";
    });
    builder.addCase(deleteClaim.fulfilled, (state, { payload }) => {
      // state.message = payload;
      state.loading = "loaded";
    });
    builder.addCase(deleteClaim.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(createClaim.pending, (state) => {
      state.message = "";
      state.loading = "loading";
    });
    builder.addCase(createClaim.fulfilled, (state, { payload }) => {
      // state.message = payload;
      state.loading = "loaded";
    });
    builder.addCase(createClaim.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(preSignedURLClaims.pending, (state) => {
      state.message = "";
      state.invoiceError = false;
      state.loading = true;
    });
    builder.addCase(preSignedURLClaims.fulfilled, (state, { payload }) => {
      // state.message = payload;
    });
    builder.addCase(preSignedURLClaims.rejected, (state, action) => {
      state.invoiceLoading = false;
      state.invoiceError = true;
    });
    builder.addCase(uploadInvoice.pending, (state) => {
      state.invoiceError = false;
      state.invoiceLoading = true;
    });
    builder.addCase(uploadInvoice.fulfilled, (state, { payload }) => {
      // state.message = payload;
      state.invoiceLoading = false;
    });
    builder.addCase(uploadInvoice.rejected, (state, action) => {
      state.invoiceLoading = false;
      state.invoiceError = true;
      state.error = action.error.message;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setClaim, setLastQuery, setClaimsAmount } = claimsSlice.actions;

export const selectClaims = createSelector(
  (state) => ({
    claims: state.claims,
    claimsList: state.claims.claimsList,
    loading: state.claims.loading,
    error: state.claims.error,
    claim: state.claims.claim,
    invoiceLoading: state.claims.invoiceLoading,
    invoiceError: state.claims.invoiceError,
    claimsAmount: state.claims.claimsAmount,
    lastQuery: state.claims.lastQuery,
  }),
  (state) => state
);

export default claimsSlice.reducer;
