import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import { errorSnack } from '../snackbar/snackbarSlice';

import axios from 'axios';
import { uuidv4 } from '../../app/utils';
import baseURL from '../../app/utils.js';
axios.interceptors.request.use(
   (req) => {
      req.headers.common['X-Request-ID'] = uuidv4();
      return req;
   },
   (err) => {
      return Promise.reject(err);
   }
);

export const fetchBrands = createAsyncThunk(
  'brands/fetchBrands',
  async (args, thunkAPI) => {
    const config = {
      headers: {Authorization: `Bearer ${args.token}`},
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/admin/core/brands${args.query}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.message.includes('401')) {
        localStorage.removeItem('clientUser');
        localStorage.removeItem('clientToken');
      }
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              'Error fetching brands list'
          )
        );
      }
      return thunkAPI.rejectWithValue({error: error.message});
    }
  }
);

export const filterBrands = createAsyncThunk(
  'brands/filterBrands',
  async (args, thunkAPI) => {
    const config = {
      headers: {Authorization: `Bearer ${args.token}`},
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/admin/core/brands${args.query}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.message.includes('401')) {
        localStorage.removeItem('clientUser');
        localStorage.removeItem('clientToken');
      }
      return thunkAPI.rejectWithValue({error: error.message});
    }
  }
);

export const fetchBrandByID = createAsyncThunk(
  'brands/fetchBrandByID',
  async (args, thunkAPI) => {
    const config = {
      headers: {Authorization: `Bearer ${args.token}`},
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/admin/core/brands/${args.id}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.message.includes('401')) {
        localStorage.removeItem('clientUser');
        localStorage.removeItem('clientToken');
      }
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              'Error fetching brands data'
          )
        );
      }
      return thunkAPI.rejectWithValue({error: error.message});
    }
  }
);

const brandsSlice = createSlice({
  name: 'brands',
  initialState: {
    brand: {},
    brandsList: [],
    brandsFilter: [],
    loading: 'idle',
    message: '',
    error: '',
    editing: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBrands.pending, (state) => {
      state.brandsList = [];
      state.loading = 'loading';
      state.editing = false;
    });
    builder.addCase(fetchBrands.fulfilled, (state, {payload}) => {
      state.brandsList = payload;
      state.brandsFilter = payload;
      state.loading = 'loaded';
      state.editing = false;
    });
    builder.addCase(fetchBrands.rejected, (state, action) => {
      state.loading = 'error';
      state.error = action.error.message;
    });
    builder.addCase(filterBrands.pending, (state) => {
      state.brandsFilter = [];
      state.loading = 'loading';
      state.editing = false;
    });
    builder.addCase(filterBrands.fulfilled, (state, {payload}) => {
      state.brandsFilter = payload;
      state.loading = 'loaded';
    });
    builder.addCase(filterBrands.rejected, (state, action) => {
      state.loading = 'error';
      state.error = action.error.message;
    });
    builder.addCase(fetchBrandByID.pending, (state) => {
      state.brand = {};
      state.loading = 'loading';
      state.editing = false;
    });
    builder.addCase(fetchBrandByID.fulfilled, (state, {payload}) => {
      state.brand = payload;
      state.loading = 'loaded';
      state.editing = true;
    });
    builder.addCase(fetchBrandByID.rejected, (state, action) => {
      state.loading = 'error';
      state.error = action.error.message;
    });
  },
});

export const selectBrands = createSelector(
  (state) => ({
    brands: state.brands,
    loading: state.brands.loading,
    error: state.brands.error,
    editing: state.brands.editing,
    brand: state.brands.brand,
    brandsList: state.brands.brandsList,
  }),
  (state) => state
);
export default brandsSlice.reducer;
