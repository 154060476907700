import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

import { errorSnack } from "../snackbar/snackbarSlice";

import axios from "axios";

import { uuidv4 } from "../../app/utils";
import baseURL from "../../app/utils.js";
axios.interceptors.request.use(
  (req) => {
    req.headers.common["X-Request-ID"] = uuidv4();
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export const fetchPointsRedeemed = createAsyncThunk(
  "reports/fetchPointsRedeemed",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/reports/points_redeemed${args.query}`,
        config
      );
      thunkAPI.dispatch(setReportsAmount(response.headers["x-total-count"]));
      thunkAPI.dispatch(setLastQuery(args.query));
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching Stores list"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchMorePointsRedeemed = createAsyncThunk(
  "points/fetchMorePointsRedeemed",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/reports/points_redeemed${args.query}&offset=${args.offset}`,
        config
      );
      // thunkAPI.dispatch(setClaimsAmount(response.headers["x-total-count"]));
      return await response.data;
    } catch (error) {
      if (error.message.includes("401")) {
        localStorage.removeItem("clientUser");
        localStorage.removeItem("clientToken");
      }
      if (error.response && error.response.data) {
        // thunkAPI.dispatch(
        //   errorSnack(
        //     error.response.data.error_description ||
        //       'Error fetching products list'
        //   )
        // );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchPointsBalance = createAsyncThunk(
  "reports/fetchPointsBalance",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/reports/points_balance`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching Stores list"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchPointsRedeemedAnnually = createAsyncThunk(
  "reports/fetchPointsRedeemedAnnually",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/reports/points_redeemed_annually?ryear=${args.year}`,
        config
      );
      const response2 = await axios.get(
        `${baseURL}/v1/admin/reports/points_redeemed_annually?ryear=${
          args.year - 1
        }`,
        config
      );
      let payload = {
        currentYear: await response.data,
        compareYear: await response2.data,
      };
      return payload;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching points redeemed data"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchPointsRedeemedMonthly = createAsyncThunk(
  "reports/fetchPointsRedeemedMonthly",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/reports/points_redeemed_monthly?rmonth=${args.month}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching points redeemed data"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchPointsEarnedAnnually = createAsyncThunk(
  "reports/fetchPointsEarnedAnnually",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/reports/points_earned_annually?ryear=${args.year}`,
        config
      );
      const response2 = await axios.get(
        `${baseURL}/v1/admin/reports/points_earned_annually?ryear=${
          args.year - 1
        }`,
        config
      );
      let payload = {
        currentYear: await response.data,
        compareYear: await response2.data,
      };
      return payload;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching points earned data"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchPointsEarnedMonthly = createAsyncThunk(
  "reports/fetchPointsEarnedMonthly",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/reports/points_earned_monthly?rmonth=${args.month}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching points earned data"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchProductWishlistDaily = createAsyncThunk(
  "reports/fetchProductWishlistDaily",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/reports/product_wishlist_daily?rdate=${args.date}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching points earned data"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchProductWishlistMonthly = createAsyncThunk(
  "reports/fetchProductWishlistMonthly",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/reports/product_wishlist_monthly?rmonth=${args.date}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching points earned data"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchProductWishlistAnnually = createAsyncThunk(
  "reports/fetchProductWishlistAnnually",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/reports/product_wishlist_annually?ryear=${args.date}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching points earned data"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchProductWishlistAllTime = createAsyncThunk(
  "reports/fetchProductWishlistAllTime",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/reports/product_wishlist`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching points earned data"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchBrandRedeemedDaily = createAsyncThunk(
  "reports/fetchBrandRedeemedDaily",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/reports/brand_redeemed_daily?rdate=${args.date}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching points earned data"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchBrandRedeemedMonthly = createAsyncThunk(
  "reports/fetchBrandRedeemedMonthly",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/reports/brand_redeemed_monthly?rmonth=${args.date}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching points earned data"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchBrandRedeemedAnnually = createAsyncThunk(
  "reports/fetchBrandRedeemedAnnually",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/reports/brand_redeemed_annually?ryear=${args.date}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching points earned data"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchBrandRedeemedAllTime = createAsyncThunk(
  "reports/fetchBrandRedeemedAllTime",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/reports/brand_redeemed`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching points earned data"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchMemberRedeemedDaily = createAsyncThunk(
  "reports/fetchMemberRedeemedDaily",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/reports/redeemer_daily?rdate=${args.date}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching points earned data"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchMemberRedeemedMonthly = createAsyncThunk(
  "reports/fetchMemberRedeemedMonthly",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/reports/redeemer_monthly?rmonth=${args.date}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching points earned data"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchMemberRedeemedAnnually = createAsyncThunk(
  "reports/fetchMemberRedeemedAnnually",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/reports/redeemer_annually?ryear=${args.date}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching points earned data"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchMemberRedeemedAllTime = createAsyncThunk(
  "reports/fetchMemberRedeemedAllTime",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/reports/redeemer`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching points earned data"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchMemberLoginDaily = createAsyncThunk(
  "reports/fetchMemberLoginDaily",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/reports/login_daily?rdate=${args.date}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching points earned data"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchMemberLoginMonthly = createAsyncThunk(
  "reports/fetchMemberLoginMonthly",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/reports/login_monthly?rmonth=${args.date}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching points earned data"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchMemberLoginAnnually = createAsyncThunk(
  "reports/fetchMemberLoginAnnually",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/reports/login_annually?ryear=${args.date}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching points earned data"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchMemberLoginAllTime = createAsyncThunk(
  "reports/fetchMemberLoginAllTime",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/reports/user_logins`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching points earned data"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchCategoryRedeemedDaily = createAsyncThunk(
  "reports/fetchCategoryRedeemedDaily",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/reports/category_redeemed_daily?rdate=${args.date}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching points earned data"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchCategoryRedeemedMonthly = createAsyncThunk(
  "reports/fetchCategoryRedeemedMonthly",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/reports/category_redeemed_monthly?rmonth=${args.date}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching points earned data"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchCategoryRedeemedAnnually = createAsyncThunk(
  "reports/fetchCategoryRedeemedAnnually",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/reports/category_redeemed_annually?ryear=${args.date}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching points earned data"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchCategoryRedeemedAllTime = createAsyncThunk(
  "reports/fetchCategoryRedeemedAllTime",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/reports/category_redeemed`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching points earned data"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchProductRedeemedDaily = createAsyncThunk(
  "reports/fetchProductRedeemedDaily",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/reports/product_redeemed_daily?rdate=${args.date}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching points earned data"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchProductRedeemedMonthly = createAsyncThunk(
  "reports/fetchProductRedeemedMonthly",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/reports/product_redeemed_monthly?rmonth=${args.date}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching points earned data"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchProductRedeemedAnnually = createAsyncThunk(
  "reports/fetchProductRedeemedAnnually",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/reports/product_redeemed_annually?ryear=${args.date}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching points earned data"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchProductRedeemedAllTime = createAsyncThunk(
  "reports/fetchProductRedeemedAllTime",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/reports/product_redeemed`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching points earned data"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchUserLoginDayAverage = createAsyncThunk(
  "reports/fetchUserLoginDayAverage",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/reports/login_day`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching points earned data"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchUserLoginTimeAverage = createAsyncThunk(
  "reports/fetchUserLoginTimeAverage",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/reports/login_time`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching points earned data"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchPointsExpired = createAsyncThunk(
  "reports/fetchPointsExpired",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };

      const response = await axios.get(
        `${baseURL}/v1/admin/reports/points_expired${args.query}`,
        config
      );
      thunkAPI.dispatch(setReportsAmount(response.headers["x-total-count"]));
      thunkAPI.dispatch(setLastQuery(args.query));
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching Stores list"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchMorePointsExpired = createAsyncThunk(
  "points/fetchMorePointsExpired",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/reports/points_redeemed${args.query}&offset=${args.offset}`,
        config
      );
      // thunkAPI.dispatch(setClaimsAmount(response.headers["x-total-count"]));
      return await response.data;
    } catch (error) {
      if (error.message.includes("401")) {
        localStorage.removeItem("clientUser");
        localStorage.removeItem("clientToken");
      }
      if (error.response && error.response.data) {
        // thunkAPI.dispatch(
        //   errorSnack(
        //     error.response.data.error_description ||
        //       'Error fetching products list'
        //   )
        // );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchSnapshot = createAsyncThunk(
  "reports/fetchSnapshot",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };

      const response = await axios.get(
        `${baseURL}/v1/admin/reports/points_snapshot`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching Stores list"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getFullListRedeemed = createAsyncThunk(
  "claims/getFullListRedeemed",
  async (args, thunkAPI) => {
    let objToMap = [];
    for (let i = 0; i < (args.targetLength - args.offset) / 100; i++) {
      objToMap.push({
        token: args.token,
        query: args.query,
        offset: args.offset + 100 * i,
      });
    }

    Promise.all(
      objToMap.map((requestData) =>
        thunkAPI.dispatch(fetchMorePointsRedeemed(requestData))
      )
    )
      .then((results) => {})
      .catch((err) => {
        return thunkAPI.rejectWithValue("Error fetching full claims list");
      });
  }
);

export const getFullListExpired = createAsyncThunk(
  "claims/getFullListExpired",
  async (args, thunkAPI) => {
    let objToMap = [];
    for (let i = 0; i < (args.targetLength - args.offset) / 100; i++) {
      objToMap.push({
        token: args.token,
        query: args.query,
        offset: args.offset + 100 * i,
      });
    }

    Promise.all(
      objToMap.map((requestData) =>
        thunkAPI.dispatch(fetchMorePointsExpired(requestData))
      )
    )
      .then((results) => {})
      .catch((err) => {
        return thunkAPI.rejectWithValue("Error fetching full claims list");
      });
  }
);

export const fetchMembersByProductClaimed = createAsyncThunk(
  "reports/fetchMembersByProductClaimed",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };

      const response = await axios.get(
        `${baseURL}/v1/admin/users?roles=99&offset=${args.offset}&limit=${args.limit}&claim_product=${args.claimProduct}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching members by claimed product list"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const reportsSlice = createSlice({
  name: "reports",
  initialState: {
    reportList: [],
    redeemedReportList: {
      daily: [],
      monthly: [],
      annually: [],
      annuallyCompare: [],
      allTime: [],
    },
    earnedReportList: {
      daily: [],
      monthly: [],
      annually: [],
      annuallyCompare: [],
      allTime: [],
    },
    userLoginList: {
      daily: [],
      hourly: [],
    },
    productRedeemedList: {
      daily: [],
      monthly: [],
      annually: [],
      allTime: [],
    },
    brandRedeemedList: {
      daily: [],
      monthly: [],
      annually: [],
      allTime: [],
    },
    categoryRedeemedList: {
      daily: [],
      monthly: [],
      annually: [],
      allTime: [],
    },
    productWishlistList: {
      daily: [],
      monthly: [],
      annually: [],
      allTime: [],
    },
    memberRedeemedList: {
      daily: [],
      monthly: [],
      annually: [],
      allTime: [],
    },
    memberLoginList: {
      daily: [],
      monthly: [],
      annually: [],
      allTime: [],
    },
    membersByClaimedProduct: [],
    pointsBalance: [],
    snapshot: {},
    loading: "idle",
    memberLoading: true,
    message: "",
    error: "",
    reportsAmount: "",
    lastQuery: "",
  },
  reducers: {
    setLastQuery: (state, action) => {
      state.lastQuery = action.payload;
    },
    setReportsAmount: (state, action) => {
      state.reportsAmount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPointsBalance.pending, (state) => {
      state.pointsBalance = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(fetchPointsBalance.fulfilled, (state, { payload }) => {
      state.pointsBalance = payload;
      state.loading = "loaded";
    });
    builder.addCase(fetchPointsBalance.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error;
    });
    builder.addCase(fetchPointsRedeemed.pending, (state) => {
      state.pointsBalance = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(fetchPointsRedeemed.fulfilled, (state, { payload }) => {
      state.reportList = payload;
      state.loading = "loaded";
    });
    builder.addCase(fetchPointsRedeemed.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error;
    });
    builder.addCase(fetchMorePointsRedeemed.fulfilled, (state, { payload }) => {
      state.error = "";
      state.reportList = state.reportList.concat(payload);
      state.loading = "loaded";
    });
    builder.addCase(fetchMorePointsRedeemed.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(getFullListRedeemed.fulfilled, (state, { payload }) => {
      state.error = "";
      state.reportList = state.reportList.sort(function (a, b) {
        return a.id - b.id;
      });
      state.loading = "loaded";
    });
    builder.addCase(getFullListRedeemed.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(getFullListExpired.fulfilled, (state, { payload }) => {
      state.error = "";
      state.reportList = state.reportList.sort(function (a, b) {
        return a.id - b.id;
      });
      state.loading = "loaded";
    });
    builder.addCase(getFullListExpired.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchMorePointsExpired.fulfilled, (state, { payload }) => {
      state.error = "";
      state.reportList = state.reportList.concat(payload);
      state.loading = "loaded";
    });
    builder.addCase(fetchMorePointsExpired.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchPointsRedeemedMonthly.pending, (state) => {
      // state.redeemedReportList.monthly = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(
      fetchPointsRedeemedMonthly.fulfilled,
      (state, { payload }) => {
        state.redeemedReportList.monthly = state.redeemedReportList.monthly.concat(payload);
        state.loading = "loaded";
      }
    );
    builder.addCase(fetchPointsRedeemedMonthly.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error;
    });
    builder.addCase(fetchPointsRedeemedAnnually.pending, (state) => {
      state.redeemedReportList.annually = [];
      state.redeemedReportList.annuallyCompare = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(
      fetchPointsRedeemedAnnually.fulfilled,
      (state, { payload }) => {
        state.redeemedReportList.annually = payload.currentYear;
        state.redeemedReportList.annuallyCompare = payload.compareYear;
        state.loading = "loaded";
      }
    );
    builder.addCase(fetchPointsRedeemedAnnually.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error;
    });
    builder.addCase(fetchPointsEarnedMonthly.pending, (state) => {
      // state.earnedReportList.monthly = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(
      fetchPointsEarnedMonthly.fulfilled,
      (state, { payload }) => {
        state.earnedReportList.monthly = state.earnedReportList.monthly.concat(payload);
        state.loading = "loaded";
      }
    );
    builder.addCase(fetchPointsEarnedMonthly.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error;
    });
    builder.addCase(fetchPointsEarnedAnnually.pending, (state) => {
      state.earnedReportList.annually = [];
      state.earnedReportList.annuallyCompare = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(
      fetchPointsEarnedAnnually.fulfilled,
      (state, { payload }) => {
        state.earnedReportList.annually = payload.currentYear;
        state.earnedReportList.annuallyCompare = payload.compareYear;
        state.loading = "loaded";
      }
    );
    builder.addCase(fetchPointsEarnedAnnually.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error;
    });
    builder.addCase(fetchUserLoginDayAverage.pending, (state) => {
      state.userLoginList.daily = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(
      fetchUserLoginDayAverage.fulfilled,
      (state, { payload }) => {
        state.userLoginList.daily = payload;
        state.loading = "loaded";
      }
    );
    builder.addCase(fetchUserLoginDayAverage.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error;
    });
    builder.addCase(fetchUserLoginTimeAverage.pending, (state) => {
      state.userLoginList.hourly = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(
      fetchUserLoginTimeAverage.fulfilled,
      (state, { payload }) => {
        state.userLoginList.hourly = payload;
        state.loading = "loaded";
      }
    );
    builder.addCase(fetchUserLoginTimeAverage.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error;
    });
    builder.addCase(fetchMemberRedeemedDaily.pending, (state) => {
      state.memberRedeemedList.daily = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(
      fetchMemberRedeemedDaily.fulfilled,
      (state, { payload }) => {
        state.memberRedeemedList.daily = payload;
        state.loading = "loaded";
      }
    );
    builder.addCase(fetchMemberRedeemedDaily.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error;
    });
    builder.addCase(fetchMemberRedeemedMonthly.pending, (state) => {
      state.memberRedeemedList.monthly = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(
      fetchMemberRedeemedMonthly.fulfilled,
      (state, { payload }) => {
        state.memberRedeemedList.monthly = payload;
        state.loading = "loaded";
      }
    );
    builder.addCase(fetchMemberRedeemedMonthly.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error;
    });
    builder.addCase(fetchMemberRedeemedAnnually.pending, (state) => {
      state.memberRedeemedList.annually = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(
      fetchMemberRedeemedAnnually.fulfilled,
      (state, { payload }) => {
        state.memberRedeemedList.annually = payload;
        state.loading = "loaded";
      }
    );
    builder.addCase(fetchMemberRedeemedAnnually.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error;
    });
    builder.addCase(fetchMemberRedeemedAllTime.pending, (state) => {
      state.memberRedeemedList.allTime = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(
      fetchMemberRedeemedAllTime.fulfilled,
      (state, { payload }) => {
        state.memberRedeemedList.allTime = payload;
        state.loading = "loaded";
      }
    );
    builder.addCase(fetchMemberRedeemedAllTime.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error;
    });
    builder.addCase(fetchMemberLoginDaily.pending, (state) => {
      state.memberLoginList.daily = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(fetchMemberLoginDaily.fulfilled, (state, { payload }) => {
      state.memberLoginList.daily = payload;
      state.loading = "loaded";
    });
    builder.addCase(fetchMemberLoginDaily.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error;
    });
    builder.addCase(fetchMemberLoginMonthly.pending, (state) => {
      state.memberLoginList.monthly = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(fetchMemberLoginMonthly.fulfilled, (state, { payload }) => {
      state.memberLoginList.monthly = payload;
      state.loading = "loaded";
    });
    builder.addCase(fetchMemberLoginMonthly.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error;
    });
    builder.addCase(fetchMemberLoginAnnually.pending, (state) => {
      state.memberLoginList.annually = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(
      fetchMemberLoginAnnually.fulfilled,
      (state, { payload }) => {
        state.memberLoginList.annually = payload;
        state.loading = "loaded";
      }
    );
    builder.addCase(fetchMemberLoginAnnually.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error;
    });
    builder.addCase(fetchMemberLoginAllTime.pending, (state) => {
      state.memberLoginList.allTime = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(fetchMemberLoginAllTime.fulfilled, (state, { payload }) => {
      state.memberLoginList.allTime = payload;
      state.loading = "loaded";
      state.memberLoading = false;
    });
    builder.addCase(fetchMemberLoginAllTime.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error;
    });
    builder.addCase(fetchBrandRedeemedDaily.pending, (state) => {
      state.brandRedeemedList.daily = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(fetchBrandRedeemedDaily.fulfilled, (state, { payload }) => {
      state.brandRedeemedList.daily = payload;
      state.loading = "loaded";
    });
    builder.addCase(fetchBrandRedeemedDaily.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error;
    });
    builder.addCase(fetchBrandRedeemedMonthly.pending, (state) => {
      state.brandRedeemedList.monthly = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(
      fetchBrandRedeemedMonthly.fulfilled,
      (state, { payload }) => {
        state.brandRedeemedList.monthly = payload;
        state.loading = "loaded";
      }
    );
    builder.addCase(fetchBrandRedeemedMonthly.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error;
    });
    builder.addCase(fetchBrandRedeemedAnnually.pending, (state) => {
      state.brandRedeemedList.annually = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(
      fetchBrandRedeemedAnnually.fulfilled,
      (state, { payload }) => {
        state.brandRedeemedList.annually = payload;
        state.loading = "loaded";
      }
    );
    builder.addCase(fetchBrandRedeemedAnnually.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error;
    });
    builder.addCase(fetchBrandRedeemedAllTime.pending, (state) => {
      state.brandRedeemedList.allTime = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(
      fetchBrandRedeemedAllTime.fulfilled,
      (state, { payload }) => {
        state.brandRedeemedList.allTime = payload;
        state.loading = "loaded";
      }
    );
    builder.addCase(fetchBrandRedeemedAllTime.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error;
    });
    builder.addCase(fetchCategoryRedeemedDaily.pending, (state) => {
      state.categoryRedeemedList.daily = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(
      fetchCategoryRedeemedDaily.fulfilled,
      (state, { payload }) => {
        state.categoryRedeemedList.daily = payload;
        state.loading = "loaded";
      }
    );
    builder.addCase(fetchCategoryRedeemedDaily.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error;
    });
    builder.addCase(fetchCategoryRedeemedMonthly.pending, (state) => {
      state.categoryRedeemedList.monthly = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(
      fetchCategoryRedeemedMonthly.fulfilled,
      (state, { payload }) => {
        state.categoryRedeemedList.monthly = payload;
        state.loading = "loaded";
      }
    );
    builder.addCase(fetchCategoryRedeemedMonthly.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error;
    });
    builder.addCase(fetchCategoryRedeemedAnnually.pending, (state) => {
      state.categoryRedeemedList.annually = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(
      fetchCategoryRedeemedAnnually.fulfilled,
      (state, { payload }) => {
        state.categoryRedeemedList.annually = payload;
        state.loading = "loaded";
      }
    );
    builder.addCase(fetchCategoryRedeemedAnnually.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error;
    });
    builder.addCase(fetchCategoryRedeemedAllTime.pending, (state) => {
      state.categoryRedeemedList.allTime = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(
      fetchCategoryRedeemedAllTime.fulfilled,
      (state, { payload }) => {
        state.categoryRedeemedList.allTime = payload;
        state.loading = "loaded";
      }
    );
    builder.addCase(fetchCategoryRedeemedAllTime.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error;
    });
    builder.addCase(fetchProductRedeemedDaily.pending, (state) => {
      state.productRedeemedList.daily = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(
      fetchProductRedeemedDaily.fulfilled,
      (state, { payload }) => {
        state.productRedeemedList.daily = payload;
        state.loading = "loaded";
      }
    );
    builder.addCase(fetchProductRedeemedDaily.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error;
    });
    builder.addCase(fetchProductRedeemedMonthly.pending, (state) => {
      state.productRedeemedList.monthly = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(
      fetchProductRedeemedMonthly.fulfilled,
      (state, { payload }) => {
        state.productRedeemedList.monthly = payload;
        state.loading = "loaded";
      }
    );
    builder.addCase(fetchProductRedeemedMonthly.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error;
    });
    builder.addCase(fetchProductRedeemedAnnually.pending, (state) => {
      state.productRedeemedList.annually = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(
      fetchProductRedeemedAnnually.fulfilled,
      (state, { payload }) => {
        state.productRedeemedList.annually = payload;
        state.loading = "loaded";
      }
    );
    builder.addCase(fetchProductRedeemedAnnually.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error;
    });
    builder.addCase(fetchProductRedeemedAllTime.pending, (state) => {
      state.productRedeemedList.allTime = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(
      fetchProductRedeemedAllTime.fulfilled,
      (state, { payload }) => {
        state.productRedeemedList.allTime = payload;
        state.loading = "loaded";
      }
    );
    builder.addCase(fetchProductRedeemedAllTime.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error;
    });
    builder.addCase(fetchProductWishlistDaily.pending, (state) => {
      state.productWishlistList.daily = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(
      fetchProductWishlistDaily.fulfilled,
      (state, { payload }) => {
        state.productWishlistList.daily = payload;
        state.loading = "loaded";
      }
    );
    builder.addCase(fetchProductWishlistDaily.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error;
    });
    builder.addCase(fetchProductWishlistMonthly.pending, (state) => {
      state.productWishlistList.monthly = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(
      fetchProductWishlistMonthly.fulfilled,
      (state, { payload }) => {
        state.productWishlistList.monthly = payload;
        state.loading = "loaded";
      }
    );
    builder.addCase(fetchProductWishlistMonthly.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error;
    });
    builder.addCase(fetchProductWishlistAnnually.pending, (state) => {
      state.productWishlistList.annually = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(
      fetchProductWishlistAnnually.fulfilled,
      (state, { payload }) => {
        state.productWishlistList.annually = payload;
        state.loading = "loaded";
      }
    );
    builder.addCase(fetchProductWishlistAnnually.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error;
    });
    builder.addCase(fetchProductWishlistAllTime.pending, (state) => {
      state.productWishlistList.allTime = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(
      fetchProductWishlistAllTime.fulfilled,
      (state, { payload }) => {
        state.productWishlistList.allTime = payload;
        state.loading = "loaded";
      }
    );
    builder.addCase(fetchProductWishlistAllTime.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error;
    });
    builder.addCase(fetchPointsExpired.pending, (state) => {
      state.reportList = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(fetchPointsExpired.fulfilled, (state, { payload }) => {
      state.reportList = payload;
      state.loading = "loaded";
    });
    builder.addCase(fetchPointsExpired.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error;
    });
    builder.addCase(fetchSnapshot.pending, (state) => {
      state.snapshot = {};
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(fetchSnapshot.fulfilled, (state, { payload }) => {
      state.snapshot = payload;
      state.loading = "loaded";
    });
    builder.addCase(fetchSnapshot.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error;
    });
    builder.addCase(fetchMembersByProductClaimed.pending, (state) => {
      state.membersByClaimedProduct = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(fetchMembersByProductClaimed.fulfilled, (state, { payload }) => {
      state.membersByClaimedProduct = payload;
      state.loading = "loaded";
    });
    builder.addCase(fetchMembersByProductClaimed.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error;
    });
  },
});

export const { setLastQuery, setReportsAmount } = reportsSlice.actions;


export const selectReports = createSelector(
  (state) => ({
    reportList: state.reports.reportList,
    redeemedReportList: state.reports.redeemedReportList,
    earnedReportList: state.reports.earnedReportList,
    userLoginList: state.reports.userLoginList,
    productRedeemedList: state.reports.productRedeemedList,
    brandRedeemedList: state.reports.brandRedeemedList,
    categoryRedeemedList: state.reports.categoryRedeemedList,
    productWishlistList: state.reports.productWishlistList,
    pointsBalance: state.reports.pointsBalance,
    memberRedeemedList: state.reports.memberRedeemedList,
    memberLoginList: state.reports.memberLoginList,
    snapshot: state.reports.snapshot,
    membersByClaimedProduct: state.reports.membersByClaimedProduct,
    memberLoading: state.reports.memberLoading,
    loading: state.reports.loading,
    error: state.reports.error,
    reportsAmount: state.reports.reportsAmount,
    lastQuery: state.reports.lastQuery,
  }),
  (state) => state
);
export default reportsSlice.reducer;
