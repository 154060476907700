import { makeStyles, withStyles } from "@material-ui/core/styles";
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import PinDropOutlinedIcon from "@material-ui/icons/PinDropOutlined";
import QueryBuilderOutlinedIcon from '@material-ui/icons/QueryBuilderOutlined';
import ReportOutlinedIcon from '@material-ui/icons/ReportOutlined';
import React from "react";
import { useHistory } from "react-router-dom";
import HelpCard from "./HelpCard";

import Button from "@material-ui/core/Button";

const BackButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#fff",
    color: theme.palette.primaryFont.main,
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: theme.palette.primaryFont.main,
    borderRadius: 20,
    width: 200,
    "&:hover": {
      backgroundColor: "#DDD",
    },
    fontWeight: "bold",
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  helpContainer: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondaryFont.main,
    display: "flex",
    flexFlow: "column nowrap",
    // justifyContent: 'center',
    alignItems: "center",
    height: "100%",
    marginBottom: 62,
  },
  cardContainer: {
    // borderRadius: 16,
    // color: theme.palette.secondaryFont.main,
    margin: 48,
    // padding: 48,
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-evenly",
  },
  buttonContainer: {
    width: 375,
    height: 425,
    margin: 24,
    padding: 24,
    borderRadius: 16,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  header: { fontWeight: "bold", marginBottom: 24 },
  title: {
    color: theme.palette.primaryFont.main,
    fontWeight: "bold",
    marginBottom: 16,
    paddingTop: 8,
  },
  subTitle: {
    fontWeight: "bold",
    marginBottom: 16,
    paddingTop: 16,
  },
  text: {
    marginBottom: 16,
  },
  icon: {
    fontSize: 150,
    color: theme.palette.highlight.main,
  },
}));

export default function Help() {
  const classes = useStyles();
  const history = useHistory();
  const cardList = [
    {
      id: 1,
      alt: "delivery icon",
      icon: <PinDropOutlinedIcon className={classes.icon} />,
      link: "/help/address",
      text: "Change of Delivery Address",
    },
    {
      id: 2,
      alt: "wishlist icon",
      icon: <FavoriteBorderOutlinedIcon className={classes.icon} />,
      link: "/help/wishlist",
      text: "Wishlist",
    },
    {
      id: 3,
      alt: "delivery icon",
      icon: <LocalShippingOutlinedIcon className={classes.icon} />,
      link: "/help/delivery",
      text: "Delivery & Receipt of Your Order",
    },
    {
      id: 4,
      alt: "damaged icon",
      icon: <ReportOutlinedIcon className={classes.icon} />,
      link: "/help/damaged",
      text: "In Transit Damage & Product Warranty Claims",
    },
    {
      id: 5,
      alt: "discontinued icon",
      icon: <QueryBuilderOutlinedIcon className={classes.icon} />,
      link: "/help/discontinued",
      text: "Superseded & Discontinued Products",
    },
  ];
  const goBack = () => {
    history.goBack();
  };

  return (
    <div className={classes.helpContainer}>
      <div className={classes.cardContainer}>
        {cardList.map((card) => (
          <HelpCard
            key={card.id}
            alt={card.alt}
            icon={card.icon}
            link={card.link}
            text={card.text}
          ></HelpCard>
        ))}
        <div className={classes.buttonContainer}>
          <BackButton onClick={goBack}>Go Back</BackButton>
        </div>
      </div>
    </div>
  );
}
