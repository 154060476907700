import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { setXClientHost } from "../../app/utils";
import { selectCustomisation } from "../customisation/customisationSlice";
import { fetchContentByName, selectContents } from "./contentsSlice";

const BackButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#fff",
    color: theme.palette.primaryFont.main,
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: theme.palette.primaryFont.main,
    borderRadius: 20,
    width: 200,
    "&:hover": {
      backgroundColor: "#DDD",
    },
    fontWeight: "bold",
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  worksContainer: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondaryFont.main,
    display: "flex",
    flexFlow: "column nowrap",
    // justifyContent: 'center',
    alignItems: "center",
    height: "100%",
    marginBottom: 62,
  },
  cardContainer: {
    backgroundColor: "#fff",
    borderRadius: 16,
    color: theme.palette.secondaryFont.main,
    margin: 48,
    padding: 48,
  },
  header: { fontWeight: "bold", marginBottom: 24 },
  title: {
    color: theme.palette.primaryFont.main,
    fontWeight: "bold",
    marginBottom: 16,
    paddingTop: 8,
  },
  subTitle: {
    fontWeight: "bold",
    marginBottom: 8,
  },
  text: {
    marginBottom: 16,
  },
  contactUS: {
    color: theme.palette.primaryFont.main,
    fontWeight: "bold",
  },
  helpColor: {
    color: theme.palette.secondaryButton.main,
  },
}));

export default function HelpWishlist() {
  const { customisation } = useSelector(selectCustomisation);
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:850px)");
  const history = useHistory();
  const dispatch = useDispatch();
  let jwtToken = localStorage.getItem("clientToken");

  const goBack = () => {
    history.goBack();
  };

  const { content, loading } = useSelector(selectContents);

  useEffect(() => {
    dispatch(
      fetchContentByName({
        host: setXClientHost(),
        token: jwtToken,
        name: "wishlist",
      })
    );
  }, []);

  return loading !== "loaded" ? (
    <div className={classes.worksContainer}></div>
  ) : content.value && content.value !== "<p><br></p>" ? (
    <div className={classes.worksContainer}>
      <Paper className={classes.cardContainer} elevation={4}>
        <div className={classes.customContentContainer}>
          <ReactQuill
            value={content.value}
            readOnly={true}
            theme={"bubble"}
            // style={{ fontSize: 18 }}
          />
        </div>
      </Paper>
      <BackButton onClick={goBack}>Go Back</BackButton>
    </div>
  ) : (
    <div className={classes.worksContainer}>
      <Paper className={classes.cardContainer} elevation={4}>
        <Typography
          style={{ fontSize: matches ? "3rem" : "28px" }}
          className={classes.header}
          variant="h3"
        >
          Wish List
        </Typography>
        <Typography className={classes.text} variant="body1">
          As you browse through the different {customisation.theme.clientName}{" "}
          categories you can bookmark to your Wish List items you may want to
          order in the future. Members normally do this when they don't yet have
          enough total available points for these items.
        </Typography>
        <Typography className={classes.text} variant="body1">
          These items are of course not added to your current order, but are
          saved in your Wish List for future easy reference to claim when you
          have enough points.
        </Typography>
        <Typography className={classes.title} variant="h5">
          Contact Service Team
        </Typography>
        <Typography className={classes.text} variant="body1">
          If you couldn't find the answer you were looking for:
        </Typography>
        <Typography
          style={{ display: "flex" }}
          className={classes.text}
          variant="body1"
        >
          <div className={classes.contactUS}>Contact Us</div>
          <div className={classes.helpColor} style={{ paddingLeft: 5 }}>
            - send us an email & we'll get back to you within 5 business days
          </div>
        </Typography>
        <Typography
          style={{ marginBottom: 0, fontWeight: "bold" }}
          className={classes.text}
          variant="body1"
        >
          Phone: {customisation.theme.phone}.
        </Typography>
        <Typography
          style={{ fontWeight: "bold" }}
          className={classes.text}
          variant="body1"
        >
          Email: {customisation.theme.email}
        </Typography>
      </Paper>
      <BackButton onClick={goBack}>Go Back</BackButton>
    </div>
  );
}
