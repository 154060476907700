import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState } from 'react';
// Original design here: https://github.com/siriwatknp/mui-treasury/issues/540

const useStyles = makeStyles((theme) => ({
  storeSelect: {
    backgroundColor: theme.palette.secondaryButton.main,
    borderWidth: 3,
    borderStyle: 'solid',
    borderColor: theme.palette.secondaryButtonFont.main,
    borderRadius: '24px',
    color: theme.palette.secondaryButtonFont.main,
    fontSize: 18,
    // fontWeight: 'bold',
    padding: 0,
    paddingLeft: 2,
    paddingRight: 24,
    textTransform: 'capitalize',
    maxWidth: 250,
  },
  colorItem: {color: theme.palette.primary.main},
}));

const usePlaceholderStyles = makeStyles((theme) => ({
  placeholder: {
    color: theme.palette.primaryFont.main,
  },
}));

const Placeholder = ({children}) => {
  const classes = usePlaceholderStyles();
  return <div className={classes.placeholder}>{children}</div>;
};

export const Dropdown = (props) => {
  const {placeholder, setSortCategory, setAsc} = props;
  const classes = useStyles();
  const [val, setVal] = useState('');
  const [open, setOpen] = React.useState(false);
  const handleChange = (event) => {
    setVal(event.target.value);
    setAsc(event.target.value === 'Ascending');
    setSortCategory(placeholder);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const iconComponent = (props) => {
    return <ExpandMoreIcon className={props.className + ' ' + classes.icon} />;
  };
  // moves the menu below the select input
  const MenuProps = {
    classes: {
      paper: classes.paper,
      list: classes.list,
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  };

  return (
    <div style={{marginRight: 16}}>
      <FormControl className={classes.formControl}>
        <Select
          labelId="store-select-label"
          id="store-select"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={val}
          onChange={handleChange}
          IconComponent={iconComponent}
          classes={{root: classes.select}}
          input={<Input className={classes.storeSelect} />}
          MenuProps={MenuProps}
          displayEmpty
          // renderValue={
          //   val !== ''
          //     ? undefined
          //     : () => <Placeholder>{placeholder}</Placeholder>
          // }
          renderValue={() => <Placeholder>{placeholder}</Placeholder>}
        >
          <MenuItem className={classes.colorItem} value={''}>
            None
          </MenuItem>
          <MenuItem
            className={classes.colorItem}
            style={{
              fontWeight: val === 'Ascending' ? 'bold' : 'normal',
            }}
            value={'Ascending'}
          >
            Ascending
          </MenuItem>
          <MenuItem
            className={classes.colorItem}
            style={{
              fontWeight: val === 'Descending' ? 'bold' : 'normal',
            }}
            value={'Descending'}
          >
            Descending
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default Dropdown;
