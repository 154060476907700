import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  addProductToWishlist,
  removeProductFromWishlist,
  selectWishlist,
} from "../wishlist/wishlistSlice";

import { setProduct } from "../products/coreProductsSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 16,
    // maxWidth: 345,
    // maxHeight: 525,

    // paddingBottom: 70,
    // paddingTop: 35,
  },
  prodCard: {
    minWidth: 350,
    maxWidth: "100%",
    flexGrow: 1,
    color: theme.palette.primaryFont.main,
  },

  cardAction: {
    padding: 14,
    paddingBottom: 16,
    paddingTop: 35,
  },
  CardContentContainer: {
    // minheight:
    padding: 8,

    paddingTop: 0,
    paddingBottom: 8,
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
  },
  cardImagecontainer: {
    display: "flex",
    // minHeight: 195,
    // maxHeight: 195,
  },

  cardName: {
    color: theme.palette.primaryFont.main,
    textAlign: "left",
    fontWeight: "bold",
    fontSize: 16,
    height: 40,
    overflow: "hidden",
  },

  cardDescription: {
    color: theme.palette.secondaryFont.main,
    textAlign: "left",
    whiteSpace: "break-spaces",
    fontSize: 12,
  },
  points: {
    // margin: theme.spacing(3, 0, 2),
    // position: 'absolute',
    // zIndex: 900,

    backgroundColor: "#fff",

    borderWidth: 3,
    borderStyle: "solid",
    borderColor: theme.palette.pointsColor.main,
    borderRadius: "24px",
    color: theme.palette.pointsColor.main,
    // right: 16,
    fontSize: 18,
    fontWeight: "bold",
    padding: 4,
    paddingLeft: 24,
    paddingRight: 24,
    // top: 30,
    textAlign: "center",
    maxWidth: 100,
  },
  cardButtons: {
    height: 30,
  },

  special: {
    position: "absolute",
    right: 0,
    bottom: 0,
    padding: 12,
    paddingLeft: 18,
    paddingRight: 18,
    fontSize: 14,
    fontWeight: "bold",
    backgroundColor: theme.palette.highlight.main,
    borderRadius: "16px 0px 16px 0px",
    // color: "#fff",
    color: "#fff",
    textalign: "center",
  },
}));

export default function WishlistMobileCard(props) {
  const classes = useStyles();
  const { product, uA, handleRemove } = props;
  const dispatch = useDispatch();
  const { wishlist } = useSelector(selectWishlist);

  console.log("prod", product);

  let jwtToken = localStorage.getItem("clientToken");
  // const [selectedImage, setSelectedImage] = useState(
  //   product.images
  //     ? product.images.filter(
  //         (image) => image.objName.includes("260-0.webp")[0]
  //       )
  //     : "/placeholder.png"
  // );
  const [selectedImage, setSelectedImage] = useState(
    product.productImg ? product.productImg : "/placeholder.png"
  );
  const [isFav, setIsFav] = useState(
    wishlist.filter((prod) => prod.productID === product.id).length
  );
  const [showFave, setShowFave] = useState(
    wishlist.filter((prod) => prod.productID === product.id).length
  );

  useEffect(() => {
    let fav = wishlist.filter((prod) => prod.productID === product.id);
    setIsFav(fav.length);
    // let prodImage = product.images
    //   ? product.images.filter(
    //       (image) =>
    //         image.objName.includes(".png") && image.objName.includes("520-0")
    //     )[0]
    //   : "/placeholder.png";

    let prodImage = product.productImg
      ? product.productImg
      : "/placeholder.png";
    setSelectedImage(prodImage);
  }, [product, wishlist]);

  const toggleFavourite = () => {
    if (uA.id != undefined) {
      if (isFav) {
        setIsFav(false);
        dispatch(
          removeProductFromWishlist({
            token: jwtToken,
            prodID: product.id,
            id: uA.id,
          })
        );
      } else {
        setIsFav(true);
        const wishProd = {
          productID: product.id,
          productName: product.name,
          productPoints: product.special
            ? product.specialPoints
            : product.points,
          productImg: product.images ? selectedImage.objName : selectedImage,
          statusID: 1,
        };
        dispatch(
          addProductToWishlist({
            token: jwtToken,
            id: uA.id,
            product: wishProd,
          })
        );
      }
    }
  };

  const addToCart = () => {
    const cartProd = {
      productID: product.id,
      productName: product.name,
      productPoints: product.special ? product.specialPoints : product.points,
      productImg: product.images ? selectedImage.objName : selectedImage,
      statusID: 1,
      quantity: 1,
    };
    // dispatch(addProductToCart({token: jwtToken,   id: uA.id, product: cartProd}));
  };

  const formatPTS = (str) => {
    return str ? str.toLocaleString() : "";
  };

  const handleProdClick = () => {
    dispatch(setProduct({ product: product, fav: isFav }));
  };

  return (
    <Grid item xs={3} className={classes.prodCard}>
      <Card className={classes.root} elevation={3} onClick={handleProdClick}>
        <CardActionArea className={classes.cardAction}>
          <Link
            to={{
              pathname: `/products/${product.productName}`,
            }}
          >
            <div className={classes.cardImagecontainer}>
              {selectedImage && (
                <CardMedia
                  component="img"
                  alt={product.productName}
                  // height="140"
                  style={{ width: 125, objectFit: "contain" }}
                  image={
                    product.productImg != "/placeholder.png"
                    ? `${process.env.REACT_APP_PRODUCT_URL}/` + selectedImage
                    : selectedImage
                  }
                  title={product.productName}
                />
              )}
              <CardContent
                id={"mobile-cc"}
                className={classes.CardContentContainer}
              >
                <Typography
                  className={classes.cardName}
                  gutterBottom
                  variant="h4"
                  component="h2"
                >
                  {product.productName.length > 80
                    ? product.productName.substring(0, 80) + "..."
                    : product.productName}
                </Typography>
                <div>
                  <div className={classes.points}>
                    {product.productPoints && formatPTS(product.productPoints)}{" "}
                    pts
                  </div>

                  {/* <Tooltip title="Add to Cart">
                    <Button size="small" color="primary">
                      <ShoppingCartOutlinedIcon onClick={addToCart} />
                    </Button>
                  </Tooltip> */}
                </div>
              </CardContent>
            </div>
          </Link>
          <CardActions
            style={{
              position: "absolute",
              bottom: 0,
              right: -14,
              zIndex: 900,
            }}
            className={classes.cardButtons}
          >
            <Tooltip title="Remove from Wishlist">
              <Button size="small" color="primary" style={{ paddingTop: 10 }}>
                <DeleteOutlineIcon
                  onClick={(event) => handleRemove(event, product)}
                />
              </Button>
            </Tooltip>
            <Tooltip title="Add to Cart">
              <Button size="small" color="primary" style={{ paddingTop: 10 }}>
                <ShoppingCartOutlinedIcon onClick={addToCart} />
              </Button>
            </Tooltip>
          </CardActions>
          {/* <CardActions
            style={{
              position: 'absolute',
              top: 0,
              right: -14,
              zIndex: 900,
            }}
          >
            <Tooltip title="Add to Wishlist">
              <Button size="small" color="primary">
                {isFav ? (
                  <FavoriteIcon onClick={toggleFavourite} />
                ) : (
                  <FavoriteBorderOutlinedIcon onClick={toggleFavourite} />
                )}
              </Button>
            </Tooltip>
          </CardActions> */}
          {product.special && <div className={classes.special}>Special</div>}
        </CardActionArea>

        {/* <CardActions className={classes.cardButtons}> */}
        {/* <Tooltip title="Add to Wishlist">
            <Button size="small" color="primary">
              {isFav ? (
                <FavoriteIcon onClick={toggleFavourite} />
              ) : (
                <FavoriteBorderOutlinedIcon onClick={toggleFavourite} />
              )}
            </Button>
          </Tooltip>
          <Tooltip title="Add to Cart">
            <Button size="small" color="primary">
              <ShoppingCartOutlinedIcon onClick={addToCart} />
            </Button>
          </Tooltip> */}
        {/* </CardActions> */}
      </Card>
    </Grid>
  );
}
