import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { replaceUrlParam } from "../../app/utils";
import CustomTable from "../../common/CustomTable";

import {
  fetchMorePointsExpired,
  fetchPointsExpired,
  getFullListExpired,
  selectReports,
} from "./reportsSlice";

import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  progress: {
    margin: theme.spacing(2),
  },
  root: {
    display: "flex",
    flexFlow: "column",
  },
}));

export default function PointsExpired(props) {
  let jwtToken = localStorage.getItem("clientToken");
  const dispatch = useDispatch();
  const classes = useStyles();

  const { error, loading, reportList, reportsAmount, lastQuery } =
    useSelector(selectReports);

  const [searchTerm, setSearchTerm] = useState([]);
  const [dateBefore, setDateBefore] = useState("");
  const [dateAfter, setDateAfter] = useState("");

  const [tablePreferences, setTablePreferences] = useState(
    localStorage.getItem("PointsExpiredAdminTable")
      ? JSON.parse(localStorage.getItem("PointsExpiredAdminTable"))
      : {
          rowsPerPage: 25,
          currentPage: 0,
          sortProperty: "id",
          sortDirection: "asc",
        }
  );

  useEffect(() => {
    localStorage.setItem(
      "PointsExpiredAdminTable",
      JSON.stringify(tablePreferences)
    );
  }, [tablePreferences]);

  const [numberShown, setNumberShown] = useState(25);

  const headCells = [
    {
      id: "username",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Username",
      long: false,
    },
    {
      id: "userDisplayName",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Display Name",
      long: false,
    },
    {
      id: "userEmail",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Email",
      long: false,
    },
    {
      id: "points",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Expired Points",
      long: false,
    },
    {
      id: "expiredAt",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Expiration Date",
      long: false,
    },
  ];

  useEffect(() => {
    let searchQuery = lastQuery ? lastQuery : "";

    if (tablePreferences.rowsPerPage * (tablePreferences.currentPage + 1)) {
      searchQuery = replaceUrlParam(
        searchQuery,
        "limit",
        tablePreferences.rowsPerPage * (tablePreferences.currentPage + 1)
      );
    }

    dispatch(
      fetchPointsExpired({
        token: jwtToken,
        query: lastQuery
          ? searchQuery
          : `?limit=${tablePreferences.rowsPerPage * (tablePreferences.currentPage + 1)}`,
      })
    );

    setNumberShown(
      tablePreferences.rowsPerPage * (tablePreferences.currentPage + 1)
    );
  }, [dispatch, tablePreferences.rowsPerPage]);

  const getFullListTrigger = () => {
    let searchQuery = lastQuery ? lastQuery : "";
    searchQuery = replaceUrlParam(searchQuery, "limit", 100);

    dispatch(
      getFullListExpired({
        token: jwtToken,
        offset:
          tablePreferences.rowsPerPage > reportList.length
            ? tablePreferences.rowsPerPage
            : reportList.length,
        query: searchQuery,
        targetLength: reportsAmount,
      })
    );
  };

  const handleMore = (e, page) => {
    if (page < tablePreferences.currentPage) {
      setTablePreferences((oldTable) => ({ ...oldTable, currentPage: page }));
      setNumberShown((page + 1) * tablePreferences.rowsPerPage);
    } else {
      let expectedLen = (page + 1) * tablePreferences.rowsPerPage;
      if (
        reportList.length < reportsAmount &&
        reportList.length < expectedLen
      ) {
        dispatch(
          fetchMorePointsExpired({
            token: jwtToken,
            query: lastQuery,
            offset: numberShown,
          })
        );
      }
      setTablePreferences((oldTable) => ({
        ...oldTable,
        currentPage: oldTable.currentPage + 1,
      }));

      setNumberShown(
        (tablePreferences.currentPage + 2) * tablePreferences.rowsPerPage
      );
    }
  };

  const handleRequestSort = (event, property) => {
    let oldProperty = property;
    const isAsc =
      tablePreferences.sortProperty === property &&
      tablePreferences.sortDirection === "asc";
    setTablePreferences((oldTable) => ({
      ...oldTable,
      sortDirection: isAsc ? "desc" : "asc",
      sortProperty: oldProperty,
      currentPage: 0,
    }));

    switch (property) {
      case "updatedAt":
        property = "updated_at";
        break;
      case "createdAt":
        property = "created_at";
        break;
      case "statusID":
        property = "status_id";
        break;
    }

    let searchQuery = lastQuery ? lastQuery : "";

    if (property) {
      searchQuery = replaceUrlParam(searchQuery, "order_by", property);
    }
    searchQuery = replaceUrlParam(searchQuery, "sort", isAsc ? "desc" : "asc");
    searchQuery = replaceUrlParam(
      searchQuery,
      "limit",
      tablePreferences.rowsPerPage * (tablePreferences.currentPage + 1)
    );

    dispatch(
      fetchPointsExpired({
        token: jwtToken,
        query: searchQuery,
      })
    );
  };

  const [ready, setReady] = useState(false);
  if (loading === "loaded" && !ready) {
    setReady(true);
  }

  const handleEdit = (id) => {};

  const handleEditClick = (id) => {};

  const handleSearch = () => {
    let daterange = "";
    if (dateBefore && dateAfter) {
      daterange = `${dateAfter.toISOString().substring(0, 10)},${dateBefore
        .toISOString()
        .substring(0, 10)}`;
    } else if (dateBefore && !dateAfter) {
      daterange = `lte,${dateBefore.toISOString().substring(0, 10)}`;
    } else if (!dateBefore && dateAfter) {
      daterange = `gte,${dateAfter.toISOString().substring(0, 10)}`;
    }

    let searchQuery = lastQuery ? lastQuery : "";

    if (daterange) {
      searchQuery = replaceUrlParam(searchQuery, "rdate", daterange);
    }
    //    if (searchTerm) {
    searchQuery = replaceUrlParam(searchQuery, "name", searchTerm);
    //    }

    dispatch(
      fetchPointsExpired({
        query: searchQuery,
        token: jwtToken,
      })
    );
    setTablePreferences((oldTable) => ({
      ...oldTable,
      currentPage: 0,
    }));
  };

  const handleClear = () => {
    setDateBefore("");
    setDateAfter("");
    dispatch(
      fetchPointsExpired({
        token: jwtToken,
        query: `?limit=${tablePreferences.rowsPerPage}`,
      })
    );
    
    setTablePreferences((oldTable) => ({
      ...oldTable,
      currentPage: 0,
      sortDirection: "asc",
      sortProperty: "id",
    }));
  };

  const selectNames = [""];

  const cellList = headCells.map((cell) => {
    return cell.id;
  });

  let content;
  if (loading === "loading") {
    content = (
      <CircularProgress className={classes.progress} color="secondary" />
    );
  } else if (loading === "loaded" || loading === "idle") {
    content = (
      <CustomTable
        data={reportList}
        actionsLists={[]}
        actionsHandleChange={[]}
        actionsNames={[]}
        headCells={headCells}
        cellList={cellList}
        handleEditClick={handleEditClick}
        handleSearch={handleSearch}
        setSearchTerm={setSearchTerm}
        handleClear={handleClear}
        handleEdit={handleEdit}
        area={"expired"}
        selectNames={selectNames}
        setDateBefore={setDateBefore}
        setDateAfter={setDateAfter}
        dateAfter={dateAfter}
        dateBefore={dateBefore}
        productsAmount={reportsAmount}
        handleMore={handleMore}
        page={tablePreferences.currentPage}
        setPage={(page) =>
          setTablePreferences((oldTable) => ({
            ...oldTable,
            currentPage: page,
          }))
        }
        handleRequestSort={handleRequestSort}
        sortProperty={tablePreferences.sortProperty}
        sortDirection={tablePreferences.sortDirection}
        rowsPerPage={tablePreferences.rowsPerPage}
        setRowsPerPage={(rows) =>
          setTablePreferences((oldTable) => ({
            ...oldTable,
            rowsPerPage: rows,
          }))
        }
        getFullList={getFullListTrigger}
      />
    );
  }

  return (
    <Grid
      container
      spacing={3}
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      style={{ height: "100%" }}
    >
      {content}
    </Grid>
  );
}
