import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { setXClientHost } from "../../app/utils";
import { selectCustomisation } from "../customisation/customisationSlice";
import { fetchContentByName, selectContents } from "./contentsSlice";

// import CircularProgress from "@material-ui/core/CircularProgress";
const BackButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#fff",
    color: theme.palette.primaryFont.main,
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: theme.palette.primaryFont.main,
    borderRadius: 20,
    width: 200,
    "&:hover": {
      backgroundColor: "#DDD",
    },
    fontWeight: "bold",
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  contactContainer: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondaryFont.main,
    display: "flex",
    flexFlow: "column nowrap",
    // justifyContent: 'center',
    alignItems: "center",
    height: "100%",
    marginBottom: 62,
  },
  cardContainer: {
    backgroundColor: "#fff",
    borderRadius: 16,
    color: theme.palette.secondaryFont.main,
    margin: 48,
    padding: 48,
  },
  header: {
    color: theme.palette.primaryFont.main,
    fontWeight: "bold",
    marginBottom: 24,
  },
  title: {
    color: theme.palette.primaryFont.main,
    fontWeight: "bold",
    marginBottom: 16,
    paddingTop: 8,
  },
  subTitle: {
    fontWeight: "bold",
    marginBottom: 8,
    marginTop: 16,
  },
  text: {
    marginBottom: 0,
  },
}));

export default function Contact() {
  const { customisation } = useSelector(selectCustomisation);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  let jwtToken = localStorage.getItem("clientToken");

  const goBack = () => {
    history.goBack();
  };

  const { content, loading } = useSelector(selectContents);

  useEffect(() => {
    dispatch(
      fetchContentByName({
        host: setXClientHost(),
        token: jwtToken,
        name: "contact-us",
      })
    );
  }, []);

  return loading !== "loaded" ? (
    <div className={classes.contactContainer}></div>
  ) : content.value && content.value !== "<p><br></p>" ? (
    <div className={classes.contactContainer}>
      <Paper className={classes.cardContainer} elevation={4}>
        <div className={classes.customContentContainer}>
          <ReactQuill
            value={content.value}
            readOnly={true}
            theme={"bubble"}
            style={{ fontSize: 18 }}
          />
        </div>
      </Paper>
      <BackButton onClick={goBack}>Go Back</BackButton>
    </div>
  ) : (
    <div className={classes.contactContainer}>
      <Paper className={classes.cardContainer} elevation={4}>
        <Typography className={classes.header} variant="h3">
          Contact Us
        </Typography>
        <Typography className={classes.text} variant="body1">
          For all queries about points allocation plus the delivery of rewards
          please contact:
        </Typography>
        <Typography className={classes.subTitle} variant="h5">
          {customisation.theme.clientName} Demo
        </Typography>
        <Typography className={classes.text} variant="body1">
          30 Thistlethwaite St, South Melbourne, VIC 3205
        </Typography>
        <Typography className={classes.text} variant="body1">
          Tel: {customisation.theme.phone}
        </Typography>
        <Typography className={classes.text} variant="body1">
          Email: {customisation.theme.email}
        </Typography>
        {/* <Typography
          style={{
            marginTop: 32,
          }}
          className={classes.text}
          variant="body1"
        >
          For all queries about {customisation.theme.clientName} products and
          services:
        </Typography>
        <Typography className={classes.subTitle} variant="h5">
          {customisation.theme.clientName}
        </Typography>
        <Typography className={classes.text} variant="body1">
          Tel: 1800 111 333
        </Typography>
        <Typography className={classes.text} variant="body1">
          Email: {customisation.theme.email}
        </Typography>
        <Typography className={classes.text} variant="body1">
          www.ps.com
        </Typography> */}
      </Paper>
      <BackButton onClick={goBack}>Go Back</BackButton>
    </div>
  );
}
