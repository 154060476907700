import axios from "axios";
import React, { useEffect } from "react";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./app/store";
import "./fonts/ABBvoice_W_Rg.woff";
// pick a date util library
import DateFnsUtils from "@date-io/date-fns";
import { BrowserRouter, useLocation } from "react-router-dom";
import App from "./App";
import "./index.css";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

axios
  .get(`${process.env.REACT_APP_ASSETS_URL}/theme/theme.json`)
  .then(function (response) {
    // success, let's render the app
    // you can pass the whole response object or just the data to your app
    ReactDOM.render(
      <React.StrictMode>
        <Provider store={store}>
          {/* <MuiThemeProvider theme={theme}> */}
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <BrowserRouter>
              <App themeData={response.data} />
            </BrowserRouter>
          </MuiPickersUtilsProvider>
          {/* </MuiThemeProvider> */}
        </Provider>
      </React.StrictMode>,
      document.getElementById("root")
    );
  })
  .catch(function (response) {
    // something went wrong
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
