import {
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import axios from "axios";
import { uuidv4 } from "../../app/utils";
import baseURL from "../../app/utils.js";
import { errorSnack } from "../snackbar/snackbarSlice";
axios.interceptors.request.use(
  (req) => {
    req.headers.common["X-Request-ID"] = uuidv4();
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

var sortBy = require("lodash/sortBy");

export const resetProduct = createAction("products/resetProduct");
export const sortProduct = createAction(
  "coreproducts/sortProducts",
  function (products, sortCategory) {
    let sortedProds = sortBy(products, [
      function (o) {
        return o[sortCategory];
      },
    ]);
    return { payload: sortedProds };
  }
);
export const updateCategories = createAction(
  "coreproducts/updateCategories",
  function (categories) {
    return { payload: categories };
  }
);

export const fetchProducts = createAsyncThunk(
  "coreproducts/fetchProducts",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/core/products${args.query}`,
        config
      );
      let catQuery = [];
      if (args.query.includes("categories")) {
        // let cats = args.query.split('categories')[1].split('=')[1].split(',');
        let urlSearchParams = new URLSearchParams(args.query);
        let params = Object.fromEntries(urlSearchParams.entries());
        catQuery = params.categories;
      }
      thunkAPI.dispatch(setLastQuery(args.query));
      thunkAPI.dispatch(setProductsAmount(response.headers["x-total-count"]));
      thunkAPI.dispatch(updateCategories(catQuery));
      return await response.data;
    } catch (error) {
      if (error.message.includes("401")) {
        localStorage.removeItem("clientUser");
        localStorage.removeItem("clientToken");
      }
      if (error.response && error.response.data) {
        // thunkAPI.dispatch(
        //   errorSnack(
        //     error.response.data.error_description ||
        //       'Error fetching products list'
        //   )
        // );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const filterProducts = createAsyncThunk(
  "coreproducts/filterProducts",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/core/products${args.query}`,
        config
      );
      let catQuery = [];
      if (args.query.includes("categories")) {
        let urlSearchParams = new URLSearchParams(args.query);
        let params = Object.fromEntries(urlSearchParams.entries());
        catQuery = params.categories;
      }
      thunkAPI.dispatch(setLastQuery(args.query));
      thunkAPI.dispatch(setProductsAmount(response.headers["x-total-count"]));
      thunkAPI.dispatch(updateCategories(catQuery));
      return await response.data;
    } catch (error) {
      if (error.message.includes("401")) {
        localStorage.removeItem("clientUser");
        localStorage.removeItem("clientToken");
      }
      if (error.response && error.response.data) {
        // thunkAPI.dispatch(
        //   errorSnack(
        //     error.response.data.error_description ||
        //       'Error fetching products list'
        //   )
        // );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchMoreProducts = createAsyncThunk(
  "coreproducts/fetchMoreProducts",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/core/products${args.query}&offset=${args.offset}`,
        config
      );
      let catQuery = [];
      if (args.query.includes("categories")) {
        let urlSearchParams = new URLSearchParams(args.query);
        let params = Object.fromEntries(urlSearchParams.entries());
        catQuery = params.categories;
      }
      thunkAPI.dispatch(setLastQuery(args.query));
      thunkAPI.dispatch(setProductsAmount(response.headers["x-total-count"]));
      thunkAPI.dispatch(updateCategories(catQuery));
      return await response.data;
    } catch (error) {
      if (error.message.includes("401")) {
        localStorage.removeItem("clientUser");
        localStorage.removeItem("clientToken");
      }
      if (error.response && error.response.data) {
        // thunkAPI.dispatch(
        //   errorSnack(
        //     error.response.data.error_description ||
        //       'Error fetching products list'
        //   )
        // );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchProductByID = createAsyncThunk(
  "coreproducts/fetchProductByID",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/admin/core/products/${args.id}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.message.includes("401")) {
        localStorage.removeItem("clientUser");
        localStorage.removeItem("clientToken");
      }
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching product data"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchProductByName = createAsyncThunk(
  "coreproducts/fetchProductByName",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/admin/core/products?query=${args.name}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.message.includes("401")) {
        localStorage.removeItem("clientUser");
        localStorage.removeItem("clientToken");
      }
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching product data"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchRelatedProductsByBrand = createAsyncThunk(
  "coreproducts/fetchRelatedProductsByBrand",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/core/products?limit=5&brands=${args.brandIDs}&categories=${args.catIDs}`,
        config
      );
      if (response.data) {
        if (!response.data.data) {
          thunkAPI.dispatch(
            fetchRelatedProductsByCategory({
              token: args.token,
              catIDs: args.catIDs,
              brandLen: response.data.data.length,
              productID: args.productID,
            })
          );
          return await response.data;
        } else {
          let filtResponse = response.data.data.filter(
            (product) => product.id != args.productID
          );
          if (filtResponse.length < 4) {
            thunkAPI.dispatch(
              fetchRelatedProductsByCategory({
                token: args.token,
                catIDs: args.catIDs,
                brandLen: filtResponse.length,
                productID: args.productID,
                brandResp: filtResponse,
              })
            );

            return filtResponse;
          } else if (filtResponse.length > 4) {
            return filtResponse.slice(0, 4);
          } else {
            return filtResponse;
          }
        }
      }
    } catch (error) {
      if (error.message.includes("401")) {
        localStorage.removeItem("clientUser");
        localStorage.removeItem("clientToken");
      }
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching products list"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchRelatedProductsByCategory = createAsyncThunk(
  "coreproducts/fetchRelatedProductsByCategory",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };

      const response = await axios.get(
        `${baseURL}/v1/admin/core/products?limit=${
          9 - args.brandLen
        }&categories=${args.catIDs}`,
        config
      );

      const filtResponse = await response.data.data.filter(
        (product) => product.id != args.productID
      );

      if (args.brandResp) {
        const filtCatResponse = filtResponse.filter(
          (product) => !args.brandResp.some((prod) => product.id == prod.id)
        );

        return filtCatResponse.slice(0, 4 - args.brandLen);
      } else {
        return filtResponse.slice(0, 4);
      }
    } catch (error) {
      if (error.message.includes("401")) {
        localStorage.removeItem("clientUser");
        localStorage.removeItem("clientToken");
      }
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching products list"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchProductsByGroup = createAsyncThunk(
  "products/fetchProductsByGroup",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/admin/core/products/groups/${args.groupID}`,
        config
      );
      return await response;
    } catch (error) {
      if (error.message.includes("401")) {
        localStorage.removeItem("clientUser");
        localStorage.removeItem("clientToken");
      } else if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching product group"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchProductsMaxPoints = createAsyncThunk(
  "coreproducts/fetchProductsMaxPoints",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/core/products_max_points`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.message.includes("401")) {
        localStorage.removeItem("clientUser");
        localStorage.removeItem("clientToken");
      }
      if (error.response && error.response.data) {
        // thunkAPI.dispatch(
        //   errorSnack(
        //     error.response.data.error_description ||
        //       'Error fetching products list'
        //   )
        // );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const initial = {
  product: {
    product: {},
    isFav: false,
  },
  productLoaded: true,
  productsAmount: "",
  searchCategories: [],
  breadcrumbCats: [],
  queryParam: "",
  relatedProducts: [],
  lastQuery: "",
  productList: { data: [], brandCounts: [] },
  productGroupList: { data: [] },
  brandsList: [],
  loading: "idle",
  message: "",
  error: "",
  maxPoints: "",
  brands: {
    // electronics
    1: [
      {
        id: 9,
        name: "Samsung",
        count: 27,
      },
      {
        id: 3,
        name: "LG",
        count: 23,
      },
      {
        id: 2,
        name: "Sony",
        count: 18,
      },
      {
        id: 14,
        name: "Google",
        count: 10,
      },
      {
        id: 7,
        name: "Hisense",
        count: 10,
      },
    ],
    // appliances
    4: [
      {
        id: 1,
        name: "Apple",
        count: 63,
      },
      {
        id: 9,
        name: "Samsung",
        count: 1,
      },
    ],
    // kids
    5: [
      {
        id: 1,
        name: "Apple",
        count: 37,
      },
      {
        id: 13,
        name: "Lenovo",
        count: 1,
      },
    ],
    // sport & leisure
    18: [
      {
        id: 1,
        name: "Apple",
        count: 21,
      },
    ],
    // homewares & lifestyles
    28: [],
    // charity
    10: [],
    // charity
  },
};

const coreproductsSlice = createSlice({
  name: "coreproducts",
  initialState: initial,
  reducers: {
    resetProduct(state, action) {
      state.productList = { data: [], brandCounts: [] };
      state.relatedProducts = [];
      state.brandsList = [];
      state.searchCategories = [];
      state.breadcrumbCats = [];
      state.queryParam = "";
      state.product = { product: {}, fav: false };
      state.productLoaded = true;
      state.loading = "idle";
      state.message = "";
      state.error = "";
      state.productsAmount = "";
    },
    setProduct(state, action) {
      state.product = action.payload;
    },
    sortProducts(state, action) {
      state.productList.data = action.payload;
    },
    updateCategories(state, action) {
      state.searchCategories = action.payload;
    },
    setLastQuery: (state, action) => {
      state.lastQuery = action.payload;
    },
    setBreadcrumbCats: (state, action) => {
      state.breadcrumbCats = action.payload;
    },
    setQueryParam: (state, action) => {
      state.queryParam = action.payload;
    },
    setProductsAmount: (state, action) => {
      state.productsAmount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProducts.pending, (state) => {
      state.productList = { data: [], brandCounts: [] };

      state.loading = "loading";
    });
    builder.addCase(fetchProducts.fulfilled, (state, { payload }) => {
      state.error = "";
      state.productList = payload;
      state.brandsList = sortBy(payload.brandCounts, "name");
      state.loading = "loaded";
    });
    builder.addCase(fetchProducts.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(filterProducts.pending, (state) => {
      state.productList = { data: [], brandCounts: [] };
      state.loading = "loading";
    });
    builder.addCase(filterProducts.fulfilled, (state, { payload }) => {
      state.error = "";
      state.productList = payload;

      state.loading = "loaded";
    });
    builder.addCase(filterProducts.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchMoreProducts.pending, (state) => {
      // state.productList = {data: [], brandCounts: []};
      state.loading = "loading";
    });
    builder.addCase(fetchMoreProducts.fulfilled, (state, { payload }) => {
      state.error = "";
      state.productList.data = state.productList.data.concat(payload.data);

      state.loading = "loaded";
    });
    builder.addCase(fetchMoreProducts.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchProductByID.pending, (state) => {
      // state.product =  {product: {}, fav: false};
      state.loading = "loading";
    });
    builder.addCase(fetchProductByID.fulfilled, (state, { payload }) => {
      state.error = "";
      state.product = payload;
      state.loading = "loaded";
    });
    builder.addCase(fetchProductByID.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchProductByName.pending, (state) => {
      state.product = { product: {}, fav: false };
      state.productLoaded = false;
      state.loading = "loading";
    });
    builder.addCase(fetchProductByName.fulfilled, (state, { payload }) => {
      state.error = "";
      state.productLoaded = true;

      state.product = payload.data.length
        ? { product: payload.data[0], fav: false }
        : { product: {}, fav: false };
      state.loading = "loaded";
    });
    builder.addCase(fetchProductByName.rejected, (state, action) => {
      state.loading = "error";
      state.productLoaded = true;

      state.error = action.error.message;
    });
    builder.addCase(fetchRelatedProductsByBrand.pending, (state) => {
      state.relatedProducts = [];
      state.loading = "loading";
    });
    builder.addCase(
      fetchRelatedProductsByBrand.fulfilled,
      (state, { payload }) => {
        state.relatedProducts = payload;
      }
    );
    builder.addCase(
      fetchRelatedProductsByBrand.rejected,
      (state, action) => {}
    );
    builder.addCase(fetchRelatedProductsByCategory.pending, (state) => {
      state.relatedProducts = [];
      state.loading = "loading";
    });
    builder.addCase(
      fetchRelatedProductsByCategory.fulfilled,
      (state, { payload }) => {
        state.error = "";
        state.relatedProducts = state.relatedProducts.concat(payload);
        state.loading = "loaded";
      }
    );
    builder.addCase(
      fetchRelatedProductsByCategory.rejected,
      (state, action) => {
        state.loading = "error";
        state.error = action.error.message;
      }
    );
    builder.addCase(fetchProductsByGroup.pending, (state) => {
      state.productGroupList = { data: [] };
      // state.loading = "loading";
    });
    builder.addCase(fetchProductsByGroup.fulfilled, (state, { payload }) => {
      state.error = "";
      state.productGroupList = payload;
      // state.loading = "loaded";
    });
    builder.addCase(fetchProductsByGroup.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchProductsMaxPoints.pending, (state) => {
      state.maxPoints = "";
      // state.loading = "loading";
    });
    builder.addCase(fetchProductsMaxPoints.fulfilled, (state, { payload }) => {
      state.error = "";
      state.maxPoints = payload.maxPoints;
      // state.loading = "loaded";
    });
    builder.addCase(fetchProductsMaxPoints.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  setLastQuery,
  setQueryParam,
  setBreadcrumbCats,
  setProduct,
  setProductsAmount,
} = coreproductsSlice.actions;

export const selectCoreProducts = createSelector(
  (state) => ({
    coreproducts: state.coreproducts,
    productList: state.coreproducts.productList,
    productGroupList: state.coreproducts.productGroupList,
    brandsList: state.coreproducts.brandsList,
    relatedProducts: state.coreproducts.relatedProducts,
    loading: state.coreproducts.loading,
    error: state.coreproducts.error,
    product: state.coreproducts.product,
    brands: state.coreproducts.brands,
    searchCategories: state.coreproducts.searchCategories,
    breadcrumbCats: state.coreproducts.breadcrumbCats,
    lastQuery: state.coreproducts.lastQuery,
    queryParam: state.coreproducts.queryParam,
    productLoaded: state.coreproducts.productLoaded,
    productsAmount: state.coreproducts.productsAmount,
    maxPoints: state.coreproducts.maxPoints
  }),
  (state) => state
);
export default coreproductsSlice.reducer;
