import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {
  const {openDialog, title, message} = props;
  const [open, setOpen] = React.useState(openDialog);
  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        style={{textAlign: 'center', minWidth: 325, minHeight: 200}}
      >
        <DialogTitle
          style={{textAling: 'center'}}
          id="alert-dialog-slide-title"
        >
          {' '}
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            tyle={{textAling: 'center'}}
            id="alert-dialog-slide-description"
          >
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{justifyContent: 'space-evenly'}}>
          <Button onClick={handleClose} color="primary">
            Exit
          </Button>
          <Link to="/signin">
            <Button onClick={handleClose} color="primary">
              Return to signin page
            </Button>
          </Link>
        </DialogActions>
      </Dialog>
    </div>
  );
}
