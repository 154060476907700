import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

import { errorSnack, infoSnack, successSnack } from "../snackbar/snackbarSlice";
import { fetchMyself } from "../users/usersSlice";


import axios from "axios";
import { uuidv4 } from "../../app/utils";
import baseURL from "../../app/utils.js";
axios.interceptors.request.use(
  (req) => {
    req.headers.common["X-Request-ID"] = uuidv4();
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export const fetchUserPoints = createAsyncThunk(
  "userPoints/fetchUserPoints",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //  const response = await axios.get(`${baseURL}v1/admin/users/wishlist?statuses=1,2&name=Sed&order_by=id&sort=asc&offset=0&limit=10`);
      const response = await axios.get(
        `${baseURL}/v1/admin/users/${args.userID}/points`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.message.includes("401")) {
        localStorage.removeItem("clientUser");
        localStorage.removeItem("clientToken");
      }
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching Wishlist list"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchUserPointsByClient = createAsyncThunk(
  "userPoints/fetchUserPointsByClient",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //  const response = await axios.get(`${baseURL}v1/admin/users/wishlist?statuses=1,2&name=Sed&order_by=id&sort=asc&offset=0&limit=10`);
      const response = await axios.get(
        `${baseURL}/v1/admin/points?order_by=redeemed&sort=desc`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.message.includes("401")) {
        localStorage.removeItem("clientUser");
        localStorage.removeItem("clientToken");
      }
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching Wishlist list"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchUserPointsByProgram = createAsyncThunk(
  "userPoints/fetchUserPointsByProgram",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/admin/programs/${args.programID}/points?order_by=redeemable&sort=desc`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.message.includes("401")) {
        localStorage.removeItem("clientUser");
        localStorage.removeItem("clientToken");
      }
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching Wishlist list"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchUserPointsByPointsID = createAsyncThunk(
  "userPoints/fetchUserPointsByPointsID",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/admin/users/${args.userID}/points_history/${args.pointsID}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error fetching Store data"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const createPoints = createAsyncThunk(
  "userPoints/createPoints",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //full options
      const response = await axios.post(
        `${baseURL}/v1/admin/users/${args.userID}/points`,
        args.pointsDetails,
        config
      );

      thunkAPI.dispatch(
        fetchUserPoints({
          token: args.token,
          userID: args.userID,
        })
      );
      thunkAPI.dispatch(
        fetchMyself({
          token: args.token,
        })
      );
      thunkAPI.dispatch(
        successSnack(`${args.pointsDetails.points} added to member account`)
      );

      return await response;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error adding points"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const updatePoints = createAsyncThunk(
  "userPoints/updatePoints",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //full options
      const response = await axios.patch(
        `${baseURL}/v1/admin/users/${args.userID}/points/${args.pointsID}`,
        args.pointsDetails,
        config
      );
      thunkAPI.dispatch(infoSnack("Account details updated successfully"));
      thunkAPI.dispatch(
        fetchMyself({
          token: args.token,
        })
      );

      return await response;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error updating Order"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const approvePoints = createAsyncThunk(
  "userPoints/approvePoints",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //full options
      const response = await axios.patch(
        `${baseURL}/v1/admin/users/${args.userID}/points/${args.pointsID}/approve`,
        args.pointsDetails,
        config
      );
      // thunkAPI.dispatch(successSnack('Points updated'));
      thunkAPI.dispatch(infoSnack("Claim points approved"));

      return await response;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error updating Points"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const declinePoints = createAsyncThunk(
  "userPoints/approvePoints",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //full options
      const response = await axios.patch(
        `${baseURL}/v1/admin/users/${args.userID}/points/${args.pointsID}/decline`,
        args.pointsDetails,
        config
      );
      // thunkAPI.dispatch(fetchOrders({token: args.token, query: '?limit=25'}));
      // thunkAPI.dispatch(successSnack('Order updated'));
      thunkAPI.dispatch(infoSnack("Claim points declined"));

      return await response;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error updating Order"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchUserPointsHistory = createAsyncThunk(
  "userPoints/fetchUserPointsHistory",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //full options
      const response = await axios.get(
        `${baseURL}/v1/admin/users/${args.userID}/points_history?limit=100`,
        config
      );
      return await response;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error updating Order"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const userPointsSlice = createSlice({
  name: "userPoints",
  initialState: {
    pointsList: [],
    pointsHistory: [],
    memberPoint: {},
    loading: "idle",
    message: "",
    error: "",
    cart: [],
    createStatus: false,
  },
  reducers: {
    addProductToCart(state, action) {
      state.cart = [...state.cart, action.payload];
      state.loading = "a";
    },
    removeProductFromCart(state, action) {
      state.cart = state.cart.filter(
        (prod) => prod[0].product.id !== action.payload
      );
      state.loading = "run";
    },
    setCreateStatus(state, action) {
      state.createStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserPoints.pending, (state) => {
      state.pointsList = [];
      state.loading = "loading";
    });
    builder.addCase(fetchUserPoints.fulfilled, (state, { payload }) => {
      state.pointsList = payload;
      state.loading = "loaded";
    });
    builder.addCase(fetchUserPoints.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchUserPointsHistory.pending, (state) => {
      state.pointsHistory = [];
      state.loading = "loading";
    });
    builder.addCase(fetchUserPointsHistory.fulfilled, (state, { payload }) => {
      state.pointsHistory = payload;
      state.loading = "loaded";
    });
    builder.addCase(fetchUserPointsHistory.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchUserPointsByClient.pending, (state) => {
      state.pointsList = [];
      state.loading = "loading";
    });
    builder.addCase(fetchUserPointsByClient.fulfilled, (state, { payload }) => {
      state.pointsList = payload;
      state.loading = "loaded";
    });
    builder.addCase(fetchUserPointsByClient.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchUserPointsByProgram.pending, (state) => {
      state.pointsList = [];
      state.loading = "loading";
    });
    builder.addCase(
      fetchUserPointsByProgram.fulfilled,
      (state, { payload }) => {
        state.pointsList = payload;
        state.loading = "loaded";
      }
    );
    builder.addCase(fetchUserPointsByProgram.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchUserPointsByPointsID.pending, (state) => {
      // state.pointsList = [];
      state.loading = "loading";
    });
    builder.addCase(
      fetchUserPointsByPointsID.fulfilled,
      (state, { payload }) => {
        state.memberPoint = payload;
        state.loading = "loaded";
      }
    );
    builder.addCase(fetchUserPointsByPointsID.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(createPoints.pending, (state) => {
      state.createStatus = false;
    });
    builder.addCase(createPoints.fulfilled, (state, { payload }) => {
      state.createStatus = true;
    });
    builder.addCase(createPoints.rejected, (state, action) => {
      state.createStatus = false;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setCreateStatus } = userPointsSlice.actions;

export const selectUserPoints = createSelector(
  (state) => ({
    userPoints: state.userPoints,
    pointsHistory: state.userPoints.pointsHistory,
    loading: state.userPoints.loading,
    error: state.userPoints.error,
    pointsList: state.userPoints.pointsList,
    memberPoint: state.userPoints.memberPoint,
    createStatus: state.userPoints.createStatus,
  }),
  (state) => state
);
export default userPointsSlice.reducer;
