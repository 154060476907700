import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  type: '',
  message: '',
};

export const snackbar = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    infoSnack: (state, action) => {
      state.type = 'info';
      state.message = action.payload;
    },
    successSnack: (state, action) => {
      state.type = 'success';
      state.message = action.payload;
    },
    errorSnack: (state, action) => {
      state.type = 'error';
      state.message = action.payload;
    },
    resetSnack: (state, action) => {
      state.type = '';
      state.message = '';
    },
  },
});

// Action creators are generated for each case reducer function
export const {infoSnack, successSnack, errorSnack, resetSnack} =
  snackbar.actions;

export default snackbar.reducer;

export const selectSnackbar = createSelector(
  (state) => ({
    snackbar: state.snackbar,
    type: state.snackbar.type,
    message: state.snackbar.message,
  }),
  (state) => state
);
