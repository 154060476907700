import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useEffect, useRef, useState } from "react";
import { pdfjs } from "react-pdf";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { replaceUrlParam } from "../../app/utils";

// import Button from "@material-ui/core/Button";

import {
  createClaim,
  preSignedURLClaims,
  selectClaims
} from "../claims/claimsSlice";
import {
  fetchMyself,
  fetchUsers,
  getFullList as getFullUsersList,
} from "../users/usersSlice";

import { selectCustomisation } from "../customisation/customisationSlice";
import {
  fetchProducts,
  getFullList as getFullProductsList,
  selectClientProducts,
} from "../products/productsSlice";
import { fetchPrograms, selectPrograms } from "../programs/programsSlice";
import ClaimIntro from "./ClaimIntro";
import ClaimStepThree from "./ClaimStepThree";
import ClaimStepTwo from "./ClaimStepTwo";
import ClaimSubmission from "./ClaimSubmission";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// const BackButton = withStyles((theme) => ({
//   root: {
//     // margin: theme.spacing(3, 0, 2),
//     backgroundColor: "transparent",
//     color: "#fff",
//     cursor: "pointer",
//     borderWidth: 3,
//     borderStyle: "solid",
//     borderColor: "#fff",
//     borderRadius: 20,
//     width: 100,
//     maxHeight: 38.5,
//     "&:hover": {
//       backgroundColor: "#0000004f",
//     },
//     fontWeight: "bold",
//   },
// }))(Button);

const useStyles = makeStyles((theme) => ({
  claimContainer: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondaryFont.main,
    display: "flex",
    flexFlow: "row nowrap",
    // width: "100%",
    height: "100%",
    marginBottom: 62,
    paddingTop: 52,
  },
  buttonContainer: {
    marginTop: 48,
    width: "10%",
    display: "flex",
    justifyContent: "center",
  },
}));

export default function InvoiceContainer() {
  const matches = useMediaQuery("(min-width:800px)");
  const classes = useStyles();
  const navigate = useHistory();
  const dispatch = useDispatch();

  const { userAccount, loading, usersList, usersAmount } = useSelector(
    (state) => state.users
  );

  const { invoiceError, claimsList } = useSelector(selectClaims);
  const { clientProductsList, productsAmount } =
    useSelector(selectClientProducts);

  const { programsList } = useSelector(selectPrograms);

  const { customisation } = useSelector(selectCustomisation);
  const [step, setStep] = useState(0);

  const [files, setFiles] = useState([{}]);

  let jwtToken = localStorage.getItem("clientToken");

  const [invoiceItems, setInvoiceItems] = useState(null);

  // const [invoiceLoading, setInvoiceLoading] = useState(false);

  const [claims, setClaims] = useState([
    {
      invoiceTotal: null,
      invoiceURL: "",
      invoiceNumber: "",
      invoiceDate: null,
      invoiceType: "",
      claimProducts: [
        {
          id: 0,
          name: "",
          price: "",
          priceExGst: "",
          quantity: 1,
          gst: Number(process.env.REACT_APP_GST).toFixed(2),
          subTotal: (0.0).toFixed(2),
        },
      ],
      town: "",
      store: "",
    },
  ]);

  const [invoiceTotal, setInvoiceTotal] = useState(null);
  const [invoiceURL, setInvoiceURL] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [invoiceDate, setInvoiceDate] = useState("");
  const [invoiceType, setInvoiceType] = useState("");

  const [claimProducts, setClaimProducts] = useState([]);
  const [manualEntry, setManualEntry] = useState(false);
  const [town, setTown] = useState("");
  const [store, setStore] = useState("");

  const [userID, setUserID] = useState(null);
  const [error, setError] = useState(false);

  const [productsReady, setProductsReady] = useState(false);

  const [autoProcessMode, setAutoProcessMode] = useState(false);

  const firstUpdate = useRef(true);
  const firstProductUpdate = useRef(true);

  useEffect(() => {
    if (userAccount.roleID !== undefined) {
      if (!usersList.length && userAccount.roleID !== 99) {
        dispatch(fetchUsers({ token: jwtToken, query: "" }));
      }
    } else {
      dispatch(fetchMyself({ token: jwtToken }));
    }
  }, [userAccount]);

  useEffect(() => {
    setStep(0);

    if (!clientProductsList.length) {
      dispatch(fetchProducts({ token: jwtToken, query: "" }));
    }
    if (!programsList.length) {
      dispatch(
        fetchPrograms({
          token: jwtToken,
          query: `?limit=200&statuses=1`,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (
      usersList.length &&
      usersList.length != usersAmount &&
      firstUpdate.current &&
      userAccount.roleID !== 99
    ) {
      let searchQuery = "?limit=100";
      searchQuery = replaceUrlParam(searchQuery, "limit", 100);
      dispatch(
        getFullUsersList({
          token: jwtToken,
          offset: usersList.length,
          query: searchQuery,
          targetLength: usersAmount,
        })
      );
      firstUpdate.current = false;
    }
  }, [usersList.length]);

  useEffect(() => {
    if (
      clientProductsList.length &&
      clientProductsList.length != productsAmount &&
      firstProductUpdate.current
    ) {
      let searchQuery = "?limit=100";
      searchQuery = replaceUrlParam(searchQuery, "limit", 100);
      dispatch(
        getFullProductsList({
          token: jwtToken,
          offset: clientProductsList.length,
          query: searchQuery,
          targetLength: productsAmount,
        })
      );
      firstProductUpdate.current = false;
    }
  }, [clientProductsList.length]);

  const resetProcess = () => {
    setStep(0);
    setUserID(null);
    setInvoiceItems(null);
    setFiles([{}]);
    setInvoiceNumber("");
    setInvoiceDate("");
    setInvoiceTotal(null);
    setInvoiceURL(null);
    setError(false);
    setClaimProducts([]);
    setTown("");
    setStore("");
    setClaims([
      {
        invoiceTotal: null,
        invoiceURL: "",
        invoiceNumber: "",
        invoiceDate: "",
        invoiceType: "",
        claimProducts: [
          {
            id: 0,
            name: "",
            price: "",
            priceExGst: "",
            quantity: 1,
            gst: Number(process.env.REACT_APP_GST).toFixed(2),
            subTotal: (0.0).toFixed(2),
          },
        ],
        town: "",
        store: "",
      },
    ]);
  };

  const handleStep = () => {
    if (step === 0) {
      navigate("/");
    } else {
      if (step == 2) {
        setStep(2);
      } else if (step == 3) {
        setStep(0);
      } else if (
        step == 4 &&
        process.env.REACT_APP_ENABLE_AUTO_CLAIMS !== "true"
      ) {
        setStep(2);
      } else if (
        step == 4 &&
        process.env.REACT_APP_ENABLE_AUTO_CLAIMS === "true"
      ) {
        setStep(0);
      } else {
        setStep(step - 1);
      }
    }
  };

  const handleSubmit = (urls) => {
    claims.forEach((claim, index) => {
      let products = [];
      claim.claimProducts.map((product) => {
        let newProd = {};
        newProd.productID = product.id;
        newProd.quantity = product.quantity;
        newProd.totalAmount = Math.floor(product.subTotal * 100);

        products.push(newProd);
      });

      const payload = {
        statusID: 1,
        distributorID: claim.store.id,
        invoiceNumber: claim.invoiceNumber,
        invoiceDate: claim.invoiceDate,
        monetaryValue: Math.floor(claim.invoiceTotal * 100),
        attachments: [
          {
            attmURL: urls[index],
            attmType: claim.invoiceType === "pdf" ? "pdf" : "image",
          },
        ],
        products: autoProcessMode ? null : products.length ? products : null,
      };

      dispatch(
        createClaim({
          token: jwtToken,
          claim: payload,
          id: userAccount.roleID <= 2 ? userID : userAccount.id,
        })
      );
    });
    resetProcess();
  };

  const fileInputRef = useRef();

  const handleUpload = (claimIndex) => {
    if (fileInputRef.current.files.length) {
      // setFiles((prevFiles) => [...prevFiles, fileInputRef.current.files[0]]);
      setFiles((prevFiles) =>
        prevFiles.map((file, index) => {
          if (index === claimIndex) {
            return Object.assign(fileInputRef.current.files[0]);
          } else {
            return file;
          }
        })
      );

      if (autoProcessMode) {
        processInvoice(fileInputRef.current.files[0]);
      }
    }
  };

  const processInvoice = (pdfFile) => {
    var doc;
    var pageOne;
    var items;
    var iItems = [];
    var lineObject = new Object();

    setAutoProcessMode(true);

    try {
      let url;
      try {
        url = window.URL.createObjectURL(pdfFile);
      } catch (error) {
        setError(error);
      }

      let loadingTask;
      try {
        loadingTask = pdfjs.getDocument(url);
      } catch (error) {
        setError(error);
      }

      loadingTask.promise
        .then(function (pdf) {
          doc = pdf;

          pdf.getPage(1).then(function (page) {
            pageOne = page;
            page
              .getTextContent()
              .then(function (tc) {
                if (!tc.items.length) {
                  setError(true);
                  return;
                }

                var invNo;
                let invPos = tc.items.findIndex((item) =>
                  item.str.includes("Our Invoice No.")
                );

                for (let i = 1; ; i++) {
                  if (
                    tc.items[invPos + i].str &&
                    tc.items[invPos + i].str.trim()
                  ) {
                    invNo = tc.items[invPos + i].str;
                    break;
                  }
                }

                setInvoiceNumber(invNo);
                setClaims((prevClaims) =>
                  prevClaims.map((claim, index) => {
                    if (index === 0) {
                      return { ...claim, invoiceNumber: invNo };
                    } else {
                      return claim;
                    }
                  })
                );

                var invDate;
                let invDatePos = tc.items.findIndex((item) =>
                  item.str.includes("Invoice Date")
                );

                for (let i = 1; ; i++) {
                  if (
                    tc.items[invDatePos + i].str &&
                    tc.items[invDatePos + i].str.trim()
                  ) {
                    invDate = tc.items[invDatePos + i].str;
                    break;
                  }
                }

                try {
                  setInvoiceDate(new Date(invDate).toISOString());
                  setClaims((prevClaims) =>
                    prevClaims.map((claim, index) => {
                      if (index === 0) {
                        return {
                          ...claim,
                          invoiceDate: new Date(invDate).toISOString(),
                        };
                      } else {
                        return claim;
                      }
                    })
                  );
                } catch (error) {
                  setError(true);
                }

                items = tc.items.filter(
                  (elem, index) =>
                    elem.str.includes("ABB") ||
                    (index + 2 < tc.items.length &&
                      tc.items[index + 2].str.includes("ABB")) ||
                    (index > 2 && tc.items[index - 2].str.includes("ABB")) ||
                    (index > 4 && tc.items[index - 4].str.includes("ABB"))
                );

                items.forEach((item, idx) => {
                  if (item.str.includes("$")) {
                    lineObject.price = parseFloat(item.str.slice(1));
                    lineObject.id = iItems.length + 1;
                    iItems.push(lineObject);
                    lineObject = new Object();
                  } else if (item.str.includes("ABB")) {
                    lineObject.productName = item.str;
                  } else if (items[idx + 1].str.includes("ABB")) {
                    lineObject.partNo = item.str;
                  } else {
                    lineObject.quantity = parseInt(item.str);
                  }
                });

                setInvoiceItems(iItems);
              })
              .catch((err) => setError(err));
          });
        })
        .catch((err) => setError(err));
    } catch (error) {
      setError(true);
    }
  };

  function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  }

  const uploadFileToAWS = () => {
    //Change to map through array of files
    let urls = [];
    let jwtToken = localStorage.getItem("clientToken");
    if (files) {
      return new Promise((resolve, reject) => {
        files.forEach((invoiceFile, fileIndex) => {
          let filename = uuidv4() + "." + invoiceFile.name.split(".")[1];

          let data = {
            objectName: filename,
            objectType: invoiceFile.type.split("/")[1],
            token: jwtToken,
            file: invoiceFile,
            contentType: invoiceFile.type,
            id: userID ? userID : userAccount.id,
          };

          setClaims((prevClaims) =>
            prevClaims.map((claim, index) => {
              if (index === fileIndex) {
                return {
                  ...claim,
                  invoiceURL: `${process.env.REACT_APP_ASSETS_URL}/users/${
                    userID ? userID : userAccount.id
                  }/claims/attachments/${filename}`,
                  invoiceType: invoiceFile.type.split("/")[1],
                };
              } else {
                return claim;
              }
            })
          );
          urls.push(
            `${process.env.REACT_APP_ASSETS_URL}/users/${
              userID ? userID : userAccount.id
            }/claims/attachments/${filename}`
          );

          dispatch(preSignedURLClaims(data)).then(() => {
            resolve();
          });
        });
      }).then(() => {
        handleSubmit(urls);
      });
    }
  };

  return (
    <div
      style={{
        padding: 26,
        // paddingTop: 52,
        // paddingBottom: 52,
        marginBottom: matches ? 0 : 0,
        flexFlow: "row nowrap",
        justifyContent: "center",
      }}
      className={classes.claimContainer}
    >
      {/* {step !== 2 && (
        <div
          style={{ width: matches ? "8.5%" : "100%" }}
          className={classes.buttonContainer}
        >
          {step != 0 && (
            <BackButton onClick={handleStep}>
              {step === 0 ? "Home" : "Go Back"}
            </BackButton>
          )}
        </div>
      )}
      {step === 2 && !matches && (
        <div
          style={{ width: matches ? "8.5%" : "100%" }}
          className={classes.buttonContainer}
        >
          {step != 0 && (
            <BackButton onClick={handleStep}>
              {step === 0 ? "Home" : "Go Back"}
            </BackButton>
          )}
        </div>
      )} */}
      {step === 0 ? (
        <ClaimIntro
          setStep={setStep}
          resetProcess={resetProcess}
          setAutoProcessMode={setAutoProcessMode}
        />
      ) : step === 2 ? (
        <ClaimStepTwo
          setStep={setStep}
          setInvoiceDate={setInvoiceDate}
          invoiceDate={invoiceDate}
          invoiceNumber={invoiceNumber}
          setInvoiceNumber={setInvoiceNumber}
          handleStep={handleStep}
          invoiceTotal={invoiceTotal}
          setInvoiceTotal={setInvoiceTotal}
          // setCalculatedPoints={setCalculatedPoints}
          claimProducts={claimProducts}
          setClaimProducts={setClaimProducts}
          clientProductsList={clientProductsList}
          setTown={setTown}
          town={town}
          setStore={setStore}
          store={store}
          claims={claims}
          setClaims={setClaims}
          fileInputRef={fileInputRef}
          handleUpload={handleUpload}
          files={files}
          setFiles={setFiles}
        />
      ) : step === 3 ? (
        <ClaimStepThree
          fileInputRef={fileInputRef}
          handleUpload={handleUpload}
          handleStep={handleStep}
          step={step}
          setStep={setStep}
          manualEntry={manualEntry}
          setManualEntry={setManualEntry}
          files={files}
          // loading={invoiceLoading}
          invoiceError={invoiceError}
          invoiceItems={invoiceItems}
          setInvoiceItems={setInvoiceItems}
          invoiceTotal={invoiceTotal}
          setInvoiceTotal={setInvoiceTotal}
          invoiceNumber={invoiceNumber}
          invoiceDate={invoiceDate}
          admin={userAccount.roleID <= 2 ? true : false}
          error={error}
          setError={setError}
          claims={claims}
          setClaims={setClaims}
          resetProcess={resetProcess}
        />
      ) : (
        <ClaimSubmission
          handleSubmit={handleSubmit}
          invoiceTotal={invoiceTotal}
          handleStep={handleStep}
          manualEntry={manualEntry}
          step={step}
          userID={userID}
          admin={userAccount.roleID <= 2 ? true : false}
          setUserID={setUserID}
          usersList={usersList}
          uploadFileToAWS={uploadFileToAWS}
        />
      )}
    </div>
  );
}
