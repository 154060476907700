import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { selectAuth } from "../features/auth/authSlice";
import { fetchMyself } from "../features/users/usersSlice";

// import ListRouter from './ListRouter';
import Contact from "../features/content/Contact";
import Help from "../features/content/Help";
import HelpAddress from "../features/content/HelpAddress";
import HelpDamaged from "../features/content/HelpDamaged";
import HelpDelivery from "../features/content/HelpDelivery";
import HelpDiscontinued from "../features/content/HelpDiscontinued";
import HelpWishlist from "../features/content/HelpWishlist";
import HowItWorks from "../features/content/HowItWorks";
import HowToClaim from "../features/content/HowToClaim";
import Privacy from "../features/content/Privacy";
import TermsConditions from "../features/content/TermsConditions";
import InvoiceContainer from "../features/invoices/InvoiceContainer";
import Cart from "../features/orders/Cart";
import Order from "../features/orders/Order";
import ReviewOrder from "../features/orders/ReviewOrder";
import SubmitOrder from "../features/orders/SubmitOrder";
import Product from "../features/products/Product";
import Account from "../features/users/Account";
import EditDetails from "../features/users/EditDetails";
import Wishlist from "../features/wishlist/Wishlist";
import AppBar from "./AppBar";
import CategoryContainer from "./CategoryContainer";
import Landing from "./Landing";
import Menu from "./Menu";
// import ClaimContainer from '../features/claims/ClaimContainer';
import Leaderboard from "../features/leaderboard/Leaderboard";

import {
  fetchCategoriesTree,
  selectCategories,
} from "../features/categories/categoriesSlice";

import AdminContainer from "./AdminContainer";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    minWidth: "100%",
    flexDirection: "column",
  },
  appBar: {
    // backgroundColor: '#131f47',
    backgroundColor: theme.palette.primary.main,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    // display: "flex",
    // justifyContent: "center",
    // paddingBottom: "2rem",
    // paddingTop: "2rem",
    // padding: theme.spacing(3),
  },
  logout: {
    // border: '1px solid "#fff"',
    color: "#fff",
    fontWeight: "bold",
    marginLeft: "auto,",
    position: "absolute",
    right: 15,
  },
  title: {
    flexGrow: 1,
    textAlign: "left",
  },
}));

export default function ContentContainer(props) {
  const matches = useMediaQuery("(min-width:850px)");
  const classes = useStyles();
  const { signedIn } = useSelector(selectAuth);
  const history = useHistory();

  const { customisation } = props;
  const { categoriesTree } = useSelector(selectCategories);
  const dispatch = useDispatch();
  let jwtToken = localStorage.getItem("clientToken");

  useEffect(() => {
    if (jwtToken && !categoriesTree.length) {
      dispatch(
        fetchCategoriesTree({
          token: jwtToken,
        })
      );      
      dispatch(fetchMyself({ token: jwtToken, history: history }))
    }
  }, []);

  return (
    <div className={classes.root}>
      {/* <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            Points Shop Core
          </Typography>
          {/* <Avatar>TD</Avatar>
          <Button className={classes.logout}>LOGOUT</Button> 
          <AvatarMenu />
        </Toolbar>
      </AppBar> */}
      {/* <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <ListRouter open={open} />
      </Drawer> */}
      {signedIn && <AppBar />}
      {matches && signedIn && <Menu />}

      <div className={classes.content}>
        {/* <div className={classes.toolbar} /> */}
        <Switch>
          <Route path="/admin" component={AdminContainer} />
          <Route path="/leaderboard" component={Leaderboard} />
          <Route path="/help/privacy" component={Privacy} />
          <Route path="/help/terms-conditions" component={TermsConditions} />
          <Route path="/help/contact" component={Contact} />
          <Route path="/help/how-it-works" component={HowItWorks} />
          {process.env.REACT_APP_ENABLE_CLAIMS == "true"  && (
            <Route path="/help/how-to-claim" component={HowToClaim} />
          )}

          <Route path="/help/wishlist" component={HelpWishlist} />
          <Route path="/help/address" component={HelpAddress} />
          <Route path="/help/damaged" component={HelpDamaged} />
          <Route path="/help/delivery" component={HelpDelivery} />
          <Route path="/help/discontinued" component={HelpDiscontinued} />
          <Route path="/help" component={Help} />
          {/* <Route path="/claim" component={ClaimContainer} /> */}
          {signedIn && <Route path="/wishlist" component={Wishlist} />}
          {signedIn && <Route path="/orders/:orderID" component={Order} />}
          {signedIn && <Route path="/account/edit" component={EditDetails} />}
          {signedIn && <Route path="/account" component={Account} />}
          {process.env.REACT_APP_ENABLE_CLAIMS == "true" && (
            <Route path="/invoice" component={InvoiceContainer} />
          )}
          {signedIn && <Route path="/cart" component={Cart} />}
          {signedIn && <Route path="/review-order" component={ReviewOrder} />}
          {signedIn && <Route path="/submit-order" component={SubmitOrder} />}
          {signedIn && (
            <Route path="/products/search" component={CategoryContainer} />
          )}
          {signedIn && (
            <Route path="/products/:productName" component={Product} />
          )}
          {signedIn && (
            <Route path="/:categories" component={CategoryContainer} />
          )}

          {signedIn && <Route path="/" component={Landing} />}
        </Switch>
      </div>
    </div>
  );
}
