import { saveAs } from "file-saver";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  fetchBulkUploadHistory,
  preSignedURL,
  selectUsers
} from "../users/usersSlice";

import IconButton from "@material-ui/core/IconButton";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import LocalDialog from "../../common/LocalDialog";

import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
// import {fetchBrands, selectBrands} from '../brands/brandsSlice';
import Dialog from "@material-ui/core/Dialog";
import Paper from "@material-ui/core/Paper";

// import {fetchSuppliers, selectSuppliers} from '../suppliers/suppliersSlice';
// import {fetchCategoriesTree, fetchCategories, selectCategories} from '../categories/categoriesSlice';
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "column",
  },
  addItem: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export default function BulkUploadHistory(props) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const { loading, uploadedFiles, url } = useSelector(selectUsers);
  // const { brandsList } = useSelector(selectBrands);

  let jwtToken = localStorage.getItem("clientToken");

  const fileInputRef = useRef();

  const [localOpen, setLocalOpen] = useState(false);
  const [downloadOpen, setDownloadOpen] = useState(false);
  const [downloadName, setDownloadName] = useState("");
  const [localValue, setLocalValue] = useState("Australia");
  const [filename, setFilename] = useState(null);

  const uploadFileToAWS = (newValue, download, filename) => {
    if (fileInputRef.current.files.length) {
      let csvFile = fileInputRef.current.files[0];
      // handleFiles(fileInputRef.current.files);

      // let name = `${uuidv4}`.csv;
      let data = {
        objectName: csvFile.name,
        objectType: "csv",
        token: jwtToken,
        csvFile,
        contentType: "text/csv",
        localization: newValue === "Australia" ? "en-AU" : "en-NZ",
        download,
      };
      dispatch(preSignedURL(data));
    } else if (download) {
      let data = {
        objectName: filename,
        objectType: "csv",
        token: jwtToken,
        contentType: "text/csv",
        download,
      };
      dispatch(preSignedURL(data));
    }
  };

  const handleUpload = (e) => {
    if (fileInputRef.current.files.length) {
      setLocalOpen(true);
      setFilename(fileInputRef.current.files[0].name);
    }
  };

  const handleDownload = (newValue, filename) => {
    uploadFileToAWS(newValue, true, filename);
    setDownloadOpen(true);
    setDownloadName(filename);
  };

  const handleClose = (newValue) => {
    setLocalOpen(false);
    if (newValue) {
      setLocalValue(newValue);
      uploadFileToAWS(newValue, false);
    }
  };

  const fileInputClicked = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    dispatch(
      fetchBulkUploadHistory({
        token: jwtToken,
      })
    );
  }, [dispatch]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        paddingBottom: 20,
        overflowY: "scroll",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Paper
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 10,
          padding: 24,
          paddingTop: 0,
        }}
      >
        <div
          style={{
            textAlign: "left",
            width: "100%",
            marginBottom: 20,
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid black",
            alignItems: "center",
          }}
        >
          Upload History
          <div className={classes.addItem}>
            <input
              ref={fileInputRef}
              accept="text/csv"
              className={classes.input}
              id="icon-button-csv"
              type="file"
              onChange={handleUpload}
              style={{ display: "none" }}
            />
            <label htmlFor="icon-button-csv">
              <IconButton
                aria-label="upload csv"
                onClick={fileInputClicked}
                style={{
                  fontSize: 30,
                  paddingLeft: 8,
                  paddingRight: 8,
                  color: "#5469d4",
                }}
              >
                <CloudUploadIcon style={{ fontSize: 40 }} />
              </IconButton>
            </label>
            <LocalDialog
              open={localOpen}
              value={localValue}
              handleClose={handleClose}
              fileName={filename}
            />
            <Dialog
              open={downloadOpen}
              onClose={() => {
                setDownloadOpen(false);
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  padding: 20,
                }}
              >
                <span style={{ textAlign: "center", marginBottom: 10 }}>
                  {downloadName} is ready for download!
                </span>
                <div
                  style={{
                    width: 200,
                    textAlign: "center",
                    marginBottom: 10,
                    padding: 5,
                    background: "#5469d4",
                    color: "#ffffff",
                    borderRadius: 4,
                    border: 0,
                    padding: "12px 16px",
                    cursor: "pointer",
                    display: "block",
                    transition: "all 0.2s ease",
                    boxShadow: "0px 4px 5.5px 0px rgba(0, 0, 0, 0.07)",
                  }}
                  onClick={() => {
                    saveAs(url, downloadName);
                    setDownloadOpen(false);
                  }}
                >
                  Download
                </div>
              </div>
            </Dialog>
          </div>
        </div>
        <div>
          <div style={{ display: "flex", textAlign: "left" }}>
            <div style={{ width: 800 }}>Filename</div>
            <div style={{ width: 200 }}>Upload Date</div>
          </div>
          <hr />
          <div style={{height: 300, overflowY: 'scroll', paddingRight: 10}}>
            {uploadedFiles.map((file) => (
              <div
                style={{
                  display: "flex",
                  textAlign: "left",
                  fontSize: 16,
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                <div style={{ width: 800 }}>{file.fileName}</div>
                <div style={{ width: 200 }}>{file.uploadDate}</div>
                <div
                  style={{
                    width: 200,
                    textAlign: "center",
                    padding: 5,
                    background: "#5469d4",
                    color: "#ffffff",
                    borderRadius: 4,
                    border: 0,
                    padding: "12px 16px",
                    cursor: "pointer",
                    display: "block",
                    transition: "all 0.2s ease",
                    boxShadow: "0px 4px 5.5px 0px rgba(0, 0, 0, 0.07)",
                  }}
                  onClick={() => {
                    handleDownload(localValue, file.fileName);
                  }}
                >
                  Download
                </div>
              </div>
            ))}
          </div>
        </div>
      </Paper>
    </div>
  );
}
