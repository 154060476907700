import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";

import { infoSnack, successSnack, errorSnack } from "../snackbar/snackbarSlice";
import { fetchWishlist } from "../wishlist/wishlistSlice";
import { fetchCart } from "../carts/cartsSlice";
import axios from "axios";

import baseURL from "../../app/utils.js";
import { uuidv4 } from "../../app/utils";
import { logout } from "../auth/authSlice";
axios.interceptors.request.use(
  (req) => {
    req.headers.common["X-Request-ID"] = uuidv4();
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export const fetchUsers = createAsyncThunk(
  "users/fetchUsers",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/users${args.query}`,
        config
      );
      thunkAPI.dispatch(setUsersAmount(response.headers["x-total-count"]));
      thunkAPI.dispatch(setLastQuery(args.query));
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error fetching users list"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchMoreUsers = createAsyncThunk(
  "users/fetchMoreUsers",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/users${args.query}&offset=${args.offset}`,
        config
      );
      // thunkAPI.dispatch(setUsersAmount(response.headers["x-total-count"]));
      return await response.data;
    } catch (error) {
      if (error.message.includes("401")) {
        localStorage.removeItem("clientUser");
        localStorage.removeItem("clientToken");
      }
      if (error.response && error.response.data) {
        // thunkAPI.dispatch(
        //   errorSnack(
        //     error.response.data.error_description ||
        //       'Error fetching products list'
        //   )
        // );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const filterUsers = createAsyncThunk(
  "users/filterUsers",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/users${args.query}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error fetching users list"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchUserAccount = createAsyncThunk(
  "users/fetchUserAccount",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/users?limit=50`,
        config
      );
      let user = response.data.filter((user) => user.username === args.user);
      //
      return await user[0];
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching user account"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchUserByID = createAsyncThunk(
  "users/fetchUserByID",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/admin/users/${args.id}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error fetching user data"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchMyself = createAsyncThunk(
  "users/fetchMyself",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      const response = await axios.get(`${baseURL}/v1/admin/myself`, config);
      thunkAPI.dispatch(
        fetchWishlist({ token: args.token, id: response.data.id })
      );
      thunkAPI.dispatch(fetchCart({ token: args.token, id: response.data.id }));
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        // thunkAPI.dispatch(
        //   errorSnack(
        //     error.response.data.error_description || 'Error fetching user data'
        //   )
        // );
        thunkAPI.dispatch(logout({ history: args.history }));
        return thunkAPI.rejectWithValue(
          error.message.includes("401")
            ? { message: "Inavlid Username or Password" }
            : { message: error.message }
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //full options
      const response = await axios.delete(
        `${baseURL}/v1/admin/users/${args.id}`,
        config
      );
      thunkAPI.dispatch(successSnack("User deleted"));
      return await response;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error deleting user"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const updateUser = createAsyncThunk(
  "users/updateUser",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //full options
      const response = await axios.patch(
        `${baseURL}/v1/admin/users/${args.id}`,
        args.user,
        config
      );
      // thunkAPI.dispatch(fetchUsers({token: args.token, query: '?limit=25'}));
      thunkAPI.dispatch(fetchMyself({ token: args.token }));
      thunkAPI.dispatch(fetchUserByID({ token: args.token, id: args.id }));

      // thunkAPI.dispatch(successSnack('User updated'));
      thunkAPI.dispatch(infoSnack("Account details updated successfully"));

      return await response;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error updating user"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const createUser = createAsyncThunk(
  "users/createUser",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //full options
      const response = await axios.post(
        `${baseURL}/v1/admin/users`,
        args.user,
        config
      );
      thunkAPI.dispatch(successSnack("User created successfully"));
      thunkAPI.dispatch(fetchUsers({ token: args.token, query: "?limit=25" }));

      return await response;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error creating user"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const registerUser = createAsyncThunk(
  "users/registerUser",
  async (args, thunkAPI) => {
    const config = {
      headers: { "X-Client-Host": args.host },
    };
    try {
      //full options
      const response = await axios.post(
        `${baseURL}/v1/users/register`,
        args.user,
        config
      );

      return await response;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue({ error_description: error.message });
      }
    }
  }
);

export const activateUser = createAsyncThunk(
  "users/activateUser",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //full options
      const response = await axios.patch(
        `${baseURL}/v1/admin/users/${args.id}/activate`,
        {},
        config
      );
      return new Promise((resolve) => {
        thunkAPI.dispatch(successSnack("User activated")).then(() => {
          resolve();
        });
      });
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error activating user"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const deactivateUser = createAsyncThunk(
  "users/deactivateUser",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //full options
      const response = await axios.patch(
        `${baseURL}/v1/admin/users/${args.id}/deactivate`,
        config
      );
      thunkAPI.dispatch(successSnack("User deactivated"));

      return await response;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error deactivating user"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const updatePassword = createAsyncThunk(
  "users/updatePassword",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //full options
      const response = await axios.patch(
        `${baseURL}/v1/admin/users/${args.id}/password`,
        args.passwordUpdate,
        config
      );
      thunkAPI.dispatch(successSnack("Password updated successfully"));

      return await response;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error updating password"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const preSignedURL = createAsyncThunk(
  "users/preSignedURL",
  async (args, thunkAPI) => {
    const config = {
      headers: {
        Authorization: `Bearer ${args.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      //full options
      const response = await axios.post(
        `${baseURL}/v1/admin/pre_signed_url`,

        {
          objectName: args.objectName,
          objectType: args.objectType,
          objectContentType: args.contentType,
          download: args.download,
        },
        config
      );
      if (response.data.preSignedURL && !args.download) {
        thunkAPI.dispatch(
          uploadCSV({
            token: args.token,
            csvFile: args.csvFile,
            url: response.data.preSignedURL,
            contentType: args.contentType,
            localization: args.localization,
          })
        );
      }
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error creating pre-signed url for AWS upload"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const uploadCSV = createAsyncThunk(
  "users/uploadCSV",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: {
          // Authorization: `Bearer ${args.token}`,
          "Content-Type": args.contentType,
          "Access-Control-Allow-Origin": "*",
        },
        //
      };
      const response = await axios.put(`${args.url}`, args.csvFile, config);
      if (response.status === 200) {
        thunkAPI.dispatch(
          bulkUpload({
            token: args.token,
            csvObj: args.csvFile.name,
            localization: args.localization,
          })
        );
      }

      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error uploading CSV to AWS"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const bulkUpload = createAsyncThunk(
  "users/bulkUpload",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${args.token}`,
          "Accept-Language": args.localization,
        },
        //
      };
      const response = await axios.post(
        `${baseURL}/v1/admin/users/bulk`,
        { csvObj: args.csvObj },
        config
      );
      if (response.data.statusToken) {
        thunkAPI.dispatch(
          bulkUploadStatusPoll({
            token: args.token,
            statusToken: response.data.statusToken,
            localization: args.localization,
          })
        );
      }

      thunkAPI.dispatch(setFeedback({ failures: [] }));
      thunkAPI.dispatch(setBulkDetails({ name: args.csvObj }));
      thunkAPI.dispatch(setBulkStatus("uploading"));

      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "bulk upload error"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const bulkUploadStatusPoll = createAsyncThunk(
  "users/bulkUploadStatusPoll",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${args.token}`,
          "Accept-Language": args.localization,
          "Access-Control-Allow-Origin": "*",
        },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/users/bulk/check/${args.statusToken}`,
        config
      );
      if (response.data && response.data.status === "completed") {
        // thunkAPI.dispatch(
        //   successSnack('Bulk CSV upload completed successfully')
        // );
        thunkAPI.dispatch(setBulkStatus("success"));
        return await response.data;
      } else if (
        response.data &&
        response.data.status === "completed with error(s)"
      ) {
        thunkAPI.dispatch(setFeedback(response.data));
        thunkAPI.dispatch(setBulkStatus("success with errors"));
      } else if (response.data && response.data.status === "incomplete") {
        setTimeout(() => {
          thunkAPI.dispatch(
            bulkUploadStatusPoll({
              token: args.token,
              statusToken: args.statusToken,
              localization: args.localization,
            })
          );
        }, 5000);
      } else {
        thunkAPI.rejectWithValue({
          error: "An error occured with the bulk upload",
        });
        thunkAPI.dispatch(
          setFeedback({
            failures: [],
            errorMessage: "An error occured with the bulk upload",
          })
        );
        thunkAPI.dispatch(setBulkStatus("error"));
      }
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "bulk upload error"
          )
        );

        thunkAPI.dispatch(
          setFeedback({
            failures: [],
            errorMessage:
              error.response.data.error_description || "bulk upload error",
          })
        );
        thunkAPI.dispatch(setBulkStatus("error"));
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchBulkUploadHistory = createAsyncThunk(
  "products/fetchBulkUploadHistory",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/users/bulk/uploaded_files`,
        config
      );

      return await response.data;
    } catch (error) {
      if (error.message.includes("401")) {
        thunkAPI.dispatch(logout());
        return thunkAPI.rejectWithValue(
          error.message.includes("401")
            ? { message: "Inavlid Username or Password" }
            : { message: error.message }
        );
      } else if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching products list"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const presSignedAvatarURL = createAsyncThunk(
  "users/presSignedAvatarURL",
  async (args, thunkAPI) => {
    const config = {
      headers: {
        Authorization: `Bearer ${args.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      //full options
      const response = await axios.post(
        `${baseURL}/v1/admin/pre_signed_url`,

        {
          objectName: `users/${args.id}/avatars/${args.objectName}`,
          objectType: "asset",
          // objectType: args.objectType,
          objectContentType: args.contentType,
        },
        config
      );
      if (response.data.preSignedURL) {
        thunkAPI.dispatch(
          uploadAvatar({
            token: args.token,
            url: response.data.preSignedURL,
            contentType: args.contentType,
            file: args.file,
            userID: args.id,
            imageURL: `${args.objectName}`,
          })
        );
      }
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error creating pre-signed url for AWS upload"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const uploadAvatar = createAsyncThunk(
  "users/uploadAvatar",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: {
          // Authorization: `Bearer ${args.token}`,
          "Content-Type": args.contentType,
          "Access-Control-Allow-Origin": "*",
        },
        //
      };
      const response = await axios.put(`${args.url}`, args.file, config);
      if (response.status === 200) {
        // thunkAPI.dispatch(
        //   updateUser({
        //     token: args.token,
        //     id: args.userID,
        //     user: {avatarImage: args.imageURL},
        //   })
        // );
      }

      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error uploading image to AWS"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getFullList = createAsyncThunk(
  "claims/getFullList",
  async (args, thunkAPI) => {
    let objToMap = [];
    for (let i = 0; i < (args.targetLength - args.offset) / 100; i++) {
      objToMap.push({
        token: args.token,
        query: args.query,
        offset: args.offset + 100 * i,
      });
    }

    Promise.all(
      objToMap.map((requestData) =>
        thunkAPI.dispatch(fetchMoreUsers(requestData))
      )
    )
      .then((results) => {})
      .catch((err) => {
        return thunkAPI.rejectWithValue("Error fetching full claims list");
      });
  }
);

const usersSlice = createSlice({
  name: "users",
  initialState: {
    user: {},
    usersList: [],
    usersFilter: [],
    loading: "idle",
    message: "",
    error: "",
    userAccount: {},
    editing: false,
    success: false,
    lastQuery: "",
    feedback: { failures: [] },
    bulkDetails: { name: "" },
    bulkStatus: "",
    avatarProgress: "",
    updatePasswordStatus: false,
    url: "",
    uploadedFiles: [],
    usersAmount: "",
  },
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
    resetUserAccount(state, action) {
      state.userAccount = {};
    },
    setFeedback: (state, action) => {
      state.feedback = action.payload;
    },
    setBulkDetails: (state, action) => {
      state.bulkDetails = action.payload;
    },
    setBulkStatus: (state, action) => {
      state.bulkStatus = action.payload;
    },
    resetBulk: (state, action) => {
      state.bulkStatus = "";
      state.bulkDetails = { name: "" };
      state.feedback = { failures: [] };
    },
    setLastQuery: (state, action) => {
      state.lastQuery = action.payload;
    },
    setUpdatePasswordStatus(state, action) {
      state.updatePasswordStatus = action.payload;
    },
    setUsersAmount: (state, action) => {
      state.usersAmount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.pending, (state) => {
      state.usersList = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(fetchUsers.fulfilled, (state, { payload }) => {
      state.usersList = payload;
      state.usersFilter = payload;
      state.loading = "loaded";
      state.editing = false;
    });
    builder.addCase(fetchUsers.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchMoreUsers.fulfilled, (state, { payload }) => {
      state.error = "";
      state.usersList = state.usersList.concat(payload).sort((a, b) => {
        return a.id - b.id;
      });
      state.loading = "loaded";
    });
    builder.addCase(fetchMoreUsers.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(getFullList.fulfilled, (state, { payload }) => {
      state.error = "";
      state.usersList = state.usersList.sort(function (a, b) {
        return a.id - b.id;
      });
      state.loading = "loaded";
    });
    builder.addCase(getFullList.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(preSignedURL.pending, (state) => {
      state.url = "";
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(preSignedURL.fulfilled, (state, { payload }) => {
      state.url = payload.preSignedURL;
      state.loading = "loaded";
      state.editing = false;
    });
    builder.addCase(preSignedURL.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchBulkUploadHistory.pending, (state) => {
      state.uploadedFiles = [];
      state.loading = "loading";
    });
    builder.addCase(fetchBulkUploadHistory.fulfilled, (state, { payload }) => {
      state.error = "";
      state.uploadedFiles = payload;
      state.loading = "loaded";
    });
    builder.addCase(fetchBulkUploadHistory.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchMyself.pending, (state) => {
      state.loading = "loading";
    });
    builder.addCase(fetchMyself.fulfilled, (state, { payload }) => {
      state.userAccount = payload;
      state.loading = "loaded";
    });
    builder.addCase(fetchMyself.rejected, (state, action) => {
      state.loading = "error";
    });
    builder.addCase(fetchUserAccount.pending, (state) => {
      state.userAccount = {};
      state.loading = "loading";
    });
    builder.addCase(fetchUserAccount.fulfilled, (state, { payload }) => {
      state.userAccount = payload;
      state.loading = "loaded";
    });
    builder.addCase(fetchUserAccount.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchUserByID.pending, (state) => {
      state.user = {};
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(fetchUserByID.fulfilled, (state, { payload }) => {
      state.user = payload;
      state.loading = "loaded";
      state.editing = true;
    });
    builder.addCase(fetchUserByID.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(deleteUser.pending, (state) => {
      state.message = "";
      state.loading = "loading";
    });
    builder.addCase(deleteUser.fulfilled, (state, { payload }) => {
      // state.message = payload;
      state.loading = "loaded";
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(updateUser.pending, (state) => {
      state.message = "";
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(updateUser.fulfilled, (state, { payload }) => {
      // state.message = payload;
      state.loading = "loaded";
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(filterUsers.pending, (state) => {
      state.usersFilter = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(filterUsers.fulfilled, (state, { payload }) => {
      state.usersFilter = payload;
      state.loading = "loaded";
    });
    builder.addCase(filterUsers.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(createUser.pending, (state) => {
      state.message = "";
      // state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(createUser.fulfilled, (state, { payload }) => {
      // state.message = payload;
      // state.loading = "loaded";
    });
    builder.addCase(createUser.rejected, (state, action) => {
      // state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(activateUser.pending, (state) => {
      state.message = "";
      state.loading = "loading";
    });
    builder.addCase(activateUser.fulfilled, (state, { payload }) => {
      // state.message = payload;
      state.loading = "loaded";
    });
    builder.addCase(activateUser.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(deactivateUser.pending, (state) => {
      state.message = "";
      state.loading = "loading";
    });
    builder.addCase(deactivateUser.fulfilled, (state, { payload }) => {
      // state.message = payload;
      state.loading = "loaded";
    });
    builder.addCase(deactivateUser.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(updatePassword.pending, (state) => {
      state.message = "";
      state.updatePasswordStatus = false;
      state.loading = "loading";
    });
    builder.addCase(updatePassword.fulfilled, (state, { payload }) => {
      // state.message = payload;
      state.updatePasswordStatus = true;
      state.loading = "loaded";
    });
    builder.addCase(updatePassword.rejected, (state, action) => {
      state.loading = "error";
      state.updatePasswordStatus = false;
      state.error = action.error.message;
    });
    builder.addCase(registerUser.pending, (state) => {
      state.error = "";
      state.success = false;
    });
    builder.addCase(registerUser.fulfilled, (state, { payload }) => {
      state.error = "";
      state.success = true;
    });
    builder.addCase(registerUser.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.payload;
    });
    builder.addCase(uploadAvatar.pending, (state) => {
      state.avatarProgress = "pending";
    });
    builder.addCase(uploadAvatar.fulfilled, (state, { payload }) => {
      state.avatarProgress = "success";
    });
    builder.addCase(uploadAvatar.rejected, (state, action) => {
      state.avatarProgress = "error";
    });
  },
});
// Action creators are generated for each case reducer function
export const {
  setUpdatePasswordStatus,
  setFeedback,
  setBulkDetails,
  setBulkStatus,
  resetBulk,
  setLastQuery,
  setUser,
  setUsersAmount,
  resetUserAccount,
} = usersSlice.actions;

export const selectUsers = createSelector(
  (state) => ({
    users: state.users,
    usersList: state.users.usersList,
    usersFilter: state.users.usersFilter,
    loading: state.users.loading,
    error: state.users.error,
    editing: state.users.editing,
    success: state.users.success,
    user: state.users.user,
    userAccount: state.users.userAccount,
    bulkStatus: state.users.bulkStatus,
    bulkDetails: state.users.bulkDetails,
    feedback: state.users.feedback,
    lastQuery: state.users.lastQuery,
    avatarProgress: state.users.avatarProgress,
    updatePasswordStatus: state.users.updatePasswordStatus,
    url: state.users.url,
    uploadedFiles: state.users.uploadedFiles,
    usersAmount: state.users.usersAmount,
  }),
  (state) => state
);
export default usersSlice.reducer;
