import Button from "@material-ui/core/Button";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "../Footer.css";

import { useMediaQuery } from "@material-ui/core";

import { setXClientHost } from "../app/utils";
import {
  fetchContentByName,
  fetchLogo,
  selectContents,
} from "../features/content/contentsSlice";
import { selectCustomisation } from "../features/customisation/customisationSlice";
const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: "center",
    backgroundColor: theme.palette.footerColor.main,
    display: "flex",
    color: theme.palette.footerFontColor.main,
    // zIndex: 1000
  },
  copyright: {
    backgroundColor: theme.palette.footerColor.main,
  },
  linkStyle: {
    // color: "#FFFFFF !important",
    color: theme.palette.footerFontColor.main,
  },
}));

const FooterButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 0, 1),
    backgroundColor: "transparent",
    border: "2px solid",
    borderColor: theme.palette.footerFontColor.main,
    borderRadius: "24px",
    color: theme.palette.footerFontColor.main,
    "&:hover": {
      backgroundColor: "#64B2CB2b",
    },
    fontWeight: "bold",
    fontSize: 11,
    minWidth: 175,
  },
}))(Button);

export default function Footer() {
  const { customisation } = useSelector(selectCustomisation);
  const dispatch = useDispatch();
  const classes = useStyles();
  let jwtToken = localStorage.getItem("clientToken");

  const { logo, social, secondaryLogo, secondaryLogoLink } =
    useSelector(selectContents);

  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [youtube, setYouTube] = useState("");
  const [linkedIn, setLinkedIn] = useState("");

  const matches = useMediaQuery("(min-width:850px)");

  useEffect(() => {
    dispatch(
      fetchContentByName({
        host: setXClientHost(),
        token: jwtToken,
        name: "social_links",
      })
    );
    dispatch(
      fetchContentByName({
        host: setXClientHost(),
        token: jwtToken,
        name: "secondary_logo",
      })
    );
    dispatch(
      fetchContentByName({
        host: setXClientHost(),
        token: jwtToken,
        name: "secondary_logo_link",
      })
    );
  }, []);

  useEffect(() => {
    if (!logo.value) {
      dispatch(
        fetchLogo({
          host: setXClientHost(),
          token: jwtToken,
          name: "logo",
        })
      );
    }
  }, [logo]);

  useEffect(() => {
    if (social.value) {
      try {
        let socialObj = JSON.parse(JSON.parse(social.value));
        setFacebook(socialObj.facebook);
        setInstagram(socialObj.instagram);
        setYouTube(socialObj.youtube);
        setLinkedIn(socialObj.linkedIn);
      } catch (error) {}
    }
  }, [social]);

  // console.log("value", logo.value);

  return (
    // <div className={classes.container}>
    <div className={classes.container} id="footer">
      <div id="footer-inner">
        <div
          id="block-pshop_core-small_logo"
          className="block block-pshop_core block-footer_block block-id-268 clearfix odd"
          style={{
            flexWrap: 'wrap',
            justifyContent: 'center',
            display:
              process.env.REACT_APP_SHOW_FOOTER_LOGO === "true"
                ? "flex"
                : "none",

          }}
        >
          {/* <div className="block-inner"> */}
          <div className="content">
            {/* <img
                height="50"
                src={`/${customisation.theme.footerLogo}`}
              /> */}
            {logo.value && (
              <img
                id="small-logo"
                src={`${process.env.REACT_APP_ASSETS_URL}/theme/logo/${logo.value}`}
                alt="logo"
                height="90px"
              />
            )}
          </div>
          {secondaryLogo.value && (
            <div className="content" style={{ marginLeft: 30 }}>
              <a href={secondaryLogoLink.value} disabled={!secondaryLogoLink.value}>
                {logo.value && (
                  <img
                    id="small-logo"
                    src={`${process.env.REACT_APP_ASSETS_URL}/theme/secondary_logo/${secondaryLogo.value}`}
                    alt="logo"
                    height="90px"
                  />
                )}
              </a>
            </div>
          )}
          {/* </div> */}
        </div>
        <div
          id="block-menu-secondary-links"
          className="block block-menu block-footer_block block-id-241 clearfix even"
        >
          <div className="block-inner">
            <h3 className="title block-title">Secondary links</h3>
            <div className="content">
              <ul className="menu">
                <li className="leaf first help">
                  <Link className={classes.linkStyle} to={"/help"} title="Help">
                    Help
                  </Link>
                </li>
                <li className="leaf privacy_policy">
                  <Link
                    className={classes.linkStyle}
                    to={"/help/privacy"}
                    title="Privacy Policy"
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li className="leaf terms_-amp-_conditions">
                  <Link
                    className={classes.linkStyle}
                    to={"/help/terms-conditions"}
                    title="Terms &amp; Conditions"
                  >
                    Terms &amp; Conditions
                  </Link>
                </li>
                <li className="leaf last contact_us">
                  <Link
                    className={classes.linkStyle}
                    to={"/help/contact"}
                    title="Contact"
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>{" "}
        <div
          id="block-pshop_core-quick-contacts"
          className="block block-pshop_core block-footer_block block-id-259 clearfix odd"
        >
          <div className="block-inner">
            <div className="content">
              <div className="item-list">
                <ul>
                  <li className="first">{customisation.theme.phone} </li>
                  {process.env.REACT_APP_SHOW_FOOTER_EMAIL == "true" && (
                    <li>
                      <a
                        className={classes.linkStyle}
                        href={`mailto:${customisation.theme.email}`}
                      >
                        {customisation.theme.email}
                      </a>
                    </li>
                  )}
                  <li className="last">
                    {facebook && (
                      <a href={facebook} target="_blank">
                        <img id="facebook" src="/Facebook.png" />
                      </a>
                    )}
                    {instagram && (
                      <a href={instagram} target="_blank">
                        <img id="instagram" src="/instagram_white.png" />
                      </a>
                    )}
                    {linkedIn && (
                      <a href={linkedIn} target="_blank">
                        <img id="linkedin" src="/LinkedIn.png" />
                      </a>
                    )}
                    {youtube && (
                      <a href={youtube} target="_blank">
                        <img id="youtube" src="/YouTube.png" />
                      </a>
                    )}
                  </li>
                </ul>
              </div>{" "}
            </div>
          </div>
        </div>{" "}
        <div
          id="block-pshop_core-melb-address"
          className="block block-pshop_core block-footer_block block-id-260 clearfix even"
          style={{
            display:
              process.env.REACT_APP_SHOW_FOOTER_ADDRESS === "true"
                ? "flex"
                : "none",
          }}
        >
          <div className="block-inner">
            <div className="content">
              <div className="item-list">
                <ul>
                  <li className="first">{customisation.theme.city} </li>
                  <li>{customisation.theme.address} </li>
                  <li className="last">{customisation.theme.state} </li>
                </ul>
              </div>{" "}
            </div>
          </div>
        </div>{" "}
        <div
          id="block-pshop_core-syd-address"
          className="block block-pshop_core block-footer_block block-id-261 clearfix odd"
        >
          <div className="block-inner">
            <div className="content">
              <div className="item-list">
                <ul>
                  <li className="first">Sydney</li>
                  <li>Suite 202, 1753 Botany Rd</li>
                  <li className="last">Banksmeadow, NSW 2019</li>
                </ul>
              </div>{" "}
            </div>
          </div>
        </div>{" "}
        <div
          id="block-menu-primary-links"
          className="block block-menu block-footer_block block-id-240 clearfix even"
        >
          <div className="block-inner">
            <h3 className="title block-title">Content links</h3>

            <div className="content">
              <ul className="menu">
                <li className="leaf first how_it_works">
                  <Link
                    className={classes.linkStyle}
                    to="/help/how-it-works"
                    title="How it Works"
                  >
                    <FooterButton>How It Works</FooterButton>
                  </Link>
                </li>
                {process.env.REACT_APP_ENABLE_CLAIMS == "true" && (
                  <li className="leaf last how_to_claim">
                    <Link
                      className={classes.linkStyle}
                      to="/help/how-to-claim"
                      title="How to Claim"
                    >
                      <FooterButton>How To Claim</FooterButton>
                    </Link>
                  </li>
                )}
              </ul>{" "}
            </div>
          </div>
        </div>{" "}
      </div>
    </div>
  );
}
