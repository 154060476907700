import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCustomisation } from "../customisation/customisationSlice";
import {
  fetchBrandRedeemedAllTime,
  fetchBrandRedeemedAnnually,
  fetchBrandRedeemedDaily,
  fetchBrandRedeemedMonthly,
  fetchCategoryRedeemedAllTime,
  fetchCategoryRedeemedAnnually,
  fetchCategoryRedeemedDaily,
  fetchCategoryRedeemedMonthly,
  fetchMemberLoginAllTime,
  fetchMemberLoginAnnually,
  fetchMemberLoginDaily,
  fetchMemberLoginMonthly,
  fetchMemberRedeemedAllTime,
  fetchMemberRedeemedAnnually,
  fetchMemberRedeemedDaily,
  fetchMemberRedeemedMonthly,
  fetchMembersByProductClaimed,
  fetchPointsBalance,
  fetchPointsEarnedAnnually,
  fetchPointsEarnedMonthly,
  fetchPointsRedeemed,
  fetchPointsRedeemedAnnually,
  fetchPointsRedeemedMonthly,
  fetchProductRedeemedAllTime,
  fetchProductRedeemedAnnually,
  fetchProductRedeemedDaily,
  fetchProductRedeemedMonthly,
  fetchProductWishlistAllTime,
  fetchProductWishlistAnnually,
  fetchProductWishlistDaily,
  fetchProductWishlistMonthly,
  fetchSnapshot,
  fetchUserLoginDayAverage,
  fetchUserLoginTimeAverage,
  getFullListRedeemed,
  selectReports,
} from "./reportsSlice";

import {
  fetchUserPointsHistory,
  selectUserPoints,
} from "../userPoints/userPointsSlice";

import { replaceUrlParam } from "../../app/utils";
import {
  fetchUsers,
  getFullList,
  selectUsers
} from "../users/usersSlice";

import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";

import {
  fetchProducts,
  getFullList as getFullProductsList,
  selectClientProducts,
} from "../products/productsSlice";

import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.secondaryFont.main,

    fontWeight: "bold",
    fontSize: 30,
    marginBottom: 32,
    // textAlign: "center",
  },
  rowContainer: {
    display: "flex",
    // justifyContent: 'space-between'
  },
  label: {
    color: theme.palette.secondaryFont.main,
    fontWeight: "bold",
    marginRight: 16,
    minWidth: 150,
    paddingBottom: 4,
    fontSize: 18,
  },
  vals: {
    fontSize: 18,
  },
  chartContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
  },
  chartArea: {
    display: "flex",
    justifyContent: "space-around",
    marginBottom: 20,
  },
  listArea: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    marginBottom: 20,
  },
  filterButtons: {
    display: "flex",
    justifyContent: "space-around",
    marginLeft: 50,
  },
  chartHeader: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around",
    alignItems: "center",
  },
  listHeader: {
    display: "flex",
    width: "100%",
    padding: 20,
    alignItems: "center",
  },
  listContainer: {
    display: "flex",
    justifyContent: "center",
    padding: 10,
  },
  list: {
    border: "1px #ccc solid",
    borderRadius: 5,
    padding: "10px 20px",
    marginRight: 10,
    width: "20rem",
  },
  memberList: {
    border: "1px #ccc solid",
    borderRadius: 5,
    padding: "10px 20px",
    marginRight: 10,
    width: "23rem",
  },
  orderedList: {
    paddingLeft: 18,
    fontSize: 16,
  },
  listTitle: {
    fontSize: 20,
  },
}));

export default function ProgramSnapshot(props) {
  let jwtToken = localStorage.getItem("clientToken");
  const { customisation } = useSelector(selectCustomisation);

  const dispatch = useDispatch();
  const classes = useStyles();

  const [earnedFilter, setEarnedFilter] = useState("annually");
  const [earnedCompare, setEarnedCompare] = useState(false);
  const [redeemedFilter, setRedeemedFilter] = useState("annually");
  const [redeemedCompare, setRedeemedCompare] = useState(false);
  const [productsFilter, setProductsFilter] = useState("annually");
  const [membersFilter, setMembersFilter] = useState("annually");

  const [earnedMonthFilter, setEarnedMonthFilter] = useState(null);
  const [redeemedMonthFilter, setRedeemedMonthFilter] = useState(null);
  const [earnedYearFilter, setEarnedYearFilter] = useState(null);
  const [redeemedYearFilter, setRedeemedYearFilter] = useState(null);

  const [intUsers, setIntUsers] = useState(false);
  const [intReports, setIntReports] = useState(false);

  const [memberByProduct, setMemberByProduct] = useState(null);

  const { clientProductsList, productsAmount } =
    useSelector(selectClientProducts);

  // const [rewardPointsLoading, setRewardPointsLoading] = useState(true);
  // const [productActivityLoading, setProductActivityLoading] = useState(true);
  // const [loginReportsLoading, setLoginReportsLoading] = useState(true);

  const {
    error,
    snapshot,
    loading,
    memberLoading,
    reportList,
    redeemedReportList,
    earnedReportList,
    userLoginList,
    productRedeemedList,
    brandRedeemedList,
    categoryRedeemedList,
    productWishlistList,
    memberRedeemedList,
    memberLoginList,
    pointsBalance,
    reportsAmount,
    membersByClaimedProduct,
  } = useSelector(selectReports);

  const { pointsHistory } = useSelector(selectUserPoints);

  useEffect(() => {
    dispatch(
      fetchUsers({
        token: jwtToken,
        query: "",
      })
    ).then(() => {
      setIntUsers(true);
    });
    dispatch(
      fetchSnapshot({
        token: jwtToken,
      })
    );
    dispatch(
      fetchPointsRedeemed({
        token: jwtToken,
        query: "",
      })
    ).then(() => {
      setIntReports(true);
    });
  }, [dispatch]);

  const { usersList, usersAmount, lastQuery } = useSelector(selectUsers);

  const firstProductUpdate = useRef(true);

  useEffect(() => {
    if (!clientProductsList.length) {
      dispatch(fetchProducts({ token: jwtToken, query: "" }));
    }
  }, []);

  useEffect(() => {
    if (
      clientProductsList.length &&
      clientProductsList.length != productsAmount &&
      firstProductUpdate.current
    ) {
      let searchQuery = "?limit=100";
      searchQuery = replaceUrlParam(searchQuery, "limit", 100);
      dispatch(
        getFullProductsList({
          token: jwtToken,
          offset: clientProductsList.length,
          query: searchQuery,
          targetLength: productsAmount,
        })
      );
      firstProductUpdate.current = false;
    }
  }, [clientProductsList.length]);

  useEffect(() => {
    if (memberByProduct) {
      dispatch(
        fetchMembersByProductClaimed({
          token: jwtToken,
          limit: "100",
          offset: "0",
          claimProduct: memberByProduct.name,
        })
      );
    }
  }, [memberByProduct]);

  const [user, setUser] = useState("");

  useEffect(() => {
    if (user) {
      dispatch(
        fetchUserPointsHistory({
          token: jwtToken,
          userID: user.id,
        })
      );
    }
  }, [user]);

  useEffect(() => {
    if (pointsHistory.data) {
      console.log("test", processPointsHistory(pointsHistory.data));
    }
  }, [pointsHistory.data]);

  // const [balance, setBalance] = useState(0);

  // useEffect(() => {
  //   let balance = 0;
  //   if (pointsHistory.data && pointsHistory.data.length) {
  //     console.log(
  //       "test",
  //       Object.values(
  //         pointsHistory.data
  //           .map((element, index) => {
  //             console.log("balance", balance);
  //             if (element.statusID == 20 || element.statusID == 3) {
  //               balance = balance - element.points;
  //             } else {
  //               balance = balance + element.points;
  //             }
  //             return Object.assign({}, element, { balance: balance });
  //           })
  //           .reduce((a, c) => {
  //             a[c.createdAt] = Object.assign(a[c.createdAt] || {}, c);
  //             return a;
  //           }, {})
  //       )
  //     );
  //   }
  // }, [pointsHistory.data]);

  const truncate = (str) => {
    return str.length > 20 ? str.substr(0, 19) + "..." : str;
  };

  const fetchRewardPointsReports = () => {
    const d = new Date();
    let day = ("0" + d.getDate()).slice(-2);
    let month = ("0" + (d.getMonth() + 1)).slice(-2);
    let year = d.getFullYear();

    let monthArr = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ];

    var years = [];
    var currentYear = new Date().getFullYear();
    let startYear = 2022;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }

    years.forEach((arrYear) => {
      monthArr.forEach((monthNo) => {
        dispatch(
          fetchPointsRedeemedMonthly({
            token: jwtToken,
            month: `${arrYear}-${monthNo}`,
          })
        );
      });
    });
    dispatch(
      fetchPointsRedeemedAnnually({
        token: jwtToken,
        year,
      })
    );

    years.forEach((arrYear) => {
      monthArr.forEach((monthNo) => {
        dispatch(
          fetchPointsEarnedMonthly({
            token: jwtToken,
            month: `${arrYear}-${monthNo}`,
          })
        );
      });
    });
    dispatch(
      fetchPointsEarnedAnnually({
        token: jwtToken,
        year,
      })
    );
  };

  const fetchProductActivityReports = () => {
    const d = new Date();
    let day = ("0" + d.getDate()).slice(-2);
    let month = ("0" + (d.getMonth() + 1)).slice(-2);
    let year = d.getFullYear();

    dispatch(
      fetchProductRedeemedDaily({
        token: jwtToken,
        //Change this back - testing purposes only
        // date: `${year}-${month}-14`,
        date: `${year}-${month}-${day}`,
      })
    );
    dispatch(
      fetchProductRedeemedMonthly({
        token: jwtToken,
        date: `${year}-${month}`,
      })
    );
    dispatch(
      fetchProductRedeemedAnnually({
        token: jwtToken,
        date: `${year}`,
      })
    );
    dispatch(
      fetchProductRedeemedAllTime({
        token: jwtToken,
      })
    );
    dispatch(
      fetchBrandRedeemedDaily({
        token: jwtToken,
        //Change this back - testing purposes only
        // date: `${year}-${month}-14`,
        date: `${year}-${month}-${day}`,
      })
    );
    dispatch(
      fetchBrandRedeemedMonthly({
        token: jwtToken,
        date: `${year}-${month}`,
      })
    );
    dispatch(
      fetchBrandRedeemedAnnually({
        token: jwtToken,
        date: `${year}`,
      })
    );
    dispatch(
      fetchBrandRedeemedAllTime({
        token: jwtToken,
      })
    );
    dispatch(
      fetchCategoryRedeemedDaily({
        token: jwtToken,
        //Change this back - testing purposes only
        // date: `${year}-${month}-14`,
        date: `${year}-${month}-${day}`,
      })
    );
    dispatch(
      fetchCategoryRedeemedMonthly({
        token: jwtToken,
        date: `${year}-${month}`,
      })
    );
    dispatch(
      fetchCategoryRedeemedAnnually({
        token: jwtToken,
        date: `${year}`,
      })
    );
    dispatch(
      fetchCategoryRedeemedAllTime({
        token: jwtToken,
      })
    );
    dispatch(
      fetchProductWishlistDaily({
        token: jwtToken,
        //Change this back - testing purposes only
        // date: `${year}-${month}-14`,
        date: `${year}-${month}-${day}`,
      })
    );
    dispatch(
      fetchProductWishlistMonthly({
        token: jwtToken,
        date: `${year}-${month}`,
      })
    );
    dispatch(
      fetchProductWishlistAnnually({
        token: jwtToken,
        date: `${year}`,
      })
    );
    dispatch(
      fetchProductWishlistAllTime({
        token: jwtToken,
      })
    );
  };

  const fetchMemberActivityReports = () => {
    const d = new Date();
    let day = ("0" + d.getDate()).slice(-2);
    let month = ("0" + (d.getMonth() + 1)).slice(-2);
    let year = d.getFullYear();

    dispatch(
      fetchPointsBalance({
        token: jwtToken,
      })
    );
    dispatch(
      fetchMemberRedeemedDaily({
        token: jwtToken,
        //Change this back - testing purposes only
        // date: `${year}-${month}-14`,
        date: `${year}-${month}-${day}`,
      })
    );
    dispatch(
      fetchMemberRedeemedMonthly({
        token: jwtToken,
        date: `${year}-${month}`,
      })
    );
    dispatch(
      fetchMemberRedeemedAnnually({
        token: jwtToken,
        date: `${year}`,
      })
    );
    dispatch(
      fetchMemberRedeemedAllTime({
        token: jwtToken,
      })
    );
    dispatch(
      fetchMemberLoginDaily({
        token: jwtToken,
        //Change this back - testing purposes only
        // date: `${year}-${month}-14`,
        date: `${year}-${month}-${day}`,
      })
    );
    dispatch(
      fetchMemberLoginMonthly({
        token: jwtToken,
        date: `${year}-${month}`,
      })
    );
    dispatch(
      fetchMemberLoginAnnually({
        token: jwtToken,
        date: `${year}`,
      })
    );
    dispatch(
      fetchMemberLoginAllTime({
        token: jwtToken,
      })
    );
  };

  const fetchLoginReports = () => {
    dispatch(
      fetchUserLoginDayAverage({
        token: jwtToken,
      })
    );
    dispatch(
      fetchUserLoginTimeAverage({
        token: jwtToken,
      })
    );
    dispatch(
      fetchSnapshot({
        token: jwtToken,
      })
    );
  };

  useEffect(() => {
    fetchRewardPointsReports();
    fetchProductActivityReports();
    fetchLoginReports();
    fetchMemberActivityReports();
  }, [dispatch]);

  useEffect(() => {
    if (intUsers) {
      let searchQuery = lastQuery ? lastQuery : "?limit=100";
      searchQuery = replaceUrlParam(searchQuery, "limit", 100);
      dispatch(
        getFullList({
          token: jwtToken,
          offset: usersList.length,
          query: searchQuery,
          targetLength: usersAmount,
        })
      );
    }
  }, [intUsers]);

  useEffect(() => {
    if (intReports) {
      let searchQuery = lastQuery ? lastQuery : "?limit=100";
      searchQuery = replaceUrlParam(searchQuery, "limit", 100);
      dispatch(
        getFullListRedeemed({
          token: jwtToken,
          offset: reportList.length,
          query: searchQuery,
          targetLength: reportsAmount,
        })
      );
    }
  }, [intReports]);

  const columns = [
    { field: "displayName", headerName: "Display Name", width: 260 },
    { field: "email", headerName: "Email", width: 260 },
    {
      field: "company",
      headerName: "Company",
      width: 260,
    },
  ];

  const displayMonths = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const generateYears = () => {
    var years = [];
    var currentYear = new Date().getFullYear();
    let startYear = 2022;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }

    return years.map((year) => {
      return <option value={year}>{year}</option>;
    });
  };

  const generateMonthArray = (monthFilter, yearFilter) => {
    let monthArray = [];

    const d = new Date();
    let day = d.getDate();

    let month;
    if (monthFilter) {
      month = monthFilter;
    } else {
      month = ("0" + (d.getMonth() + 1)).slice(-2);
    }

    let year;
    if (yearFilter) {
      year = yearFilter;
    } else {
      year = d.getFullYear();
    }
    //Check for 31 days
    for (let i = 0; i < 31; i++) {
      let dispDay = ("0" + (i + 1)).slice(-2);

      let dayObject = {
        points: 0,
        rptDate: `${year}-${month}-${dispDay}`,
      };
      monthArray.push(dayObject);
    }

    return monthArray;
  };

  const generateWeekArray = () => {
    const weekdays = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    let weekArray = [];

    const d = new Date();
    // let day = d.getDate();
    // let month = ("0" + (d.getMonth() + 1)).slice(-2);
    // let year = d.getFullYear();

    for (let i = 0; i < 7; i++) {
      let dayObject = {
        loginAverage: 0,
        rptDay: weekdays[i],
      };
      weekArray.push(dayObject);
    }

    return weekArray;
  };

  const generateDayArray = () => {
    let dayArray = [];

    const d = new Date();
    // let day = d.getDate();
    // let month = ("0" + (d.getMonth() + 1)).slice(-2);
    // let year = d.getFullYear();

    for (let i = 0; i < 24; i++) {
      let hourObject = {
        loginAverage: 0,
        rptTime: `${("0" + (i + 1)).slice(-2)}:00:00`,
      };
      dayArray.push(hourObject);
    }

    return dayArray;
  };

  const generateYearArray = () => {
    let yearArray = [];

    const d = new Date();
    let month = d.getMonth() + 1;
    let year = d.getFullYear();

    for (let i = 0; i < 12; i++) {
      let dispMonth = ("0" + (i + 1)).slice(-2);

      let monthObject = {
        points: 0,
        comparePoints: 0,
        rptDate: `${year}-${dispMonth}-01`,
      };
      yearArray.push(monthObject);
    }

    return yearArray;
  };

  // const generateAllTimeArray = () => {
  //   let allTimeArray = [];

  //   const d = new Date();
  //   let year = d.getFullYear();
  //   let rptStartDate = new Date(2020, 1, 1);
  //   let rptStartYear = rptStartDate.getFullYear();

  //   let yearsCount = year - rptStartYear;

  //   for (let i = 0; i < yearsCount; i++) {
  //     let dispYear = rptStartYear + i;

  //     let yearObject = {
  //       points: 0,
  //       rptDate: `${dispYear}-01-01`,
  //     };
  //     allTimeArray.push(yearObject);
  //   }

  //   return allTimeArray;
  // };

  const createRedeemMonthlyData = () => {
    if (redeemedMonthFilter) {
      if (redeemedYearFilter) {
        let monthArray = generateMonthArray(
          redeemedMonthFilter,
          redeemedYearFilter
        );
        redeemedReportList.monthly
          .filter(
            (date) =>
              date.rptDate.substring(5, 7) == redeemedMonthFilter &&
              date.rptDate.substring(0, 4) == redeemedYearFilter
          )
          .forEach((date) => {
            monthArray.forEach((blankDate) => {
              if (date.rptDate == blankDate.rptDate) {
                blankDate.points = date.points;
              }
            });
          });

        return monthArray;
      } else {
        let monthArray = generateMonthArray(redeemedMonthFilter, null);
        redeemedReportList.monthly
          .filter((date) => date.rptDate.substring(5, 7) == redeemedMonthFilter)
          .forEach((date) => {
            monthArray.forEach((blankDate) => {
              if (date.rptDate == blankDate.rptDate) {
                blankDate.points = date.points;
              }
            });
          });

        return monthArray;
      }
    } else {
      let monthArray = generateMonthArray();
      redeemedReportList.monthly.forEach((date) => {
        monthArray.forEach((blankDate) => {
          if (date.rptDate === blankDate.rptDate) {
            blankDate.points = date.points;
          }
        });
      });

      return monthArray;
    }
  };

  const createRedeemAnnualData = () => {
    let yearArray = generateYearArray();

    redeemedReportList.annually.forEach((year) => {
      yearArray.forEach((blankYear) => {
        if (year.rptMonth === blankYear.rptDate) {
          blankYear.points = year.points;
        }
      });
    });

    redeemedReportList.annuallyCompare.forEach((month) => {
      yearArray.forEach((blankYear) => {
        if (month.rptMonth.substring(5) === blankYear.rptDate.substring(5)) {
          blankYear.comparePoints = month.points;
        }
      });
    });

    return yearArray;
  };

  const createEarnedMonthlyData = () => {
    if (earnedMonthFilter) {
      if (earnedYearFilter) {
        let monthArray = generateMonthArray(
          earnedMonthFilter,
          earnedYearFilter
        );
        earnedReportList.monthly
          .filter(
            (date) =>
              date.rptDate.substring(5, 7) == earnedMonthFilter &&
              date.rptDate.substring(0, 4) == earnedYearFilter
          )
          .forEach((date) => {
            monthArray.forEach((blankDate) => {
              if (date.rptDate == blankDate.rptDate) {
                blankDate.points = date.points;
              }
            });
          });

        return monthArray;
      } else {
        let monthArray = generateMonthArray(earnedMonthFilter, null);
        earnedReportList.monthly
          .filter((date) => date.rptDate.substring(5, 7) == earnedMonthFilter)
          .forEach((date) => {
            monthArray.forEach((blankDate) => {
              if (date.rptDate == blankDate.rptDate) {
                blankDate.points = date.points;
              }
            });
          });

        return monthArray;
      }
    } else {
      let monthArray = generateMonthArray();
      earnedReportList.monthly.forEach((date) => {
        monthArray.forEach((blankDate) => {
          if (date.rptDate === blankDate.rptDate) {
            blankDate.points = date.points;
          }
        });
      });

      return monthArray;
    }
  };

  const createEarnedAnnualData = () => {
    let yearArray = generateYearArray();

    earnedReportList.annually.forEach((year) => {
      yearArray.forEach((blankYear) => {
        if (year.rptMonth === blankYear.rptDate) {
          blankYear.points = year.points;
        }
      });
    });

    earnedReportList.annuallyCompare.forEach((year) => {
      yearArray.forEach((blankYear) => {
        if (year.rptMonth.substring(5) === blankYear.rptDate.substring(5)) {
          blankYear.comparePoints = year.points;
        }
      });
    });

    return yearArray;
  };

  const createLoginWeeklyData = () => {
    let weekArray = generateWeekArray();

    userLoginList.daily.forEach((day) => {
      weekArray.forEach((blankDay) => {
        if (day.rptDay === blankDay.rptDay) {
          blankDay.loginAverage = day.loginAverage;
        }
      });
    });

    return weekArray;
  };

  const createLoginDailyData = () => {
    let dayArray = generateDayArray();

    userLoginList.hourly.forEach((hour) => {
      dayArray.forEach((blankHour) => {
        if (hour.rptTime === blankHour.rptTime) {
          blankHour.loginAverage = hour.loginAverage;
        }
      });
    });

    return dayArray;
  };

  const createAcquisitionDailyData = (usersList) => {
    let d = new Date();
    d.setDate(d.getDate() - 1);
    let dayArray = usersList.filter(
      (element) => new Date(element.createdAt).valueOf() > d.valueOf()
    );

    return dayArray.reverse();
  };

  // const createAcquisitionWeeklyData = (usersList) => {
  //   let d = new Date();
  //   let weekArray = usersList.filter((element) => new Date(element.createdAt) < d.setDate(d.getDate() - 7));

  //   return weekArray;
  // }

  const createAcquisitionMonthlyData = (usersList) => {
    let d = new Date();
    d.setMonth(d.getMonth() - 1);
    let monthArray = usersList.filter(
      (element) => new Date(element.createdAt).valueOf() > d.valueOf()
    );

    return monthArray.reverse();
  };

  const createAcquisitionYearlyData = (usersList) => {
    let d = new Date();
    d.setFullYear(d.getFullYear(), 0, 1);
    let yearArray = usersList.filter(
      (element) => new Date(element.createdAt).valueOf() > d.valueOf()
    );

    return yearArray.reverse();
  };

  const processPointsHistory = (data) => {
    let balance = 0;
    return Object.values(
      data
        .filter((element) => element.statusID != 5 && element.statusID != 21)
        .map((element, index) => {
          console.log("balance", balance);

          if (element.statusID == 20 || element.statusID == 3) {
            balance = balance - element.points;
          } else {
            balance = balance + element.points;
          }
          return (element = Object.assign({}, element, {
            balance: Number(balance),
            createdAt: element.createdAt
              .split("T")[0]
              .split("-")
              .reverse()
              .join("/"),
          }));
        })
    );
  };

  const [ready, setReady] = useState(false);
  if (loading === "loaded" && !ready) {
    setReady(true);
  }

  console.log("earnedMonthFilter", earnedMonthFilter);

  // May need to add js function to limit list size to 10 here.
  //productRedeemedList.daily.map((product)

  let content;
  if (memberLoading) {
    content = (
      <CircularProgress className={classes.progress} color="secondary" />
    );
  } else if (loading === "loaded" || loading === "idle") {
    content = (
      <Paper style={{ padding: 40, borderRadius: 10, width: "90%" }}>
        <div className={classes.title}>Reporting Dashboard</div>
        <div className={classes.chartArea}>
          <div className={classes.chartContainer}>
            <div className={classes.chartHeader}>
              <span>Reward Points Earned</span>
              {earnedFilter == "annually" &&
                earnedReportList.annuallyCompare.length > 0 && (
                  <div className={classes.filterButtons}>
                    <ToggleButton
                      className={classes.button}
                      selected={earnedCompare}
                      onChange={(e, value) =>
                        setEarnedCompare((cValue) => !cValue)
                      }
                    >
                      Compare
                    </ToggleButton>
                  </div>
                )}
              {earnedFilter == "monthly" && (
                <div className={classes.filterButtons}>
                  <select
                    labelId="demo-simple-select-label"
                    id="earned-month-select"
                    value={earnedMonthFilter}
                    label="Month"
                    placeholder="Month"
                    className={classes.button}
                    onChange={(e) => setEarnedMonthFilter(e.target.value)}
                  >
                    <option disabled selected value>
                      Month
                    </option>
                    <option value={"01"}>Jan</option>
                    <option value={"02"}>Feb</option>
                    <option value={"03"}>Mar</option>
                    <option value={"04"}>Apr</option>
                    <option value={"05"}>May</option>
                    <option value={"06"}>Jun</option>
                    <option value={"07"}>Jul</option>
                    <option value={"08"}>Aug</option>
                    <option value={"09"}>Sep</option>
                    <option value={"10"}>Oct</option>
                    <option value={"11"}>Nov</option>
                    <option value={"12"}>Dec</option>
                  </select>
                  <select
                    labelId="demo-simple-select-label"
                    id="earned-years-select"
                    value={earnedYearFilter}
                    label="Year"
                    placeholder="Year"
                    className={classes.button}
                    onChange={(e) => setEarnedYearFilter(e.target.value)}
                  >
                    <option disabled selected value>
                      Year
                    </option>
                    {generateYears()}
                  </select>
                </div>
              )}
              <div className={classes.filterButtons}>
                <ToggleButtonGroup
                  value={earnedFilter}
                  exclusive
                  onChange={(e, value) => setEarnedFilter(value)}
                >
                  <ToggleButton className={classes.button} value="monthly">
                    Monthly
                  </ToggleButton>
                  <ToggleButton className={classes.button} value="annually">
                    Annually
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </div>
            <ResponsiveContainer width="100%" height={350}>
              <LineChart
                data={
                  earnedFilter == "monthly"
                    ? createEarnedMonthlyData()
                    : createEarnedAnnualData()
                }
                // padding={50}
                margin={{ top: 20, right: 20, left: 20, bottom: 20 }}
              >
                <CartesianGrid stroke="#ccc" />
                <XAxis
                  dataKey="rptDate"
                  //   angle={45}
                  //   textAnchor="start"
                  tickFormatter={(value) =>
                    earnedFilter == "monthly"
                      ? value && value.split("-")[2]
                      : displayMonths[Number(value.split("-")[1]) - 1]
                  }
                  tick={{ fontSize: 10 }}
                >
                  {/* <Label value="Date (Feb)" offset={20} position="bottom" /> */}
                </XAxis>
                <YAxis tick={{ fontSize: 10 }} />
                <Tooltip />
                {earnedCompare && (
                  <Legend wrapperStyle={{ fontSize: "10px" }} />
                )}
                <Line
                  type="monotone"
                  dataKey="points"
                  name="Points Earned"
                  stroke="red"
                />
                {earnedCompare && (
                  <Line
                    type="monotone"
                    dataKey="comparePoints"
                    name="Points Earned - Last Year"
                    stroke="blue"
                  />
                )}
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div className={classes.chartContainer}>
            <div className={classes.chartHeader}>
              <span>Reward Points Redeemed</span>
              {redeemedFilter == "annually" &&
                redeemedReportList.annuallyCompare.length > 0 && (
                  <div className={classes.filterButtons}>
                    <ToggleButton
                      className={classes.button}
                      selected={redeemedCompare}
                      onChange={(e, value) =>
                        setRedeemedCompare((cValue) => !cValue)
                      }
                    >
                      Compare
                    </ToggleButton>
                  </div>
                )}

              {redeemedFilter == "monthly" && (
                <div className={classes.filterButtons}>
                  <select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={earnedMonthFilter}
                    label="Month"
                    placeholder="Month"
                    className={classes.button}
                    onChange={(e) => setRedeemedMonthFilter(e.target.value)}
                  >
                    <option disabled selected value>
                      Month
                    </option>
                    <option value={"01"}>Jan</option>
                    <option value={"02"}>Feb</option>
                    <option value={"03"}>Mar</option>
                    <option value={"04"}>Apr</option>
                    <option value={"05"}>May</option>
                    <option value={"06"}>Jun</option>
                    <option value={"07"}>Jul</option>
                    <option value={"08"}>Aug</option>
                    <option value={"09"}>Sep</option>
                    <option value={"10"}>Oct</option>
                    <option value={"11"}>Nov</option>
                    <option value={"12"}>Dec</option>
                  </select>
                  <select
                    labelId="demo-simple-select-label"
                    id="earned-years-select"
                    value={earnedYearFilter}
                    label="Year"
                    placeholder="Year"
                    className={classes.button}
                    onChange={(e) => setEarnedYearFilter(e.target.value)}
                  >
                    <option disabled selected value>
                      Year
                    </option>
                    {generateYears()}
                  </select>
                </div>
              )}
              <div className={classes.filterButtons}>
                <ToggleButtonGroup
                  value={redeemedFilter}
                  exclusive
                  onChange={(e, value) => setRedeemedFilter(value)}
                >
                  <ToggleButton className={classes.button} value="monthly">
                    Monthly
                  </ToggleButton>
                  <ToggleButton className={classes.button} value="annually">
                    Annually
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </div>
            <ResponsiveContainer width="100%" height={350}>
              <LineChart
                data={
                  redeemedFilter == "monthly"
                    ? createRedeemMonthlyData()
                    : createRedeemAnnualData()
                }
                margin={{ top: 20, right: 20, left: 20, bottom: 20 }}
              >
                <CartesianGrid stroke="#ccc" />
                <XAxis
                  dataKey="rptDate"
                  //   angle={45}
                  //   textAnchor="start"
                  tickFormatter={(value) =>
                    redeemedFilter == "monthly"
                      ? value && value.split("-")[2]
                      : displayMonths[Number(value.split("-")[1]) - 1]
                  }
                  tick={{ fontSize: 10 }}
                ></XAxis>
                <YAxis tick={{ fontSize: 10 }} />
                <Tooltip />
                {redeemedCompare && (
                  <Legend wrapperStyle={{ fontSize: "10px" }} />
                )}
                <Line
                  type="monotone"
                  dataKey="points"
                  name="Points Redeemed"
                  stroke="red"
                />
                {redeemedCompare && (
                  <Line
                    type="monotone"
                    dataKey="comparePoints"
                    name="Points Redeemed - Last Year"
                    stroke="blue"
                  />
                )}
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className={classes.listArea}>
          <div className={classes.listHeader}>
            <span>Product Activity</span>
            <div className={classes.filterButtons}>
              <ToggleButtonGroup
                value={productsFilter}
                exclusive
                onChange={(e, value) => setProductsFilter(value)}
              >
                <ToggleButton className={classes.button} value="daily">
                  Daily
                </ToggleButton>
                <ToggleButton className={classes.button} value="monthly">
                  Monthly
                </ToggleButton>
                <ToggleButton className={classes.button} value="annually">
                  Annually
                </ToggleButton>
                <ToggleButton className={classes.button} value="allTime">
                  All Time
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
          <div className={classes.listContainer}>
            <div className={classes.list}>
              <span className={classes.listTitle}>Most Redeemed Products</span>
              <List>
                <ol className={classes.orderedList}>
                  {productRedeemedList && productsFilter == "daily"
                    ? productRedeemedList.daily.slice(0, 9).map((product) => (
                        <li>
                          <ListItem
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              paddingLeft: 5,
                              paddingRight: 0,
                            }}
                          >
                            <ListItemText primary={product.productName} />
                          </ListItem>
                        </li>
                      ))
                    : productsFilter == "monthly"
                    ? productRedeemedList.monthly.slice(0, 9).map((product) => (
                        <li>
                          <ListItem
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              paddingLeft: 5,
                              paddingRight: 0,
                            }}
                          >
                            <ListItemText primary={product.productName} />
                          </ListItem>
                        </li>
                      ))
                    : productsFilter == "annually"
                    ? productRedeemedList.annually
                        .slice(0, 9)
                        .map((product) => (
                          <li>
                            <ListItem
                              style={{ paddingTop: 0, paddingBottom: 0 }}
                            >
                              <ListItemText primary={product.productName} />
                            </ListItem>
                          </li>
                        ))
                    : productRedeemedList.allTime.slice(0, 9).map((product) => (
                        <li>
                          <ListItem
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              paddingLeft: 5,
                              paddingRight: 0,
                            }}
                          >
                            <ListItemText primary={product.productName} />
                          </ListItem>
                        </li>
                      ))}
                </ol>
              </List>
            </div>
            <div className={classes.list}>
              <span className={classes.listTitle}>
                Most Wishlisted Products
              </span>
              <List>
                <ol className={classes.orderedList}>
                  {productWishlistList && productsFilter == "daily"
                    ? productWishlistList.daily.map((product) => (
                        <li>
                          <ListItem
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              paddingLeft: 5,
                              paddingRight: 0,
                            }}
                          >
                            <ListItemText primary={product.productName} />
                          </ListItem>
                        </li>
                      ))
                    : productsFilter == "monthly"
                    ? productWishlistList.monthly.map((product) => (
                        <li>
                          <ListItem
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              paddingLeft: 5,
                              paddingRight: 0,
                            }}
                          >
                            <ListItemText primary={product.productName} />
                          </ListItem>
                        </li>
                      ))
                    : productsFilter == "annually"
                    ? productWishlistList.annually.map((product) => (
                        <li>
                          <ListItem
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              paddingLeft: 5,
                              paddingRight: 0,
                            }}
                          >
                            <ListItemText primary={product.productName} />
                          </ListItem>
                        </li>
                      ))
                    : productWishlistList.allTime.map((product) => (
                        <li>
                          <ListItem
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              paddingLeft: 5,
                              paddingRight: 0,
                            }}
                          >
                            <ListItemText primary={product.productName} />
                          </ListItem>
                        </li>
                      ))}
                </ol>
              </List>
            </div>
            <div className={classes.list}>
              <span className={classes.listTitle}>Most Popular Brands</span>
              <List>
                <ol className={classes.orderedList}>
                  {brandRedeemedList && productsFilter == "daily"
                    ? brandRedeemedList.daily.map((brand) => (
                        <li>
                          <ListItem
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              paddingLeft: 5,
                              paddingRight: 0,
                            }}
                          >
                            <ListItemText primary={brand.brandName} />
                          </ListItem>
                        </li>
                      ))
                    : productsFilter == "monthly"
                    ? brandRedeemedList.monthly.map((brand) => (
                        <li>
                          <ListItem
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              paddingLeft: 5,
                              paddingRight: 0,
                            }}
                          >
                            <ListItemText primary={brand.brandName} />
                          </ListItem>
                        </li>
                      ))
                    : productsFilter == "annually"
                    ? brandRedeemedList.annually.map((brand) => (
                        <li>
                          <ListItem
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              paddingLeft: 5,
                              paddingRight: 0,
                            }}
                          >
                            <ListItemText primary={brand.brandName} />
                          </ListItem>
                        </li>
                      ))
                    : brandRedeemedList.allTime.map((brand) => (
                        <li>
                          <ListItem
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              paddingLeft: 5,
                              paddingRight: 0,
                            }}
                          >
                            <ListItemText primary={brand.brandName} />
                          </ListItem>
                        </li>
                      ))}
                </ol>
              </List>
            </div>
            <div className={classes.list}>
              <span className={classes.listTitle}>Most Popular Categories</span>
              <List>
                <ol className={classes.orderedList}>
                  {categoryRedeemedList && productsFilter == "daily"
                    ? categoryRedeemedList.daily.map((category) => (
                        <li>
                          <ListItem
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              paddingLeft: 5,
                              paddingRight: 0,
                            }}
                          >
                            <ListItemText primary={category.categoryName} />
                          </ListItem>
                        </li>
                      ))
                    : productsFilter == "monthly"
                    ? categoryRedeemedList.monthly.map((category) => (
                        <li>
                          <ListItem
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              paddingLeft: 5,
                              paddingRight: 0,
                            }}
                          >
                            <ListItemText primary={category.categoryName} />
                          </ListItem>
                        </li>
                      ))
                    : productsFilter == "annually"
                    ? categoryRedeemedList.annually.map((category) => (
                        <li>
                          <ListItem
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              paddingLeft: 5,
                              paddingRight: 0,
                            }}
                          >
                            <ListItemText primary={category.categoryName} />
                          </ListItem>
                        </li>
                      ))
                    : categoryRedeemedList.allTime.map((category) => (
                        <li>
                          <ListItem
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              paddingLeft: 5,
                              paddingRight: 0,
                            }}
                          >
                            <ListItemText primary={category.categoryName} />
                          </ListItem>
                        </li>
                      ))}
                </ol>
              </List>
            </div>
          </div>
        </div>
        <div className={classes.chartArea}>
          <div className={classes.chartContainer}>
            <div className={classes.chartHeader}>
              <span>Average Member Logins By Day</span>
            </div>
            <ResponsiveContainer width="100%" height={350}>
              <BarChart
                data={createLoginWeeklyData()}
                // padding={50}
                margin={{ top: 20, right: 20, left: 20, bottom: 20 }}
              >
                <CartesianGrid stroke="#ccc" vertical={false} />
                <XAxis
                  dataKey="rptDay"
                  //   angle={45}
                  //   textAnchor="start"
                  // tickFormatter={(value) =>
                  //   earnedFilter == "monthly"
                  //     ? value.split("-")[2]
                  //     : displayMonths[Number(value.split("-")[1]) - 1]
                  // }
                  tick={{ fontSize: 10 }}
                >
                  {/* <Label value="Date (Feb)" offset={20} position="bottom" /> */}
                </XAxis>
                <YAxis tick={{ fontSize: 10 }} />
                <Tooltip />
                <Bar
                  type="monotone"
                  dataKey="loginAverage"
                  name="Avg. Member Logins"
                  fill="red"
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div className={classes.chartContainer}>
            <div className={classes.chartHeader}>
              <span>Average Member Logins By Hour</span>
            </div>
            <ResponsiveContainer width="100%" height={350}>
              <BarChart
                data={createLoginDailyData()}
                // padding={50}
                margin={{ top: 20, right: 20, left: 20, bottom: 20 }}
              >
                <CartesianGrid stroke="#ccc" vertical={false} />
                <XAxis
                  dataKey="rptTime"
                  angle={45}
                  textAnchor="start"
                  // offset={10}
                  tickFormatter={(value) => value.substring(0, 5)}
                  tick={{ fontSize: 10 }}
                  interval={0}
                >
                  {/* <Label value="Date (Feb)" offset={20} position="bottom" /> */}
                </XAxis>
                <YAxis tick={{ fontSize: 10 }} />
                <Tooltip />
                <Bar
                  type="monotone"
                  dataKey="loginAverage"
                  name="Avg. Member Logins"
                  fill="red"
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className={classes.listArea}>
          <div className={classes.listHeader}>
            <span>Member Activity</span>
            <div className={classes.filterButtons}>
              <ToggleButtonGroup
                value={membersFilter}
                exclusive
                onChange={(e, value) => setMembersFilter(value)}
              >
                <ToggleButton className={classes.button} value="daily">
                  Daily
                </ToggleButton>
                <ToggleButton className={classes.button} value="monthly">
                  Monthly
                </ToggleButton>
                <ToggleButton className={classes.button} value="annually">
                  Annually
                </ToggleButton>
                <ToggleButton className={classes.button} value="allTime">
                  All Time
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
          <div className={classes.listContainer}>
            <div className={classes.memberList}>
              <span className={classes.listTitle}>
                Highest Rewards Point Balance
              </span>
              <List>
                <ol className={classes.orderedList}>
                  {pointsBalance &&
                    pointsBalance.map((user) => (
                      <li>
                        <ListItem
                          style={{
                            paddingTop: 0,
                            paddingBottom: 0,
                            paddingLeft: 5,
                            paddingRight: 0,
                          }}
                        >
                          <ListItemText
                            primary={user.userDisplayName}
                            secondary={truncate(user.userEmail)}
                            style={{ width: "85%" }}
                          />
                          <ListItemText
                            primary={"PTS"}
                            secondary={user.points}
                          />
                        </ListItem>
                      </li>
                    ))}
                </ol>
              </List>
            </div>
            <div className={classes.memberList}>
              <span className={classes.listTitle}>Biggest Redeemer</span>
              <List>
                <ol className={classes.orderedList}>
                  {memberRedeemedList && membersFilter == "daily"
                    ? memberRedeemedList.daily.map((user) => (
                        <li>
                          <ListItem
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              paddingLeft: 5,
                              paddingRight: 0,
                            }}
                          >
                            <ListItemText
                              primary={user.userDisplayName}
                              secondary={truncate(user.userEmail)}
                              style={{ width: "85%" }}
                            />
                            <ListItemText
                              primary={"PTS"}
                              secondary={user.points}
                            />
                          </ListItem>
                        </li>
                      ))
                    : membersFilter == "monthly"
                    ? memberRedeemedList.monthly.map((user) => (
                        <li>
                          <ListItem
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              paddingLeft: 5,
                              paddingRight: 0,
                            }}
                          >
                            <ListItemText
                              primary={user.userDisplayName}
                              secondary={truncate(user.userEmail)}
                              style={{ width: "85%" }}
                            />
                            <ListItemText
                              primary={"PTS"}
                              secondary={user.points}
                            />
                          </ListItem>
                        </li>
                      ))
                    : membersFilter == "annually"
                    ? memberRedeemedList.annually.map((user) => (
                        <li>
                          <ListItem
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              paddingLeft: 5,
                              paddingRight: 0,
                            }}
                          >
                            <ListItemText
                              primary={user.userDisplayName}
                              secondary={truncate(user.userEmail)}
                              style={{ width: "85%" }}
                            />
                            <ListItemText
                              primary={"PTS"}
                              secondary={user.points}
                            />
                          </ListItem>
                        </li>
                      ))
                    : memberRedeemedList.allTime.map((user) => (
                        <li>
                          <ListItem
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              paddingLeft: 5,
                              paddingRight: 0,
                            }}
                          >
                            <ListItemText
                              primary={user.userDisplayName}
                              secondary={truncate(user.userEmail)}
                              style={{ width: "85%" }}
                            />
                            <ListItemText
                              primary={"PTS"}
                              secondary={user.points}
                            />
                          </ListItem>
                        </li>
                      ))}
                </ol>
              </List>
            </div>
            <div className={classes.memberList}>
              <span className={classes.listTitle}>Most Active Members</span>
              <List>
                <ol className={classes.orderedList}>
                  {memberLoginList && membersFilter == "daily"
                    ? memberLoginList.daily.map((user) => (
                        <li>
                          <ListItem
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              paddingLeft: 5,
                              paddingRight: 0,
                            }}
                          >
                            <ListItemText
                              primary={user.userDisplayName}
                              secondary={truncate(user.userEmail)}
                              style={{ width: "70%" }}
                            />
                            <ListItemText
                              primary={"Logins"}
                              secondary={user.loginCount}
                            />
                          </ListItem>
                        </li>
                      ))
                    : membersFilter == "monthly"
                    ? memberLoginList.monthly.map((user) => (
                        <li>
                          <ListItem
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              paddingLeft: 5,
                              paddingRight: 0,
                            }}
                          >
                            <ListItemText
                              primary={user.userDisplayName}
                              secondary={truncate(user.userEmail)}
                              style={{ width: "70%" }}
                            />
                            <ListItemText
                              primary={"Logins"}
                              secondary={user.loginCount}
                            />
                          </ListItem>
                        </li>
                      ))
                    : membersFilter == "annually"
                    ? memberLoginList.annually.map((user) => (
                        <li>
                          <ListItem
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              paddingLeft: 5,
                              paddingRight: 0,
                            }}
                          >
                            <ListItemText
                              primary={user.userDisplayName}
                              secondary={truncate(user.userEmail)}
                              style={{ width: "70%" }}
                            />
                            <ListItemText
                              primary={"Logins"}
                              secondary={user.loginCount}
                            />
                          </ListItem>
                        </li>
                      ))
                    : memberLoginList.allTime.map((user) => (
                        <li>
                          <ListItem
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              paddingLeft: 5,
                              paddingRight: 0,
                            }}
                          >
                            <ListItemText
                              primary={user.userDisplayName}
                              secondary={truncate(user.userEmail)}
                              style={{ width: "70%" }}
                            />
                            <ListItemText
                              primary={"Logins"}
                              secondary={user.loginCount}
                            />
                          </ListItem>
                        </li>
                      ))}
                </ol>
              </List>
            </div>
            <div className={classes.memberList}>
              <span className={classes.listTitle}>Member Acquisitions</span>
              <List>
                <ol className={classes.orderedList}>
                  {usersList && membersFilter == "daily"
                    ? createAcquisitionDailyData(usersList).length > 0 &&
                      createAcquisitionDailyData(usersList)
                        .slice(0, 9)
                        .map((user) => (
                          <li>
                            <ListItem
                              style={{
                                paddingTop: 0,
                                paddingBottom: 0,
                                paddingLeft: 5,
                                paddingRight: 0,
                              }}
                            >
                              <ListItemText
                                primary={user.displayName}
                                secondary={truncate(user.email)}
                                style={{ width: "70%" }}
                              />
                              <ListItemText
                                primary={"Acquired"}
                                secondary={user.createdAt
                                  .split("T")[0]
                                  .split("-")
                                  .reverse()
                                  .join("/")}
                              />
                            </ListItem>
                          </li>
                        ))
                    : membersFilter == "monthly"
                    ? createAcquisitionMonthlyData(usersList).length > 0 &&
                      createAcquisitionMonthlyData(usersList)
                        .slice(0, 9)
                        .map((user) => (
                          <li>
                            <ListItem
                              style={{
                                paddingTop: 0,
                                paddingBottom: 0,
                                paddingLeft: 5,
                                paddingRight: 0,
                              }}
                            >
                              <ListItemText
                                primary={user.displayName}
                                secondary={truncate(user.email)}
                                style={{ width: "70%" }}
                              />
                              <ListItemText
                                primary={"Acquired"}
                                secondary={user.createdAt
                                  .split("T")[0]
                                  .split("-")
                                  .reverse()
                                  .join("/")}
                              />
                            </ListItem>
                          </li>
                        ))
                    : membersFilter == "annually"
                    ? createAcquisitionYearlyData(usersList).length > 0 &&
                      createAcquisitionYearlyData(usersList)
                        .slice(0, 9)
                        .map((user) => (
                          <li>
                            <ListItem
                              style={{
                                paddingTop: 0,
                                paddingBottom: 0,
                                paddingLeft: 5,
                                paddingRight: 0,
                              }}
                            >
                              <ListItemText
                                primary={user.displayName}
                                secondary={truncate(user.email)}
                                style={{ width: "70%" }}
                              />
                              <ListItemText
                                primary={"Acquired"}
                                secondary={user.createdAt
                                  .split("T")[0]
                                  .split("-")
                                  .reverse()
                                  .join("/")}
                              />
                            </ListItem>
                          </li>
                        ))
                    : usersList.slice(0, 9).map((user) => (
                        <li>
                          <ListItem
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              paddingLeft: 5,
                              paddingRight: 0,
                            }}
                          >
                            <ListItemText
                              primary={user.displayName}
                              secondary={truncate(user.email)}
                              style={{ width: "70%" }}
                            />
                            <ListItemText
                              primary={"Acquired"}
                              secondary={user.createdAt
                                .split("T")[0]
                                .split("-")
                                .reverse()
                                .join("/")}
                            />
                          </ListItem>
                        </li>
                      ))}
                </ol>
              </List>
            </div>
          </div>
        </div>
        <div className={classes.listArea}>
          <div className={classes.listHeader}>
            <span>Member Activity</span>
            <div className={classes.filterButtons}>
              <Autocomplete
                onChange={(event, user) => {
                  setUser(user);
                }}
                id={`combo-box-products`}
                options={usersList}
                getOptionSelected={(option, value) =>
                  option.displayName === value.displayName
                }
                getOptionLabel={(option) => option.displayName}
                value={user}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} margin="dense" variant="outlined" />
                )}
              />
            </div>
          </div>
          {user && (
            <div className={classes.listContainer}>
              <div
                style={{
                  display: "flex",
                  // border: "1px #ccc solid",
                  // borderRadius: 5,
                  width: "100%",
                }}
              >
                <div
                  style={{
                    border: "1px #ccc solid",
                    borderRadius: 5,
                    display: "flex",
                    flexDirection: "column",
                    height: "auto",
                    padding: 20,
                    margin: 10,
                  }}
                >
                  <div>
                    <span>Login Count:</span>
                    <span style={{ marginLeft: 10 }}>
                      {user && user.loginCount}
                    </span>
                  </div>

                  {snapshot && (
                    <div style={{ fontSize: 14 }}>
                      <span>Program Average Login Count:</span>
                      <span style={{ marginLeft: 10 }}>
                        {snapshot &&
                          Math.floor(snapshot.loginCount / snapshot.userCount)}
                      </span>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    border: "1px #ccc solid",
                    borderRadius: 5,
                    display: "flex",
                    height: "auto",
                    padding: 20,
                    margin: 10,
                  }}
                >
                  <span>Last Login:</span>
                  <span style={{ marginLeft: 10 }}>
                    {user &&
                      user.lastLogin &&
                      user.lastLogin
                        .split("T")[0]
                        .split("-")
                        .reverse()
                        .join("/")}
                  </span>
                </div>
                {reportList.length == reportsAmount && (
                  <div
                    style={{
                      border: "1px #ccc solid",
                      borderRadius: 5,
                      display: "flex",
                      flexDirection: "column",
                      height: "auto",
                      padding: 20,
                      margin: 10,
                    }}
                  >
                    <div>
                      <span>Redemption Count:</span>
                      <span style={{ marginLeft: 10 }}>
                        {user &&
                          reportList
                            .filter((obj) => obj.userID === user.id)
                            .reduce(function (acc, obj) {
                              return acc + 1;
                            }, 0)}
                      </span>
                    </div>
                    <div style={{ fontSize: 14 }}>
                      <span>Program Average Redemption Count:</span>
                      <span style={{ marginLeft: 10 }}>
                        {snapshot &&
                          Math.floor(
                            reportList.reduce(function (acc, obj) {
                              return acc + 1;
                            }, 0) / snapshot.userCount
                          )}
                      </span>
                    </div>
                  </div>
                )}
                {reportList.length == reportsAmount && (
                  <div
                    style={{
                      border: "1px #ccc solid",
                      borderRadius: 5,
                      display: "flex",
                      flexDirection: "column",
                      height: "auto",
                      padding: 20,
                      margin: 10,
                    }}
                  >
                    <div>
                      <span>Total Redemption Amount (pts):</span>
                      <span style={{ marginLeft: 10 }}>
                        {user &&
                          reportList
                            .filter((obj) => obj.userID === user.id)
                            .reduce(function (acc, obj) {
                              return acc + obj.points;
                            }, 0)}
                      </span>
                    </div>
                    <div style={{ fontSize: 14 }}>
                      <span>Program Average Redemption Amount:</span>
                      <span style={{ marginLeft: 10 }}>
                        {snapshot &&
                          Math.floor(
                            reportList.reduce(function (acc, obj) {
                              return acc + obj.points;
                            }, 0) / snapshot.userCount
                          )}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        {user && (
          <div className={classes.chartArea}>
            <div className={classes.chartContainer}>
              <div className={classes.chartHeader}>
                <span>Member Redemptions</span>
              </div>
              {reportList.filter((obj) => obj.userID === user.id).length >
                0 && (
                <ResponsiveContainer width="100%" height={350}>
                  <LineChart
                    data={Object.values(
                      reportList
                        .filter((obj) => obj.userID === user.id)
                        .reverse()
                        .reduce((a, c) => {
                          a[c.redeemedAt] = Object.assign(
                            a[c.redeemedAt] || {},
                            c
                          );
                          return a;
                        }, {})
                    )}
                    // data={reportList.filter((obj) => obj.userID === user.id)}
                    // padding={50}
                    margin={{ top: 20, right: 20, left: 20, bottom: 20 }}
                  >
                    <CartesianGrid stroke="#ccc" />
                    <XAxis
                      dataKey="redeemedAt"
                      //   angle={45}
                      //   textAnchor="start"
                      // tickFormatter={(value) =>
                      //   displayMonths[Number(value.split("-")[1]) - 1]
                      // }
                      tick={{ fontSize: 10 }}
                    >
                      {/* <Label value="Date (Feb)" offset={20} position="bottom" /> */}
                    </XAxis>
                    <YAxis tick={{ fontSize: 10 }} />
                    <Tooltip />
                    <Line
                      type="monotone"
                      dataKey="points"
                      name="Points Redeemed"
                      stroke="red"
                    />
                  </LineChart>
                </ResponsiveContainer>
              )}
            </div>
            <div className={classes.chartContainer}>
              <div className={classes.chartHeader}>
                <span>Points Balance</span>
              </div>
              {pointsHistory.data && pointsHistory.data.length > 0 && user && (
                <ResponsiveContainer width="100%" height={350}>
                  <LineChart
                    data={processPointsHistory(pointsHistory.data)}
                    // padding={50}
                    margin={{ top: 20, right: 20, left: 20, bottom: 20 }}
                  >
                    <CartesianGrid stroke="#ccc" />
                    <XAxis
                      dataKey="createdAt"
                      //   angle={45}
                      //   textAnchor="start"
                      tickFormatter={(value) =>
                        // displayMonths[Number(value.split("-")[1]) - 1]
                        value.split("T")[0].split("-").reverse().join("/")
                      }
                      tick={{ fontSize: 10 }}
                    >
                      {/* <Label value="Date (Feb)" offset={20} position="bottom" /> */}
                    </XAxis>
                    <YAxis tick={{ fontSize: 10 }} />
                    <Tooltip />
                    <Line
                      type="monotone"
                      dataKey="balance"
                      name="Points Balance"
                      stroke="red"
                    />
                  </LineChart>
                </ResponsiveContainer>
              )}
            </div>
          </div>
        )}
        <div className={classes.listArea}>
          <div className={classes.listHeader}>
            <span>Members By Products Claimed</span>
            <div className={classes.filterButtons}>
              <Autocomplete
                onChange={(event, product) => {
                  setMemberByProduct(product);
                }}
                id={`combo-box-products`}
                options={clientProductsList}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                value={memberByProduct}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} margin="dense" variant="outlined" />
                )}
              />
            </div>
          </div>
          {memberByProduct && <div>
            {membersByClaimedProduct.length ? (
              <div style={{ height: 400, width: "100%" }}>
                <DataGrid
                  rows={membersByClaimedProduct}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                />
              </div>
            ) : (
              <div
                style={{
                  fontSize: 16,
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                No Results Found.
              </div>
            )}
          </div>}
        </div>
        {/* <div className={classes.listArea}>
          <div className={classes.listContainer}>
            <List style={{ padding: 40, borderRadius: 16, width: 500, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <span style={{marginBottom: 10}}>Program Snapshot</span>
              <div>
                <div className={classes.rowContainer}>
                  <div className={classes.label}>Participants:</div>
                  <div className={classes.vals}>
                    {snapshot.userCount
                      ? snapshot.userCount.toLocaleString()
                      : ""}
                  </div>
                </div>
                <div className={classes.rowContainer}>
                  <div className={classes.label}>Total Logins:</div>
                  <div className={classes.vals}>
                    {snapshot.loginCount
                      ? snapshot.loginCount.toLocaleString()
                      : ""}
                  </div>
                </div>
                <div className={classes.rowContainer}>
                  <div className={classes.label}>Unspent Points:</div>
                  <div className={classes.vals}>
                    {snapshot.redeemable
                      ? snapshot.redeemable.toLocaleString()
                      : ""}
                  </div>
                </div>
                <div className={classes.rowContainer}>
                  <div className={classes.label}>Spent Points:</div>
                  <div className={classes.vals}>
                    {snapshot.redeemed
                      ? snapshot.redeemed.toLocaleString()
                      : ""}
                  </div>
                </div>
                <div className={classes.rowContainer}>
                  <div className={classes.label}>Total Points:</div>
                  <div className={classes.vals}>
                    {snapshot.totalPoints
                      ? snapshot.totalPoints.toLocaleString()
                      : ""}
                  </div>
                </div>
              </div>
            </List>
          </div>
        </div> */}
      </Paper>
    );
  }

  return (
    <Grid
      container
      spacing={3}
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      style={{ height: "100%" }}
    >
      {content}
    </Grid>
  );
}
