import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { selectCustomisation } from "../features/customisation/customisationSlice";

import FavoriteIcon from "@material-ui/icons/Favorite";
import { isInCart } from "../app/utils";
import {
  addProductToCart,
  selectCarts,
  updateProductQuantity,
} from "../features/carts/cartsSlice";
import { setProduct } from "../features/products/coreProductsSlice";
import { selectUsers } from "../features/users/usersSlice";
import {
  addProductToWishlist,
  removeProductFromWishlist,
  selectWishlist,
} from "../features/wishlist/wishlistSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 16,
    maxWidth: 345,
    // minHeight: 525,
    maxHeight: 525,

    // paddingBottom: 70,
    // paddingTop: 35,
  },
  prodCard: {
    minWidth: 385,
    color: theme.palette.primaryFont.main,
  },

  cardAction: {
    padding: 14,
    paddingBottom: 16,
    paddingTop: 35,
  },
  CardContentContainer: {
    // minheight:
  },
  cardImagecontainer: {
    minHeight: 195,
    maxHeight: 195,
  },

  cardName: {
    color: theme.palette.primaryFont.main,
    textAlign: "left",
    fontWeight: "bold",
    fontSize: 18,
  },

  cardDescription: {
    color: theme.palette.secondaryFont.main,
    textAlign: "left",
    whiteSpace: "break-spaces",
    fontSize: 14,
    marginBottom: 4,
    marginTop: 4,
  },
  points: {
    // margin: theme.spacing(3, 0, 2),
    position: "absolute",
    zIndex: 900,

    backgroundColor: "#FFFFFF",
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: theme.palette.pointsColor.main,
    borderRadius: "24px",
    color: theme.palette.pointsColor.main,
    right: 16,
    fontSize: 24,
    fontWeight: "bold",
    padding: 4,
    paddingLeft: 24,
    paddingRight: 24,
    top: 30,
  },
  cardButtons: {
    height: 30,
    marginTop: 20,
    marginBottom: 10,
  },

  special: {
    position: "absolute",
    padding: 16,
    paddingLeft: 24,
    paddingRight: 24,
    fontSize: 16,
    fontWeight: "bold",
    backgroundColor: theme.palette.highlight.main,
    borderRadius: "16px 0px 16px 0px",
    color: "#FFFFFF",
    textalign: "center",
  },
}));

export default function ProductCard(props) {
  const classes = useStyles();
  const { product, uA } = props;
  const location = useLocation();
  const { customisation } = useSelector(selectCustomisation);

  const dispatch = useDispatch();
  const { wishlists, wishlist } = useSelector(selectWishlist);
  const { cartList } = useSelector(selectCarts);
  const { userAccount } = useSelector(selectUsers);

  let jwtToken = localStorage.getItem("clientToken");
  const [selectedImage, setSelectedImage] = useState(
    product.images
      ? product.images.filter(
          (image) =>
            image.objName.includes(".png") &&
            image.objName.includes("520-0.")[0]
        )
      : "/placeholder.png"
  );

  // const [imageSrcList, setImageSrcList] = useState([]);
  const [isFav, setIsFav] = useState(false);
  const [firstTime, setFirstTime] = useState(true);

  const [inCart, setIsInCart] = useState(isInCart(product.id, cartList.items));

  useEffect(() => {
    setIsInCart(isInCart(product.id, cartList.items));
  }, [cartList]);

  useEffect(() => {
    if (wishlists.loading === "loaded" && firstTime) {
      setIsFav(wishlist.filter((prod) => prod.productID === product.id).length);
      setFirstTime(false);
    }
  }, [wishlists.loading]);

  useEffect(() => {
    // let imageURLs = product.images.map(image => image.objName)
    //   imageURLs.forEach(url => {
    //     let size = url.split('_')
    //     if (size.length > 1) {
    //       let isolatedSize = size[1].split('-')
    //       url = `${url} ${isolatedSize}w`

    //     }

    //   });
    // setImageSrcList(imageURLs)
    let prodImage = product.images
      ? product.images.filter(
          (image) =>
            (image.objName.includes(".png") ||
              image.objName.includes(".jpeg")) &&
            image.objName.includes("520-0")
        )[0]
      : "/placeholder.png";
    // if (product.images) { image.objName.includes("520-0.jpeg") ||
    // image.objName.includes("520-0.png")
    //   switch (product.images) {
    //     case "825-0.webp":
    //       break;
    //     case "1650-0.webp":
    //       break;
    //     case "520-0.webp":
    //       break;
    //     default:
    //       break;
    //   }
    // }

    setSelectedImage(prodImage);
  }, [product, wishlist]);

  const toggleFavourite = () => {
    if (uA.id != undefined) {
      if (isFav) {
        setIsFav(false);
        dispatch(
          removeProductFromWishlist({
            token: jwtToken,
            prodID: product.id,
            id: uA.id,
          })
        );
      } else {
        setIsFav(true);
        const wishProd = {
          productID: product.id,
          productName: product.name,
          productPoints: product.special
            ? product.specialPoints
            : product.points,
          productImg: product.images ? selectedImage.objName : selectedImage,
          statusID: 1,
        };
        dispatch(
          addProductToWishlist({
            token: jwtToken,
            id: uA.id,
            product: wishProd,
          })
        );
      }
    }
  };

  const addToCart = () => {
    const cartProd = {
      productID: product.id,
      productName: product.name,
      productPoints: product.special ? product.specialPoints : product.points,
      productImg: product.images ? selectedImage.objName : selectedImage,
      statusID: 1,
      quantity: 1,
    };
    dispatch(
      addProductToCart({ token: jwtToken, id: uA.id, product: cartProd })
    );
  };

  const updateCartQuantity = () => {
    dispatch(
      updateProductQuantity({
        token: jwtToken,
        id: userAccount.id,
        productID: product.id,
        product: { quantity: Number(inCart.quantity + 1) },
        showMessage: true,
      })
    );
  };

  const formatPTS = (str) => {
    return str ? str.toLocaleString() : "";
  };

  const handleProdClick = () => {
    dispatch(setProduct({ product: product, fav: isFav }));
  };

  const getPath = () => {
    let productPath = `/products${location.pathname}/${encodeURIComponent(product.name)}`;
    if (
      location.pathname.includes("products") ||
      location.pathname.includes("wishlist") ||
      location.pathname === "/"
    ) {
      productPath = `/products/${encodeURIComponent(product.name)}`;
    }
    return productPath;
  };

  const productPathName = getPath();

  getPath();

  return (
    <Grid key={product.id} item xs={3} className={classes.prodCard}>
      <Card className={classes.root} elevation={3} onClick={handleProdClick}>
        {product.special && <div className={classes.special}>Sale</div>}
        <Link
          to={{
            pathname: productPathName,
          }}
        >
          <CardActionArea className={classes.cardAction}>
            <div className={classes.cardImagecontainer}>
              <div
                className={classes.points}
                style={{
                  borderStyle: product.special ? "none" : "solid",
                  color: product.special ? "grey" : customisation.theme.pointsColor,
                  top: product.special ? 55 : 30,
                  backgroundColor: product.special ? "rgba(255,255,255,0)"  : "white",
                  textDecoration: product.special ? "line-through" : "none",
                  fontSize: product.special ? 18 : 24
                }}
              >
                {product.points &&
                  formatPTS(
                    product.points
                  )}{" "}
                pts
              </div>
              {product.special && (
                <div
                  className={classes.points}
                  style={{
                    top: 15,
                    color: customisation.theme.highlightColor,
                    borderColor: customisation.theme.highlightColor,
                  }}
                >
                  {product.points &&
                    formatPTS(
                      product.special ? product.specialPoints : product.points
                    )}{" "}
                  pts
                </div>
              )}
              {selectedImage && (
                <CardMedia
                  component="img"
                  alt={product.name}
                  // height="140"
                  style={{ marginTop: 16, maxHeight: 195 }}
                  image={
                    product.images
                      ? `${process.env.REACT_APP_PRODUCT_URL}/` +
                        selectedImage.objName
                      : selectedImage
                  }
                  title={product.name}
                />
              )}
              {/* {selectedImage && (
                  <img
                    style={{
                      marginTop: 16,
                      maxHeight: 195,
                      width: "100%",
                      objectFit: "contain",
                      display: "block"
                    }}
                    border="0"
                    src={
                      `${process.env.REACT_APP_PRODUCT_URL}/` +
                      selectedImage.objName
                    }
                    alt={product.name}
                    title={product.name}
                    srcset={product.images
                      .filter(
                        (image) =>
                          image.objName.includes(".webp")
                      )
                      .map(
                        (image, idx) =>
                          `${process.env.REACT_APP_PRODUCT_URL}/` +
                          image.objName +
                          ` ${idx + 1}x`
                      )}
                  />
              )} */}

              {/* <picture>
  <source type="image/webp"  srcSet=  {`${process.env.REACT_APP_PRODUCT_URL}/products/64/images/485156_260-0.webp 260w, ${process.env.REACT_APP_PRODUCT_URL}/products/64/images/485156_520-0.webp 520w, ${process.env.REACT_APP_PRODUCT_URL}/products/64/images/485156_825-0.webp 825w, ${process.env.REACT_APP_PRODUCT_URL}/products/64/images/485156_1650-0.webp 1650w`}
/>
  <source type="imagejpeg"  srcSet={`${process.env.REACT_APP_PRODUCT_URL}/products/64/images/485156_260-0.jpeg 260w, ${process.env.REACT_APP_PRODUCT_URL}/products/64/images/485156_520-0.jpeg 520w, ${process.env.REACT_APP_PRODUCT_URL}/products/64/images/485156_825-0.jpeg 825w, ${process.env.REACT_APP_PRODUCT_URL}/products/64/images/485156_1650-0.jpeg 1650w`}/>
  <img width={195} src="illustration.png" alt="A hand-made illustration" />
</picture> */}
            </div>
            <CardContent
              style={{ height: 80 }}
              className={classes.CardContentContainer}
            >
              <Typography
                className={classes.cardDescription}
                variant="body1"
                color="textSecondary"
                component="p"
              >
                {product.brandName}
              </Typography>
              <Typography
                className={classes.cardName}
                gutterBottom
                variant="h4"
                component="h2"
              >
                {product.name.length > 80
                  ? product.name.substring(0, 80) + "..."
                  : product.name}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Link>

        <CardActions className={classes.cardButtons}>
          <Tooltip title="Add to Wishlist">
            <Button size="small" color="primary">
              {isFav ? (
                <FavoriteIcon onClick={toggleFavourite} />
              ) : (
                <FavoriteBorderOutlinedIcon onClick={toggleFavourite} />
              )}
            </Button>
          </Tooltip>
          <Tooltip title="Add to Cart">
            <Button size="small" color="primary">
              <ShoppingCartOutlinedIcon
                onClick={inCart.isInCart ? updateCartQuantity : addToCart}
              />
            </Button>
          </Tooltip>
        </CardActions>
      </Card>
    </Grid>
  );
}
