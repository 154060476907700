import {
  createAsyncThunk,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";

import { errorSnack, infoSnack } from "../snackbar/snackbarSlice";

import axios from "axios";

import { uuidv4 } from "../../app/utils";
import baseURL from "../../app/utils.js";
axios.interceptors.request.use(
  (req) => {
    req.headers.common["X-Request-ID"] = uuidv4();
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export const fetchCart = createAsyncThunk(
  "cart/fetchCart",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/admin/users/${args.id}/carts`,
        config
      );

      return await response.data;
    } catch (error) {
      if (error.message.includes("401")) {
        localStorage.removeItem("clientUser");
        localStorage.removeItem("clientToken");
      }
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error fetching cart"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const addProductToCart = createAsyncThunk(
  "cart/addProductToCart",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      const response = await axios.post(
        `${baseURL}/v1/admin/users/${args.id}/carts`,
        args.product,
        config
      );
      thunkAPI.dispatch(
        infoSnack(`Added to cart`)
      );
      thunkAPI.dispatch(
        fetchCart({
          token: args.token,
          id: args.id,
        })
      );
      return await response.data;
    } catch (error) {
      if (error.message.includes("401")) {
        localStorage.removeItem("clientUser");
        localStorage.removeItem("clientToken");
      }
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error creating Cartlist"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const removeProductFromCart = createAsyncThunk(
  "cart/removeProductFromCart",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      const response = await axios.delete(
        `${baseURL}/v1/admin/users/${args.id}/carts/product/${args.prodID}`,
        config
      );
      // thunkAPI.dispatch(successSnack('Product removed from cart'));
      thunkAPI.dispatch(
        fetchCart({
          token: args.token,
          id: args.id,
        })
      );

      return await response.data;
    } catch (error) {
      if (error.message.includes("401")) {
        localStorage.removeItem("clientUser");
        localStorage.removeItem("clientToken");
      }
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error removing product"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const updateProductQuantity = createAsyncThunk(
  "cart/updateProductQuantity",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //full options
      const response = await axios.patch(
        `${baseURL}/v1/admin/users/${args.id}/carts/product/${args.productID}`,
        args.product,
        config
      );

      if (args.showMessage) {
        thunkAPI.dispatch(
          infoSnack(`Already in cart, quantity increased`)
        );
      }
      thunkAPI.dispatch(
        fetchCart({
          token: args.token,
          id: args.id,
        })
      );
      return await response;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error updating user"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const cartsSlice = createSlice({
  name: "cart",
  initialState: {
    cartList: { items: [], pointsTotal: "" },
    loading: "idle",
    message: "",
    error: "",
  },
  reducers: {
    resetCart(state, action) {
      state.cartList = { items: [], pointsTotal: "" };
      state.loading = "idle";
      state.message = "";
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCart.pending, (state) => {
      // state.cartList =  {items: [], pointsTotal: ''};
      state.loading = "loading";
    });
    builder.addCase(fetchCart.fulfilled, (state, { payload }) => {
      state.cartList = payload;
      state.loading = "loaded";
    });
    builder.addCase(fetchCart.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });

    builder.addCase(removeProductFromCart.pending, (state) => {
      state.loading = "loading";
    });
    builder.addCase(removeProductFromCart.fulfilled, (state, { payload }) => {
      state.loading = "loaded";
    });
    builder.addCase(removeProductFromCart.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(addProductToCart.pending, (state) => {
      state.loading = "loading";
    });
    builder.addCase(addProductToCart.fulfilled, (state, { payload }) => {
      state.loading = "loaded";
    });
    builder.addCase(addProductToCart.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(updateProductQuantity.pending, (state) => {
      state.loading = "loading";
    });
    builder.addCase(updateProductQuantity.fulfilled, (state, { payload }) => {
      state.loading = "loaded";
    });
    builder.addCase(updateProductQuantity.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
  },
});

// Action creators are generated for each case reducer function
export const { resetCart } = cartsSlice.actions;

export const selectCarts = createSelector(
  (state) => ({
    cart: state.cart,
    loading: state.cart.loading,
    error: state.cart.error,
    cartList: state.cart.cartList,
  }),
  (state) => state
);
export default cartsSlice.reducer;
