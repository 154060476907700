import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { selectCategories } from "../features/categories/categoriesSlice";

import {
  fetchProducts,
  selectCoreProducts,
  setBreadcrumbCats,
} from "../features/products/coreProductsSlice";

const useStyles = makeStyles((theme) => ({
  headMenuWrapper: {
    display: "flex",
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: theme.palette.menuColor.main,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    justifyContent: "center",
  },

  headMenuContainer: {
    alignItems: "center",
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: theme.palette.menuColor.main,

    // color: "#fff",
    color: theme.palette.menuFontColor.main,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    fontWeight: "bold",
    // fontSize: 18,
    width: "100%",
    borderBottom: 2,
    // borderColor: theme.palette.primary.main
    backgroundColor: theme.palette.menuColor.main,
  },
  headMenuItem: {
    cursor: "pointer",

    paddingBottom: 8,
    paddingTop: 8,
    height: 50,
    boxSizing: "border-box",
    alignItems: "center",
    display: "flex",
  },
  selectedHeadMenuItem: {
    paddingBottom: 8,
    paddingTop: 8,
    cursor: "pointer",
    color: theme.palette.highlight.main,
    backgroundColor: theme.palette.subMenuColor.main,
    height: 50,
    boxSizing: "border-box",
    alignItems: "center",
    display: "flex",
  },
  subMenuContainer: {
    borderBottom: `2px solid ${theme.palette.subMenuColor.main}`,
    color: theme.palette.subMenuFontColor.main,

    paddingLeft: 16,
    paddingRight: 32,

    position: "absolute",
    zIndex: 1000,
    width: "95%",
    backgroundColor: theme.palette.subMenuColor.main,

    maxWidth: 1752,
  },
  subMenuItemContainer: {},
  subMenuItem: {
    cursor: "pointer",
    fontWeight: "bold",
    fontSize: 18,
    paddingBottom: 8,
    paddingTop: 8,
    paddingRight: 8,
    paddingLeft: 8,
    "&:hover": {
      backgroundColor: "#ddd",
    },
  },
  childMenuItem: {
    cursor: "pointer",
    paddingBottom: 8,
    paddingTop: 8,
    paddingRight: 8,
    paddingLeft: 8,

    "&:hover": {
      backgroundColor: "#ddd",
    },
  },
  underlined: {},
}));

export default function Menu(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const midpoint = useMediaQuery("(max-width:1450px)");
  const small = useMediaQuery("(max-width:1000px)");

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState("");
  const [selectedCat, setSelectedCat] = useState({ id: 1, name: "" });
  const { pathname } = useLocation();

  useEffect(() => {
    setOpen(false);
  }, [pathname]);

  //categories
  const { categoriesTree } = useSelector(selectCategories);
  let jwtToken = localStorage.getItem("clientToken");
  const { productList } = useSelector(selectCoreProducts);
  const [catLists, setCatLists] = useState(categoriesTree);

  // useEffect(() => {
  //   dispatch(
  //     fetchCategoriesTree({
  //       token: jwtToken,
  //     })
  //   );
  // }, []);

  // const [catLists, setCatLists] = useState(categoriesTree.length ? [...categoriesTree] : null);

  // console.log("catLists", catLists);

  const handleClick = (e, id, name, breadIDs) => {
    setSelected((selected) => (selected == id ? "" : id));
    if (process.env.REACT_APP_CLIENT_ID == "12") {
      let urlName = name.split(" ").join("-").toLowerCase();
      let payload = {
        token: jwtToken,
        query: `?categories=${id}&limit=24`,
      };
      dispatch(fetchProducts(payload));
      history.push(`/${urlName}`);
    } else if (id === "soon") {
      history.push(`/coming-soon/${name}`);
    } else {
      setOpen(selected === id ? !open : true);
      dispatch(setBreadcrumbCats(breadIDs));
    }
  };

  const handleClickCategory = (e, id, name, breadIDs) => {
    dispatch(setBreadcrumbCats(breadIDs));

    let urlName = name.split(" ").join("-").toLowerCase();
    let payload = {
      token: jwtToken,
      query: `?categories=${id}&limit=24`,
    };

    if (urlName == "taylors-wines-exclusives") {
      payload["query"] = `?categories=${id}&limit=24&order_by=points&sort=asc`;
    }

    dispatch(fetchProducts(payload));

    setSelected("");

    history.push(`/${urlName}`);
  };

  // useEffect(() => {
  //   // dispatch(
  //   //   fetchCategoriesTree({
  //   //     token: jwtToken,
  //   //   })
  //   // );
  //   if (!catLists.length) {
  //     setCatLists(categoriesTree);
  //   }
  // }, [categoriesTree]);

  // const handleBrandsClick = (e, id, brandID, name) => {

  //   let urlName = name.split(' ').join('-').toLowerCase();
  //   dispatch(
  //     fetchProducts({
  //       token: jwtToken,
  //       query: `?limit=24&categories=${id}&brands=${brandID}`,
  //     })
  //   );
  //   history.push(`/${urlName}`);
  // };

  useEffect(() => {
    let selection = categoriesTree.filter(
      (category) => category.id === selected
    );
    selection.length && setSelectedCat(selection[0]);
  }, [window.location.pathname, selected]);

  useEffect(() => {
    setOpen(false);
  }, [productList]);

  const tworder = [
    "Experiences",
    "Bar Supplies",
    "Glassware",
    "Apparel",
    "Outdoor",
  ];

  // const checkPets = (obj) => obj.name.toUpperCase() === "PETS";
  // const catLists = categoriesTree.some(checkPets)
  //   ? categoriesTree
  //   : categoriesTree.concat({
  //       name: "Pets",
  //       id: "soon",
  //       displayOrder: 1000,
  //     });

  // useEffect(() => {
  //   if (catLists) {
  //     /^Brands [A-Z]-[A-Z]/.test(categoriesTree[0].name) &&
  //       setCatLists([
  //         ...catLists.sort((a, b) =>
  //           a.name > b.name ? 1 : b.name > a.name ? -1 : 0
  //         ),
  //       ]);
  //   } else {
  //     setCatLists([...categoriesTree])
  //   }
  // }, [categoriesTree.length]);


  const handleClickAway = () => {
    setSelected("");
    setOpen(false);
  };

  useEffect(() => {
    setCatLists(
      process.env.REACT_APP_CLIENT_ID == "12"
        ? [...categoriesTree].sort(
            (a, b) => tworder.indexOf(a.name) - tworder.indexOf(b.name)
          )
        : categoriesTree
    );
  }, [categoriesTree]);

  return (
    <div className={classes.menuContainer}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Paper elevation={0}>
          <div className={classes.headMenuWrapper}>
            <div
              className={classes.headMenuContainer}
              style={{ fontSize: small ? 12 : midpoint ? 14 : 18 }}
            >
              {catLists &&
                catLists.map((category) => (
                  <div
                    key={category.id}
                    // onClick={(e) => handleClick(e, category.id)}
                    onClick={(e) =>
                      handleClick(e, category.id, `${category.name}`, [
                        category.id,
                      ])
                    }
                    className={
                      category.id === selected
                        ? classes.selectedHeadMenuItem
                        : classes.headMenuItem
                    }
                    // category.name === window.location.pathname
                    // .split("/")
                    // .filter((x) => x)
                    // .filter((x) => x !== "products")[0].replace(" ", "-").toLowerCase()
                    style={{
                      order: process.env.REACT_APP_CLIENT_ID != "12" && category.displayOrder,
                      paddingLeft: small ? 8 : midpoint ? 12 : 24,
                      paddingRight: small ? 8 : midpoint ? 12 : 24,
                    }}
                  >
                    {category.name}
                  </div>
                ))}
              {/* <div  onClick={handleWhatsNew} className={classes.headMenuItem}
          style={{
                paddingRight: 24, paddingLeft: 24
          }}>What's New</div> */}
            </div>
          </div>
          <Paper
            style={{ display: "flex", justifyContent: "center" }}
            elevation={3}
          >
            <div
              className={classes.subMenuContainer}
              style={{
                display: open ? "flex" : "none",
                flexDirection: "column",
                padding: midpoint ? 16 : 24,
              }}
            >
              {categoriesTree
                .filter((category) => category.id === selected)
                .map((cat) => (
                  <div style={{ marginBottom: 12 }}>
                    <div
                      onClick={(e) =>
                        handleClickCategory(e, cat.id, `${cat.name}`, [cat.id])
                      }
                    >
                      <div>
                        <span
                          className={classes.subMenuItem}
                          style={{ fontSize: small ? 12 : midpoint ? 14 : 18 }}
                        >
                          {" "}
                          All {cat.name}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              <div style={{ display: "flex" }}>
                {categoriesTree
                  .filter((category) => category.id === selected)
                  .map(
                    (cat) =>
                      cat.subCategories &&
                      cat.subCategories.map(
                        (subCat) =>
                          subCat.depth === 2 && (
                            <div
                              key={subCat.id}
                              className={classes.subMenuItemContainer}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginRight: small ? 16 : midpoint ? 18 : 32,
                                order: subCat.displayOrder,
                              }}
                            >
                              <div
                                onClick={(e) =>
                                  handleClickCategory(
                                    e,
                                    subCat.id,
                                    `${cat.name}/${subCat.name}`,
                                    [cat.id, subCat.id]
                                  )
                                }
                                className={classes.subMenuItem}
                                style={{
                                  fontSize: small ? 12 : midpoint ? 14 : 18,
                                }}
                              >
                                <span className={classes.underlined}>
                                  {subCat.name}
                                </span>
                              </div>

                              {subCat.subCategories &&
                                subCat.subCategories.map((childCat) => (
                                  <div
                                    key={childCat.id}
                                    onClick={(e) =>
                                      handleClickCategory(
                                        e,
                                        childCat.id,
                                        `${cat.name}/${subCat.name}/${childCat.name}`,
                                        [cat.id, subCat.id, childCat.id]
                                      )
                                    }
                                    className={classes.childMenuItem}
                                    style={{
                                      fontSize: small ? 10 : midpoint ? 12 : 16,
                                      order: childCat.displayOrder,
                                    }}
                                  >
                                    {childCat.name}
                                  </div>
                                ))}
                            </div>
                          )
                      )
                  )}
              </div>
              {/* <div
              style={{marginLeft: 'auto', marginRight: 0}}
              key={'brands'}
              className={classes.subMenuItemContainer}
            >
              <div
                // onClick={(e) =>
                //   handleClickCategory(e, selectedCat.id, `${selectedCat.name}`)
                // }
                style={{cursor: 'auto'}}
                className={classes.subMenuItem}
                style={{fontSize: small ? 12 : midpoint ? 14  :  18}}
              >
                Search by Brand
              </div>
              {brands[selectedCat.id] &&
                brands[selectedCat.id].slice(0, 5).map((brand) => (
                  <div
                    key={selectedCat.id + brand.id}
                    onClick={(e) =>
                      handleBrandsClick(
                        e,
                        selectedCat.id,
                        brand.id,
                        `${selectedCat.name}`
                      )
                    }
                    className={classes.childMenuItem}
                  >
                    {brand.name}
                  </div>
                ))}
              <div
                key={selectedCat.id + 2}
                style={{fontWeight: 'bold', fontStyle: 'italic'}}
                onClick={(e) =>
                  handleClickCategory(e, selectedCat.id, `${selectedCat.name}`)
                }
                className={classes.childMenuItem}
              >
                All Brands
              </div>
            </div> */}
            </div>
          </Paper>
        </Paper>
      </ClickAwayListener>
    </div>
  );
}
