import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

import axios from "axios";
import { uuidv4 } from "../../app/utils";
import baseURL from "../../app/utils.js";
import { errorSnack } from "../snackbar/snackbarSlice";
axios.interceptors.request.use(
  (req) => {
    req.headers.common["X-Request-ID"] = uuidv4();
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

const getParent = (category, list) => {
  if (category.parentID === null) {
    return;
  } else {
    let parent = list.filter((cats) => cats.id === category.parentID);
    return parent[0];
  }
};

const getParents = (category, list) => {
  let parentsArr = [];
  let hasParent = true;
  let currentCat = JSON.parse(JSON.stringify(category));
  while (hasParent) {
    let parent = getParent(currentCat, list);
    if (parent) {
      parentsArr.push(parent.name);
      currentCat = parent;
    } else {
      hasParent = false;
    }
  }

  return parentsArr.length ? parentsArr.reverse().join(" > ") : "";
};

export const fetchCategories = createAsyncThunk(
  "categories/fetchCategories",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/admin/categories${args.query}`,
        config
      );

      response.data.length &&
        response.data.forEach((category) => {
          let parents = getParents(category, response.data);
          category.parentList = parents;
        });

      return (await response.data) === null ? [] : response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        // thunkAPI.dispatch(
        //   errorSnack(
        //     error.response.data.error_description || 'Error fetching categories'
        //   )
        // );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const filterCategories = createAsyncThunk(
  "categories/filterCategories",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/admin/categories${args.query}`,
        config
      );
      return (await response.data) === null ? [] : response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchCategoriesTree = createAsyncThunk(
  "categories/fetchCategoriesTree",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/admin/core/categories/tree`,
        config
      );

      let updatedCatTree = JSON.parse(JSON.stringify(response.data));
      for (let i = 0; i < updatedCatTree.length; i++) {
        const parentCategory = updatedCatTree[i];
        if (parentCategory.subCategories && parentCategory.subCategories.length) {
          for (let j = parentCategory.subCategories.length - 1; j >= 0; j--) {
            const cat = parentCategory.subCategories[j];

            if (cat.depth > 2) {
              let catParent = parentCategory.subCategories.find(
                (o) => o.id === cat.parentID
              );
              if (catParent) {
                if (!catParent.subCategories) {
                  catParent.subCategories = [];
                }
                catParent.subCategories.push(cat);
              }
            }
          }
        }
      }
      updatedCatTree.forEach(function (cat, i) {
        if (cat.name === "Electronics") {
          updatedCatTree.splice(i, 1);
          updatedCatTree.unshift(cat);
        }
      });

      if (/^Brands [A-Z]-[A-Z]/.test(updatedCatTree[0].name)) {
        updatedCatTree.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
      }
      return updatedCatTree;
    } catch (error) {
      console.log('error', error);
      if (error.response && error.response.data) {
        // thunkAPI.dispatch(
        //   errorSnack(
        //     error.response.data.error_description ||
        //       'Error fetching categories tree list'
        //   )
        // );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchCategoryByID = createAsyncThunk(
  "categories/fetchCategoryByID",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //full options
      const response = await axios.get(
        `${baseURL}/v1/admin/categories/${args.id}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching category data"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const categoriesSlice = createSlice({
  name: "categories",
  initialState: {
    category: {},
    categoriesFilter: [],
    categoriesList: [],
    categoriesTree: [],
    loading: "idle",
    message: "",
    editing: false,
    error: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCategories.pending, (state) => {
      state.categoriesList = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(fetchCategories.fulfilled, (state, { payload }) => {
      state.categoriesList = payload;
      state.categoriesFilter = payload;
      state.loading = "loaded";
    });
    builder.addCase(fetchCategories.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(filterCategories.pending, (state) => {
      state.categoriesFilter = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(filterCategories.fulfilled, (state, { payload }) => {
      state.categoriesFilter = payload;
      state.loading = "loaded";
    });
    builder.addCase(filterCategories.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchCategoriesTree.pending, (state) => {
      // state.categoriesTree = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(fetchCategoriesTree.fulfilled, (state, { payload }) => {
      state.categoriesTree = payload;
      state.loading = "loaded";
    });
    builder.addCase(fetchCategoriesTree.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchCategoryByID.pending, (state) => {
      state.category = {};
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(fetchCategoryByID.fulfilled, (state, { payload }) => {
      state.category = payload;
      state.loading = "loaded";
      state.editing = true;
    });
    builder.addCase(fetchCategoryByID.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
  },
});

export const selectCategories = createSelector(
  (state) => ({
    categories: state.categories,
    loading: state.categories.loading,
    error: state.categories.error,
    editing: state.categories.editing,
    category: state.categories.category,
    categoriesList: state.categories.categoriesList,
    categoriesFilter: state.categories.categoriesFilter,
    categoriesTree: state.categories.categoriesTree,
  }),
  (state) => state
);
export default categoriesSlice.reducer;
