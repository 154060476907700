import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  addProductToWishlist,
  removeProductFromWishlist,
  selectWishlist,
} from "../features/wishlist/wishlistSlice";

import { setProduct } from "../features/products/coreProductsSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 16,
    // maxWidth: 345,
    // maxHeight: 525,

    // paddingBottom: 70,
    // paddingTop: 35,
  },
  prodCard: {
    // minWidth: 350,
    maxWidth: "100%",
    flexGrow: 1,
    color: theme.palette.primaryFont.main,
  },

  cardAction: {
    padding: 14,
    paddingBottom: 16,
    paddingTop: 35,
  },
  CardContentContainer: {
    // minheight:
    padding: 8,

    paddingTop: 0,
    paddingBottom: 8,
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
  },
  cardImagecontainer: {
    display: "flex",
    // minHeight: 195,
    // maxHeight: 195,
  },

  cardName: {
    color: theme.palette.primaryFont.main,
    textAlign: "left",
    fontWeight: "bold",
    fontSize: 16,
    height: 40,
    overflow: "hidden",
  },

  cardDescription: {
    color: theme.palette.secondaryFont.main,
    textAlign: "left",
    whiteSpace: "break-spaces",
    fontSize: 12,
  },
  points: {
    // margin: theme.spacing(3, 0, 2),
    // position: 'absolute',
    // zIndex: 900,

    backgroundColor: "#FFF",
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: theme.palette.pointsColor.main,
    borderRadius: "24px",
    color: theme.palette.pointsColor.main,
    // right: 16,
    fontSize: 18,
    fontWeight: "bold",
    padding: 4,
    paddingLeft: 24,
    paddingRight: 24,
    // top: 30,
    textAlign: "center",
    maxWidth: 100,
  },
  cardButtons: {
    height: 30,
  },

  special: {
    position: "absolute",
    right: 0,
    bottom: 0,
    padding: 12,
    paddingLeft: 18,
    paddingRight: 18,
    fontSize: 14,
    fontWeight: "bold",
    backgroundColor: theme.palette.highlight.main,
    borderRadius: "16px 0px 16px 0px",
    color: "#fff",
    textalign: "center",
  },
}));

export default function MobileProductCard(props) {
  const classes = useStyles();
  const { product, uA } = props;
  const dispatch = useDispatch();
  const { wishlist } = useSelector(selectWishlist);

  let jwtToken = localStorage.getItem("clientToken");
  const [selectedImage, setSelectedImage] = useState(
    product.images
      ? product.images.filter(
          (image) => image.objName.includes("260-0.webp")[0]
        )
      : ""
  );
  const [isFav, setIsFav] = useState(
    wishlist.filter((prod) => prod.productID === product.id).length
  );
  const [showFave, setShowFave] = useState(
    wishlist.filter((prod) => prod.productID === product.id).length
  );

  useEffect(() => {
    let fav = wishlist.filter((prod) => prod.productID === product.id);
    setIsFav(fav.length);
    let prodImage = product.images
      ? product.images.filter(
          (image) =>
            (image.objName.includes(".png") ||
              image.objName.includes(".jpeg")) &&
            image.objName.includes("260-0.")
        )[0]
      : "/placeholder.png";

    if (product.images) {
      switch (product.images) {
        case "260-0.jpeg":
          break;
        case "1650-0.jpeg":
          break;
        case "520-0.jpeg":
          break;
        default:
          break;
      }
    }

    setSelectedImage(prodImage);
  }, [product, wishlist]);

  // const toggleFavourite = () => {
  //   if (isFav) {
  //     dispatch(
  //       removeProductFromWishlist({
  //         token: jwtToken,
  //         prodID: product.id,
  //         id: user.id ? user.id : 1,
  //       })
  //     );
  //   } else {
  //     const wishProd = {
  //       productID: product.id,
  //       productName: product.name,
  //       productPoints: product.special ? product.specialPoints : product.points,
  //       productImg: product.images[3].objName,
  //       statusID: 1,
  //     };
  //     dispatch(
  //       addProductToWishlist({
  //         token: jwtToken,
  //         id: user.id ? user.id : 1,
  //         product: wishProd,
  //       })
  //     );
  //   }
  //   setShowFave(!showFave);
  // };

  const toggleFavourite = () => {
    if (uA.id != undefined) {
      if (isFav) {
        setIsFav(false);
        dispatch(
          removeProductFromWishlist({
            token: jwtToken,
            prodID: product.id,
            id: uA.id,
          })
        );
      } else {
        setIsFav(true);
        const wishProd = {
          productID: product.id,
          productName: product.name,
          productPoints: product.special
            ? product.specialPoints
            : product.points,
          productImg: product.images ? selectedImage.objName : selectedImage,
          statusID: 1,
        };
        dispatch(
          addProductToWishlist({
            token: jwtToken,
            id: uA.id,
            product: wishProd,
          })
        );
      }
    }
  };

  const formatPTS = (str) => {
    return str ? str.toLocaleString() : "";
  };

  const handleProdClick = () => {
    dispatch(setProduct({ product: product, fav: isFav }));
  };

  return (
    <Grid item xs={3} className={classes.prodCard}>
      <Card className={classes.root} elevation={3} onClick={handleProdClick}>
        <CardActionArea className={classes.cardAction}>
          <Link
            to={{
              pathname: `/products/${product.name}`,
            }}
          >
            <div className={classes.cardImagecontainer}>
              {selectedImage && (
                <CardMedia
                  component="img"
                  alt={product.name}
                  // height="140"
                  style={{ width: 125, height: 125, objectFit: "contain" }}
                  image={
                    product.images
                      ? `${process.env.REACT_APP_PRODUCT_URL}/` +
                        selectedImage.objName
                      : selectedImage
                  }
                  title={product.name}
                />
              )}
              <CardContent
                id={"mobile-cc"}
                className={classes.CardContentContainer}
              >
                <Typography
                  className={classes.cardName}
                  gutterBottom
                  variant="h4"
                  component="h2"
                >
                  {product.name}
                </Typography>
                <div>
                  <div className={classes.points}>
                    {product.points &&
                      formatPTS(
                        product.special ? product.specialPoints : product.points
                      )}{" "}
                    pts
                  </div>

                  {/* <Tooltip title="Add to Cart">
                    <Button size="small" color="primary">
                      <ShoppingCartOutlinedIcon onClick={addToCart} />
                    </Button>
                  </Tooltip> */}
                </div>
              </CardContent>
            </div>
          </Link>

          <CardActions
            style={{
              position: "absolute",
              top: 0,
              right: -14,
              zIndex: 900,
            }}
          >
            <Tooltip title="Add to Wishlist">
              <Button size="small" color="primary">
                {isFav ? (
                  <FavoriteIcon onClick={toggleFavourite} />
                ) : (
                  <FavoriteBorderOutlinedIcon onClick={toggleFavourite} />
                )}
              </Button>
            </Tooltip>
          </CardActions>
          {product.special && <div className={classes.special}>Special</div>}
        </CardActionArea>

        {/* <CardActions className={classes.cardButtons}> */}
        {/* <Tooltip title="Add to Wishlist">
            <Button size="small" color="primary">
              {isFav ? (
                <FavoriteIcon onClick={toggleFavourite} />
              ) : (
                <FavoriteBorderOutlinedIcon onClick={toggleFavourite} />
              )}
            </Button>
          </Tooltip>
          <Tooltip title="Add to Cart">
            <Button size="small" color="primary">
              <ShoppingCartOutlinedIcon onClick={addToCart} />
            </Button>
          </Tooltip> */}
        {/* </CardActions> */}
      </Card>
    </Grid>
  );
}
