import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createProgram, selectPrograms, updateProgram } from './programsSlice';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Datepicker from '../../components/Datepicker';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import format from 'date-fns/format';
import { checkFieldUpdate } from '../../common/utils';

const ColorButton = withStyles((theme) => ({
  root: {
    // margin: theme.spacing(3, 0, 2),
    margin: theme.spacing(0, 0, 0),
    backgroundColor: '#D86D7E',
    color: '#FFF',
    '&:hover': {
      opacity: 0.9,
      backgroundColor: '#D86D7E',
    },
    width: '100%',
    // fontWeight: 'bold',
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  outer: { display: 'flex', flexFlow: 'column' },
  addIcon: {
    marginRight: 8,
    color: '#fff',
  },
  title: {
    color: '#fff',
    padding: 16,
    backgroundColor: ' #42494E',
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 'bold',
  },
  textItem: {
    marginBottom: 16,
  },
}));

export default function Program(props) {
  let jwtToken = localStorage.getItem('clientToken');
  const classes = useStyles();
  const dispatch = useDispatch();

  //   "statusID": 1,
  //   "name": "Test Program",

  //   "startDate": "2021-10-01",
  //   "endDate": "2022-09-30",
  //   "claimStartDate": null,
  //   "claimEndDate": null,
  //   "redemptStartDate": null,
  //   "redemptEndDate": null,

  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [claimStartDate, setClaimStartDate] = useState('');
  const [claimEndDate, setClaimEndDate] = useState('');
  const [redemptStartDate, setRedemptStartDate] = useState('');
  const [redemptEndDate, setRedemptEndDate] = useState('');

  const [editProgram, setEditProgram] = useState(false);
  const [statusID, setStatusID] = useState(1);
  const [statusList, setStatusList] = useState([
    { id: 1, value: 'active' },
    { id: 2, value: 'inactive' },
  ]);
  const [programStatus, setProgramStatus] = useState('active');
  const { editing, program } = useSelector(selectPrograms);
  useEffect(() => {
    if (
      editing &&
      program !== {} &&
      program.name !== name &&
      program.statusID
    ) {
      setName(program.name);
      setStatusID(program.statusID);
      setProgramStatus(statusList[program.statusID - 1].value);

      setStartDate(program.startDate);
      setEndDate(program.endDate);
      setClaimStartDate(program.claimStartDate);
      setClaimEndDate(program.claimEndDate);
      setRedemptStartDate(program.redemptStartDate);
      setRedemptEndDate(program.redemptEndDate);

      setEditProgram(true);
      setOpen(true);
    }
  }, [program]);

  const handleClickOpen = () => {
    setName('');
    setStatusID(1);
    setProgramStatus('active');
    setEditProgram(false);

    setStartDate('');
    setEndDate('');
    setClaimStartDate('');
    setClaimEndDate('');
    setRedemptStartDate('');
    setRedemptEndDate('');
    setOpen(true);
  };

  const handleClose = () => {
    setName('');
    setStatusID(1);
    setProgramStatus('active');
    setStartDate('');
    setEndDate('');
    setClaimStartDate('');
    setClaimEndDate('');
    setRedemptStartDate('');
    setRedemptEndDate('');
    setEditProgram(false);

    setOpen(false);
  };

  const handleStatusChange = (event) => {
    let selectedStatus = statusList.filter(
      (status) => status.value === event.target.value
    );
    selectedStatus[0] && setStatusID(selectedStatus[0].id);

    setProgramStatus(event.target.value);
  };
  const handleCreate = (e) => {
    e.preventDefault();
    let programData = {
      statusID: statusID,
      name: name,
      startDate: startDate ? format(startDate, 'yyyy-MM-dd') : '',
      endDate: endDate ? format(endDate, 'yyyy-MM-dd') : '',
      claimStartDate: claimStartDate
        ? format(claimStartDate, 'yyyy-MM-dd')
        : '',
      claimEndDate: claimEndDate ? format(claimEndDate, 'yyyy-MM-dd') : '',
      redemptStartDate: redemptStartDate
        ? format(redemptStartDate, 'yyyy-MM-dd')
        : '',
      redemptEndDate: redemptEndDate
        ? format(redemptEndDate, 'yyyy-MM-dd')
        : '',
    };
    dispatch(
      createProgram({
        program: programData,
        token: jwtToken,
      })
    );
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    const updatedProgramFields = checkFieldUpdate(
      [
        { name: 'statusID', val: statusID },
        { name: 'name', val: name },
        {
          name: 'startDate',
          val:
            typeof startDate === 'string'
              ? startDate
              : format(startDate, 'yyyy-MM-dd'),
        },
        {
          name: 'endDate',
          val:
            typeof endDate === 'string'
              ? endDate
              : format(endDate, 'yyyy-MM-dd'),
        },
        {
          name: 'claimStartDate',
          val:
            typeof claimStartDate === 'string'
              ? claimStartDate
              : format(claimStartDate, 'yyyy-MM-dd'),
        },
        {
          name: 'claimEndDate',
          val:
            typeof claimEndDate === 'string'
              ? claimEndDate
              : format(claimEndDate, 'yyyy-MM-dd'),
        },
        {
          name: 'redemptStartDate',
          val:
            typeof redemptStartDate === 'string'
              ? redemptStartDate
              : format(redemptStartDate, 'yyyy-MM-dd'),
        },
        {
          name: 'redemptEndDate',
          val:
            typeof redemptEndDate === 'string'
              ? redemptEndDate
              : format(redemptEndDate, 'yyyy-MM-dd'),
        },
      ],
      program
    );

    dispatch(
      updateProgram({
        program: updatedProgramFields,
        token: jwtToken,
        id: program.id,
      })
    );
  };

  return (
    <div>
      <IconButton
        aria-label={editProgram ? 'edit Program' : 'add Program'}
        style={{ marginLeft: 0 }}
      >
        <AddCircleIcon
          style={{ marginRight: 0, color: '#fff' }}
          onClick={handleClickOpen}
        />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="lg"
      >
        <form
          onSubmit={
            editProgram ? (e) => handleUpdate(e) : (e) => handleCreate(e)
          }
        >
          <DialogTitle
            disableTypography
            className={classes.title}
            id="form-dialog-title"
          >
            {editProgram ? 'Edit Program' : 'Add Program'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText style={{ paddingTop: 8, textAlign: 'center' }}>
              {editProgram ? 'Update Program Details' : 'Enter Program Details'}
            </DialogContentText>
            <Grid container direction="column">
              <Grid item sm>
                <TextField
                  className={classes.textItem}
                  defaultValue={name}
                  variant="outlined"                  
                  margin="dense"
                  required
                  id="name"
                  label="Name"
                  type="name"
                  fullWidth
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>{' '}
              <Grid item sm style={{ paddingTop: 8 }}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{
                    width: '100%',
                  }}
                >
                  <InputLabel htmlFor="outlined-status-simple" margin="dense">
                    Program Status*
                  </InputLabel>
                  <Select
                    defaultValue={programStatus}
                    value={programStatus}
                    onChange={(e) => handleStatusChange(e)}
                    margin="dense"
                    label="Program Status"
                    required
                    inputProps={{
                      name: 'status',
                      id: 'outlined-status-simple',
                    }}
                  >
                    <MenuItem value="active">Active</MenuItem>
                    <MenuItem value="inactive">Inactive</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container direction="row" style={{ marginTop: 32 }}>
              <Grid item sm style={{ paddingTop: 8, marginRight: 16 }}>
                <div
                  style={{ marginBottom: 0, fontWeight: 'bold' }}
                  className={classes.textLabel}
                >
                  Program Start Date:{' '}
                </div>
                <div style={{ marginBottom: 24 }}>
                  <Datepicker
                    invoiceDate={startDate}
                    setInvoiceDate={setStartDate}
                    report={true}
                    label={'  '}
                  />
                </div>
              </Grid>
              <Grid item sm style={{ paddingTop: 8, marginLeft: 16 }}>
                <div
                  style={{ marginBottom: 0, fontWeight: 'bold' }}
                  className={classes.textLabel}
                >
                  Program End Date:{' '}
                </div>
                <div style={{ marginBottom: 24 }}>
                  <Datepicker
                    invoiceDate={endDate}
                    setInvoiceDate={setEndDate}
                    report={false}
                    label={'  '}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container direction="row">
              <Grid item sm style={{ paddingTop: 8, marginRight: 16 }}>
                <div
                  style={{ marginBottom: 0, fontWeight: 'bold' }}
                  className={classes.textLabel}
                >
                  Claims Start Date:{' '}
                </div>
                <div style={{ marginBottom: 24 }}>
                  <Datepicker
                    invoiceDate={claimStartDate}
                    setInvoiceDate={setClaimStartDate}
                    report={true}
                    label={'  '}
                  />
                </div>
              </Grid>
              <Grid item sm style={{ paddingTop: 8, marginLeft: 16 }}>
                <div
                  style={{ marginBottom: 0, fontWeight: 'bold' }}
                  className={classes.textLabel}
                >
                  Claims End Date:{' '}
                </div>
                <div style={{ marginBottom: 24 }}>
                  <Datepicker
                    invoiceDate={claimEndDate}
                    setInvoiceDate={setClaimEndDate}
                    report={false}
                    label={'  '}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container direction="row">
              <Grid item sm style={{ paddingTop: 8, marginRight: 16 }}>
                <div
                  style={{ marginBottom: 0, fontWeight: 'bold' }}
                  className={classes.textLabel}
                >
                  Redemptions Start Date:{' '}
                </div>
                <div style={{ marginBottom: 24 }}>
                  <Datepicker
                    invoiceDate={redemptStartDate}
                    setInvoiceDate={setRedemptStartDate}
                    report={true}
                    label={'  '}
                  />
                </div>
              </Grid>
              <Grid item sm style={{ paddingTop: 8, marginLeft: 16 }}>
                <div
                  style={{ marginBottom: 0, fontWeight: 'bold' }}
                  className={classes.textLabel}
                >
                  Redemptions End Date:{' '}
                </div>
                <div style={{ marginBottom: 24 }}>
                  <Datepicker
                    invoiceDate={redemptEndDate}
                    setInvoiceDate={setRedemptEndDate}
                    report={false}
                    label={'  '}
                  />
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{ paddingLeft: 24, paddingRight: 24, paddingBottom: 16 }}
          >
            <Grid container spacing={2} direction="row">
              <Grid item sm>
                <ColorButton onClick={handleClose} color="primary">
                  Cancel
                </ColorButton>
              </Grid>
              <Grid item sm>
                <ColorButton
                  type="submit"
                  // onClick={editProgram ? handleUpdate : handleCreate}
                  color="secondary"
                >
                  {editProgram ? 'Edit Program' : 'Create Program'}
                </ColorButton>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
