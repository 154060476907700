import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { replaceUrlParam } from "../../app/utils";
import CustomTable from "../../common/CustomTable";
import { fetchUserByID } from "../users/usersSlice";
import {
  fetchClaims,
  fetchMoreClaims,
  getFullList,
  selectClaims,
  setClaim,
} from "./claimsSlice";

const useStyles = makeStyles((theme) => ({
  progress: {
    margin: theme.spacing(2),
  },
  root: {
    display: "flex",
    flexFlow: "column",
  },
}));

export default function ClaimsAdminContainer(props) {
  let jwtToken = localStorage.getItem("clientToken");
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const edit = props.location.pathname.includes("edit");
  const { loading, claimsList, claimsAmount, lastQuery } =
    useSelector(selectClaims);

  const [statusNames, setStatusNames] = useState([]);
  const [statusIDs, setStatusIDs] = useState([]);

  const statusList = [
    { id: 5, value: "Pending", name: "Pending" },
    { id: 21, value: "Declined", name: "Declined" },
    { id: 27, value: "Approved", name: "Approved" },
  ];

  const [searchTerm, setSearchTerm] = useState("");
  const [dateBefore, setDateBefore] = useState("");
  const [dateAfter, setDateAfter] = useState("");

  const [tablePreferences, setTablePreferences] = useState(
    localStorage.getItem("ClaimsAdminTable")
      ? JSON.parse(localStorage.getItem("ClaimsAdminTable"))
      : {
          rowsPerPage: 25,
          currentPage: 0,
          sortProperty: "id",
          sortDirection: "asc",
        }
  );

  useEffect(() => {
    localStorage.setItem("ClaimsAdminTable", JSON.stringify(tablePreferences));
  }, [tablePreferences]);

  const [numberShown, setNumberShown] = useState(25);

  const headCells = [
    {
      id: "id",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Claim #",
      long: false,
      sortable: true,
    },
    {
      id: "userDisplayName",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Member",
      long: true,
    },
    {
      id: "userEmail",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Member Email",
      long: false,
    },
    {
      id: "notes",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Claim Notes",
      long: true,
    },
    {
      id: "invoiceNumber",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Invoice Number",
      long: true,
    },
    {
      id: "invoiceDate",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Invoice Date",
      long: true,
    },
    {
      id: "monetaryValue",
      checked: true,
      numeric: true,
      disablePadding: false,
      label: "Monetary Value",
      long: true,
    },
    {
      id: "points",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Point Total",
      long: false,
      object: true,
      objectProperty: "points",
    },
    {
      id: "createdAt",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Claim Date",
      long: false,
      sortable: true,
    },
    {
      id: "updatedAt",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Last Updated",
      long: false,
      sortable: true,
    },
    {
      id: "statusID",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Status",
      long: false,
      sortable: true,
    },
  ];

  useEffect(() => {
    let searchQuery = lastQuery ? lastQuery : "";

    if (tablePreferences.rowsPerPage * (tablePreferences.currentPage + 1)) {
      searchQuery = replaceUrlParam(
        searchQuery,
        "limit",
        tablePreferences.rowsPerPage * (tablePreferences.currentPage + 1)
      );
    }

    dispatch(
      fetchClaims({
        token: jwtToken,
        query: lastQuery
          ? searchQuery
          : `?limit=${tablePreferences.rowsPerPage * (tablePreferences.currentPage + 1)}`,
      })
    );

    setNumberShown(
      tablePreferences.rowsPerPage * (tablePreferences.currentPage + 1)
    );
  }, [dispatch, tablePreferences.rowsPerPage]);

  const getFullListTrigger = () => {
    let searchQuery = lastQuery ? lastQuery : "";
    searchQuery = replaceUrlParam(searchQuery, "limit", 100);

    dispatch(
      getFullList({
        token: jwtToken,
        offset:
          tablePreferences.rowsPerPage > claimsList.length
            ? tablePreferences.rowsPerPage
            : claimsList.length,
        query: searchQuery,
        targetLength: claimsAmount,
      })
    );
  };

  const handleMore = (e, page) => {
    if (page < tablePreferences.currentPage) {
      setTablePreferences((oldTable) => ({ ...oldTable, currentPage: page }));
      setNumberShown((page + 1) * tablePreferences.rowsPerPage);
    } else {
      let expectedLen = (page + 1) * tablePreferences.rowsPerPage;
      if (claimsList.length < claimsAmount && claimsList.length < expectedLen) {
        dispatch(
          fetchMoreClaims({
            token: jwtToken,
            query: lastQuery,
            offset: numberShown,
          })
        );
      }
      setTablePreferences((oldTable) => ({
        ...oldTable,
        currentPage: oldTable.currentPage + 1,
      }));

      setNumberShown(
        (tablePreferences.currentPage + 2) * tablePreferences.rowsPerPage
      );
    }
  };

  const handleRequestSort = (event, property) => {
    let oldProperty = property;
    const isAsc =
      tablePreferences.sortProperty === property &&
      tablePreferences.sortDirection === "asc";
    setTablePreferences((oldTable) => ({
      ...oldTable,
      sortDirection: isAsc ? "desc" : "asc",
      sortProperty: oldProperty,
      currentPage: 0,
    }));

    switch (property) {
      case "updatedAt":
        property = "updated_at";
        break;
      case "createdAt":
        property = "created_at";
        break;
      case "statusID":
        property = "status_id";
        break;
    }

    let searchQuery = lastQuery ? lastQuery : "";

    if (property) {
      searchQuery = replaceUrlParam(searchQuery, "order_by", property);
    }
    searchQuery = replaceUrlParam(searchQuery, "sort", isAsc ? "desc" : "asc");
    searchQuery = replaceUrlParam(
      searchQuery,
      "limit",
      tablePreferences.rowsPerPage * (tablePreferences.currentPage + 1)
    );

    dispatch(
      fetchClaims({
        token: jwtToken,
        query: searchQuery,
      })
    );
  };

  // const handleAssignedChange = (event) => {
  //   let idsArr = [];
  //   // event.target.value.forEach((selected) => {
  //   let selectedOrder = claimsList.filter(
  //     (order) => order.orderNumber === event.target.value
  //   );
  //   selectedOrder && idsArr.push(selectedOrder[0].id);
  //   // });

  //   setAssignedTo(event.target.value);
  //   setUserIDs(idsArr);
  // };

  const handleStatusChange = (event) => {
    let idsArr = [];
    event.target.value.forEach((selected) => {
      let selectedStatus = statusList.filter(
        (status) => status.name === selected
      );
      selectedStatus[0] && idsArr.push(selectedStatus[0].id);
    });

    setStatusNames(event.target.value);
    setStatusIDs(idsArr);
  };

  const [ready, setReady] = useState(false);
  if (loading === "loaded" && !ready) {
    setReady(true);
  }

  const handleEdit = (id) => {};

  const handleEditClick = (id) => {
    const selectedClaim = claimsList.filter((claim) => claim.id === id);
    dispatch(fetchUserByID({ token: jwtToken, id: selectedClaim[0].userID }));
    dispatch(setClaim(selectedClaim[0]));
    history.push(`/admin/claims/${id}`);
  };

  const handleSearch = () => {
    let daterange = "";
    if (dateBefore && dateAfter) {
      daterange = `${dateAfter.toISOString().substring(0, 10)},${dateBefore
        .toISOString()
        .substring(0, 10)}`;
    } else if (dateBefore && !dateAfter) {
      daterange = `lte,${dateBefore.toISOString().substring(0, 10)}`;
    } else if (!dateBefore && dateAfter) {
      daterange = `gte,${dateAfter.toISOString().substring(0, 10)}`;
    }

    let searchQuery = lastQuery ? lastQuery : "";

    if (statusIDs.length) {
      searchQuery = replaceUrlParam(
        searchQuery,
        "statuses",
        statusIDs.join(",")
      );
    }
    if (daterange) {
      searchQuery = replaceUrlParam(searchQuery, "udate", daterange);
    }
    //    if (searchTerm) {
    searchQuery = replaceUrlParam(searchQuery, "ino", searchTerm);
    //    }

    dispatch(
      fetchClaims({
        query: searchQuery,
        token: jwtToken,
      })
    );
    setTablePreferences((oldTable) => ({
      ...oldTable,
      currentPage: 0,
    }));
  };

  const handleClear = () => {    
    setStatusNames([]);
    setStatusIDs([]);
    setDateBefore("");
    setDateAfter("");
    setSearchTerm("");    

    dispatch(
      fetchClaims({
        token: jwtToken,
        query: `?limit=${tablePreferences.rowsPerPage}`,
      })
    );
    setTablePreferences((oldTable) => ({
      ...oldTable,
      currentPage: 0,
      sortDirection: "asc",
      sortProperty: "id",
    }));
  };

  const selectNames = ["status"];

  const cellList = headCells.map((cell) => {
    return cell.id;
  });

  let content;
  if (loading === "loading") {
    content = (
      <CircularProgress className={classes.progress} color="secondary" />
    );
  } else if (loading === "loaded" || loading === "idle") {
    content = (
      <CustomTable
        data={claimsList}
        actionsLists={[statusList]}
        // actionsLists={[usersList, statusList]}
        actionsHandleChange={[handleStatusChange]}
        // actionsHandleChange={[handleAssignedChange, handleStatusChange]}
        actionsNames={[statusNames]}
        // actionsNames={[assignedTo, statusNames]}
        headCells={headCells}
        cellList={cellList}
        handleEditClick={handleEditClick}
        handleSearch={handleSearch}
        handleClear={handleClear}
        handleEdit={handleEdit}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        area={"claim"}
        setDateBefore={setDateBefore}
        setDateAfter={setDateAfter}
        dateAfter={dateAfter}
        dateBefore={dateBefore}
        selectNames={selectNames}
        productsAmount={claimsAmount}
        handleMore={handleMore}
        page={tablePreferences.currentPage}
        setPage={(page) =>
          setTablePreferences((oldTable) => ({
            ...oldTable,
            currentPage: page,
          }))
        }
        handleRequestSort={handleRequestSort}
        sortProperty={tablePreferences.sortProperty}
        sortDirection={tablePreferences.sortDirection}
        rowsPerPage={tablePreferences.rowsPerPage}
        setRowsPerPage={(rows) =>
          setTablePreferences((oldTable) => ({
            ...oldTable,
            rowsPerPage: rows,
          }))
        }
        getFullList={getFullListTrigger}
      />
    );
  }
  return (
    <Grid
      container
      key={claimsList.length}
      spacing={3}
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      style={{ height: "100%" }}
    >
      {content}
    </Grid>
  );
}
