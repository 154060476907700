import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { setXClientHost } from "../../app/utils";
import { selectCustomisation } from "../customisation/customisationSlice";
import { fetchContentByName, selectContents } from "./contentsSlice";

const BackButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#fff",
    color: theme.palette.primaryFont.main,
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: theme.palette.primaryFont.main,
    borderRadius: 20,
    width: 200,
    "&:hover": {
      backgroundColor: "#DDD",
    },
    fontWeight: "bold",
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  privacyContainer: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondaryFont.main,
    display: "flex",
    flexFlow: "column nowrap",
    // justifyContent: 'center',
    alignItems: "center",
    height: "100%",
    marginBottom: 62,
  },
  cardContainer: {
    backgroundColor: "#fff",
    borderRadius: 16,
    color: theme.palette.secondaryFont.main,
    margin: 48,
    padding: 48,
  },
  header: { fontWeight: "bold", marginBottom: 24 },
  title: {
    color: theme.palette.primaryFont.main,
    fontWeight: "bold",
    marginBottom: 16,
    paddingTop: 8,
  },
  subTitle: {
    fontWeight: "bold",
    marginBottom: 16,
    paddingTop: 16,
  },
  text: {
    marginBottom: 16,
  },
}));

export default function Privacy() {
  const { customisation } = useSelector(selectCustomisation);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  let jwtToken = localStorage.getItem("clientToken");

  const goBack = () => {
    history.goBack();
  };

  const { content, loading } = useSelector(selectContents);

  useEffect(() => {
    dispatch(
      fetchContentByName({
        host: setXClientHost(),
        token: jwtToken,
        name: "privacy",
      })
    );
  }, []);

  return loading !== "loaded" ? (
    <div className={classes.privacyContainer}></div>
  ) : content.value && content.value !== "<p><br></p>" ? (
    <div className={classes.privacyContainer}>
      <Paper className={classes.cardContainer} elevation={4}>
        <div className={classes.customContentContainer}>
          <ReactQuill
            value={content.value}
            readOnly={true}
            theme={"bubble"}
            // style={{ fontSize: 18 }}
          />
        </div>
      </Paper>
      <BackButton onClick={goBack}>Go Back</BackButton>
    </div>
  ) : (
    <div className={classes.privacyContainer}>
      <Paper className={classes.cardContainer} elevation={2}>
        <Typography className={classes.header} variant="h3">
          Privacy Statement
        </Typography>

        <Typography className={classes.subTitle} variant="h6">
          A condition of becoming a participant in the{" "}
          {customisation.theme.clientName} program is that the information
          provided by you or {customisation.theme.clientName} as The Promoter
          may be used for the following:
        </Typography>
        <Typography
          style={{ marginBottom: 0 }}
          className={classes.text}
          variant="body1"
        >
          - Updating your Total Available Points as per the Terms & Conditions
          specified in the program
        </Typography>
        <Typography
          style={{ marginBottom: 0 }}
          className={classes.text}
          variant="body1"
        >
          - Placing orders for the {customisation.theme.clientName} prizes you
          select
        </Typography>
        <Typography
          style={{ marginBottom: 0 }}
          className={classes.text}
          variant="body1"
        >
          - Forwarding you your selected {customisation.theme.clientName} prizes
          either direct from manufacturers or via selected carriers
        </Typography>
        <Typography className={classes.text} variant="body1">
          - Forwarding you information about this program, future incentive
          programs or other related programs.
        </Typography>
        <Typography className={classes.text} variant="body1">
          As a participant in the {customisation.theme.clientName} program you
          are provided with a confidential Username and Password.
        </Typography>
        <Typography className={classes.text} variant="body1">
          This allows you to access your total points available, claim{" "}
          {customisation.theme.clientName} prizes up to the total value of those
          points and change your delivery address if required.
        </Typography>
        <Typography className={classes.text} variant="body1">
          Your Username and Password is thus a valuable asset and should not be
          disclosed to others. {customisation.theme.clientName} accepts no
          responsibility for unauthorised access to members' personal
          information or to any wrongful claims made or delivered as a result of
          unauthorised access.
        </Typography>

        <Typography className={classes.subTitle} variant="h6">
          Warranties by {customisation.theme.clientName}
        </Typography>
        <Typography className={classes.text} variant="body1">
          Personal information submitted to and held in the{" "}
          {customisation.theme.clientName} database shall be used only to
          administer the points program, make deliveries of reward prizes and to
          send any other marketing information required by the promoter. It
          shall not be disclosed to any other third party without your express
          permission.
        </Typography>
        <Typography className={classes.text} variant="body1">
          {customisation.theme.clientName} takes all reasonable steps to ensure
          your personal information is kept secure. Your information is stored
          on secure servers within highly secure 24 x 7 controlled and monitored
          facilities. The {customisation.theme.clientName} staff and contractors
          are trained in the correct procedures in using and handling any
          personal information they have access to when carrying out all
          necessary administrative functions while running the program. As part
          of delivery and supply contracts all suppliers to the{" "}
          {customisation.theme.clientName} program are obliged to respect and
          keep the confidentiality of any personal information held by the
          {customisation.theme.clientName} program.
        </Typography>
        <Typography className={classes.text} variant="body1">
          {customisation.theme.clientName} takes no responsibility for any
          incorrect delivery or loss, due to the failure of the promoter to
          supply or update correct participant details.
        </Typography>
      </Paper>
      <BackButton onClick={goBack}>Go Back</BackButton>
    </div>
  );
}
