import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

import axios from "axios";
import { errorSnack, successSnack } from "../snackbar/snackbarSlice";

import { uuidv4 } from "../../app/utils";
import baseURL from "../../app/utils.js";
axios.interceptors.request.use(
  (req) => {
    req.headers.common["X-Request-ID"] = uuidv4();
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export const fetchMemberTypes = createAsyncThunk(
  "members/fetchMemberTypes",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };

      const response = await axios.get(
        `${baseURL}/v1/admin/member_types${args.query}`,
        config
      );
      thunkAPI.dispatch(
        setMemberTypesAmount(response.headers["x-total-count"])
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching Members list"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchMemberTypeByID = createAsyncThunk(
  "members/fetchMemberTypeByID",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/admin/member_types/${args.id}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.message.includes("401")) {
        localStorage.removeItem("clientUser");
        localStorage.removeItem("clientToken");
      }
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching member data"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const createMemberType = createAsyncThunk(
  "members/createMemberType",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //full options
      const response = await axios.post(
        `${baseURL}/v1/admin/member_types`,
        args.member,
        config
      );
      thunkAPI.dispatch(successSnack("Member created successfully"));
      thunkAPI.dispatch(
        fetchMemberTypes({ token: args.token, query: "?limit=25" })
      );

      return await response;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error creating member"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const updateMemberType = createAsyncThunk(
  "members/updateMemberType",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //full options
      const response = await axios.patch(
        `${baseURL}/v1/admin/member_types/${args.id}`,
        args.member,
        config
      );
      thunkAPI.dispatch(
        fetchMemberTypes({ token: args.token, query: "?limit=25" })
      );
      // thunkAPI.dispatch(fetchMemberTypeByID({ token: args.token, id: args.id }));

      // thunkAPI.dispatch(successSnack('Member updated'));
      thunkAPI.dispatch(successSnack("Member details updated successfully"));

      return await response;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error updating member"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const deleteMemberType = createAsyncThunk(
  "members/deleteMemberType",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //full options
      const response = await axios.delete(
        `${baseURL}/v1/admin/member_types/${args.id}`,
        config
      );
      thunkAPI.dispatch(successSnack("Member deleted successfully"));
      return await response;
    } catch (error) {
      if (error.message.includes("401")) {
        localStorage.removeItem("clientUser");
        localStorage.removeItem("clientToken");
      }
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error deleting member"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchMemberGroups = createAsyncThunk(
  "members/fetchMemberGroups",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/member_groups${args.query}`,
        config
      );
      thunkAPI.dispatch(
        setMemberGroupsAmount(response.headers["x-total-count"])
      );
      // thunkAPI.dispatch(setMembersAmount(response.headers["x-total-count"]));
      // thunkAPI.dispatch(setLastQuery(args.query));
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching Members list"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchMemberGroupsPublic = createAsyncThunk(
  "members/fetchMemberGroupsPublic",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { "X-Client-Host": process.env.REACT_APP_LOCAL_URL },
      };
      const response = await axios.get(
        `${baseURL}/v1/rt/member_groups${args.query}`,
        config
      );
      thunkAPI.dispatch(
        setMemberGroupsAmount(response.headers["x-total-count"])
      );
      // thunkAPI.dispatch(setMembersAmount(response.headers["x-total-count"]));
      // thunkAPI.dispatch(setLastQuery(args.query));
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching Members list"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchManagersPublic = createAsyncThunk(
  "managers/fetchManagersPublic",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: {
          "X-Request-ID": uuidv4(),
          "Content-Type": "application/json",
          "X-Client-Host": process.env.REACT_APP_LOCAL_URL,
        },
      };
      const response = await axios.get(
        `${baseURL}/v1/rt/users/managers/names${args.query}`,
        config
      );
      thunkAPI.dispatch(
        setManagersAmount(response.headers["x-total-count"])
      );
      // thunkAPI.dispatch(setLastQuery(args.query));
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching Managers list"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchManagers = createAsyncThunk(
  "managers/fetchManagers",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${args.token}`,
          "X-Request-ID": uuidv4(),
          "Content-Type": "application/json",          
        },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/users/managers/names${args.query}`,
        config
      );
      thunkAPI.dispatch(
        setManagersAmount(response.headers["x-total-count"])
      );
      // thunkAPI.dispatch(setLastQuery(args.query));
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching Managers list"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);



export const fetchMemberGroupByID = createAsyncThunk(
  "members/fetchMemberGroupByID",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/admin/member_groups/${args.id}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.message.includes("401")) {
        localStorage.removeItem("clientUser");
        localStorage.removeItem("clientToken");
      }
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching member data"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const createMemberGroup = createAsyncThunk(
  "members/createMemberGroup",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //full options
      const response = await axios.post(
        `${baseURL}/v1/admin/member_groups`,
        args.member,
        config
      );
      thunkAPI.dispatch(successSnack("Member created successfully"));
      thunkAPI.dispatch(
        fetchMemberGroups({ token: args.token, query: "?limit=25" })
      );

      return await response;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error creating member"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const updateMemberGroup = createAsyncThunk(
  "members/updateMemberGroup",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //full options
      const response = await axios.patch(
        `${baseURL}/v1/admin/member_groups/${args.id}`,
        args.member,
        config
      );
      thunkAPI.dispatch(
        fetchMemberGroups({ token: args.token, query: "?limit=25" })
      );
      // thunkAPI.dispatch(fetchMemberGroupByID({ token: args.token, id: args.id }));

      // thunkAPI.dispatch(successSnack('Member updated'));
      thunkAPI.dispatch(successSnack("Member details updated successfully"));

      return await response;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error updating member"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const deleteMemberGroup = createAsyncThunk(
  "members/deleteMemberGroup",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //full options
      const response = await axios.delete(
        `${baseURL}/v1/admin/member_groups/${args.id}`,
        config
      );
      thunkAPI.dispatch(
        fetchMemberGroups({ token: args.token, query: "?limit=25" })
      );
      thunkAPI.dispatch(successSnack("Member deleted successfully"));
      return await response;
    } catch (error) {
      if (error.message.includes("401")) {
        localStorage.removeItem("clientUser");
        localStorage.removeItem("clientToken");
      }
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error deleting member"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchMoreMemberGroups = createAsyncThunk(
  "memberGroups/fetchMoreMemberGroups",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/member_groups${args.query}&offset=${args.offset}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.message.includes("401")) {
        localStorage.removeItem("clientUser");
        localStorage.removeItem("clientToken");
      }
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching member groups list"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getFullMemberGroupsList = createAsyncThunk(
  "memberGroups/getFullList",
  async (args, thunkAPI) => {
    let objToMap = [];
    for (let i = 0; i < (args.targetLength - args.offset) / 100; i++) {
      objToMap.push({
        token: args.token,
        query: args.query,
        offset: args.offset + 100 * i,
      });
    }

    return new Promise((resolve) => {
      Promise.all(
        objToMap.map((requestData) =>
          thunkAPI.dispatch(fetchMoreMemberGroups(requestData))
        )
      )
        .then((results) => {})
        .catch((err) => {
          return thunkAPI.rejectWithValue("Error fetching full member groups list");
        });
    });
  }
);


export const fetchReputations = createAsyncThunk(
  "members/fetchReputations",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/reputations${args.query}`,
        config
      );
      thunkAPI.dispatch(setReputationAmount(response.headers["x-total-count"]));
      // thunkAPI.dispatch(setMembersAmount(response.headers["x-total-count"]));
      // thunkAPI.dispatch(setLastQuery(args.query));
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching Members list"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchReputationByID = createAsyncThunk(
  "members/fetchReputationByID",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/admin/reputations/${args.id}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.message.includes("401")) {
        localStorage.removeItem("clientUser");
        localStorage.removeItem("clientToken");
      }
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching member data"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const createReputation = createAsyncThunk(
  "members/createReputation",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //full options
      const response = await axios.post(
        `${baseURL}/v1/admin/reputations`,
        args.member,
        config
      );
      thunkAPI.dispatch(successSnack("Member created successfully"));
      thunkAPI.dispatch(
        fetchReputations({ token: args.token, query: "?limit=25" })
      );

      return await response;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error creating member"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const updateReputation = createAsyncThunk(
  "members/updateReputation",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //full options
      const response = await axios.patch(
        `${baseURL}/v1/admin/reputations/${args.id}`,
        args.member,
        config
      );
      thunkAPI.dispatch(
        fetchReputations({ token: args.token, query: "?limit=25" })
      );
      // thunkAPI.dispatch(fetchReputationByID({ token: args.token, id: args.id }));

      // thunkAPI.dispatch(successSnack('Member updated'));
      thunkAPI.dispatch(successSnack("Member details updated successfully"));

      return await response;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error updating member"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const deleteReputation = createAsyncThunk(
  "members/deleteReputation",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //full options
      const response = await axios.delete(
        `${baseURL}/v1/admin/reputations/${args.id}`,
        config
      );
      thunkAPI.dispatch(successSnack("Member deleted successfully"));
      return await response;
    } catch (error) {
      if (error.message.includes("401")) {
        localStorage.removeItem("clientUser");
        localStorage.removeItem("clientToken");
      }
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error deleting member"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const membersSlice = createSlice({
  name: "members",
  initialState: {
    memberType: {},
    memberGroup: {},
    reputation: {},
    memberTypesList: [],
    memberGroupsList: [],
    reputationList: [],
    managersList: [],
    loading: "idle",
    message: "",
    error: "",
  },
  reducers: {
    setMemberType(state, action) {
      state.memberType = action.payload;
    },
    setMemberGroup(state, action) {
      state.memberGroup = action.payload;
    },
    setReputation(state, action) {
      state.reputation = action.payload;
    },
    setMemberGroupsAmount(state, action) {
      state.memberGroupsAmount = action.payload;
    },
    setMemberTypesAmount(state, action) {
      state.memberTypesAmount = action.payload;
    },
    setReputationAmount(state, action) {
      state.reputationAmount = action.payload;
    },
    setManagersAmount(state, action) {
      state.managersAmount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMemberTypes.pending, (state) => {
      state.memberTypesList = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(fetchMemberTypes.fulfilled, (state, { payload }) => {
      state.memberTypesList = payload;
      state.loading = "loaded";
      state.editing = false;
    });
    builder.addCase(fetchMemberTypes.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchMemberGroups.pending, (state) => {
      state.memberGroupsList = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(fetchMemberGroups.fulfilled, (state, { payload }) => {
      state.memberGroupsList = payload;
      state.loading = "loaded";
      state.editing = false;
    });
    builder.addCase(fetchMemberGroups.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchMemberGroupsPublic.pending, (state) => {
      state.memberGroupsList = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(fetchMemberGroupsPublic.fulfilled, (state, { payload }) => {
      state.memberGroupsList = payload;
      state.loading = "loaded";
      state.editing = false;
    });
    builder.addCase(fetchMemberGroupsPublic.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchManagers.pending, (state) => {
      state.managersList = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(fetchManagers.fulfilled, (state, { payload }) => {
      state.managersList = payload;
      state.loading = "loaded";
      state.editing = false;
    });
    builder.addCase(fetchManagers.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchManagersPublic.pending, (state) => {
      state.managersList = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(fetchManagersPublic.fulfilled, (state, { payload }) => {
      state.managersList = payload;
      state.loading = "loaded";
      state.editing = false;
    });
    builder.addCase(fetchManagersPublic.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchReputations.pending, (state) => {
      state.reputationList = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(fetchReputations.fulfilled, (state, { payload }) => {
      state.reputationList = payload;
      state.loading = "loaded";
      state.editing = false;
    });
    builder.addCase(fetchReputations.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchMemberTypeByID.pending, (state) => {
      // state.membertype = {};
      state.loading = "loading";
    });
    builder.addCase(fetchMemberTypeByID.fulfilled, (state, { payload }) => {
      state.error = "";
      state.memberType = payload;
      state.loading = "loaded";
    });
    builder.addCase(fetchMemberTypeByID.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(createMemberType.pending, (state) => {
      state.message = "";
      // state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(createMemberType.fulfilled, (state, { payload }) => {
      // state.message = payload;
      // state.loading = "loaded";
    });
    builder.addCase(createMemberType.rejected, (state, action) => {
      // state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(updateMemberType.pending, (state) => {
      state.message = "";
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(updateMemberType.fulfilled, (state, { payload }) => {
      // state.message = payload;
      state.loading = "loaded";
    });
    builder.addCase(updateMemberType.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchMemberGroupByID.pending, (state) => {
      // state.membergroup = {};
      state.loading = "loading";
    });
    builder.addCase(fetchMemberGroupByID.fulfilled, (state, { payload }) => {
      state.error = "";
      state.memberGroup = payload;
      state.loading = "loaded";
    });
    builder.addCase(fetchMemberGroupByID.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(createMemberGroup.pending, (state) => {
      state.message = "";
      // state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(createMemberGroup.fulfilled, (state, { payload }) => {
      // state.message = payload;
      // state.loading = "loaded";
    });
    builder.addCase(createMemberGroup.rejected, (state, action) => {
      // state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(updateMemberGroup.pending, (state) => {
      state.message = "";
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(updateMemberGroup.fulfilled, (state, { payload }) => {
      // state.message = payload;
      state.loading = "loaded";
    });
    builder.addCase(updateMemberGroup.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchReputationByID.pending, (state) => {
      // state.reputation = {};
      state.loading = "loading";
    });
    builder.addCase(fetchReputationByID.fulfilled, (state, { payload }) => {
      state.error = "";
      state.reputation = payload;
      state.loading = "loaded";
    });
    builder.addCase(fetchReputationByID.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(createReputation.pending, (state) => {
      state.message = "";
      // state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(createReputation.fulfilled, (state, { payload }) => {
      // state.message = payload;
      // state.loading = "loaded";
    });
    builder.addCase(createReputation.rejected, (state, action) => {
      // state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(updateReputation.pending, (state) => {
      state.message = "";
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(updateReputation.fulfilled, (state, { payload }) => {
      // state.message = payload;
      state.loading = "loaded";
    });
    builder.addCase(updateReputation.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
  },
});
// Action creators are generated for each case reducer function
export const {
  setMemberGroup,
  setMemberType,
  setReputation,
  setMemberGroupsAmount,
  setMemberTypesAmount,
  setReputationAmount,
  setManagersAmount
} = membersSlice.actions;

export const selectMembers = createSelector(
  (state) => ({
    memberType: state.members.memberType,
    memberGroup: state.members.memberGroup,
    reputation: state.members.reputation,
    memberTypesList: state.members.memberTypesList,
    memberGroupsList: state.members.memberGroupsList,
    reputationList: state.members.reputationList,
    managersList: state.members.managersList,
    loading: state.members.loading,
    error: state.members.error,
    memberTypesAmount: state.members.memberTypesAmount,
    memberGroupsAmount: state.members.memberGroupsAmount,
    reputationAmount: state.members.reputationAmount,
    managersAmount: state.members.managersAmount,
  }),
  (state) => state
);
export default membersSlice.reducer;
