import PropTypes from 'prop-types';
import React from 'react';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';

export default function Editor(props) {
  const {editorValue, setEditorValue, placeholder} = props;

  const handleFocus = (range, source, editor) => {};
  const modules = {
    toolbar: [
      [{header: [1, 2, false]}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        {list: 'ordered'},
        {list: 'bullet'},
        {indent: '-1'},
        {indent: '+1'},
        {align: []},
      ],
      ['link', 'image'],
      ['clean'],
    ],
    history: {
      delay: 2000,
      maxStack: 500,
      userOnly: true,
    },
  };
  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'align',
    'image',
  ];

  return (
    <ReactQuill
      placeholder={placeholder ? placeholder : 'Product Description...'}
      theme="snow"
      value={editorValue}
      // readOnly={readonly}
      onChange={setEditorValue}
      onFocus={handleFocus}
      modules={modules}
      formats={formats}
      style={{
        minHeight: 300,
        // height: 300,
        display: 'flex',
        flexFlow: 'column nowrap',
      }}
    />
  );
}

/*
 * PropType validation
 */
Editor.propTypes = {
  placeholder: PropTypes.string,
};
