import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

import { infoSnack, successSnack, errorSnack } from "../snackbar/snackbarSlice";

import axios from "axios";

import { uuidv4 } from "../../app/utils";
import baseURL from "../../app/utils.js";
axios.interceptors.request.use(
  (req) => {
    req.headers.common["X-Request-ID"] = uuidv4();
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export const fetchEmails = createAsyncThunk(
  "emails/fetchEmails",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/email_logs${args.query}`,
        config
      );
      thunkAPI.dispatch(setEmailsAmount(response.headers["x-total-count"]));
      thunkAPI.dispatch(setLastQuery(args.query));

      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching emails list"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchEmailTemplates = createAsyncThunk(
  "emails/fetchEmailTemplates",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/email_templates`,
        config
      );

      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching email templates"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const updateEmailTemplate = createAsyncThunk(
  "emails/updateEmailTemplate",
  async (args, thunkAPI) => {
    try {
      const { templateId, token, templateData } = args;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "X-Request-ID": uuidv4(), // Generate a unique request ID
        },
      };

      const response = await axios.patch(
        `${baseURL}/v1/admin/email_templates/${templateId}`,
        templateData,
        config
      );

      thunkAPI.dispatch(successSnack("Template Updated"))
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error updating email template"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const sendTestEmail = createAsyncThunk(
  "emails/sendTestEmail",
  async (args, thunkAPI) => {
    try {
      const { token, emailData } = args;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "X-Request-ID": uuidv4(),
        },
      };

      const response = await axios.post(
        `${baseURL}/v1/admin/email_templates/sample_email`,
        emailData,
        config
      );

      thunkAPI.dispatch(successSnack("Test Email Sent"));
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error sending test email"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


export const fetchMoreEmails = createAsyncThunk(
  "emails/fetchMoreEmails",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/email_logs${args.query}&offset=${args.offset}`,
        config
      );
      // thunkAPI.dispatch(setEmailsAmount(response.headers["x-total-count"]));
      return await response.data;
    } catch (error) {
      if (error.message.includes("401")) {
        localStorage.removeItem("clientUser");
        localStorage.removeItem("clientToken");
      }
      if (error.response && error.response.data) {
        // thunkAPI.dispatch(
        //   errorSnack(
        //     error.response.data.error_description ||
        //       'Error fetching products list'
        //   )
        // );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const filterEmails = createAsyncThunk(
  "emails/filterEmails",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/email_logs${args.query}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching emails list"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchEmailByID = createAsyncThunk(
  "emails/fetchEmailByID",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/admin/email_logs/${args.id}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error fetching email data"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const resendEmail = createAsyncThunk(
  "emails/resendEmail",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //full options
      const response = await axios.patch(
        `${baseURL}/v1/admin/email_logs/${args.id}/resend${args.query}`,
        {},
        config
      );
      thunkAPI.dispatch(infoSnack("Email Resent"));

      return await response;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error resending email"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getFullList = createAsyncThunk(
  "claims/getFullList",
  async (args, thunkAPI) => {
    let objToMap = [];
    for (let i = 0; i < (args.targetLength - args.offset) / 100; i++) {
      objToMap.push({
        token: args.token,
        query: args.query,
        offset: args.offset + 100 * i,
      });
    }

    Promise.all(
      objToMap.map((requestData) =>
        thunkAPI.dispatch(fetchMoreEmails(requestData))
      )
    )
      .then((results) => {})
      .catch((err) => {
        return thunkAPI.rejectWithValue("Error fetching full email list");
      });
  }
);

const emailsSlice = createSlice({
  name: "emails",
  initialState: {
    email: {},
    emailsList: [],
    emailsFilter: [],
    emailTemplates: [],
    loading: "idle",
    message: "",
    error: "",
    emailsAmount: "",
    lastQuery: "",
  },
  reducers: {
    setLastQuery: (state, action) => {
      state.lastQuery = action.payload;
    },
    setEmailsAmount: (state, action) => {
      state.emailsAmount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEmails.pending, (state) => {
      state.emailsList = [];
      state.emailsFilter = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(fetchEmails.fulfilled, (state, { payload }) => {
      state.emailsList = payload;
      state.emailsFilter = payload;
      state.loading = "loaded";
    });
    builder.addCase(fetchEmails.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchEmailTemplates.pending, (state) => {
      state.emailTemplates = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(fetchEmailTemplates.fulfilled, (state, { payload }) => {
      state.emailTemplates = payload;
      state.loading = "loaded";
    });
    builder.addCase(fetchEmailTemplates.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchMoreEmails.fulfilled, (state, { payload }) => {
      state.error = "";
      state.emailsList = state.emailsList.concat(payload);
      state.loading = "loaded";
    });
    builder.addCase(fetchMoreEmails.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(getFullList.fulfilled, (state, { payload }) => {
      state.error = "";
      state.emailsList = state.emailsList.sort(function (a, b) {
        return a.id - b.id;
      });
      state.loading = "loaded";
    });
    builder.addCase(getFullList.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(filterEmails.pending, (state) => {
      state.emailsFilter = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(filterEmails.fulfilled, (state, { payload }) => {
      state.emailsFilter = payload;
      state.loading = "loaded";
    });
    builder.addCase(filterEmails.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchEmailByID.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchEmailByID.pending, (state) => {
      state.email = {};
      state.loading = "loading";
    });
    builder.addCase(fetchEmailByID.fulfilled, (state, { payload }) => {
      state.email = payload;
      state.loading = "loaded";
    });
    builder.addCase(resendEmail.pending, (state) => {
      state.message = "";
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(resendEmail.fulfilled, (state, { payload }) => {
      // state.message = payload;
      state.loading = "loaded";
    });
    builder.addCase(resendEmail.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(updateEmailTemplate.pending, (state) => {
      state.error = "";
      state.success = false;
    });
    builder.addCase(updateEmailTemplate.fulfilled, (state, { payload }) => {
      state.error = "";
      state.success = true;
    });
    builder.addCase(updateEmailTemplate.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(sendTestEmail.pending, (state) => {
      state.error = "";
      state.success = false;
    });
    builder.addCase(sendTestEmail.fulfilled, (state, { payload }) => {
      state.error = "";
      state.success = true;
    });
    builder.addCase(sendTestEmail.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setLastQuery, setEmailsAmount } = emailsSlice.actions;

export const selectEmails = createSelector(
  (state) => ({
    emails: state.emails,
    emailsList: state.emails.emailsList,
    emailsFilter: state.emails.emailsFilter,
    emailTemplates: state.emails.emailTemplates,
    email: state.emails.email,
    loading: state.emails.loading,
    error: state.emails.error,
    email: state.emails.email,
    emailsAmount: state.emails.emailsAmount,
    lastQuery: state.emails.lastQuery,
  }),
  (state) => state
);

export default emailsSlice.reducer;
