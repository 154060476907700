import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Link as RouterLink, useHistory } from "react-router-dom";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import { logoutUser, selectAuth } from "../features/auth/authSlice";

import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuIcon from "@material-ui/icons/Menu";

import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import { setXClientHost } from "../app/utils";
import { selectCategories } from "../features/categories/categoriesSlice";
import { fetchLogo, selectContents } from "../features/content/contentsSlice";
import { selectCustomisation } from "../features/customisation/customisationSlice";
import { fetchProducts } from "../features/products/coreProductsSlice";
import { selectUsers } from "../features/users/usersSlice";
import { resetWishlistCart } from "../features/wishlist/wishlistSlice";
import MobileMenu from "./MobileMenu";

function ListItemLink(props) {
  const classes = useStyles();

  const { onClick, selected, icon, primary, show, to, sub } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    show && (
      <li>
        <ListItem
          selected={selected}
          onClick={onClick}
          button
          component={renderLink}
          classes={{
            selected: classes.selected, //
          }}
        >
          <ListItemText
            // style={sub ? {fontSize: '0.9rem'} : {fontSize: '1rem'}}
            primary={primary}
            className={sub && classes.sub}
          />
        </ListItem>
      </li>
    )
  );
}

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  sub: {
    color: "rgba(109, 113, 117, 1)",
    fontSize: "0.9rem",
  },
  selected: {
    borderLeftStyle: "solid",
    borderLeftColor: theme.palette.primary.main,
    borderLeftSize: 5,
    paddingLeft: "11px",
  },

  container: {
    alignItems: "center",
    backgroundColor: theme.palette.headerColor.main,
    // backgroundColor: "#FFFF",
    display: "flex",
    flexDirection: "row",
    height: "75px",
    justifyContent: "center",
  },

  itemContainer: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    cursor: "pointer",
    // marginLeft: 50,
    color: "#fff",
  },
  itemIcon: {
    paddingtop: 2,
    paddingLeft: 8,
    paddingRight: 8,
    display: "flex",
    alignItems: "center",
  },

  headerIcon: {
    color: theme.palette.headerButtonFont.main,
  },
  root: {
    display: "flex",
    zIndex: 2,
    // marginRight: "1.5rem",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
    minHeight: "74px !important",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  pointsText: {
    color: theme.palette.primaryFont.main,
  },
  pointsNumber: {
    color: theme.palette.highlight.main,
  },
}));

export default function MobileHeader() {
  const { customisation } = useSelector(selectCustomisation);
  const history = useHistory();
  const theme = useTheme();
  const anchorRef = React.useRef(null);
  const [show, setShow] = useState(true);
  const dispatch = useDispatch();
  const { signedIn } = useSelector(selectAuth);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { userAccount } = useSelector(selectUsers);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { categoriesTree } = useSelector(selectCategories);
  let jwtToken = localStorage.getItem("clientToken");
  const handleChildClick = (index, event) => {
    setSelectedIndex(index);
  };

  const { logo } = useSelector(selectContents);
  useEffect(() => {
    if (!logo.value) {
      dispatch(
        fetchLogo({
          host: setXClientHost(),
          token: jwtToken,
          name: "logo",
        })
      );
    }
  }, [logo]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleSearch = () => {
    dispatch(
      fetchProducts({
        token: jwtToken,
        query: `?limit=24`,
      })
    );
    history.push(`/products/search`);
  };

  useEffect(() => {
    if (
      window.location.pathname.includes("signin") ||
      window.location.pathname.includes("resetpassword") ||
      window.location.pathname.includes("help")
    ) {
      setShow(false);
    } else {
      setShow(true);
      let jwtToken = localStorage.getItem("clientToken");
      if (!jwtToken) {
        handleLogout();
      }
    }
  }, [window.location.pathname]);
  const handleLogout = () => {
    localStorage.removeItem("clientUser");
    localStorage.removeItem("clientToken");
    setShow(false);
    dispatch(logoutUser());
    dispatch(resetWishlistCart());
    history.push("/signin");
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  // const handleDrawerClose = () => {
  //   setState({ ...state, left: false });
  // };
  // const [state, setState] = useState({
  //   top: false,
  //   left: false,
  //   bottom: false,
  //   right: false,
  // });

  // const toggleDrawer = (anchor, open) => (event) => {
  //   if (
  //     event &&
  //     event.type === "keydown" &&
  //     (event.key === "Tab" || event.key === "Shift")
  //   ) {
  //     return;
  //   }

  //   setState({ ...state, [anchor]: open });
  // };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"

      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction !== "rtl" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </div>
      <Divider />

      <MobileMenu drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
    </div>
  );

  // style={{props.location.pathname.includes('signin')}}
  if (window.location.pathname.includes('signin') && process.env.REACT_APP_CLIENT_ID == "17") {
    return <div></div>;
  } else {
    return (
      <div className={classes.container}>
        {/* {signedIn && (
        <div style={{ color: "#fff", width: 48 }}>
          <IconButton
            color="#FFF"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            className={clsx(open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction !== "rtl" ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </div>
            <Divider />
            <List>
              {list.map((listItem, index) => (
                <ListItemLink
                  key={index}
                  to={listItem.url}
                  primary={listItem.name}
                  // icon={listItem.icon}
                  show={listItem.show}
                  onClick={(e) => handleChildClick(index, e)}
                  selected={selectedIndex === index}
                  sub={listItem.sub}
                />
              ))}
            </List>
            <div
              style={{ color: theme.palette.primaryButton.main }}
              className={classes.headerItem}
              onClick={handleLogout}
            >
              Sign Out
            </div>
          </Drawer>
        </div>
      )} */}
        <Link to="/">
          <div style={{ marginLeft: 24, marginRight: 24 }}>
            {/* <img
            src={`/${customisation.theme.mobileLogo}`}
            alt="logo"
            // height="40px"
            width="110px"
          /> */}
            {logo.value && (
              <img
                src={`${process.env.REACT_APP_ASSETS_URL}/theme/logo/${logo.value}`}
                alt="logo"
                height="50px"
              />
            )}
          </div>
        </Link>
        {show && (
          <div style={{ marginLeft: "auto" }} className={classes.itemContainer}>
            <div style={{ color: "#fff", width: 48 }}>
              {["Categories"].map((anchor) => (
                <React.Fragment key={anchor}>
                  <ClickAwayListener
                    onClickAway={() => {
                      setDrawerOpen(false);
                    }}
                  >
                    <div>
                      <IconButton
                        className={classes.headerIcon}
                        aria-label="open drawer"
                        edge="end"
                        onClick={() => {
                          setDrawerOpen((dOpen) => !dOpen);
                        }}
                      >
                        <MenuIcon />
                      </IconButton>

                      <SwipeableDrawer
                        className={classes.drawer}
                        variant="persistent"
                        anchor="left"
                        onClose={() => {}}
                        onOpen={() => {}}
                        open={drawerOpen}
                        classes={{
                          paper: classes.drawerPaper,
                        }}
                      >
                        {list(anchor)}
                      </SwipeableDrawer>
                    </div>
                  </ClickAwayListener>
                </React.Fragment>
              ))}
            </div>
            <div className={classes.root}>
              <div>
                <Button
                  ref={anchorRef}
                  aria-controls={open ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                >
                  <AccountCircleOutlinedIcon
                    className={classes.headerIcon}
                    style={{ fontSize: 30 }}
                  />
                </Button>

                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                  modifiers={{
                    offset: {
                      enabled: true,
                      offset: "-20, 0",
                    },
                  }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            autoFocusItem={open}
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                            onClick={handleClose}
                          >
                            <MenuItem>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div className={classes.pointsText}>
                                  Points Balance:{" "}
                                </div>
                                <div
                                  className={classes.pointsNumber}
                                  style={
                                    {
                                      // marginLeft: midpoint ? 8 : 16,
                                      // marginRight: midpoint ? 8 : 48,
                                    }
                                  }
                                >
                                  {userAccount.redeemablePoints}
                                </div>
                              </div>
                            </MenuItem>
                            <hr />
                            <Link className={classes.accountLink} to="/account">
                              <MenuItem>My Account</MenuItem>
                            </Link>
                            <Link className={classes.accountLink} to="/invoice">
                              <MenuItem>Claim Form</MenuItem>
                            </Link>
                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
