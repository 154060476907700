import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { setXClientHost } from "../../app/utils";
import { selectCustomisation } from "../customisation/customisationSlice";
import { fetchContentByName, selectContents } from "./contentsSlice";

const BackButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#fff",
    color: theme.palette.primaryFont.main,
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: theme.palette.primaryFont.main,
    borderRadius: 20,
    width: 200,
    "&:hover": {
      backgroundColor: "#DDD",
    },
    fontWeight: "bold",
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  worksContainer: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondaryFont.main,
    display: "flex",
    flexFlow: "column nowrap",
    // justifyContent: 'center',
    alignItems: "center",
    height: "100%",
    marginBottom: 62,
  },
  cardContainer: {
    backgroundColor: "#fff",
    borderRadius: 16,
    color: theme.palette.secondaryFont.main,
    margin: 48,
    padding: 48,
  },
  header: { fontWeight: "bold", marginBottom: 24 },
  title: {
    color: theme.palette.primaryFont.main,
    fontWeight: "bold",
    marginBottom: 16,
    paddingTop: 8,
  },
  subTitle: {
    fontWeight: "bold",
    marginBottom: 8,
  },
  text: {
    marginBottom: 16,
  },
}));

export default function HowItWorks() {
  const { customisation } = useSelector(selectCustomisation);
  const classes = useStyles();
  const history = useHistory();
  let jwtToken = localStorage.getItem("clientToken");
  const dispatch = useDispatch();
  const goBack = () => {
    history.goBack();
  };

  const { content, loading } = useSelector(selectContents);

  useEffect(() => {
    dispatch(
      fetchContentByName({
        host: setXClientHost(),
        token: jwtToken,
        name: "how-it-works",
      })
    );
  }, []);

  return loading !== "loaded" ? (
    <div className={classes.worksContainer}></div>
  ) : content.value && content.value !== "<p><br></p>" ? (
    <div className={classes.worksContainer}>
      <Paper className={classes.cardContainer} elevation={4}>
        <div className={classes.customContentContainer}>
          <ReactQuill
            value={content.value}
            readOnly={true}
            theme={"bubble"}
            // style={{ fontSize: 18 }}
          />
        </div>
      </Paper>
      <BackButton onClick={goBack}>Go Back</BackButton>
    </div>
  ) : (
    <div className={classes.worksContainer}>
      <Paper className={classes.cardContainer} elevation={4}>
        <Typography className={classes.header} variant="h3">
          How It Works
        </Typography>

        <Typography className={classes.subTitle} variant="h6">
          Earning Your {customisation.theme.clientName} points:
        </Typography>
        <Typography className={classes.text} variant="body1">
          At the end of each month you will be credited with points against all
          approved sales made for that month, subject to the trading terms of
          the promoter. These points will be uploaded to your personal web page
          and your Total Available Points will appear at the top of your home
          page, accumulating over time so you can save for larger and larger
          rewards prizes over the course of the program. Please note that points
          earned in any given month will not appear on your web site page for 5
          working days from the close of that month.
        </Typography>
        <Typography className={classes.text} variant="body1">
          Prize claims may be made at any time, using all or part of your Total
          Available Points.
        </Typography>
        <Typography className={classes.text} variant="body1">
          Reward prizes can only be delivered to a business address open 9.00am
          - 5.00pm Monday to Friday and NOT to a PO Box or home address.
        </Typography>
        <Typography className={classes.text} variant="body1">
          Please allow 30 days from your date of claim for the processing,
          handling and delivery of your reward prizes.
        </Typography>
        <Typography
          style={{ paddingTop: 24 }}
          className={classes.subTitle}
          variant="h6"
        >
          Program Hotline
        </Typography>
        <Typography className={classes.text} variant="body1">
          For any queries on points or gift deliveries please contact{" "}
          {customisation.theme.clientName} via
        </Typography>
        <Typography
          style={{ marginBottom: 0 }}
          className={classes.text}
          variant="body1"
        >
          Phone: {customisation.theme.phone}.
        </Typography>
        <Typography className={classes.text} variant="body1">
          Email: {customisation.theme.email}
        </Typography>
      </Paper>
      <BackButton onClick={goBack}>Go Back</BackButton>
    </div>
  );
}
