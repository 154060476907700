import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import useMediaQuery from "@material-ui/core/useMediaQuery";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";

import { selectCustomisation } from "../customisation/customisationSlice";
import { fetchUserByID, selectUsers } from "../users/usersSlice";
import { fetchUserOrderByID, selectOrders } from "./ordersSlice";
const ColorButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.primaryButton.main,
    color: theme.palette.primaryButtonFont.main,
    "&:hover": {
      backgroundColor: theme.palette.primaryButton.main,
      opacity: 0.7,
    },
    // fontWeight: 'bold',
  },
}))(Button);

const SecondaryButton = withStyles((theme) => ({
  root: {
    // margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.primaryButton.main,
    color: "#fff",
    width: 250,
    fontWeight: "bold",
    borderRadius: 16,
    "&:hover": {
      backgroundColor: " #ce5087",
    },
    // fontWeight: 'bold',
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  orderContainer: {
    // margin: 60,
    // minWidth: 1280,
    width: "100%",
  },
  cartTitle: {
    textAlign: "left",
    marginTop: 60,
    marginBottom: 24,
    fontWeight: "bold",
    fontSize: 40,
  },
  cartProducts: {
    margin: "2rem",
    borderRadius: 16,
    padding: 32,
    marginBottom: 60,
    color: theme.palette.secondaryFont.main,
    fontSize: 16,
    fontWeight: "bold",
  },
  orderNumber: {
    color: theme.palette.primaryFont.main,
  },
  productsContainer: { marginTop: 16, paddingLeft: 8, paddignRight: 8 },
  productsHeader: {
    // marginRight: 50,
    paddingBottom: 0,
    // padding: 0,
    display: "flex",
    alignItems: "center",
    backgroundColor: "#ddd",
  },
  productsList: {
    borderBottom: "1px solid #DDD",
    paddingBottom: 16,
    paddingTop: 16,
  },
  productsActions: {
    paddingTop: 8,
  },
  cartDetailsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    color: theme.palette.secondaryFont.main,
    fontSize: 24,
  },
  deliveryDetailsContainer: { width: "75%", marginRight: 48 },
  deliveryDetails: {
    padding: 32,
    paddingBottom: 24,
    paddingTop: 24,
    color: theme.palette.secondaryFont.main,
    fontSize: 24,
    borderRadius: 16,
  },
  additionalPapersContainer: {
    display: "flex",
    flexDirection: "column",
  },
  comments: {
    borderRadius: 16,
    padding: 32,
    paddingBottom: 56,
    paddingTop: 48,
    marginBottom: 82,
    color: theme.palette.secondaryFont.main,
    fontSize: 24,
  },
  assistance: {
    borderRadius: 16,
    padding: 32,
    paddingBottom: 56,
    paddingTop: 48,
    marginBottom: 82,
    color: theme.palette.secondaryFont.main,
    fontSize: 24,
    textAlign: "center",
  },
  assistanceText: {
    fontWeight: "bold",
    marginBottom: 8,
  },
  review: {
    borderRadius: 16,
    padding: 32,
    paddingBottom: 56,
    paddingTop: 48,
    marginBottom: 82,
    color: theme.palette.secondaryFont.main,
    fontSize: 24,
  },
  textLabel: {
    marginBottom: 4,
    // marginTop: 16,
  },
  header: {
    color: theme.palette.primaryFont.main,
  },
}));

export default function Order() {
  const classes = useStyles();

  const { customisation } = useSelector(selectCustomisation);
  const { order, orderProducts } = useSelector(selectOrders);
  let jwtToken = localStorage.getItem("clientToken");
  const matches = useMediaQuery("(min-width:850px)");

  const dispatch = useDispatch();
  const location = useLocation();
  const { userAccount, user } = useSelector(selectUsers);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    let id = location.pathname.split("/")[2];
    if (!userAccount.id) {
    } else if (order.id !== Number(id)) {
      dispatch(
        fetchUserOrderByID({
          token: jwtToken,
          userID: userAccount.id,
          orderID: id,
        })
      );
    } else {
      setLoaded(true);
    }
  }, [userAccount]);

  useEffect(() => {
    if (order.userID) {
      dispatch(
        fetchUserByID({
          token: jwtToken,
          id: order.userID,
        })
      );
    }
  }, []);

  useEffect(() => {
    let id = location.pathname.split("/")[2];
    if (order.id === Number(id)) {
      setLoaded(true);
    }
  }, [order]);

  const formatPTS = (str) => {
    return str.toLocaleString();
  };

  const statusList = [
    { id: 1, name: "active" },
    { id: 2, name: "inactive" },
    { id: 3, name: "unavailable" },
    { id: 4, name: "deleted" },
    { id: 5, name: "pending" },
    { id: 6, name: "confirmed" },
    { id: 7, name: "cancelled" },
    { id: 8, name: "outstanding" },
    { id: 9, name: "completed" },
    { id: 10, name: "done" },
    { id: 11, name: "paid" },
    { id: 12, name: "sent" },
    { id: 16, name: "dispatched" },
    { id: 21, name: "declined" },
    { id: 23, name: "refunded" },
    { id: 24, name: "processing" },
    { id: 25, name: "invoiced" },
    { id: 26, name: "purchaseordered" },
    { id: 27, name: "approved" },
    { id: 28, name: "purchased" },
  ];

  const getStatusName = (id) => {
    let status = statusList.filter((stat) => stat.id === id);
    return status.length ? status[0].name : "";
  };
  //

  let content = <div></div>;
  if (loaded && order) {
    content = (
      <Paper elevation={3} className={classes.cartProducts}>
        <div
          style={{
            fontSize: 40,
            fontWeight: "bold",
            marginBottom: 24,
          }}
          className={classes.orderNumber}
        >
          Order Number: {order.id}
        </div>
        <div
          style={{
            display: "flex",
            flexFlow: matches ? "row nowrap" : "column",
          }}
        >
          <div style={{ marginRight: 60, marginBottom: matches ? 0 : 16 }}>
            <div
              style={{
                fontSize: 24,
                fontWeight: "bold",
                marginBottom: 16,
              }}
              className={classes.header}
            >
              Member Info
            </div>
            <div className={classes.textLabel}>
              Member Display Name:{" "}
              <span style={{ fontWeight: "normal" }}>
                {order.userDisplayName}
              </span>
            </div>
            <div className={classes.textLabel}>
              Email:{" "}
              <span style={{ fontWeight: "normal" }}>{order.userEmail}</span>
            </div>
            <div className={classes.textLabel}>
              Contact Number:{" "}
              <span style={{ fontWeight: "normal" }}>{order.userMobile} </span>
            </div>
            {(process.env.REACT_APP_CLIENT_ID == "11" ||
              process.env.REACT_APP_CLIENT_ID == "12") && (
              <div className={classes.textLabel}>
                User Code:{" "}
                <span style={{ fontWeight: "normal" }}>
                  {user.externalID ? user.externalID : "N/A"}
                </span>
              </div>
            )}
            {(process.env.REACT_APP_CLIENT_ID == "11" ||
              process.env.REACT_APP_CLIENT_ID == "12") && (
              <div className={classes.textLabel}>
                Company/Organisation Name:{" "}
                <span style={{ fontWeight: "normal" }}>
                  {user.company ? user.company : "N/A"}
                </span>
              </div>
            )}
          </div>
          <div>
            <div
              style={{
                fontSize: 24,
                fontWeight: "bold",
                marginBottom: 16,
              }}
              className={classes.header}
            >
              Delivery Address
            </div>
            <Grid container direction="column">
              {(process.env.REACT_APP_CLIENT_ID == "11" ||
                process.env.REACT_APP_CLIENT_ID == "12") && (
                <Grid item sm>
                  <div className={classes.textLabel}>
                    Company Name:{" "}
                    {order.deliveryAddress
                      ? order.deliveryAddress.companyName
                        ? order.deliveryAddress.companyName
                        : "N/A"
                      : "N/A"}
                  </div>
                </Grid>
              )}
              <Grid item sm>
                <div className={classes.textLabel}>
                  {
                    // (order.deliveryAddress ?  order.deliveryAddress.streetNumber : '') +
                    // ' ' +
                    order.deliveryAddress
                      ? order.deliveryAddress.streetName
                      : ""
                  }
                </div>
              </Grid>

              <Grid item sm>
                <div className={classes.textLabel}>
                  {order.deliveryAddress ? order.deliveryAddress.suburb : ""}{" "}
                </div>
              </Grid>

              <Grid item sm>
                <div className={classes.textLabel}>
                  {(order.deliveryAddress ? order.deliveryAddress.city : "") +
                    " " +
                    (order.deliveryAddress
                      ? order.deliveryAddress.postCode
                      : "")}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        {matches && (
          <Grid
            container
            spacing={3}
            direction="column"
            className={classes.productsContainer}
            style={{
              marginBottom: matches ? 0 : 16,
              // width: !matches && "fit-content",
              overflowX: "scroll",
            }}
          >
            <Grid
              item
              sm
              style={{ paddingTop: 0, paddingBottom: 0, marginTop: 24 }}
            >
              <Grid
                container
                spacing={3}
                direction="row"
                className={classes.productsHeader}
                style={{ flexWrap: !matches && "nowrap" }}
              >
                <Grid item sm style={{ minWidth: !matches && 200 }}>
                  Product:
                </Grid>
                <Grid item sm style={{ minWidth: !matches && 200 }}>
                  SKU:
                </Grid>
                <Grid item sm style={{ minWidth: !matches && 200 }}>
                  Quantity:
                </Grid>
                <Grid item sm style={{ minWidth: !matches && 200 }}>
                  Price (pts)
                </Grid>
                <Grid item sm style={{ minWidth: !matches && 200 }}>
                  Status:
                </Grid>
                <Grid item sm style={{ minWidth: !matches && 200 }}>
                  Tracking URL:
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm style={{ flexWrap: !matches && "nowrap" }}>
              {order.products.length &&
                order.products.map((pr, index) => (
                  <Grid
                    container
                    spacing={3}
                    direction="row"
                    alignItems="center"
                    className={classes.productsList}
                  >
                    <Grid item sm style={{ minWidth: !matches && 200 }}>
                      {pr.productName}
                    </Grid>
                    <Grid item sm style={{ minWidth: !matches && 200 }}>
                      {pr.productSKU}
                    </Grid>
                    <Grid item sm style={{ minWidth: !matches && 200 }}>
                      {pr.quantity}
                    </Grid>
                    <Grid item sm style={{ minWidth: !matches && 200 }}>
                      {pr.totalPoints.toFixed(0)}
                    </Grid>
                    {/* {<Grid item sm style={{  minWidth: !matches && 200 }}>
                    {pr.totalPoints.toFixed(0)}
                  </Grid>} */}
                    <Grid item sm style={{ minWidth: !matches && 200 }}>
                      {getStatusName(pr.statusID)}
                    </Grid>
                    <Grid item sm style={{ minWidth: !matches && 200 }}>
                      {pr.courierTrackingURL && (
                        <Link
                          to={{ pathname: pr.courierTrackingURL }}
                          target="_blank"
                        >
                          View Tracking
                        </Link>
                      )}
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        )}
        {!matches && (
          <div
            style={{
              display: "flex",
              width: "100%",
              marginTop: 20,
              marginBottom: 20,
              border: `1px solid ${customisation.theme.secondaryColor}`,
              borderRadius: 10,
            }}
          >
            {order.products.length &&
              order.products.map((pr, index) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: 10,
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      padding: 5,
                      display: "flex",
                      borderBottom: "1px solid lightgrey",
                    }}
                  >
                    <span style={{ width: "50%" }}>Product:</span>
                    <span style={{ width: "50%", fontSize: 12 }}>
                      {pr.productName}
                    </span>
                  </div>
                  <div
                    style={{
                      padding: 5,
                      display: "flex",
                      borderBottom: "1px solid lightgrey",
                    }}
                  >
                    <span style={{ width: "50%" }}>SKU:</span>
                    <span style={{ width: "50%", fontSize: 12 }}>
                      {pr.productSKU}
                    </span>
                  </div>
                  <div
                    style={{
                      padding: 5,
                      display: "flex",
                      borderBottom: "1px solid lightgrey",
                    }}
                  >
                    <span style={{ width: "50%" }}>Quantity:</span>
                    <span style={{ width: "50%", fontSize: 12 }}>
                      {pr.quantity}
                    </span>
                  </div>
                  <div
                    style={{
                      padding: 5,
                      display: "flex",
                      borderBottom: "1px solid lightgrey",
                    }}
                  >
                    <span style={{ width: "50%" }}>Price (pts):</span>
                    <span style={{ width: "50%", fontSize: 12 }}>
                      {pr.totalPoints.toFixed(0)}
                    </span>
                  </div>
                  <div
                    style={{
                      padding: 5,
                      display: "flex",
                      borderBottom: "1px solid lightgrey",
                    }}
                  >
                    <span style={{ width: "50%" }}>Status:</span>
                    <span style={{ width: "50%", fontSize: 12 }}>
                      {getStatusName(pr.statusID)}
                    </span>
                  </div>
                  <div
                    style={{
                      padding: 5,
                      display: "flex",
                    }}
                  >
                    <span style={{ width: "50%" }}>Tracking URL:</span>
                    <span style={{ width: "50%", fontSize: 12 }}>
                      {pr.courierTrackingURL && (
                        <Link
                          to={{ pathname: pr.courierTrackingURL }}
                          target="_blank"
                        >
                          View Tracking
                        </Link>
                      )}
                    </span>
                  </div>
                  {order.products.length > index + 1 && (
                    <hr
                      style={{
                        width: "100%",
                        borderTop: `1px solid ${customisation.theme.secondaryColor}`,
                      }}
                    />
                  )}
                </div>
              ))}
          </div>
        )}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              fontSize: 24,
              fontWeight: "bold",
              marginBottom: 16,
            }}
            className={classes.header}
          >
            Order Notes
          </div>
          <span>{order.notes}</span>
        </div>
        <Grid item sm style={{ maxHeight: 90 }}>
          <Grid
            container
            spacing={3}
            direction="row"
            className={classes.productsActions}
          >
            <Grid item sm style={{ textAlign: "right" }}>
              <div>
                Subtotal:{" "}
                <span style={{ fontWeight: "normal" }}>
                  {order.totalPoints.toLocaleString()} pts
                </span>
              </div>
              <div style={{ marginTop: 8 }}>
                Total:{" "}
                <span style={{ fontWeight: "normal" }}>
                  {order.totalPoints.toLocaleString()} pts
                </span>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  return <div className={classes.orderContainer}>{content}</div>;
}
