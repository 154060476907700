import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

import { errorSnack, successSnack } from "../snackbar/snackbarSlice";

import axios from "axios";

import { uuidv4 } from "../../app/utils";
import baseURL from "../../app/utils.js";
axios.interceptors.request.use(
  (req) => {
    req.headers.common["X-Request-ID"] = uuidv4();
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export const fetchStores = createAsyncThunk(
  "stores/fetchStores",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/stores${args.query}`,
        config
      );
      thunkAPI.dispatch(setStoresAmount(response.headers["x-total-count"]));
      thunkAPI.dispatch(setLastQuery(args.query));
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching Stores list"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchStoresPublic = createAsyncThunk(
  "stores/fetchStoresPublic",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { 'X-Client-Host': process.env.REACT_APP_LOCAL_URL, },
      };
      const response = await axios.get(
        `${baseURL}/v1/rt/stores${args.query}`,
        config
      );
      thunkAPI.dispatch(setStoresAmount(response.headers["x-total-count"]));
      thunkAPI.dispatch(setLastQuery(args.query));
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching Stores list"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchMoreStores = createAsyncThunk(
  "stores/fetchMoreStores",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/stores${args.query}&offset=${args.offset}`,
        config
      );
      // thunkAPI.dispatch(setStoresAmount(response.headers["x-total-count"]));
      return await response.data;
    } catch (error) {
      if (error.message.includes("401")) {
        localStorage.removeItem("clientUser");
        localStorage.removeItem("clientToken");
      }
      if (error.response && error.response.data) {
        // thunkAPI.dispatch(
        //   errorSnack(
        //     error.response.data.error_description ||
        //       'Error fetching products list'
        //   )
        // );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchUserStores = createAsyncThunk(
  "stores/fetchUserStores",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/admin/stores/${args.id}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error fetching Store data"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchUserStoreByID = createAsyncThunk(
  "stores/fetchUserStoreByID",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/admin/stores/${args.id}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error fetching Store data"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchStoreByID = createAsyncThunk(
  "stores/fetchStoreByID",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/admin/stores/${args.id}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.message.includes("401")) {
        localStorage.removeItem("clientUser");
        localStorage.removeItem("clientToken");
      }
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error fetching store data"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const deleteStore = createAsyncThunk(
  "stores/deleteStore",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //full options
      const response = await axios.delete(
        `${baseURL}/v1/admin/stores/${args.id}`,
        config
      );
      thunkAPI.dispatch(successSnack("Store deleted"));
      return await response;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error deleting Store"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const createStore = createAsyncThunk(
  "stores/createStore",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //full options
      const response = await axios.post(
        `${baseURL}/v1/admin/stores`,
        args.store,
        config
      );
      thunkAPI.dispatch(successSnack("Store created successfully"));

      return await response;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error creating Store"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const updateStore = createAsyncThunk(
  "stores/updateStore",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //full options
      const response = await axios.patch(
        `${baseURL}/v1/admin/stores/${args.id}`,
        args.store,
        config
      );
      thunkAPI.dispatch(
        fetchStores({ token: args.token, query: "?limit=200" })
      );
      // thunkAPI.dispatch(fetchStoreByID({ token: args.token, id: args.id }));

      // thunkAPI.dispatch(successSnack('Store updated'));
      thunkAPI.dispatch(successSnack("Store details updated successfully"));

      return await response;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error updating store"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getFullList = createAsyncThunk(
  "claims/getFullList",
  async (args, thunkAPI) => {
    let objToMap = [];
    for (let i = 0; i < (args.targetLength - args.offset) / 100; i++) {
      objToMap.push({
        token: args.token,
        query: args.query,
        offset: args.offset + 100 * i,
      });
    }

    return new Promise((resolve) => {
      Promise.all(
        objToMap.map((requestData) =>
          thunkAPI.dispatch(fetchMoreStores(requestData))
        )
      )
        .then((results) => {})
        .catch((err) => {
          return thunkAPI.rejectWithValue("Error fetching full stores list");
        });
    });
  }
);

const storesSlice = createSlice({
  name: "stores",
  initialState: {
    store: {},
    storesList: [],
    storesFilter: [],
    loading: "idle",
    message: "",
    error: "",
    storesAmount: "",
    lastQuery: "",
  },
  reducers: {
    resetStore(state, action) {
      state.storesList = { data: [] };
      state.loading = "idle";
    },
    setStore(state, action) {
      state.store = action.payload;
    },
    setLastQuery: (state, action) => {
      state.lastQuery = action.payload;
    },
    setStoresAmount: (state, action) => {
      state.storesAmount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStores.pending, (state) => {
      state.storesList = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(fetchStores.fulfilled, (state, { payload }) => {
      state.storesList = payload;
      state.loading = "loaded";
    });
    builder.addCase(fetchStores.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchStoresPublic.pending, (state) => {
      state.storesList = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(fetchStoresPublic.fulfilled, (state, { payload }) => {
      state.storesList = payload;
      state.loading = "loaded";
    });
    builder.addCase(fetchStoresPublic.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchMoreStores.fulfilled, (state, { payload }) => {
      state.error = "";
      state.storesList = state.storesList.concat(payload);
      state.loading = "loaded";
    });
    builder.addCase(fetchMoreStores.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(getFullList.fulfilled, (state, { payload }) => {
      state.error = "";
      state.storesList = state.storesList.sort(function (a, b) {
        return a.id - b.id;
      });
      state.loading = "loaded";
    });
    builder.addCase(getFullList.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchUserStores.pending, (state) => {
      state.storesList = [];
      state.loading = "loading";
    });
    builder.addCase(fetchUserStores.fulfilled, (state, { payload }) => {
      state.storesList = payload;
      state.loading = "loaded";
    });
    builder.addCase(fetchUserStoreByID.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchUserStoreByID.pending, (state) => {
      state.store = {};
      state.loading = "loading";
    });
    builder.addCase(fetchUserStoreByID.fulfilled, (state, { payload }) => {
      state.store = payload;
      state.loading = "loaded";
    });
    builder.addCase(fetchUserStores.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(deleteStore.pending, (state) => {
      state.message = "";
      state.loading = "loading";
    });
    builder.addCase(deleteStore.fulfilled, (state, { payload }) => {
      // state.message = payload;
      state.loading = "loaded";
    });
    builder.addCase(deleteStore.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(createStore.pending, (state) => {
      state.message = "";
      state.loading = "loading";
    });
    builder.addCase(createStore.fulfilled, (state, { payload }) => {
      // state.message = payload;
      state.loading = "loaded";
    });
    builder.addCase(createStore.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(updateStore.pending, (state) => {
      state.message = "";
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(updateStore.fulfilled, (state, { payload }) => {
      // state.message = payload;
      state.loading = "loaded";
    });
    builder.addCase(updateStore.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
  },
});

export const { setStore, setLastQuery, setStoresAmount } = storesSlice.actions;

export const selectStores = createSelector(
  (state) => ({
    stores: state.stores,
    storesList: state.stores.storesList,
    storesFilter: state.stores.storesFilter,
    loading: state.stores.loading,
    error: state.stores.error,
    store: state.stores.store,
    storesAmount: state.stores.storesAmount,
    lastQuery: state.stores.lastQuery,
  }),
  (state) => state
);
export default storesSlice.reducer;
