import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import React, { useState } from "react";
import { CSVDownloader } from "react-papaparse";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  outer: { display: "flex", flexFlow: "column" },
  title: {
    color: "#fff",
    padding: 16,
    backgroundColor: theme.palette.secondary.main,
    textAlign: "center",
    fontSize: 24,
    fontWeight: "bold",
  },
  textItem: {
    marginBottom: 16,
  },
  iconButtonComponent: {
    marginRight: 8,
    color: "#fff",
    paddingLeft: 8,
    paddingRight: 8,
  },
  colorButton: {
    margin: theme.spacing(0, 0, 0),
    backgroundColor: theme.palette.secondaryButton.main,
    color: theme.palette.secondaryButtonFont.main,
    "&:hover": {
      backgroundColor: theme.palette.secondaryButton.main,
      opacity: 0.7,
    },
    width: "100%",
  },
}));

export default function CSVDownload(props) {
  const { rowList, data, productsAmount, getFullList } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [rowsSelected, setRowList] = useState(rowList);
  const [fileName, setFileName] = useState("download");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if (data.length < productsAmount) {
      getFullList();
    }
  };
  const formatCSVData = (csvData) => {
    let newData = [...csvData]; // copying the old array
    let updatedArr = [];
    let timeZoneOptions = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZone: "Australia/Sydney",
    };

    newData.forEach((item) => {
      if (item.margin) {
        item.margin = item.margin * 100;
      }
      if (item.rrp) {
        item.rrp = Number(item.rrp) / 100;
      }
      if (item.costPrice) {
        item.costPrice = Number(item.costPrice) / 100;
      }
      if (item.freight) {
        item.freight = Number(item.freight) / 100;
      }
      if (item.sellingPrice) {
        item.sellingPrice = Number(item.sellingPrice) / 100;
      }
      if (item.unitPrice) {
        item.unitPrice = Number(item.unitPrice) / 100;
      }
      if (item.unitCostPrice) {
        item.unitCostPrice = Number(item.unitCostPrice) / 100;
      }
      if (item.unitRRP) {
        item.unitRRP = Number(item.unitRRP) / 100;
      }
      if (item.createdAt) {
        item.createdAt = new Intl.DateTimeFormat(
          "en-AU",
          timeZoneOptions
        ).format(new Date(item.createdAt));
      }
      if (item.updatedAt) {
        item.updatedAt = new Intl.DateTimeFormat(
          "en-AU",
          timeZoneOptions
        ).format(new Date(item.updatedAt));
      }
      if (item.lastLogin) {
        item.lastLogin = new Intl.DateTimeFormat(
          "en-AU",
          timeZoneOptions
        ).format(new Date(item.lastLogin));
      }
      if (item.expiryDate) {
        item.expiryDate = new Intl.DateTimeFormat(
          "en-AU",
          timeZoneOptions
        ).format(new Date(item.expiryDate));
      }
      if (item.postalAddress) {
      }

      if (item.points && typeof item.points === "object") {
        item.points = item.points.points;
      }
      let renamedObj = renameKeys(item);

      updatedArr.push(renamedObj);
    });

    return updatedArr.sort(function (a, b) {
      return a.id - b.id;
    });
  };

  function renameKeys(obj) {
    const keyValues = Object.keys(obj).map((key) => {
      let newKey = key;

      const rowsListName = rowList.filter((head) =>
        head.object ? head.objectProperty === key : head.id === key
      );
      if (rowsListName.length) {
        newKey = rowsListName[0].label;
      }
      return { [newKey]: obj[key] };
    });
    return Object.assign({}, ...keyValues);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColSelect = () => {
    const addressDetails = [
      "companyName",
      "streetName",
      "suburb",
      "postCode",
      "city",
      "country",
    ];
    let selectedNames = rowsSelected
      .filter((row) => row.checked)
      .map((row) => (row.object ? row.objectProperty : row.id));
    let keysList = data[0] ? Object.keys(data[0]) : [];

    if (keysList.includes("postalAddress")) {
      addressDetails.forEach((detail) => {
        keysList.push(detail);
      });
    }

    let keepCols = keysList.filter((col) => selectedNames.includes(col));

    const filtered = data.map((dataItem) =>
      keepCols.reduce((obj, key) => {
        return {
          ...obj,
          [key]: addressDetails.includes(key)
            ? dataItem["postalAddress"][key]
            : Array.isArray(dataItem[key])
              ? dataItem[key] &&
              dataItem[key]
                .map(function (obj) {
                  return obj.name || obj.objName;
                })
                .join(", ")
              : dataItem[key],
        };
      }, {})
    );
    return formatCSVData(filtered);
  };

  const handleRowFilter = (event) => {
    setRowList(
      rowsSelected.map((item) =>
        item.object
          ? item.objectProperty === [event.target.name][0]
            ? { ...item, checked: event.target.checked }
            : item
          : item.id === [event.target.name][0]
            ? { ...item, checked: event.target.checked }
            : item
      )
    );
  };

  return (
    <div>
      <IconButton
        aria-label="csv download"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.iconButtonComponent}
      >
        <CloudDownloadIcon />
      </IconButton>
      <Menu
        id="column-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: 250,
            padding: 16,
            paddingBottom: 8,
            paddingTop: 24,
          },
        }}
      >
        <FormControl component="fieldset">
          <FormLabel
            component="legend"
            style={{
              paddingLeft: 16,
              marginBottom: 8,
              fontWeight: "bold",
              fontSize: 20,
            }}
          >
            CSV Download
          </FormLabel>

          <TextField
            style={{ marginLeft: 16, marginBottom: 16 }}
            variant="outlined"
            required
            margin="dense"
            id="filename"
            label="Filename"
            name="filename"
            autoComplete="text"
            onChange={(e) => setFileName(e.target.value)}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
          />

          <Typography style={{ paddingLeft: 16, marginBottom: 16 }}>
            Select columns to include
          </Typography>
          <FormGroup>
            {rowsSelected.map((option) => (
              <MenuItem
                key={option.label}
                style={{ padding: 0, paddingLeft: 16 }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={option.checked}
                      onChange={handleRowFilter}
                      name={option.object ? option.objectProperty : option.id}
                    />
                  }
                  label={option.label}
                />
              </MenuItem>
            ))}
          </FormGroup>

          {data.length == productsAmount ? (
            <CSVDownloader
              data={handleColSelect}
              type="button"
              filename={fileName}
              bom={true}
              config={{}}
              className={classes.colorButton}
              style={{
                border: "none",
                padding: 0,
                marginTop: 24,
              }}
            >
              Download
            </CSVDownloader>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: 20,
              }}
            >
              <CircularProgress color="secondary" />
            </div>
          )}
          <FormHelperText style={{ paddingLeft: 16, marginTop: 24 }}>
            {/* Be careful */}
          </FormHelperText>
        </FormControl>
      </Menu>
    </div>
  );
}
