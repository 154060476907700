import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { setXClientHost } from "../../app/utils";
import { selectCustomisation } from "../customisation/customisationSlice";
import { fetchContentByName, selectContents } from "./contentsSlice";

const BackButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#fff",
    color: theme.palette.primaryFont.main,
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: theme.palette.primaryFont.main,
    borderRadius: 20,
    width: 200,
    "&:hover": {
      backgroundColor: "#DDD",
    },
    fontWeight: "bold",
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  termsContainer: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondaryFont.main,
    display: "flex",
    flexFlow: "column nowrap",
    // justifyContent: 'center',
    alignItems: "center",
    height: "100%",
    marginBottom: 62,
  },
  cardContainer: {
    backgroundColor: "#fff",
    borderRadius: 16,
    color: theme.palette.secondaryFont.main,
    margin: 48,
    padding: 48,
  },
  header: { fontWeight: "bold", marginBottom: 24 },
  title: {
    color: theme.palette.primaryFont.main,
    fontWeight: "bold",
    marginBottom: 16,
    paddingTop: 8,
  },
  subTitle: {
    fontWeight: "bold",
    marginBottom: 8,
  },
  text: {
    marginBottom: 16,
  },
}));

export default function TermsConditions() {
  const { customisation } = useSelector(selectCustomisation);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  let jwtToken = localStorage.getItem("clientToken");

  const goBack = () => {
    history.goBack();
  };

  const { content, loading } = useSelector(selectContents);

  useEffect(() => {
    dispatch(
      fetchContentByName({
        host: setXClientHost(),
        token: jwtToken,
        name: "terms-conditions",
      })
    );
  }, []);

  return loading !== "loaded" ? (
    <div className={classes.termsContainer}></div>
  ) : content.value && content.value !== "<p><br></p>" ? (
    <div className={classes.termsContainer}>
      <Paper className={classes.cardContainer} elevation={4}>
        <div className={classes.customContentContainer}>
          <ReactQuill
            value={content.value}
            readOnly={true}
            theme={"bubble"}
            // style={{ fontSize: 18 }}
          />
        </div>
      </Paper>
      <BackButton onClick={goBack}>Go Back</BackButton>
    </div>
  ) : (
    <div className={classes.termsContainer}>
      <Paper className={classes.cardContainer} elevation={4}>
        <Typography className={classes.header} variant="h3">
          Terms & Conditions
        </Typography>

        <Typography className={classes.text} variant="body1">
          1. The Promoter is {customisation.theme.clientName}.
        </Typography>
        <Typography className={classes.text} variant="body1">
          2. {customisation.theme.clientName} is a points-based program where
          participants receive points based upon their purchases of approved{" "}
          {customisation.theme.clientName} products or other nominated sales
          activities.
        </Typography>
        <Typography className={classes.text} variant="body1">
          3. Each approved participant will be sent a Username and Password for
          accessing the {customisation.theme.clientName} website.
        </Typography>
        <Typography className={classes.text} variant="body1">
          4. Participation in the program is open only to eligible participants
          nominated by The Promoter.
        </Typography>
        <Typography className={classes.text} variant="body1">
          5. A list of rewards and the points required for each reward are
          contained on this website and may be altered from time to time without
          notice.
        </Typography>
        <Typography className={classes.text} variant="body1">
          6. Reward prize claims may be made at any time up to the Total
          Available Points nominated on each participant's home page.
        </Typography>
        <Typography className={classes.text} variant="body1">
          7. Claims must be made using the official claiming process on this
          website.
        </Typography>
        <Typography className={classes.text} variant="body1">
          8. Cancellation of the eligibility of any participant shall be at the
          sole discretion of The Promoter.
        </Typography>
        <Typography className={classes.text} variant="body1">
          9. The Promoter may alter the program without notice to participants
          and may also delete, substitute or alter the points required for any
          prize at any time. In the event that a prize is superseded, The
          Promoter reserves the right to substitute an alternative prize of
          equal or similar value without notice.
        </Typography>
        <Typography className={classes.text} variant="body1">
          10. Reward prizes can only be delivered to a business address open
          9.00am - 5.00pm Monday to Friday and NOT to a PO Box or home address.
          A business phone number should also be provided.
        </Typography>
        <Typography className={classes.text} variant="body1">
          11. {customisation.theme.clientName} points cannot be exchanged for
          cash.
        </Typography>
        <Typography className={classes.text} variant="body1">
          12. Reward claims are processed weekly. Please allow up to 30 days
          from the date of your order for the processing, handling and delivery
          of your prizes.
        </Typography>
        <Typography className={classes.text} variant="body1">
          13. There may be Fringe Benefits Tax (FBT) implications and it is
          recommended that all stakeholders within the program seek their own
          independent accountant's advice.
        </Typography>
        <Typography className={classes.text} variant="body1">
          14. {customisation.theme.clientName} is conducted in accordance with
          the Terms & Conditions specified on this website and via any other
          subsequent communications by The Promoter and all participant members
          agree to be so bound. In all matters relating to The Program the
          decision of The Promoter shall be final and no correspondence shall be
          entered into.
        </Typography>
      </Paper>
      <BackButton onClick={goBack}>Go Back</BackButton>
    </div>
  );
}
