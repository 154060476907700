import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
// import Select from "@material-ui/core/Select";

import { useSelector } from "react-redux";
import { selectUsers } from "../users/usersSlice";

const SubmitButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.secondaryButton.main,
    color: theme.palette.secondaryButtonFont.main,
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: theme.palette.secondaryButtonFont.main,
    borderRadius: 30,
    height: 40,
    fontSize: 20,
    width: 400,
    "&:hover": {
      backgroundColor: `${theme.palette.secondaryButtonFont.main}2b`,
    },
    fontWeight: "bold",
  },
}))(Button);

const BackButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0, 2),
    backgroundColor: theme.palette.secondaryButton.main,
    color: theme.palette.secondaryButtonFont.main,
    cursor: "pointer",
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: theme.palette.secondaryButtonFont.main,
    borderRadius: 20,
    // width: 100,
    maxHeight: 38.5,
    "&:hover": {
      backgroundColor: `${theme.palette.secondaryButtonFont.main}2b`,
    },
    fontWeight: "bold",
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  storeSelect: {
    backgroundColor: "#fff",

    borderWidth: 3,
    borderStyle: "solid",
    borderColor: theme.palette.primaryFont.main,
    borderRadius: "24px",
    color: theme.palette.primaryFont.main,
    fontSize: 24,
    fontWeight: "bold",
    padding: 0,
    paddingLeft: 2,
    // paddingRight: 24,
    textTransform: "capitalize",
    width: 450,
  },
  claimContainer: {},
  cardContainer: {
    backgroundColor: "#fff",
    borderRadius: 16,
    color: theme.palette.secondaryFont.main,
    padding: 32,
  },
  header: { fontWeight: "bold", marginBottom: 24 },
  title: {
    color: theme.palette.primaryFont.main,
    fontWeight: "bold",
    // marginBottom: 16,
    paddingTop: 8,
    textAlign: "center",
  },
  subTitle: {
    fontWeight: "bold",
    marginBottom: 8,
  },
  text: {
    // fontWeight: 'bold',
    // marginBottom: 8,
    fontSize: 18,
    textAlign: "center",
  },

  progressContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "auto",
    marginBottom: 40,
    maxWidth: 800,
    marginTop: 16,
  },
  submitContainer: {
    display: "flex",
    justifyContent: "center",
    // margin: 62,
    marginBottom: 0,
  },
  idInput: {},
  progressBackground: {
    backgroundColor: theme.palette.highlight.main,
    borderRadius: 20,
  },
}));

export default function ClaimSubmission(props) {
  const {
    setStep,
    handleSubmit,
    usersList,
    userID,
    setUserID,
    admin,
    invoiceTotal,
    handleStep,
    step,
    manualEntry,
    uploadFileToAWS,
  } = props;
  const matches = useMediaQuery("(min-width:800px)");

  const { userAccount } = useSelector(selectUsers);

  const classes = useStyles();
  const navigate = useHistory();
  useEffect(() => {
    if (!admin) {
      setUserID(userAccount.id);
    }
  }, []);

  return (
    <div
      style={{
        width: matches ? "85%" : "100%",
      }}
      className={classes.claimContainer}
    >
      <Paper className={classes.cardContainer} elevation={4}>
        <div>
          <BackButton onClick={handleStep}>
            {step === 0 ? "Home" : "Go Back"}
          </BackButton>
        </div>
        <Typography
          style={{ marginBottom: 24, marginTop: 24 }}
          className={classes.title}
          variant={matches ? "h5" : "h5"}
        >
          You’re on your way to being rewarded{" "}
        </Typography>
        <div className={classes.progressContainer}>
          <img
            src={matches ? "/stepthree.png" : "/mobilethree.png"}
            width={matches ? "50%" : "90%"}
            alt='full progress bar'
            className={classes.progressBackground}
          />
        </div>
        <Divider style={{ height: 2, marginBottom: 20 }} />
        {/* <Typography className={classes.title} variant="h6">
          This invoice is estimated to be eligible for{" "}
          {Math.floor(invoiceTotal * (25 / 1000))} points.
        </Typography> */}

        {admin && (
          <Typography className={classes.text} variant='h6'>
            Please select the user to credit approved points.
          </Typography>
        )}
        {admin && usersList.length && (
          <div className={classes.submitContainer}>
            <Autocomplete
              onChange={(e, user) => setUserID(user ? user.id : null)}
              id={`combo-box-distributor`}
              options={usersList.length && usersList}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.displayName}
              value={usersList.filter((user) => user.id === userID)[0] || null}
              style={{ marginBottom: 8, marginTop: 8, width: 437 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin='dense'
                  variant='outlined'
                  required
                />
              )}
            />
          </div>
        )}

        <Typography className={classes.text} variant='h6'>
          Please allow 3-5 business days for your claim to be approved and
          points made available in your account
        </Typography>
        {userID && (
          <div className={classes.submitContainer}>
            <SubmitButton onClick={uploadFileToAWS}>
              Complete Submission
            </SubmitButton>
          </div>
        )}
      </Paper>
    </div>
  );
}
