import React, { useEffect, useState } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUsers,
  setUpdatePasswordStatus,
  updatePassword,
} from "../users/usersSlice";

const ColorButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.primaryButton.main,
    color: theme.palette.primaryButtonFont.main,
    borderRadius: "24px",
    padding: 4,
    paddingLeft: 12,
    paddingRight: 12,
    "&:hover": {
      backgroundColor: theme.palette.primaryButton.main,
      color: theme.palette.primaryButtonFont.main,
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "45px",
    // backgroundColor: theme.palette.secondary.main,
    width: "100%",
  },
  accountContainer: {
    height: "100%",
    color: theme.palette.primaryFont.main,
  },
  topBackground: {
    // backgroundColor: theme.palette.secondary.main,
    // height: '100%',
  },
  form: {
    // borderBottom: '1px solid rgb(221, 221, 221)',
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: theme.spacing(1),
    width: "100%", // Fix IE 11 issue.
    color: theme.palette.secondaryFont.main,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  registerContainer: {
    borderRadius: 16,
    backgroundColor: "#fff",
    color: theme.palette.secondaryFont.main,
    padding: "50px !important",
  },
  textLabel: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 4,
    marginTop: 8,
  },
  textInput: {},
  userDetailsContainer: {
    padding: 24,
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-between",
  },
  userDetails: {
    display: "flex",
    justifyContent: "space-between",
    // color: "#fff",
    fontSize: 40,
  },
  infoContainerEdit: {
    display: "flex",
    flexFlow: "column wrap",
    justifyContent: "space-between",
    marginLeft: 80,
  },

  userCardsContainerEdit: {
    display: "flex",
    flexFlow: "row wrap",
    padding: 24,
    boxSizing: "border-box",
    justifyContent: "space-between",
    flexGrow: 1,
    width: "100%",
  },
  historyCardContainerEdit: {
    display: "flex",
    flexGrow: 1,
    marginRight: 80,

    // padding: 24,
    // boxSizing: 'border-box',
  },
  historyCardEdit: {
    flexGrow: 1,
  },
  cardHeader: {
    color: theme.palette.primaryFont.main,
    fontWeight: "bold",
    fontSize: 40,
  },
  formControl: {
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: theme.palette.primaryFont.main,
    borderRadius: 16,
    display: "flex",
    marginTop: 12,
  },
  addCircle: { marginRight: 0, color: "#FFF" },
  title: {
    color: "#FFF",
    padding: 16,
    backgroundColor: theme.palette.primary.main,
    textAlign: "center",
    fontSize: 24,
    fontWeight: "bold",
  },
}));

const usePlaceholderStyles = makeStyles((theme) => ({
  placeholder: {
    color: theme.palette.primaryFont.main,
  },
}));

const Placeholder = ({ children }) => {
  const classes = usePlaceholderStyles();
  return <div className={classes.placeholder}>{children}</div>;
};

export default function UpdatePassword(props) {
  const classes = useStyles();
  const { userAccount, updatePasswordStatus } = useSelector(selectUsers);
  const {} = useSelector(selectUsers);
  let jwtToken = localStorage.getItem("clientToken");

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmError, setConfirmError] = useState("");

  const submitUpdatePassword = (event) => {
    event.preventDefault();

    const passwordUpdate = {
      oldPassword: password,
      newPassword: newPassword,
    };

    if (!validatePassword()) {
      setPasswordError("Invalid password");
    } else if (newPassword !== confirmPassword) {
      setConfirmError("Passwords don't match");
    } else {
      setPasswordError("");
      setConfirmError("");
      dispatch(
        updatePassword({
          id: userAccount.id,
          token: jwtToken,
          passwordUpdate: passwordUpdate,
        })
      );
    }
  };

  const validatePassword = () => {
    var conditions = /^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{8,99}$/;
    if (newPassword.match(conditions)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (updatePasswordStatus) {
      handleClose();
    }
  }, [updatePasswordStatus]);

  const handleClickOpen = () => {
    setNewPassword("");
    setPassword("");
    setConfirmPassword("");
    setPasswordError("");
    setConfirmError("");
    dispatch(setUpdatePasswordStatus(false));
    setOpen(true);
  };

  const handleClose = () => {
    setNewPassword("");
    setPassword("");
    setConfirmPassword("");
    setPasswordError("");
    setConfirmError("");
    dispatch(setUpdatePasswordStatus(false));
    setOpen(false);
  };

  return (
    <div style={{ marginRight: 32 }}>
      <ColorButton onClick={handleClickOpen}>Update Password</ColorButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle
          disableTypography
          className={classes.title}
          id="form-dialog-title"
        >
          {"Update Password"}
          <ClearIcon
            style={{ right: 20, position: "absolute", cursor: "pointer" }}
            onClick={() => {
              setOpen(false);
            }}
          />
        </DialogTitle>
        <form className={classes.form} onSubmit={submitUpdatePassword}>
          <DialogContent>
            <DialogContentText style={{ paddingTop: 8, textAlign: "center" }}>
              <Grid className={classes.subHeader}>
                Passwords must be a minimum of 8 characters long and contain at
                least one number.
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions
            style={{
              paddingLeft: 24,
              paddingRight: 24,
              paddingBottom: 16,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div className={classes.tabContent}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth                
                name="current-password"
                label="Current Password"
                type="password"
                id="current-password"
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="current-password"
                inputProps={{ minLength: 8, maxLength: 99 }}
                // error={passwordError}
                // helperText={passwordError ? passwordError : ''}
              />

              <TextField
                variant="outlined"
                margin="normal"
                required={true}
                fullWidth
                name="new-password"
                label="New Password"
                type="password"
                id="new-password"
                autoComplete="new-password"
                onChange={(e) => setNewPassword(e.target.value)}
                error={passwordError}
                helperText={passwordError ? passwordError : ""}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required={true}
                fullWidth
                name="confirm-password"
                label="Confirm New Password"
                type="password"
                id="confirm-password"
                autoComplete="new-password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                error={confirmError}
                helperText={confirmError ? confirmError : ""}
              />

              <ColorButton type="submit" fullWidth variant="contained">
                Update Password
              </ColorButton>
            </div>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
