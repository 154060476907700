/* eslint-disable no-nested-ternary */
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route } from "react-router";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import { selectCategories } from "../features/categories/categoriesSlice";
import {
  fetchProducts,
  selectCoreProducts,
} from "../features/products/coreProductsSlice";

function ListItemLink(props) {
  const { to, open, ...other } = props;
  return (
    <li>
      <ListItem button component={RouterLink} to={to} {...other}>
        <ListItemText />
        {open != null ? open ? <ExpandLess /> : <ExpandMore /> : null}
      </ListItem>
    </li>
  );
}

ListItemLink.propTypes = {
  open: PropTypes.bool,
  to: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
  },
  lists: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(1),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  last: {
    color: "#3e3e3e",
    textTransform: "capitalize",
  },
  breadcrumbItem: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    textTransform: "capitalize",
  },
}));

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

export default function BreadCrumbs(props) {
  const classes = useStyles();

  const { pathname } = useLocation();
  const { breadcrumbCats } = useSelector(selectCoreProducts);
  const { categoriesTree } = useSelector(selectCategories);
  const history = useHistory();
  const dispatch = useDispatch();

  let jwtToken = localStorage.getItem("clientToken");

  const handleBreadClick = (e, to, catID, index) => {
    e.preventDefault();
    if (catID) {
      dispatch(
        fetchProducts({
          token: jwtToken,
          query: `?categories=${catID}&limit=24`,
        })
      );
      history.push(to);
    } else {
      const pathArr = pathname
        .split("/")
        .filter((x) => x)
        .filter((x) => x !== "products");
      let categoryID = "";
      if (index === 0) {
        const filtered = categoriesTree.filter(
          (cat) =>
            cat.name.toUpperCase() ===
            pathArr[0].split("-").join(" ").toUpperCase()
        );
        categoryID = filtered[0].id;
      } else if (index === 1) {
        const top = categoriesTree.filter(
          (cat) =>
            cat.name.toUpperCase() ===
            pathArr[0].split("-").join(" ").toUpperCase()
        );
        const second = top[0].subCategories.filter(
          (cat) =>
            cat.name.toUpperCase() ===
            pathArr[1].split("-").join(" ").toUpperCase()
        );
        categoryID = second[0].id;
      } else if (index === 2) {
        const top = categoriesTree.filter(
          (cat) =>
            cat.name.toUpperCase() ===
            pathArr[0].split("-").join(" ").toUpperCase()
        );
        const second = top[0].subCategories.filter(
          (cat) =>
            cat.name.toUpperCase() ===
            pathArr[1].split("-").join(" ").toUpperCase()
        );
        const third = second[0].subCategories.filter(
          (cat) =>
            cat.name.toUpperCase() ===
            pathArr[2].split("-").join(" ").toUpperCase()
        );
        categoryID = third[0].id;
      }
      dispatch(
        fetchProducts({
          token: jwtToken,
          query: `?categories=${categoryID}&limit=24`,
        })
      );
      history.push(to);
    }
  };

  return (
    <div className={classes.root}>
      <Route>
        {() => {
          const pathnames = pathname
            .split("/")
            .filter((x) => x)
            .filter((x) => x !== "products");
          return (
            <Breadcrumbs
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              aria-label="breadcrumb"
            >
              <LinkRouter color="inherit" to="/">
                Home
              </LinkRouter>
              {pathnames.map((value, index) => {
                const last =
                  pathnames[index] === pathnames[pathnames.length - 1];
                let to = ``;
                for (let i = 0; i <= index; i++) {
                  to += `/${pathnames[i]}`;
                }

                return last ? (
                  <Typography className={classes.last} to={to} key={to}>
                    {pathnames[index].split("-").join(" ")}
                  </Typography>
                ) : (
                  <LinkRouter
                    onClick={(e) =>
                      handleBreadClick(e, to, breadcrumbCats[index], index)
                    }
                    className={classes.breadcrumbItem}
                    to={to}
                    key={to}
                  >
                    {pathnames[index].split("-").join(" ")}
                  </LinkRouter>
                );
              })}
            </Breadcrumbs>
          );
        }}
      </Route>
    </div>
  );
}
