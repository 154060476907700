import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Typography from "@material-ui/core/Typography";
import { lighten, makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";
import AddPoints from "../features/userPoints/AddPoints";

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    fontSize: 12,
    maxWidth: 150,
    padding: 0,
    paddingBottom: 5,
  },
  body: {
    cursor: "pointer",
    border: "1px solid rgb(224, 224, 224)",
    fontSize: 12,
    lineHeight: 1.43,
    maxWidth: 150,
    textOverflow: "ellipsis",
    overflow: "hidden",
    padding: 0,
    paddingRight: 2,
  },
}))(TableCell);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
    headCells,
    rowList,
    editItem,
    setEdit,
    area,
    restricted,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        <StyledTableCell padding="checkbox">
          <div
            className={classes.addItem}
            style={{ flexGrow: 0, paddingRight: 8 }}
          >
            {area === "points" && !restricted ? (
              <AddPoints editItem={editItem} setEdit={setEdit} />
            ) : (
              ""
            )}
          </div>
        </StyledTableCell>
        {headCells.map((headCell, index) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={
              rowList[index].long && rowList[index].checked
                ? { visiblity: "visible", minWidth: 175 }
                : rowList[index].checked
                ? { visiblity: "visible" }
                : { display: "none" }
            }
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              className={classes.root}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  selectAll: PropTypes.bool.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
  },

  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondaryFont.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
  filterIcon: {
    color: "#fff",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <TableHead className={classes.tableHead}>
      {numSelected > 0 && (
        <TableRow
          className={clsx(classes.root, {
            [classes.highlight]: numSelected > 0,
          })}
        >
          <TableCell align="center" colSpan={props.cols} style={{ padding: 0 }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {numSelected > 0 ? (
                <Typography
                  className={classes.title}
                  color="inherit"
                  variant="subtitle1"
                  component="div"
                >
                  {numSelected} selected
                </Typography>
              ) : (
                <Typography
                  className={classes.title}
                  variant="h6"
                  id="tableTitle"
                  component="div"
                >
                  User List
                </Typography>
              )}
            </div>
          </TableCell>
        </TableRow>
      )}
    </TableHead>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 12,
    width: "100%",
  },
  addIcon: {
    color: "#fff",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableHead: {
    backgroundColor: theme.palette.secondary.main,
    fontWeight: "bold",
    color: "#fff",
  },
  root: {
    "&:hover": {
      color: theme.palette.primaryFont.main,
    },
    color: "#fff",
    fontWeight: "bold",
    backgroundColor: theme.palette.secondary.main,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },

  rowListContainer: {
    padding: 0,
  },
  rowListCheck: {
    fontSize: 12,
    fontWeight: "bold",
  },

  actionsContainer: {
    display: "flex",
    backgroundColor: theme.palette.secondary.main,
    fontWeight: "bold",
    color: "#fff",
    borderRadius: "4px 4px 0px 0px",
    paddingBottom: 4,
    paddingTop: 4,
  },
  actionsItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 16,
  },
  addItem: {
    alignItems: "center",
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-end",
  },
  inputBaseAction: {
    backgroundColor: "#fff",
    paddingLeft: 4,
  },
  iconButtonAction: {
    paddingLeft: 8,
    paddingRight: 8,
    color: "#fff",
  },
  searchIcon: {
    color: "#fff",
  },
  highlightIcon: {
    marginRight: 0,
    color: "#fff",
  },
}));

export default function MemberTable(props) {
  const { cellList, data, headCells, area, restricted } = props;
  const classes = useStyles();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const page = 0;
  const dense = false;
  const rowsPerPage = 1000;
  const rowList = [...headCells];
  const [editItem, setEdit] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, data ? data.length - page * rowsPerPage : 0);

  const handleEditClick = (event, id) => {
    if (
      area == "points" &&
      (data.filter((point) => point.id === id)[0].statusID == 1 ||
        data.filter((point) => point.id === id)[0].statusID == 23)
    ) {
      props.handleEditClick(id);
      setEdit(true);
    } else if (area == "order") {
      props.handleEditClick(id);
    }
  };

  return (
    <div className={classes.container}>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={dense ? "small" : "medium"}
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={data ? data.length : []}
            selectAll={true}
            headCells={headCells}
            rowList={rowList}
            area={area}
            editItem={editItem}
            setEdit={setEdit}
            restricted={restricted}
          />
          <EnhancedTableToolbar
            numSelected={selected.length}
            selected={selected}
            cols={headCells.length + 1}
            area={area}
          />
          <TableBody>
            {stableSort(data ? data : [], getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.id);

                return (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={`${row.username}-${index}`}
                    selected={isItemSelected}
                  >
                    <TableCell
                      padding="checkbox"
                      style={{ visiblity: "hidden" }}
                    ></TableCell>
                    {cellList.map((cell, index) => {
                      return (
                        <StyledTableCell
                          key={index}
                          align={headCells[index].numeric ? "right" : "left"}
                          onClick={(event) => {
                            handleEditClick(event, row.id);
                          }}
                          style={
                            (rowList[index].checked && cell === "sku") ||
                            cell === "name"
                              ? { visiblity: "visible", cursor: "auto" }
                              : rowList[index].checked
                              ? { visiblity: "visible" }
                              : { display: "none" }
                          }
                        >
                          {cell === "categories" ? (
                            row.categories &&
                            row.categories
                              .map(function (obj) {
                                return obj.name;
                              })
                              .join(", ")
                          ) : (cell === "updatedAt" && row[cell]) ||
                            (cell === "expiryDate" && row[cell]) ||
                            (cell === "lastLogin" && row[cell]) ? (
                            row[cell]
                              .split("T")[0]
                              .split("-")
                              .reverse()
                              .join("/")
                          ) : cell === "statusID" ? (
                            row.statusID === 1 ? (
                              "Active"
                            ) : row.statusID === 2 ? (
                              "Inactive"
                            ) : row.statusID === 3 ? (
                              "Deleted"
                            ) : (
                              "Unavailable"
                            )
                          ) : cell === "margin" ? (
                            (Number(row[cell]) * 100).toFixed(2)
                          ) : headCells[index].numeric ? (
                            (Number(row[cell]) / 100).toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })
                          ) : cell === "images" &&
                            row[cell] &&
                            row[cell].length ? (
                            <img
                              width={25}                              
                              src={
                                `${process.env.REACT_APP_PRODUCT_URL}/` +
                                row[cell][0].objName
                              }
                              alt={row[cell][0].title}
                            />
                          ) : cell === "points" ? (
                            row["statusID"] === 20 || row["statusID"] === 3 ? (
                              "-" + row[cell]
                            ) : (
                              row[cell]
                            )
                          ) : row[cell] ? (
                            row[cell]
                          ) : (
                            ""
                          )}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: dense ? 33 : 53 }}>
                <StyledTableCell colSpan={headCells.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
