import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

import { errorSnack } from "../snackbar/snackbarSlice";

import axios from "axios";

let themeImport = require(`../../themes/${process.env.REACT_APP_THEME}`);

export const fetchThemeJSON = createAsyncThunk(
  "customisation/fetchThemeJSON",
  async (args, thunkAPI) => {
    try {
      const config = {
        // headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(`${args.url}`, config);
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching contents list"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const customisationSlice = createSlice({
  name: "customisation",
  initialState: {
    theme: themeImport,
    themeJSON: {},
    initialThemeLoaded: false,
  },
  reducers: {
    setCustomisation(state, action) {
      state.theme.primaryColor = action.payload.primaryColor;
      state.theme.secondaryColor = action.payload.secondaryColor;
      state.theme.alternativeColor = action.payload.alternativeColor;
      state.theme.highlightColor = action.payload.highlightColor;
      state.theme.primaryButtonColor = action.payload.primaryButtonColor;
      state.theme.secondaryButtonColor = action.payload.secondaryButtonColor;
      state.theme.headerButtonColor = action.payload.headerButtonColor;
      state.theme.primaryButtonFontColor =
        action.payload.primaryButtonFontColor;
      state.theme.secondaryButtonFontColor =
        action.payload.secondaryButtonFontColor;
      state.theme.headerButtonFontColor = action.payload.headerButtonFontColor;
      state.theme.primaryFontColor = action.payload.primaryFontColor;
      state.theme.secondaryFontColor = action.payload.secondaryFontColor;
      state.theme.alternativeFontColor = action.payload.alternativeFontColor;
      state.theme.fontFamily = action.payload.fontFamily;
      state.theme.localURL = action.payload.localURL;
      state.theme.enableClaims = action.payload.enableClaims;
      state.theme.enableStripe = action.payload.enableStripe;
      state.theme.pointsColor = action.payload.pointsColor;

      state.theme.headerColor = action.payload.headerColor;
      state.theme.headerFontColor = action.payload.headerFontColor;
      state.theme.footerColor = action.payload.footerColor;
      state.theme.footerFontColor = action.payload.footerFontColor;
      state.theme.appbarColor = action.payload.appbarColor;
      state.theme.appbarFontColor = action.payload.appbarFontColor;
      state.theme.menuColor = action.payload.menuColor;
      state.theme.menuFontColor = action.payload.menuFontColor;
      state.theme.subMenuColor = action.payload.subMenuColor;
      state.theme.subMenuFontColor = action.payload.subMenuFontColor;
      state.theme.landingTileFontColor = action.payload.landingTileFontColor
      state.theme.landingTileButtonFontColor = action.payload.landingTileButtonFontColor
      state.initialThemeLoaded = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchThemeJSON.pending, (state) => {
      state.themeJSON = {};
    });
    builder.addCase(fetchThemeJSON.fulfilled, (state, { payload }) => {
      state.themeJSON = payload;
    });
    builder.addCase(fetchThemeJSON.rejected, (state, action) => {
      state.themeJSON = action.error.message;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setCustomisation } = customisationSlice.actions;
export const selectCustomisation = createSelector(
  (state) => ({
    customisation: state.customisation,
    themeJSON: state.customisation.themeJSON,
    initialThemeLoaded: state.customisation.initialThemeLoaded,
  }),
  (state) => state
);
export default customisationSlice.reducer;
