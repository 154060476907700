import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { replaceUrlParam } from "../../app/utils";
import CustomTable from "../../common/CustomTable";
import {
  fetchMoreMemberGroups,
  fetchMemberGroups,
  getFullList,
  selectMembers,
  setMemberGroup,
  getFullMemberGroupsList,
} from "./membersSlice";

import Grid from "@material-ui/core/Grid";
import {
  fetchStores,
  getFullList as getFullStoresList,
  selectStores,
} from "../stores/storesSlice";

const useStyles = makeStyles((theme) => ({
  progress: {
    margin: theme.spacing(2),
  },
  root: {
    display: "flex",
    flexFlow: "column",
  },
}));

export default function MemberGroupAdminContainer(props) {
  let jwtToken = localStorage.getItem("clientToken");
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [memberGroupNames, setMemberGroupNames] = useState([]);
  const [memberGroupIDs, setMemberGroupIDs] = useState([]);
  const edit = props.location.pathname.includes("edit");
  const {
    memberGroups,
    error,
    loading,
    memberGroupsList,
    memberGroupsAmount,
    lastQuery,
  } = useSelector(selectMembers);
  const { storesList, storesAmount } = useSelector(selectStores);

  const firstUpdateStore = useRef(true);

  const [tablePreferences, setTablePreferences] = useState(
    localStorage.getItem("MemberGroupAdminTable")
      ? JSON.parse(localStorage.getItem("MemberGroupAdminTable"))
      : {
          rowsPerPage: 25,
          currentPage: 0,
          sortProperty: "id",
          sortDirection: "asc",
        }
  );

  useEffect(() => {
    if (storesList.length < 1) {
      dispatch(
        fetchStores({
          token: jwtToken,
          query: `?limit=100`,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (
      storesList.length &&
      storesList.length != storesAmount &&
      firstUpdateStore.current
    ) {
      let searchQuery = "?limit=100";
      searchQuery = replaceUrlParam(searchQuery, "limit", 100);
      dispatch(
        getFullStoresList({
          token: jwtToken,
          offset: storesList.length,
          query: searchQuery,
          targetLength: storesAmount,
        })
      );
      firstUpdateStore.current = false;
    }
  }, [storesList.length]);

  useEffect(() => {
    localStorage.setItem(
      "MemberGroupAdminTable",
      JSON.stringify(tablePreferences)
    );
  }, [tablePreferences]);

  const [numberShown, setNumberShown] = useState(25);

  const [searchTerm, setSearchTerm] = useState("");
  const [dateBefore, setDateBefore] = useState("");
  const [dateAfter, setDateAfter] = useState("");

  const headCells = [
    {
      id: "id",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Group #",
      long: false,
      sortable: true,
    },
    {
      id: "name",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Name",
      long: false,
      sortable: true,
    },
    {
      id: "storeID",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Store ID",
      long: false,
      sortable: true,
    },
    {
      id: "createdAt",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Created Date",
      long: false,
      sortable: true,
    },
    {
      id: "updatedAt",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Last Update",
      long: false,
      sortable: true,
    },
  ];

  useEffect(() => {
    let searchQuery = lastQuery ? lastQuery : "";

    if (tablePreferences.rowsPerPage * (tablePreferences.currentPage + 1)) {
      searchQuery = replaceUrlParam(
        searchQuery,
        "limit",
        tablePreferences.rowsPerPage * (tablePreferences.currentPage + 1)
      );
    }

    dispatch(
      fetchMemberGroups({
        token: jwtToken,
        query: lastQuery
          ? searchQuery
          : `?limit=${
              tablePreferences.rowsPerPage * (tablePreferences.currentPage + 1)
            }`,
      })
    );

    setNumberShown(
      tablePreferences.rowsPerPage * (tablePreferences.currentPage + 1)
    );
  }, [dispatch, tablePreferences.rowsPerPage]);

  const getFullListTrigger = () => {
    let searchQuery = lastQuery ? lastQuery : "";
    searchQuery = replaceUrlParam(searchQuery, "limit", 100);

    dispatch(
      getFullMemberGroupsList({
        token: jwtToken,
        offset:
          tablePreferences.rowsPerPage > memberGroupsList.length
            ? tablePreferences.rowsPerPage
            : memberGroupsList.length,
        query: searchQuery,
        targetLength: memberGroupsAmount,
      })
    );
  };

  const handleMore = (e, page) => {
    if (page < tablePreferences.currentPage) {
      setTablePreferences((oldTable) => ({ ...oldTable, currentPage: page }));
      setNumberShown((page + 1) * tablePreferences.rowsPerPage);
    } else {
      let expectedLen = (page + 1) * tablePreferences.rowsPerPage;
      if (
        memberGroupsList.length < memberGroupsAmount &&
        memberGroupsList.length < expectedLen
      ) {
        dispatch(
          fetchMoreMemberGroups({
            token: jwtToken,
            query: lastQuery,
            offset: numberShown,
          })
        );
      }
      setTablePreferences((oldTable) => ({
        ...oldTable,
        currentPage: oldTable.currentPage + 1,
      }));

      setNumberShown(
        (tablePreferences.currentPage + 2) * tablePreferences.rowsPerPage
      );
    }
  };

  const handleRequestSort = (event, property) => {
    let oldProperty = property;
    const isAsc =
      tablePreferences.sortProperty === property &&
      tablePreferences.sortDirection === "asc";
    setTablePreferences((oldTable) => ({
      ...oldTable,
      sortDirection: isAsc ? "desc" : "asc",
      sortProperty: oldProperty,
      currentPage: 0,
    }));

    switch (property) {
      case "updatedAt":
        property = "updated_at";
        break;
      case "createdAt":
        property = "created_at";
        break;
    }

    let searchQuery = lastQuery ? lastQuery : "";

    if (property) {
      searchQuery = replaceUrlParam(searchQuery, "order_by", property);
    }
    searchQuery = replaceUrlParam(searchQuery, "sort", isAsc ? "desc" : "asc");
    searchQuery = replaceUrlParam(
      searchQuery,
      "limit",
      tablePreferences.rowsPerPage * (tablePreferences.currentPage + 1)
    );

    dispatch(
      fetchMemberGroups({
        token: jwtToken,
        query: searchQuery,
      })
    );
  };

  const handleMemberGroupsChange = (event) => {
    let idsArr = [];
    // event.target.value.forEach((selected) => {
    let selectedMemberGroup = memberGroupsList.filter(
      (group) => group.groupNumber === event.target.value
    );
    selectedMemberGroup && idsArr.push(selectedMemberGroup[0].id);
    // });

    setMemberGroupNames(event.target.value);
    setMemberGroupIDs(idsArr);
  };

  const [ready, setReady] = useState(false);
  if (loading === "loaded" && !ready) {
    setReady(true);
  }

  const handleEdit = (id) => {
    // dispatch(
    //   fetchUserByID({
    //     id: id,
    //     token: jwtToken,
    //   })
    // );
  };

  const handleEditClick = (id) => {
    const selectedMemberGroup = memberGroupsList.filter(
      (group) => group.id === id
    );
    dispatch(setMemberGroup(selectedMemberGroup[0]));

    // history.push(`/admin/memberGroups/${id}`);
  };

  const handleSearch = () => {
    let daterange = "";
    if (dateBefore && dateAfter) {
      daterange = `${dateAfter.toISOString().substring(0, 10)},${dateBefore
        .toISOString()
        .substring(0, 10)}`;
    } else if (dateBefore && !dateAfter) {
      daterange = `lte,${dateBefore.toISOString().substring(0, 10)}`;
    } else if (!dateBefore && dateAfter) {
      daterange = `gte,${dateAfter.toISOString().substring(0, 10)}`;
    }

    let searchQuery = lastQuery ? lastQuery : "";

    if (daterange) {
      searchQuery = replaceUrlParam(searchQuery, "udate", daterange);
    }
    //    if (searchTerm) {
    searchQuery = replaceUrlParam(searchQuery, "name", searchTerm);
    //    }

    dispatch(
      fetchMemberGroups({
        query: searchQuery,
        token: jwtToken,
      })
    );

    setTablePreferences((oldTable) => ({
      ...oldTable,
      currentPage: 0,
    }));
  };

  const handleClear = () => {
    setMemberGroupNames([]);
    setMemberGroupIDs([]);
    setDateBefore("");
    setDateAfter("");
    setSearchTerm("");

    dispatch(
      fetchMemberGroups({
        token: jwtToken,
        query: `?limit=${tablePreferences.rowsPerPage}`,
      })
    );

    setTablePreferences((oldTable) => ({
      ...oldTable,
      currentPage: 0,
      sortDirection: "asc",
      sortProperty: "id",
    }));
  };

  const cellList = headCells.map((cell) => {
    return cell.id;
  });

  let content;
  if (loading === "loading") {
    content = (
      <CircularProgress className={classes.progress} color="secondary" />
    );
  } else if (loading === "loaded") {
    content = (
      <CustomTable
        data={memberGroupsList}
        headCells={headCells}
        cellList={cellList}
        actionsLists={[]}
        actionsHandleChange={[]}
        handleEditClick={handleEditClick}
        handleSearch={handleSearch}
        setSearchTerm={setSearchTerm}
        handleClear={handleClear}
        handleEdit={handleEdit}
        area={"membergroup"}
        setDateBefore={setDateBefore}
        setDateAfter={setDateAfter}
        dateAfter={dateAfter}
        dateBefore={dateBefore}
        productsAmount={memberGroupsAmount}
        handleMore={handleMore}
        page={tablePreferences.currentPage}
        setPage={(page) =>
          setTablePreferences((oldTable) => ({
            ...oldTable,
            currentPage: page,
          }))
        }
        handleRequestSort={handleRequestSort}
        sortProperty={tablePreferences.sortProperty}
        sortDirection={tablePreferences.sortDirection}
        rowsPerPage={tablePreferences.rowsPerPage}
        setRowsPerPage={(rows) =>
          setTablePreferences((oldTable) => ({
            ...oldTable,
            rowsPerPage: rows,
          }))
        }
        getFullList={getFullListTrigger}
      />
    );
  }

  return (
    <Grid
      container
      spacing={3}
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      style={{ height: "100%" }}
    >
      {content}
    </Grid>
  );
}
