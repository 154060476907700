import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useDispatch } from "react-redux";
import { resetSnack } from "../snackbar/snackbarSlice";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const ContinueButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.secondaryButton.main,
    color: theme.palette.secondaryButtonFont.main,
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: theme.palette.secondaryButtonFont.main,
    borderRadius: 30,
    height: 42,
    fontSize: 20,
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
    "&:hover": {
      backgroundColor: `${theme.palette.secondaryButtonFont.main}2b`,
    },
    fontWeight: "bold",
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  introContainer: {
    // backgroundColor: theme.palette.secondary.main,
    // color: theme.palette.secondaryFont.main,
    // display: 'flex',
    // flexFlow: 'column nowrap',
    // // justifyContent: 'center',
    // alignItems: 'center',
    // height: '100%',
    // marginBottom: 62,
  },
  cardContainer: {
    backgroundColor: "#fff",
    borderRadius: 16,
    color: theme.palette.secondaryFont.main,
    padding: 32,
  },
  header: {
    color: theme.palette.primaryFont.main,
    fontWeight: "bold",
    marginBottom: 32,
    textAlign: "center",
  },
  title: {
    color: theme.palette.primaryFont.main,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 32,
    paddingTop: 32,
  },
  subTitle: {
    fontWeight: "bold",
    marginBottom: 8,
  },
  text: {
    fontWeight: "bold",
    marginBottom: 16,
    textAlign: "center",
  },
  progressContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "auto",
    marginBottom: 40,
    maxWidth: 800,
    marginTop: 16,
  },
  continueContainer: {
    display: "flex",
    justifyContent: "center",
    // margin: 62,
    marginBottom: 0,
  },
  more: {
    backgroundColor: "#fff",
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: theme.palette.primaryFont.main,
    borderRadius: "24px",
    color: theme.palette.primaryFont.main,
    fontSize: 24,
    fontWeight: "bold",
    padding: 4,
    paddingLeft: 24,
    paddingRight: 24,
    textTransform: "capitalize",
    width: 120,
    cursor: "pointer",
  },
  progressBackground: {
    backgroundColor: theme.palette.highlight.main,
    borderRadius: 20,
  },
}));

export default function ClaimIntro(props) {
  const { setStep, resetProcess, setAutoProcessMode } = props;
  const matches = useMediaQuery("(min-width:800px)");
  const classes = useStyles();
  const navigate = useHistory();
  const dispatch = useDispatch();

  const handleContinue = () => {
    setStep(3);
  };

  useEffect(() => {
    dispatch(resetSnack());
    resetProcess();
  }, []);

  return (
    <div
      style={{ width: matches ? "85%" : "" }}
      className={classes.introContainer}
    >
      <Paper className={classes.cardContainer} elevation={4}>
        <Typography className={classes.header} variant={matches ? "h3" : "h4"}>
          Process A Claim{" "}
        </Typography>
        <Divider />
        <Typography className={classes.title} variant={matches ? "h5" : "h5"}>
          Three Easy Steps
        </Typography>
        <div className={classes.progressContainer}>
          <img
            src={matches ? "/stepone.png" : "/mobileone.png"}
            width={matches ? "50%" : "90%"}
            alt="empty progress bar"
            className={classes.progressBackground}
          />
        </div>
        <Typography className={classes.text} variant="body1">
          Please follow the next steps to submit a claim for approval; once
          approved your points will be available instantly!
        </Typography>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          {process.env.REACT_APP_ENABLE_AUTO_CLAIMS === "true" && (
            <div
              style={{
                margin: matches ? 0 : 0,
              }}
              className={classes.continueContainer}
            >
              <ContinueButton
                onClick={() => {
                  setStep(3);
                  setAutoProcessMode(true);
                }}
              >
                {"Begin Invoice Scanning"}
              </ContinueButton>
            </div>
          )}
          <div
            style={{
              margin: matches ? 0 : 0,
            }}
            className={classes.continueContainer}
          >
            <ContinueButton
              onClick={() => {
                setStep(2);
              }}
            >
              {process.env.REACT_APP_ENABLE_AUTO_CLAIMS === "true"
                ? "Manually Process Claim"
                : "Continue"}
            </ContinueButton>
          </div>
        </div>
      </Paper>
    </div>
  );
}
