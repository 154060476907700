import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { replaceUrlParam } from "../../app/utils";
import CustomTable from "../../common/CustomTable";
import {
  fetchMoreStores,
  fetchStores,
  getFullList,
  selectStores,
  setStore
} from "./storesSlice";

import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  progress: {
    margin: theme.spacing(2),
  },
  root: {
    display: "flex",
    flexFlow: "column",
  },
}));

export default function StoresAdminContainer(props) {
  let jwtToken = localStorage.getItem("clientToken");
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [storeNames, setStoreNames] = useState([]);
  const [userIDs, setUserIDs] = useState([]);
  const edit = props.location.pathname.includes("edit");
  const { stores, error, loading, storesList, storesAmount, lastQuery } =
    useSelector(selectStores);

  const [statusNames, setStatusNames] = useState([]);
  const [statusIDs, setStatusIDs] = useState([]);
  const [statusList, setStatusList] = useState([
    { id: 1, value: "Active", name: "Active" },
    { id: 5, value: "Pending", name: "Pending" },
    { id: 16, value: "Dispatched", name: "Dispatched" },
    { id: 9, value: "Completed", name: "Completed" },
    { id: 23, value: "Refunded", name: "Refunded" },
  ]);

  const [tablePreferences, setTablePreferences] = useState(
    localStorage.getItem("StoresAdminTable")
      ? JSON.parse(localStorage.getItem("StoresAdminTable"))
      : {
          rowsPerPage: 25,
          currentPage: 0,
          sortProperty: "id",
          sortDirection: "asc",
        }
  );

  useEffect(() => {
    localStorage.setItem("StoresAdminTable", JSON.stringify(tablePreferences));
  }, [tablePreferences]);
  
  const [numberShown, setNumberShown] = useState(25);

  const [searchTerm, setSearchTerm] = useState("");
  const [dateBefore, setDateBefore] = useState("");
  const [dateAfter, setDateAfter] = useState("");

  const headCells = [
    {
      id: "id",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Store #",
      long: false,
      sortable: true,
    },
    {
      id: "name",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Name",
      long: false,
      sortable: true,
    },
    {
      id: "externalID",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "External ID",
      long: false,
    },
    {
      id: "externalIDType",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "External ID Type",
      long: false,
    },
    {
      id: "emailAddress",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Email Address",
      long: true,
    },
    {
      id: "websiteURL",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Website",
      long: false,
    },
    {
      id: "description",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Description",
      long: false,
    },
    {
      id: "phoneNumber",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Phone No.",
      long: false,
    },
    {
      id: "faxNumber",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Fax No.",
      long: false,
    },
    {
      id: "mobileNumber",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Mobile No.",
      long: false,
    },
    {
      id: "statusID",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Status",
      long: false,
      sortable: true,
    },
    {
      id: "createdAt",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Created Date",
      long: false,
      sortable: true,
    },
    {
      id: "updatedAt",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Last Update",
      long: false,
      sortable: true,
    },
    // {
    //   id: "points",
    //   checked: true,
    //   numeric: false,
    //   disablePadding: false,
    //   label: "Points Value",
    //   long: false,
    // },
  ];

  useEffect(() => {
    let searchQuery = lastQuery ? lastQuery : "";

    if (tablePreferences.rowsPerPage * (tablePreferences.currentPage + 1)) {
      searchQuery = replaceUrlParam(
        searchQuery,
        "limit",
        tablePreferences.rowsPerPage * (tablePreferences.currentPage + 1)
      );
    }

    dispatch(
      fetchStores({
        token: jwtToken,
        query: lastQuery
          ? searchQuery
          : `?limit=${tablePreferences.rowsPerPage * (tablePreferences.currentPage + 1)}`,
      })
    );

    setNumberShown(
      tablePreferences.rowsPerPage * (tablePreferences.currentPage + 1)
    );
  }, [dispatch, tablePreferences.rowsPerPage]);

  const getFullListTrigger = () => {
    let searchQuery = lastQuery ? lastQuery : "";
    searchQuery = replaceUrlParam(searchQuery, "limit", 100);

    dispatch(
      getFullList({
        token: jwtToken,
        offset:
          tablePreferences.rowsPerPage > storesList.length ? tablePreferences.rowsPerPage : storesList.length,
        query: searchQuery,
        targetLength: storesAmount,
      })
    );
  };

  const handleMore = (e, page) => {
    if (page < tablePreferences.currentPage) {
      setTablePreferences((oldTable) => ({ ...oldTable, currentPage: page }));
      setNumberShown((page + 1) * tablePreferences.rowsPerPage);
    } else {
      let expectedLen = (page + 1) * tablePreferences.rowsPerPage;
      if (storesList.length < storesAmount && storesList.length < expectedLen) {
        dispatch(
          fetchMoreStores({
            token: jwtToken,
            query: lastQuery,
            offset: numberShown,
          })
        );
      }
      setTablePreferences((oldTable) => ({
        ...oldTable,
        currentPage: oldTable.currentPage + 1,
      }));

      setNumberShown(
        (tablePreferences.currentPage + 2) * tablePreferences.rowsPerPage
      );
    }
  };

  const handleRequestSort = (event, property) => {
    let oldProperty = property;
    const isAsc =
      tablePreferences.sortProperty === property &&
      tablePreferences.sortDirection === "asc";
    setTablePreferences((oldTable) => ({
      ...oldTable,
      sortDirection: isAsc ? "desc" : "asc",
      sortProperty: oldProperty,
      currentPage: 0,
    }));

    switch (property) {
      case "updatedAt":
        property = "updated_at";
        break;
      case "createdAt":
        property = "created_at";
        break;
      case "statusID":
        property = "status_id";
        break;
    }

    let searchQuery = lastQuery ? lastQuery : "";

    if (property) {
      searchQuery = replaceUrlParam(searchQuery, "order_by", property);
    }
    searchQuery = replaceUrlParam(searchQuery, "sort", isAsc ? "desc" : "asc");
    searchQuery = replaceUrlParam(
      searchQuery,
      "limit",
      tablePreferences.rowsPerPage * (tablePreferences.currentPage + 1)
    );

    dispatch(
      fetchStores({
        token: jwtToken,
        query: searchQuery,
      })
    );
  };



  const handleStoresChange = (event) => {
    let idsArr = [];
    // event.target.value.forEach((selected) => {
    let selectedStore = storesList.filter(
      (store) => store.storeNumber === event.target.value
    );
    selectedStore && idsArr.push(selectedStore[0].id);
    // });

    setStoreNames(event.target.value);
    setUserIDs(idsArr);
  };

  const handleStatusChange = (event) => {
    let idsArr = [];
    event.target.value.forEach((selected) => {
      let selectedStatus = statusList.filter(
        (status) => status.name === selected
      );
      selectedStatus[0] && idsArr.push(selectedStatus[0].id);
    });

    setStatusNames(event.target.value);
    setStatusIDs(idsArr);
  };

  const [ready, setReady] = useState(false);
  if (loading === "loaded" && !ready) {
    setReady(true);
  }

  const handleEdit = (id) => {
    // dispatch(
    //   fetchUserByID({
    //     id: id,
    //     token: jwtToken,
    //   })
    // );
  };

  const handleEditClick = (id) => {
    const selectedStore = storesList.filter((store) => store.id === id);
    dispatch(setStore(selectedStore[0]));

    // history.push(`/admin/stores/${id}`);
  };

  const handleSearch = () => {
    let daterange = "";
    if (dateBefore && dateAfter) {
      daterange = `${dateAfter.toISOString().substring(0, 10)},${dateBefore
        .toISOString()
        .substring(0, 10)}`;
    } else if (dateBefore && !dateAfter) {
      daterange = `lte,${dateBefore.toISOString().substring(0, 10)}`;
    } else if (!dateBefore && dateAfter) {
      daterange = `gte,${dateAfter.toISOString().substring(0, 10)}`;
    }

    let searchQuery = lastQuery ? lastQuery : "";

    if (statusIDs.length) {
      searchQuery = replaceUrlParam(
        searchQuery,
        "statuses",
        statusIDs.join(",")
      );
    }
    if (daterange) {
      searchQuery = replaceUrlParam(searchQuery, "udate", daterange);
    }
    //    if (searchTerm) {
    searchQuery = replaceUrlParam(searchQuery, "name", searchTerm);
    //    }
    
    dispatch(
      fetchStores({
        query: searchQuery,
        token: jwtToken,
      })
    );
    
    setTablePreferences((oldTable) => ({
      ...oldTable,
      currentPage: 0,
    }));
  };

  const handleClear = () => {
    setStoreNames([]);
    setUserIDs([]);
    setStatusNames([]);
    setStatusIDs([]);
    setDateBefore("");
    setDateAfter("");
    setSearchTerm("");    

    dispatch(
      fetchStores({
        token: jwtToken,
        query: `?limit=${tablePreferences.rowsPerPage}`,
      })
    );
    
    setTablePreferences((oldTable) => ({
      ...oldTable,
      currentPage: 0,
      sortDirection: "asc",
      sortProperty: "id",
    }));
  };

  const selectNames = ["status"];

  const cellList = headCells.map((cell) => {
    return cell.id;
  });

  let content;
  if (loading === "loading") {
    content = (
      <CircularProgress className={classes.progress} color="secondary" />
    );
  } else if (loading === "loaded") {
    content = (
      <CustomTable
        data={storesList}
        actionsLists={[statusList]}
        actionsHandleChange={[handleStatusChange]}
        actionsNames={[statusNames]}
        headCells={headCells}
        cellList={cellList}
        handleEditClick={handleEditClick}
        handleSearch={handleSearch}
        setSearchTerm={setSearchTerm}
        handleClear={handleClear}
        handleEdit={handleEdit}
        area={"store"}
        selectNames={selectNames}
        setDateBefore={setDateBefore}
        setDateAfter={setDateAfter}
        dateAfter={dateAfter}
        dateBefore={dateBefore}
        productsAmount={storesAmount}
        handleMore={handleMore}
        page={tablePreferences.currentPage}
        setPage={(page) =>
          setTablePreferences((oldTable) => ({
            ...oldTable,
            currentPage: page,
          }))
        }
        handleRequestSort={handleRequestSort}
        sortProperty={tablePreferences.sortProperty}
        sortDirection={tablePreferences.sortDirection}
        rowsPerPage={tablePreferences.rowsPerPage}
        setRowsPerPage={(rows) =>
          setTablePreferences((oldTable) => ({
            ...oldTable,
            rowsPerPage: rows,
          }))
        }
        getFullList={getFullListTrigger}
      />
    );
  }

  return (
    <Grid
      container
      spacing={3}
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      style={{ height: "100%" }}
    >
      {content}
    </Grid>
  );
}
