import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { selectUsers } from "../users/usersSlice";
import {
  createPoints,
  selectUserPoints,
  setCreateStatus,
  updatePoints,
} from "./userPointsSlice";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Datepicker from "../../components/Datepicker";
import { fetchPrograms, selectPrograms } from "../programs/programsSlice";

const ColorButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.primaryButton.main,
    color: theme.palette.primaryButtonFont.main,
    width: "100%",
    borderRadius: "24px",
    "&:hover": {
      backgroundColor: theme.palette.primaryButton.main,
      opacity: 0.7,
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "45px",
    // backgroundColor: theme.palette.secondary.main,
    width: "100%",
  },
  accountContainer: {
    height: "100%",
    color: theme.palette.primaryFont.main,
  },
  topBackground: {
    // backgroundColor: theme.palette.secondary.main,
    // height: '100%',
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    color: theme.palette.secondaryFont.main,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  registerContainer: {
    borderRadius: 16,
    backgroundColor: "#fff",
    color: theme.palette.secondaryFont.main,
    padding: "50px !important",
  },
  textLabel: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 8,
    marginTop: 8,
  },
  textInput: {},
  userDetailsContainer: {
    padding: 24,
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-between",
  },
  userDetails: {
    display: "flex",
    justifyContent: "space-between",
    // color: "#fff",
    fontSize: 40,
  },
  infoContainerEdit: {
    display: "flex",
    flexFlow: "column wrap",
    justifyContent: "space-between",
    marginLeft: 80,
  },

  userCardsContainerEdit: {
    display: "flex",
    flexFlow: "row wrap",
    padding: 24,
    boxSizing: "border-box",
    justifyContent: "space-between",
    flexGrow: 1,
    width: "100%",
  },
  historyCardContainerEdit: {
    display: "flex",
    flexGrow: 1,
    marginRight: 80,

    // padding: 24,
    // boxSizing: 'border-box',
  },
  historyCardEdit: {
    flexGrow: 1,
  },
  cardHeader: {
    color: theme.palette.primaryFont.main,
    fontWeight: "bold",
    fontSize: 40,
  },
  formControl: {
    // borderWidth: 2,
    borderStyle: "solid",
    // borderColor: theme.palette.primaryFont.main,
    borderRadius: 16,
    display: "flex",
    marginTop: 12,
    marginBottom: 24,
  },
  addCircle: { marginRight: 0, color: "#FFF" },
  textInputField: {
    marginBottom: 16,
  },
  title: {
    color: "#fff",
    padding: 16,
    backgroundColor: theme.palette.secondary.main,
    textAlign: "center",
    fontSize: 24,
    fontWeight: "bold",
  },
}));

// const usePlaceholderStyles = makeStyles((theme) => ({
//   placeholder: {
//     color: theme.palette.primaryFont.main,
//   },
// }));

export default function AddPoints(props) {
  // const { editItem, setEdit } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const { createStatus, memberPoint } = useSelector(selectUserPoints);
  const { user } = useSelector(selectUsers);
  const { programsList } = useSelector(selectPrograms);

  const [points, setUserPoints] = useState("");
  const [notes, setNotes] = useState("");
  const [expiryDate, setExpiryDate] = useState("");

  const [pointType, setPointType] = useState(1);
  const [programID, setProgramID] = useState("");

  useEffect(() => {
    dispatch(
      fetchPrograms({
        token: jwtToken,
        query: `?limit=200&statuses=1`,
      })
    );
  }, []);

  useEffect(() => {
    if (createStatus) {
      handleClose();
    }
  }, [createStatus]);

  let jwtToken = localStorage.getItem("clientToken");
  const handleSubmit = (event) => {
    event.preventDefault();

    // if (editItem) {
    //   const pointsDetails = {
    //     statusID: pointType,
    //     approvalProcessID: 1,
    //     points: Number(points),
    //     notes: notes,
    //   };
    //   dispatch(
    //     updatePoints({
    //       token: jwtToken,
    //       userID: user.id,
    //       pointsDetails: pointsDetails,
    //       pointsID: memberPoint.id,
    //     })
    //   );
    // } else {
      const pointsDetails = {
        statusID: pointType,
        approvalProcessID: 1,
        points: Number(points),
        notes: notes,
      };
      if (pointType === 2) {
        pointsDetails.complimentaryCode = "Bonus";
      }
      if (pointType === 23) {
        pointsDetails.statusID = 23;
      }
      if (expiryDate) {
        pointsDetails.expiryDate = expiryDate.toISOString();
      }
      if (programID) {
        pointsDetails.programID = programID;
      }

      dispatch(
        createPoints({
          token: jwtToken,
          userID: user.id,
          pointsDetails: pointsDetails,
        })
      );
    // }
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    // if (editItem) {
    //   setUserPoints(memberPoint.points);
    //   setNotes(memberPoint.notes);
    //   setPointType(memberPoint.statusID);
    //   setExpiryDate(memberPoint.expiryDate);
    //   setProgramID(memberPoint.programID);
    //   // dispatch(setCreateStatus(false));
    // } else {
      setUserPoints("");
      setNotes("");
      setPointType(1);
      setExpiryDate("");
      setProgramID("");
      dispatch(setCreateStatus(false));
    // }
    setOpen(true);
  };

  // useEffect(() => {
  //   console.log("Opened Add points");
  //   console.log("editItem", editItem);
  //   if (editItem) {
  //     setUserPoints(memberPoint.points);
  //     setNotes(memberPoint.notes);
  //     setPointType(memberPoint.statusID);
  //     setExpiryDate(memberPoint.expiryDate);
  //     setProgramID(memberPoint.programID);
  //     // dispatch(setCreateStatus(false));
  //     setOpen(true);
  //   }
  // }, [editItem]);

  const handleClose = () => {
    setUserPoints("");
    setPointType(1);
    setExpiryDate("");
    setNotes("");
    setProgramID("");

    setOpen(false);
    // setEdit(false);
    dispatch(setCreateStatus(false));
  };

  return (
    <div>
      <IconButton aria-label={"add user points"} style={{ marginLeft: 0 }}>
        <AddCircleIcon
          className={classes.addCircle}
          onClick={handleClickOpen}
        />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle
          disableTypography
          className={classes.title}
          id="form-dialog-title"
        >
          {"Add Member Points"}
        </DialogTitle>
        <form className={classes.form} onSubmit={handleSubmit}>
          <DialogContent style={{ padding: 24 }}>
            <Grid className={classes.historyCardEdit}>
              <div className={classes.textLabel}>Points: </div>
              <TextField
                id="standard-number"
                label=""
                type="number"
                variant="outlined"
                min="0"
                className={classes.textInputField}
                value={points}
                fullWidth
                required
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) =>
                  e.target.value >= 0
                    ? setUserPoints(e.target.value)
                    : setUserPoints(0)
                }
              />
              <div className={classes.textLabel}>Program: </div>
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  labelId="programID-simple-select-outlined-label"
                  id="programID-simple-select-outlined"
                  value={programID}
                  onChange={(e) => setProgramID(e.target.value)}
                  InputLabelProps={{ shrink: false }}
                >
                  {programsList.map((program) => (
                    <MenuItem
                      className={classes.colorItem}
                      key={program.id}
                      value={program.id}
                    >
                      {program.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className={classes.textLabel}>Point Type: </div>
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  labelId="pointtype-simple-select-outlined-label"
                  id="pointtype-simple-select-outlined"
                  value={pointType}
                  onChange={(e) => setPointType(e.target.value)}
                  required
                  InputLabelProps={{ shrink: false }}
                >
                  <MenuItem value={1}>Active</MenuItem>
                  <MenuItem value={23}>Refund</MenuItem>
                  <MenuItem value={3}>Remove</MenuItem>
                </Select>
              </FormControl>

              <div style={{ marginBottom: 0 }} className={classes.textLabel}>
                Expiry Date:{" "}
              </div>
              <div style={{ marginBottom: 24 }}>
                <Datepicker
                  invoiceDate={expiryDate}
                  setInvoiceDate={setExpiryDate}
                  report={true}
                  label={"  "}                  
                />
              </div>

              <div className={classes.textLabel}>Notes: </div>
              <TextField
                className={classes.textInputField}
                fullWidth
                required
                id="outlined-multiline-static"
                label=""
                multiline
                variant="outlined"
                rows={4}
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </Grid>
          </DialogContent>
          <Divider />
          <DialogActions
            style={{ paddingLeft: 24, paddingRight: 24, paddingBottom: 16 }}
          >
            <Grid container spacing={2} direction="row">
              <Grid item sm>
                <ColorButton onClick={handleClose} color="primary">
                  Cancel
                </ColorButton>
              </Grid>
              <Grid item sm>
                <ColorButton type="submit" color="secondary">
                  {/* {editItem ? "Update Points" : "Add Points"} */}
                  Add Points
                </ColorButton>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
