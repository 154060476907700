import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// import Carousel from "react-img-carousel";
import { Carousel } from "react-responsive-carousel";

import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import { replaceUrlParam, setXClientHost } from "../../app/utils";
import {
  fetchLogo,
  getFullList,
  preSignedURLLogo,
  preSignedURLSecondaryLogo,
  selectContents,
  updateBannerImages,
  updateBannerLinks,
  updateContent,
  createContent,
  fetchContentByName,
} from "../content/contentsSlice";
import { selectCustomisation } from "./customisationSlice";
// require("react-img-carousel/lib/carousel.css");
const ColorButton = withStyles((theme) => ({
  root: {
    // margin: theme.spacing(3, 0, 2),
    margin: theme.spacing(0, 0, 0),
    backgroundColor: theme.palette.primaryButton.main,
    color: theme.palette.primaryButtonFont.main,
    "&:hover": {
      backgroundColor: theme.palette.primaryButton.main,
      opacity: 0.7,
    },
    width: "100%",
    maxWidth: 300,
    // fontWeight: 'bold',
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  customisationContainer: {
    margin: "auto",
    maxWidth: "100%",
    backgroundColor: "#FFFFFF",
    // textAlign: "center",
    padding: 32,
  },
  title: {
    fontSize: 32,
    fontWeight: "bold",
    marginBottom: 16,
    color: theme.palette.primary.main,
  },
  subTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 16,
    marginTop: 16,
    color: theme.palette.secondaryFont.main,
  },
  imageListContainer: {
    display: "flex",
    overflow: "auto",
  },
  imageUnitContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 8,
    marginTop: 8,
  },
  unitImage: {},
  unitButton: {},
  inputLabel: { width: 215 },

  customDisplay: {},
  bannerImagePreview: {
    backgroundColor: theme.palette.headerColor.main,
    height: 50,
    marginBottom: 24,
    padding: 24,
  },
}));

export default function CustomImageUpload() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const matches = useMediaQuery("(min-width:850px)");
  let jwtToken = localStorage.getItem("clientToken");

  const { customisation } = useSelector(selectCustomisation);
  const {
    contentsList,
    logo,
    contentsAmount,
    secondaryLogo,
    secondaryLogoLink,
  } = useSelector(selectContents);
  const [imageList, setImageList] = useState([]);
  const [originalList, setOriginalList] = useState([]);
  const [mobileImageList, setMobileImageList] = useState([
    "/abb-mob-one.jpeg",
    "/abb-mob-two.jpeg",
  ]);

  const [linkList, setLinkList] = useState([]);
  console.log("linkList", linkList);

  const [LogoLink2, setLogoLink2] = useState("");

  useEffect(() => {
    if (!logo.value) {
      dispatch(
        fetchLogo({
          token: jwtToken,
          name: "logo",
          host: setXClientHost(),
        })
      );
    }
    if (!secondaryLogo.value) {
      dispatch(
        fetchContentByName({
          token: jwtToken,
          name: "secondary_logo",
          host: setXClientHost(),
        })
      );
    }
    if (!secondaryLogoLink.value) {
      dispatch(
        fetchContentByName({
          token: jwtToken,
          name: "secondary_logo_link",
          host: setXClientHost(),
        })
      );
    }
  }, [logo, secondaryLogo, secondaryLogoLink]);

  useEffect(() => {
    if (secondaryLogoLink.value) {
      setLogoLink2(secondaryLogoLink.value);
    }
  }, [secondaryLogoLink]);

  useEffect(() => {
    let searchQuery = "";
    searchQuery = replaceUrlParam(searchQuery, "limit", 100);
    dispatch(
      getFullList({
        token: jwtToken,
        offset: contentsList.length,
        query: searchQuery,
        targetLength: contentsAmount,
      })
    );
  }, []);

  useEffect(() => {
    if (contentsList) {
      let filtered = contentsList.filter(
        (content) => content.name === "banner"
      );
      setImageList(filtered.length ? filtered[0].value.split(",") : []);
      setOriginalList(filtered.length ? filtered[0].value.split(",") : []);

      let linkFiltered = contentsList.filter(
        (content) => content.name === "bannerLink"
      );

      let noLinkArray = [];
      filtered.length &&
        filtered[0].value.split(",").forEach(() => {
          noLinkArray.push("");
        });

      setLinkList(
        linkFiltered.length ? linkFiltered[0].value.split(",") : noLinkArray
      );
    }
  }, [contentsList]);

  // const originalList = [
  //   '/abb-banner-three.jpg',
  //   '/abb-banner.jpg',
  //   '/abb-banner-two.jpg',
  // ];

  const [fileURL, setFileURL] = useState(null);
  const [file, setFile] = useState(null);
  const [secondaryFileURL, setSecondaryFileURL] = useState(null);
  const [secondaryFile, setSecondaryFile] = useState(null);

  console.log("secondaryFile", secondaryFile);

  const fileInputRef = useRef();
  const secondaryFileInputRef = useRef();

  // const [fileURL, setFileURL] = useState(null);
  const [bannerFiles, setBannerFiles] = useState([]);

  const bannerFileInputRef = useRef();

  const handleBannerUpload = (jsonData, filename) => {
    if (bannerFileInputRef.current.files.length) {
      let bannerFile = bannerFileInputRef.current.files[0];
      let urlOBJ = URL.createObjectURL(bannerFile);

      let data = {
        objectName: `${bannerFile.name}`,
        objectType: "asset",
        token: jwtToken,
        file: bannerFile,
        contentType: bannerFile.type,
        assetURL: customisation.theme.cloudfrontAssetURL,
        urlOBJ: urlOBJ,
      };
      setBannerFiles(bannerFiles.concat(data));
      setImageList(imageList.concat(urlOBJ));
    }
  };

  const handleBannerClick = () => {
    let bannerContent = contentsList.filter(
      (content) => content.name === "banner"
    );
    let bannerLinkContent = contentsList.filter(
      (content) => content.name === "bannerLink"
    );

    const existingImages = imageList.filter((img) =>
      originalList.includes(img)
    );
    const fileNames = existingImages.concat(
      bannerFiles.map((file) => file.file.name)
    );

    dispatch(
      updateBannerImages({
        token: jwtToken,
        addedImages: bannerFiles,
        existingImages: existingImages,
        fileNames: fileNames,
        bannerContent: bannerContent,
      })
    );
    dispatch(
      updateBannerLinks({
        token: jwtToken,
        bannerLinks: linkList,
        bannerContent: bannerLinkContent,
      })
    );
  };

  const handleRemoveImage = (e, image, index) => {
    setImageList(imageList.filter((img) => img !== image));
    setBannerFiles(bannerFiles.filter((img) => img.urlOBJ !== image));
  };

  const handleLogoUpload = (e) => {
    if (fileInputRef.current.files.length) {
      let logoFile = fileInputRef.current.files[0];
      setFile(URL.createObjectURL(logoFile));
      setFileURL(logoFile.name);
    }
  };

  const handleSecondaryLogoUpload = (e) => {
    if (secondaryFileInputRef.current.files.length) {
      let logoFile = secondaryFileInputRef.current.files[0];
      setSecondaryFile(URL.createObjectURL(logoFile));
      setSecondaryFileURL(logoFile.name);
    }
  };
  const handleLogoClick = () => {
    let logoContent = contentsList.filter((content) => content.name === "logo");
    if (fileInputRef.current.files.length) {
      let logoFile = fileInputRef.current.files[0];
      // handleFiles(fileInputRef.current.files);

      let data = {
        objectName: logoFile.name,
        objectType: "asset",
        token: jwtToken,
        file: logoFile,
        contentType: logoFile.type,
        assetURL: customisation.theme.cloudfrontAssetURL,
        logoContent: logoContent,
      };
      dispatch(preSignedURLLogo(data));
    }
  };
  const handleSecondaryLogoClick = () => {
    console.log("here1");

    let logoContent = contentsList.filter(
      (content) => content.name === "secondary_logo"
    );
    let logoLinkContent = contentsList.filter(
      (content) => content.name === "secondary_logo_link"
    );
    if (secondaryFileInputRef.current.files.length) {
      console.log("here2");
      let logoFile = secondaryFileInputRef.current.files[0];

      let data = {
        objectName: logoFile.name,
        objectType: "asset",
        token: jwtToken,
        file: logoFile,
        contentType: logoFile.type,
        assetURL: customisation.theme.cloudfrontAssetURL,
        logoContent: logoContent,
        secondaryLogoLink: LogoLink2,
        linkContent: logoLinkContent,
      };
      dispatch(preSignedURLSecondaryLogo(data));
    } else {
      if (logoLinkContent.length) {
        dispatch(
          updateContent({
            token: jwtToken,
            content: {
              value: LogoLink2,
            },
            id: logoLinkContent[0].id,
          })
        );
      } else {
        dispatch(
          createContent({
            token: jwtToken,
            content: {
              name: "secondary_logo_link",
              classification: "data",
              value: LogoLink2,
              storageKey: null,
            },
          })
        );
      }
    }
  };

  const arrows = {
    left: (
      <div class="arrow-container">
        {/* <img src="/circ.png" class="circ" /> */}
        <img id="left-caret" src="/leftcaret.png" />
      </div>
    ),
    right: (
      <div class="arrow-container">
        {/* <img src="/circ.png" class="circ" /> */}
        <img id="right-caret" src="/rightcaret.png" />
      </div>
    ),
  };

  return (
    <div className={classes.customisationContainer}>
      <div className={classes.title}>Logo</div>
      <div style={{ display: "flex" }}>
        <div style={{ marginRight: 24 }}>
          <label htmlFor="logo-upload">
            <ColorButton component="span" variant="contained">
              Update Logo Image
            </ColorButton>
          </label>
        </div>
        <div>
          <ColorButton disabled={!file} onClick={handleLogoClick}>
            Save Logo Changes
          </ColorButton>
        </div>
      </div>
      <div className={classes.subTitle}>Current Logo</div>
      <div className={classes.bannerImagePreview}>
        <img
          style={{ height: 50 }}
          src={`${process.env.REACT_APP_ASSETS_URL}/theme/logo/${logo.value}`}
          alt={"Logo"}
        />
      </div>
      {file && (
        <div>
          <Divider style={{ marginTop: 16, marginBottom: 16 }} />
          <div className={classes.subTitle}>Preview</div>
          <div className={classes.bannerImagePreview}>
            <img style={{ height: 50 }} src={file} alt={"Logo"} />
          </div>
        </div>
      )}
      <div>
        <input
          ref={fileInputRef}
          style={{ display: "none" }}
          accept="image/*"
          className={classes.input}
          id="logo-upload"
          // multiple
          onChange={handleLogoUpload}
          type="file"
        />
      </div>
      <Divider style={{ height: 3, marginTop: 16, marginBottom: 16 }} />
      <div className={classes.title}>Secondary Logo</div>
      <div style={{ display: "flex" }}>
        <div style={{ marginRight: 24 }}>
          <label htmlFor="logo-upload-2">
            <ColorButton component="span" variant="contained">
              Update Logo Image
            </ColorButton>
          </label>
        </div>
        <div>
          <ColorButton onClick={handleSecondaryLogoClick}>
            Save Logo Changes
          </ColorButton>
        </div>
      </div>
      {secondaryLogo.value && (
        <div>
          <div className={classes.subTitle}>Current Logo</div>
          <div className={classes.bannerImagePreview}>
            <img
              style={{ height: 50 }}
              src={`${process.env.REACT_APP_ASSETS_URL}/theme/secondary_logo/${secondaryLogo.value}`}
              alt={"Logo"}
            />
          </div>
        </div>
      )}
      <div lassName={classes.unitButton}>
        <div style={{ fontSize: 12 }}>Clickable Link URL</div>
        <TextField
          className={classes.pickerInput}
          variant="outlined"
          margin="dense"
          id="secondaryLogoLink"
          name="secondaryLogoLink"
          autoComplete="text"
          value={LogoLink2}
          onChange={(e) => {
            setLogoLink2(e.target.value);
          }}
        />
      </div>
      {secondaryFile && (
        <div>
          <Divider style={{ marginTop: 16, marginBottom: 16 }} />
          <div className={classes.subTitle}>Preview</div>
          <div className={classes.bannerImagePreview}>
            <img
              style={{ height: 50 }}
              src={secondaryFile}
              alt={"SecondaryLogo"}
            />
          </div>
        </div>
      )}
      <div>
        <input
          ref={secondaryFileInputRef}
          style={{ display: "none" }}
          accept="image/*"
          className={classes.input}
          id="logo-upload-2"
          // multiple
          onChange={handleSecondaryLogoUpload}
          type="file"
        />
      </div>
      <Divider style={{ height: 3, marginTop: 16, marginBottom: 16 }} />

      <div className={classes.title}>Landing Page Carousel</div>

      <div style={{ display: "flex" }}>
        <div style={{ marginRight: 24 }}>
          <label htmlFor="banner-upload">
            <ColorButton component="span" variant="contained">
              Add Banner Images
            </ColorButton>
          </label>
          <input
            ref={bannerFileInputRef}
            style={{ display: "none" }}
            accept="image/*"
            className={classes.input}
            id="banner-upload"
            multiple
            onChange={handleBannerUpload}
            type="file"
          />
        </div>
        <div>
          <ColorButton onClick={handleBannerClick}>
            Save Banner Changes
          </ColorButton>
        </div>
      </div>
      <div className={classes.subTitle}>Image List</div>

      <div className={classes.imageListContainer}>
        {imageList &&
          imageList.map((img, index) => (
            <div className={classes.imageUnitContainer}>
              <div className={classes.unitImage}>
                <img
                  height={450}
                  src={
                    img.includes("blob")
                      ? img
                      : `${process.env.REACT_APP_ASSETS_URL}/theme/banner/${img}`
                  }
                />
              </div>
              <div lassName={classes.unitButton}>
                <div style={{ fontSize: 12 }}>Clickable Link URL</div>
                <TextField
                  className={classes.pickerInput}
                  variant="outlined"
                  margin="dense"
                  id="linkList"
                  name="linkList"
                  autoComplete="text"
                  value={linkList[index]}
                  onChange={(e) => {
                    let newLinkList = [...linkList];
                    newLinkList[index] = e.target.value;
                    setLinkList(newLinkList);
                  }}
                />
              </div>
              <div lassName={classes.unitButton}>
                <ColorButton onClick={(e) => handleRemoveImage(e, img, index)}>
                  Remove Image
                </ColorButton>
              </div>
            </div>
          ))}
      </div>
      <Divider style={{ marginTop: 16, marginBottom: 16 }} />
      <div className={classes.subTitle}>Carousel Preview</div>
      {imageList.length ? (
        <div>
          {/* <Carousel
            // slideWidth="100%"
            // slideAlignment="center"
            // dynamicHeight="true"
            viewportWidth="100%"
            // slideWidth="90%"
            viewportHeight={matches ? "450px" : "256px"}
            slideHeight={matches ? "450px" : "256px"}
            cellPadding={0}
            arrows={arrows}
            autoplay={true}
            autoplaySpeed={6000}
            style={{
              slide: {
                opacity: 0.2,
                objectFit: "contain",
                margin: "auto",
                // maxHeight: matches ? '450px' : '256px',
              },
              selectedSlide: {
                opacity: 1,
                objectFit: "contain",
                margin: "auto",
                // maxHeight: matches ? '450px' : '256px',
              },
            }}
          >
            {imageList.map((img) => (
              <img
                src={
                  img.includes("blob")
                    ? img
                    : `${process.env.REACT_APP_ASSETS_URL}/theme/banner/${img}`
                }
              />
            ))}
          </Carousel> */}
          <Carousel
            swipeable={false}
            showStatus={false}
            infiniteLoop={true}
            autoPlay={true}
            interval={6000}
            showThumbs={false}
            dynamicHeight={true}
            onClickItem={(index) => {
              if (linkList && linkList[index]) {
                window.location = linkList[index];
              }
            }}
          >
            {matches
              ? imageList &&
                linkList &&
                imageList.map((img, index) => (
                  <img
                    src={
                      img.includes("blob")
                        ? img
                        : `${process.env.REACT_APP_ASSETS_URL}/theme/banner/${img}`
                    }
                    style={{
                      cursor: linkList && linkList[index] ? "pointer" : "auto",
                    }}
                  />
                ))
              : imageList &&
                imageList.map((img) => (
                  <img
                    src={
                      img.includes("blob")
                        ? img
                        : `${process.env.REACT_APP_ASSETS_URL}/theme/banner/${img}`
                    }
                  />
                ))}
          </Carousel>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}
