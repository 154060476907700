import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import { errorSnack, successSnack } from '../snackbar/snackbarSlice';
import { fetchMyself } from '../users/usersSlice';

import axios from 'axios';
import { uuidv4 } from '../../app/utils';
import baseURL from '../../app/utils.js';
import { resetWishlistCart } from '../wishlist/wishlistSlice';
axios.interceptors.request.use(
   (req) => {
      req.headers.common['X-Request-ID'] = uuidv4();
      return req;
   },
   (err) => {
      return Promise.reject(err);
   }
);

export const fetchOrders = createAsyncThunk(
  'orders/fetchOrders',
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: {Authorization: `Bearer ${args.token}`},
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/orders${args.query}`,
        config
      );
      thunkAPI.dispatch(setOrdersAmount(response.headers["x-total-count"]));
      thunkAPI.dispatch(setLastQuery(args.query));
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              'Error fetching Orders list'
          )
        );
      }
      return thunkAPI.rejectWithValue({error: error.message});
    }
  }
);

export const fetchMoreOrders = createAsyncThunk(
  "orders/fetchMoreOrders",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/orders${args.query}&offset=${args.offset}`,
        config
      );
      // thunkAPI.dispatch(setOrdersAmount(response.headers["x-total-count"]));
      return await response.data;
    } catch (error) {
      if (error.message.includes("401")) {
        localStorage.removeItem("clientUser");
        localStorage.removeItem("clientToken");
      }
      if (error.response && error.response.data) {
        // thunkAPI.dispatch(
        //   errorSnack(
        //     error.response.data.error_description ||
        //       'Error fetching products list'
        //   )
        // );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const filterOrders = createAsyncThunk(
  'orders/filterOrders',
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: {Authorization: `Bearer ${args.token}`},
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/orders${args.query}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              'Error fetching Orders list'
          )
        );
      }
      return thunkAPI.rejectWithValue({error: error.message});
    }
  }
);

export const filterOrderByID = createAsyncThunk(
  'orders/filterOrderByID',
  async (args, thunkAPI) => {
    const config = {
      headers: {Authorization: `Bearer ${args.token}`},
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/admin/orders/${args.orderID}`,
        config
      );

      return await [response.data];
    } catch (error) {
      if (error.response && error.response.data) {
        if ( error.response.data.error_description ===  'Order not found') {
          return []
        } else {
          thunkAPI.dispatch(
            errorSnack(
              error.response.data.error_description || 'Error fetching order data'
            )
          );
        }
        
        
      }

      return thunkAPI.rejectWithValue({error: error.message});
    }
  }
);

export const fetchOrderByID = createAsyncThunk(
  'orders/fetchOrderByID',
  async (args, thunkAPI) => {
    const config = {
      headers: {Authorization: `Bearer ${args.token}`},
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/admin/clients/${args.clientID}/orders/${args.orderID}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || 'Error fetching order data'
          )
        );
      }

      return thunkAPI.rejectWithValue({error: error.message});
    }
  }
);

export const fetchUserOrders = createAsyncThunk(
  'orders/fetchUserOrders',
  async (args, thunkAPI) => {
    const config = {
      headers: {Authorization: `Bearer ${args.token}`},
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/admin/users/${args.userID}/orders?limit=100`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || 'Error fetching order data'
          )
        );
      }

      return thunkAPI.rejectWithValue({error: error.message});
    }
  }
);

export const fetchUserOrderByID = createAsyncThunk(
  'orders/fetchUserOrderByID',
  async (args, thunkAPI) => {
    const config = {
      headers: {Authorization: `Bearer ${args.token}`},
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/admin/users/${args.userID}/orders/${args.orderID}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || 'Error fetching order data'
          )
        );
      }

      return thunkAPI.rejectWithValue({error: error.message});
    }
  }
);
export const createOrder = createAsyncThunk(
  'orders/createOrder',
  async (args, thunkAPI) => {
    const config = {
      headers: {Authorization: `Bearer ${args.token}`},
    };
    try {
      //full options
      const response = await axios.post(
        `${baseURL}/v1/admin/users/${args.userID}/orders`,
        args.order,
        config
      );

      thunkAPI.dispatch(resetWishlistCart());
      thunkAPI.dispatch(fetchMyself({token: args.token}));


      thunkAPI.dispatch(successSnack('Your order has been submitted'));

      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || 'Error creating Order'
          )
        );
      }

      return thunkAPI.rejectWithValue({error: error.message});
    }
  }
);

export const fetchOrderProductByID = createAsyncThunk(
  'orders/fetchOrderProductByID',
  async (args, thunkAPI) => {
    const config = {
      headers: {Authorization: `Bearer ${args.token}`},
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/admin/core/products/${args.id}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.message.includes('401')) {
        localStorage.removeItem('clientUser');
        localStorage.removeItem('clientToken');
      }
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              'Error fetching product data'
          )
        );
      }
      return thunkAPI.rejectWithValue({error: error.message});
    }
  }
);



export const setOrderProducts = createAsyncThunk(
  'orders/setOrderProducts',
  async (args, thunkAPI) => {
  
    Promise.all(args.ids.map((id) =>  thunkAPI.dispatch(fetchOrderProductByID({id: id, token: args.token}))))
.then((results) => {
  let productPayload = []
  results.forEach(result => {
    productPayload.push(result.payload)
  });
   thunkAPI.dispatch(setProds(productPayload))
   return  productPayload;
})
.catch(err => {
  return thunkAPI.rejectWithValue("Error getting order product details");
});


    // try {
    //   const response = await axios.get(
    //     `${baseURL}/v1/admin/core/products/${args.id}`,
    //     config
    //   );
    //   return await response.data;
    // } catch (error) {
    //   if (error.message.includes('401')) {
    //     localStorage.removeItem('clientUser');
    //     localStorage.removeItem('clientToken');
    //   }
    //   if (error.response && error.response.data) {
    //     thunkAPI.dispatch(
    //       errorSnack(
    //         error.response.data.error_description ||
    //           'Error fetching product data'
    //       )
    //     );
    //   }
    //   return thunkAPI.rejectWithValue({error: error.message});
    // }
  }
);

export const updateOrderDetails = createAsyncThunk(
  "orders/updateOrderDetails",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      const response = await axios.patch(
        `${baseURL}/v1/admin/orders/${args.orderID}`,
        args.order,
        config
      );
      thunkAPI.dispatch(
        fetchOrders({
          token: args.token,
          query: `?limit=25&sort=desc`,
        })
      );
      thunkAPI.dispatch(successSnack("Order updated successfully"));

      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error updating order"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getFullList = createAsyncThunk(
  "claims/getFullList",
  async (args, thunkAPI) => {
    let objToMap = [];
    for (let i = 0; i < (args.targetLength - args.offset) / 100; i++) {
      objToMap.push({
        token: args.token,
        query: args.query,
        offset: args.offset + 100 * i,
      });
    }

    Promise.all(
      objToMap.map((requestData) =>
        thunkAPI.dispatch(fetchMoreOrders(requestData))
      )
    )
      .then((results) => {})
      .catch((err) => {
        return thunkAPI.rejectWithValue("Error fetching full claims list");
      });
  }
);


const initialStateObj  = {
  order: {},
  ordersList: [],
  ordersFilter: [],
  orderDetails: {},
  loading: 'idle',
  message: '',
  error: '',
  orderAccount: {},
  orderUser: {},
  orderComplete: false,
  orderID: '',
  orderProducts: [],
  orderNumber: {id: ''}, 
  editing: false,
  ordersAmount: "",
  lastQuery: "",
}


const ordersSlice = createSlice({
  name: 'orders',
  initialState: initialStateObj
  ,
  reducers: {
    setOrder(state, action) {
      state.order = action.payload;
    },
    setOrderDetails(state, action) {
      state.orderDetails = action.payload;
    },
    resetComplete(state, action) {
      state.orderComplete = false;
    },
    resetOrder(state, action) {
      state = initialStateObj;
    },
    setEditing(state, action) {
      state.editing = action.payload;
    },
    setOrderUser(state, action) {
      state.orderUser = action.payload;
    },
    setProds(state, action) {
      state.orderProducts = action.payload;
    },
    setLastQuery: (state, action) => {
      state.lastQuery = action.payload;
    },
    setOrdersAmount: (state, action) => {
      state.ordersAmount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrders.pending, (state) => {
      state.ordersList = [];
      state.ordersFilter = [];

      state.loading = 'loading';
      state.editing = false;
    });
    builder.addCase(fetchOrders.fulfilled, (state, {payload}) => {
      state.ordersList = payload;
      state.ordersFilter = payload;
      state.loading = 'loaded';
      state.editing = false;
    });
    builder.addCase(fetchOrders.rejected, (state, action) => {
      state.loading = 'error';
      state.error = action.error.message;
    });
    builder.addCase(fetchMoreOrders.fulfilled, (state, { payload }) => {
      state.error = "";
      state.ordersList = state.ordersList.concat(payload);
      state.loading = "loaded";
    });
    builder.addCase(fetchMoreOrders.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(getFullList.fulfilled, (state, { payload }) => {
      state.error = "";
      state.ordersList = state.ordersList.sort(function (a, b) {
        return a.id - b.id;
      });
      state.loading = "loaded";
    });
    builder.addCase(getFullList.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchUserOrders.pending, (state) => {
      state.ordersList = [];
      state.loading = 'loading';
      state.editing = false;
    });
    builder.addCase(fetchUserOrders.fulfilled, (state, {payload}) => {
      state.ordersList = payload;
      state.ordersFilter = payload;
      state.loading = 'loaded';
      state.editing = false;
    });
    builder.addCase(fetchUserOrders.rejected, (state, action) => {
      state.loading = 'error';
      state.error = action.error.message;
    });
    builder.addCase(fetchOrderByID.pending, (state) => {
      state.order = {};
      state.loading = 'loading';
      state.editing = false;
    });
    builder.addCase(fetchOrderByID.fulfilled, (state, {payload}) => {
      state.order = payload;
      state.loading = 'loaded';
      state.editing = true;
    });
    builder.addCase(fetchOrderByID.rejected, (state, action) => {
      state.loading = 'error';
      state.error = action.error.message;
    });
    builder.addCase(fetchUserOrderByID.pending, (state) => {
      state.order = {};
      state.loading = 'loading';
      state.editing = false;
    });
    builder.addCase(fetchUserOrderByID.fulfilled, (state, {payload}) => {
      state.order = payload;
      state.loading = 'loaded';
      state.editing = true;
    });
    builder.addCase(fetchUserOrderByID.rejected, (state, action) => {
      state.loading = 'error';
      state.error = action.error.message;
    });

    builder.addCase(filterOrders.pending, (state) => {
      state.ordersFilter = [];
      state.loading = 'loading';
      state.editing = false;
    });
    builder.addCase(filterOrders.fulfilled, (state, {payload}) => {
      state.ordersFilter = payload;
      state.loading = 'loaded';
    });
    builder.addCase(filterOrders.rejected, (state, action) => {
      state.loading = 'error';
      state.error = action.error.message;
    });
    builder.addCase(filterOrderByID.pending, (state) => {
      state.ordersFilter = [];
      state.loading = 'loading';
      state.editing = false;
    });
    builder.addCase(filterOrderByID.fulfilled, (state, {payload}) => {
      state.ordersFilter = payload;
      state.loading = 'loaded';
    });
    builder.addCase(filterOrderByID.rejected, (state, action) => {
      state.loading = 'error';
      state.error = action.error.message;
    });
    builder.addCase(createOrder.pending, (state) => {
      state.message = '';
      state.orderNumber = {id: ''};
      state.loading = 'loading';
      state.editing = false;
    });
    builder.addCase(createOrder.fulfilled, (state, {payload}) => {
      // state.message = payload;
      state.orderComplete = true;
      state.orderNumber = payload;
      state.orderID = payload;
      state.loading = 'loaded';
    });
    builder.addCase(createOrder.rejected, (state, action) => {
      state.loading = 'error';
      state.error = action.error.message;
    });
    builder.addCase(setOrderProducts.pending, (state) => {
      state.message = '';
      state.loading = 'loading';
      state.orderProducts = [];

    });
    builder.addCase(setOrderProducts.fulfilled, (state, {payload}) => {
      // state.message = payload;
      state.loading = 'loaded';
    });
    builder.addCase(setOrderProducts.rejected, (state, action) => {
      state.loading = 'error';
      state.error = action.error;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  setOrder,
  setOrderUser,
  setEditing,
  setOrderDetails,
  setProds,
  resetComplete,
  resetOrder,
  setLastQuery, 
  setOrdersAmount
} = ordersSlice.actions;
// Action creators are generated for each case reducer function

export const selectOrders = createSelector(
  (state) => ({
    orders: state.orders,
    ordersList: state.orders.ordersList,
    ordersFilter: state.orders.ordersFilter,
    loading: state.orders.loading,
    error: state.orders.error,
    editing: state.orders.editing,
    order: state.orders.order,
    orderDetails: state.orders.orderDetails,
    orderComplete: state.orders.orderComplete,
    orderID: state.orders.orderID,
    orderProducts: state.orders.orderProducts,
    orderNumber: state.orders.orderNumber,
    ordersAmount: state.orders.ordersAmount,
    lastQuery: state.orders.lastQuery,
  }),
  (state) => state
);
export default ordersSlice.reducer;
