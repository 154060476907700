import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import MobileProductCard from "../../components/MobileProductCard";
import ProductCard from "../../components/ProductCard";
// import Carousel from "react-img-carousel";
import { Carousel } from "react-responsive-carousel";

import { isInCart } from "../../app/utils";
import BreadCrumbs from "../../common/BreadCrumbs";
import {
  addProductToCart,
  selectCarts,
  updateProductQuantity,
} from "../carts/cartsSlice";
import { selectUsers } from "../users/usersSlice";
import {
  addProductToWishlist,
  removeProductFromWishlist,
  selectWishlist,
} from "../wishlist/wishlistSlice";
import {
  fetchProductByName,
  fetchProductsByGroup,
  fetchRelatedProductsByBrand,
  selectCoreProducts,
  setProduct,
} from "./coreProductsSlice";

import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import CircularProgress from "@material-ui/core/CircularProgress";
import "react-quill/dist/quill.bubble.css";
// require('react-img-carousel/lib/carousel.css');

const ColorButton = withStyles((theme) => ({
  root: {
    // marginBottom: theme.spacing(3),
    // marginTop: theme.spacing(3),
    marginTop: 8,
    backgroundColor: theme.palette.primaryButton.main,
    color: theme.palette.primaryButtonFont.main,
    borderRadius: "24px",
    fontWeight: "bold",
    // width: "100%",
    width: 350,
    "&:hover": {
      backgroundColor: theme.palette.primaryButton.main,
      opacity: 0.7,
    },
  },
}))(Button);

const WishlistButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondaryButton.main,
    border: "3px solid",
    borderColor: theme.palette.secondaryButtonFont.main,
    borderRadius: "24px",
    color: theme.palette.secondaryButtonFont.main,
    fontWeight: "bold",
    width: 350,
    marginTop: 8,
    "&:hover": {
      backgroundColor: `${theme.palette.secondaryButtonFont.main}2b`,
      // backgroundColor: theme.palette.secondaryButton.main,
      // opacity: 0.7,
    },
    fontWeight: "bold",
    minWidth: 175,
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  productContainer: {
    color: theme.palette.secondaryFont.main,
    fontSize: 20,
    fontWeight: "bold",
  },
  productDetailsContainer: {},
  detailsTitle: {
    padding: 24,
    color: "#DDD",
    marginBottom: 24,
    paddingTop: 36,
  },
  productPaper: {
    paddingBottom: 48,
  },
  detailsContainer: {
    display: "flex",
    flexFlow: "row nowrap",
    // justifyContent: 'center',
    // alignItems: 'center',
  },
  imagesContainer: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  detailsImage: {
    display: "flex",
    // flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  multiImageContainer: {
    display: "flex",
    overflow: "scroll",
    marginTop: 12,
    height: "100%",
    // justifyContent: 'space-between'
  },
  multiImageDiv: {
    cursor: "pointer",
    marginLeft: 6,
    marginRight: 6,
  },
  detailsInfo: {
    display: "flex",
    flexFlow: "column nowrap",
    flexGrow: 1,

    // width: "30%",
    padding: 50,
    paddingTop: 0,
    color: theme.palette.secondaryFont.main,

    // justifyContent: 'center',
    // alignItems: 'center',
  },
  detailsName: {
    textAlign: "left",
    marginBottom: 12,
    fontWeight: "bold",
    fontSize: 40,
    maxWidth: 725,
  },
  detailsSKU: {
    color: "#DDD",
    display: "flex",

    paddingTop: 16,
    fontSize: 16,
  },
  detailsPoints: {
    color: theme.palette.highlight.main,
    fontWeight: "bold",
    fontSize: 40,
    marginBottom: 32,
  },
  detailsShortDesc: {
    // marginBottom: 12,
    whiteSpace: "pre-line",
  },
  detailsQuantityContainer: {
    display: "flex",
    // flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "space-between",
    marginBottom: 8,
  },
  detailsQuantityContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "space-between",
    marginBottom: 8,
  },
  detailsQuantityTitle: {
    // marginBottom: 20,
    fontWeight: "bold",
    fontSize: 24,
    marginRight: 24,
    width: 100,
  },
  detailsQuantitySelector: {
    display: "flex",
    // flexFlow: "row nowrap",
    justifyContent: "center",
    alignItems: "center",
  },
  detailsGroupSelector: {
    display: "flex",
    flexFlow: "row nowrap",
    // justifyContent: 'center',
    alignItems: "center",
    width: "70%",
  },
  quantityMinus: {
    border: "1px solid #DDD",
    borderRadius: 4,
    paddingLeft: 12,
    paddingRight: 12,
    paddingBottom: 3,
    lineHeight: "24px",
    cursor: "pointer",
    outline: "none",
  },
  quantity: {
    marginLeft: 16,
    marginRight: 16,
    minWidth: 32,
    textAlign: "center",
  },
  quantityPlus: {
    border: "1px solid #DDD",
    borderRadius: 4,
    paddingLeft: 12,
    paddingRight: 12,
    paddingBottom: 3,
    lineHeight: "24px",
    cursor: "pointer",
    outline: "none",
  },
  detailsButtonsContainer: {
    display: "flex",
    alignItems: "center",
  },

  productDescriptionContainer: {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    // alignItems: 'center',
    padding: 24,
  },
  descriptionTitle: {
    textAlign: "left",
    marginBottom: 20,
    fontWeight: "bold",
    fontSize: 48,
  },
  descriptionText: {
    fontWeight: "normal",
  },
  productRelatedContainer: {
    padding: 24,

    marginBottom: 32,
  },
  relatedTitle: {
    textAlign: "center",
    margin: 32,
    fontWeight: "bold",
    fontSize: 48,
  },
  relatedCardsContainer: {},
  colorItem: { color: theme.palette.secondaryFont.main },
  breadcrumbs: {
    padding: 24,
    paddingBottom: 8,
    paddingTop: 8,
    borderBottom: "2px solid #F4F4F4",
  },
  specialStrikeout: {
    // color: '#DDD',
    textDecoration: "line-through",
  },
  special: {
    position: "absolute",
    padding: 16,
    paddingLeft: 24,
    paddingRight: 24,
    fontSize: 16,
    fontWeight: "bold",
    backgroundColor: theme.palette.highlight.main,
    borderRadius: "0px 0px 16px 0px",
    color: "#fff",
    textalign: "center",
  },
  specialMobile: {
    position: "absolute",
    padding: 16,
    paddingLeft: 24,
    paddingRight: 24,
    fontSize: 16,
    fontWeight: "bold",
    backgroundColor: theme.palette.highlight.main,
    borderRadius: "0px 0px 0px 16px",
    color: "#fff",
    textalign: "center",
    right: 0,
  },
  select: {
    width: 350,
  },
}));

export default function Product(props) {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:1000px)");
  let jwtToken = localStorage.getItem("clientToken");

  const dispatch = useDispatch();

  const { users, userAccount } = useSelector(selectUsers);
  const { cartList } = useSelector(selectCarts);
  const [uA, setUA] = useState("");

  useEffect(() => {
    if (users.loading === "loaded") {
      setUA(userAccount);
    }
  }, [users.loading]);

  const { wishlist } = useSelector(selectWishlist);
  const { product, relatedProducts, productLoaded, productGroupList } =
    useSelector(selectCoreProducts);

  // const [product, setProduct] = useState(null);
  const [imgSrc, setImgSrc] = useState("");
  const [hasMultipleImages, setHasMultipleImages] = useState(false);
  const [multiImageList, setMultiImageList] = useState([]);
  const [selectedImage, setSelectedImage] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [isFav, setIsFav] = useState(false);

  const [inCart, setIsInCart] = useState(
    isInCart(product.product && product.product.id, cartList.items)
  );
  useEffect(() => {
    setIsInCart(isInCart(product.product.id, cartList.items));
  }, [cartList, product]);

  useEffect(() => {
    if (product.product.hasOwnProperty("brandID")) {
      dispatch(
        fetchRelatedProductsByBrand({
          token: jwtToken,
          productID: product.product.id,
          brandIDs: product.product.brandID,
          catIDs: product.product.categories
            ? product.product.categories
                .filter((cat) => cat.mode === "manual")
                .map((cat) => cat.id)
            : [],
        })
      );
      dispatch(
        fetchProductsByGroup({
          token: jwtToken,
          groupID: product.product.groupID,
        })
      );
    } else if (!product.product || !product.product.hasOwnProperty("id")) {
      const name = window.location.pathname.split("/").reverse()[0];
      dispatch(fetchProductByName({ token: jwtToken, name: decodeURIComponent(name) }));
    }
  }, [productLoaded]);

  useEffect(() => {
    if (product.product) {
      // setProduct(product);
      setIsFav(product.product.isFav);
      let prodImage = product.product.images
        ? product.product.images.filter((image) =>
            image.objName.includes("825-0.")
          )[0]
        : "/placeholder.png";
      setHasMultipleImages(
        product.product.images ? product.product.images.length > 8 : false
      );
      // setMultiImageList(product.product.images ? product.product.images.filter(image => image.objName.includes('260-0.jpeg') || image.objName.includes('260-0.png')): [])
      setMultiImageList(
        product.product.images
          ? product.product.images.filter(
              (image) =>
                image.objName.includes("520-0.jpeg") ||
                image.objName.includes("520-0.png")
            )
          : []
      );
      setImgSrc(prodImage);
    } else {
    }
  }, [product]);

  useEffect(() => {
    let fav = wishlist.filter((prod) => prod.productID === product.product.id);
    setIsFav(fav.length);
  }, [wishlist]);

  const increaseQuantity = () => {
    setQuantity(quantity + 1);
  };
  const decreaseQuantity = () => {
    setQuantity(quantity === 1 ? 1 : quantity - 1);
  };

  const addToWishList = () => {
    if (uA.id != undefined) {
      dispatch(
        addProductToWishlist({
          token: jwtToken,
          prodID: product.product.id,
          id: uA.id,
        })
      );
    }
  };

  const toggleFavourite = () => {
    if (uA.id != undefined) {
      if (isFav) {
        dispatch(
          removeProductFromWishlist({
            token: jwtToken,
            prodID: product.product.id,
            id: uA.id,
          })
        );
      } else {
        const wishProd = {
          productID: product.product.id,
          productName: product.product.name,
          productPoints: product.product.special
            ? product.product.specialPoints
            : product.product.points,
          productImg: product.product.images[3].objName,
          statusID: 1,
        };
        dispatch(
          addProductToWishlist({
            token: jwtToken,
            id: uA.id,
            product: wishProd,
          })
        );
      }
    }
  };

  const addToCart = () => {
    const cartProd = {
      productID: product.product.id,
      productName: product.product.name,
      productPoints: product.product.special
        ? product.product.specialPoints
        : product.product.points,
      productImg: product.product.images ? imgSrc.objName : imgSrc,
      statusID: 1,
      quantity: quantity,
    };

    dispatch(
      addProductToCart({ token: jwtToken, id: uA.id, product: cartProd })
    );
  };

  const updateCartQuantity = () => {
    dispatch(
      updateProductQuantity({
        token: jwtToken,
        id: userAccount.id,
        productID: product.product.id,
        product: { quantity: Number(inCart.quantity + quantity) },
        showMessage: true,
      })
    );
  };

  const formatPTS = (str) => {
    return str ? str.toLocaleString() : "";
  };

  const handleChange = (e) => {
    if (e.target.value) {
      dispatch(
        setProduct({
          product: productGroupList.data.filter(
            (prod) => prod.id == e.target.value
          )[0],
          fav: isFav,
        })
      );
    }
  };

  const usePlaceholderStyles = makeStyles((theme) => ({
    placeholder: {
      color: theme.palette.primaryFont.main,
    },
  }));

  const Placeholder = ({ children }) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{children}</div>;
  };

  const handleSetImage = (e, image, index) => {
    // let selecting = document.querySelectorAll(".carousel-dot");
    // let matchingDot = selecting[index];
    // matchingDot.click();

    // const matchingImages = getMatchingImages(image);
    // let largerImage = matchingImages.filter((image) =>
    //   image.objName.includes("_520-0.png")
    // );
    // largerImage && setImgSrc(largerImage[0]);
    setSelectedImage(index);
  };

  const getMatchingImages = (image) => {
    const imageName = image.objName.split("images/")[1].split("_")[0];
    let matchingImages = product.product.images.filter((image) =>
      image.objName.includes(imageName)
    );
    return matchingImages;
  };

  const handlePreviousImage = () => {
    let index = selectedImage - 1;

    handleSetImage({}, multiImageList[index], index);
  };
  const handleNextImage = () => {
    let index = selectedImage + 1;
    handleSetImage({}, multiImageList[index], index);
  };

  const arrows = {
    left: (
      <div class="arrow-container-product">
        <img id="left-caret-product" src="/leftcaret.png" />
      </div>
    ),
    right: (
      <div class="arrow-container-product">
        <img id="right-caret-product" src="/rightcaret.png" />
      </div>
    ),
  };

  return product.product.hasOwnProperty("id") && productLoaded ? (
    <div className={classes.productContainer}>
      <div className={classes.productDetailsContainer}>
        <div className={classes.breadcrumbs}>
          <BreadCrumbs />
        </div>
        <Paper className={classes.productPaper} elevation={2}>
          {product.product.special && (
            <div className={matches ? classes.special : classes.specialMobile}>
              Special
            </div>
          )}
          {/* {matches && (
            <div className={classes.detailsTitle}></div>
          )} */}
          <div
            style={{
              marginBottom: matches ? 32 : 0,
              justifyContent: matches ? "flex-end" : "flex-start",
              paddingLeft: matches ? 0 : 16,
              paddingRight: matches ? 16 : 0,
            }}
            className={classes.detailsSKU}
          >
            SKU: {product.product.sku}
          </div>
          <div
            style={{
              flexFlow: matches ? "row nowrap" : "column wrap",
              margin: matches ? 0 : 16,
            }}
            className={classes.detailsContainer}
          >
            {!matches && (
              <div
                className={classes.colorItem}
                style={{ fontSize: 18, textAlign: "center" }}
              >
                <div
                  style={{
                    fontSize: 28,
                    marginBottom: 8,
                    marginTop: 16,
                    textAlign: "center",
                  }}
                  className={classes.detailsName}
                >
                  {product.product.name}
                </div>
                <div style={{ fontSize: 14, marginBottom: 8, marginTop: 18 }}>
                  {product.product.brandName}
                </div>

                {product.product.special && (
                  <div className={classes.specialStrikeout}>
                    {formatPTS(
                      product.product.points ? product.product.points : ""
                    )}{" "}
                    pts
                  </div>
                )}
                <div style={{ fontSize: 24 }} className={classes.detailsPoints}>
                  {formatPTS(
                    product.product.special
                      ? product.product.specialPoints
                      : product.product.points
                      ? product.product.points
                      : ""
                  )}{" "}
                  pts
                </div>
              </div>
            )}
            <div
              className={classes.imagesContainer}
              style={{
                flexDirection: matches ? "row-reverse" : "column",
              }}
            >
              <div className={classes.detailsImage} id={"details-image"}>
                <div>
                  {/* {hasMultipleImages && <IconButton  style={{marginRight: 8, backgroundColor: 'grey', opacity: 0.8}} disabled={selectedImage === 0} onClick={handlePreviousImage} aria-label="previous">
           
             
              <ArrowBackIosRoundedIcon  style={{fontSize: 20}}/>
            </IconButton>} */}
                  {hasMultipleImages ? (
                    <Carousel
                      swipeable={false}
                      selectedItem={selectedImage}
                      centerMode={true}
                      showStatus={false}
                      infiniteLoop={true}
                      showThumbs={false}
                      width={matches ? 500 : 300}
                    >
                      {/* <img src={matches ? '/abb-two.jpeg' : '/abb-mob-one.jpeg'} />
                  <img  src={matches ? '/abb-three.jpeg' : '/abb-mob-two.jpeg'} /> */}
                      {multiImageList.map((image) => (
                        <img
                          alt={image.altText}
                          src={
                            product.product.images
                              ? `${process.env.REACT_APP_PRODUCT_URL}/` +
                                image.objName
                              : image
                          }
                          style={{
                            //  maxHeight: matches ? 500 : 250,
                            //  maxWidth: matches ? 500 : "100%",
                            width: "100%",
                            height: "100%",
                            maxHeight: 250,
                            maxWidth: 250,
                            objectFit: "contain",
                          }}
                        />
                      ))}
                    </Carousel>
                  ) : (
                    <img
                      alt={imgSrc.altText}
                      src={
                        product.product.images
                          ? `${process.env.REACT_APP_PRODUCT_URL}/` +
                            imgSrc.objName
                          : imgSrc
                      }
                      style={{
                        maxHeight: matches ? 500 : 250,
                        maxWidth: matches ? 500 : "100%",
                        objectFit: "contain",
                      }}
                    />
                  )}
                  {/* {hasMultipleImages &&<IconButton style={{marginLeft: 8}} disabled={selectedImage === multiImageList.length -1} onClick={handleNextImage} aria-label="next">
                <ArrowForwardIosRoundedIcon style={{fontSize: 20}} />
              </IconButton>
          } */}
                </div>
              </div>
              <div
                className={classes.multiImageContainer}
                style={{
                  flexDirection: matches ? "column" : "row",
                  maxWidth: matches ? 860 : 460,
                }}
              >
                {hasMultipleImages &&
                  multiImageList.map((image, index) => (
                    <div
                      key={image.id}
                      className={classes.multiImageDiv}
                      style={{ opacity: selectedImage === index ? 1 : 1 }}
                      onClick={(e) => handleSetImage(e, image, index)}
                    >
                      {" "}
                      <img
                        alt={imgSrc.altText}
                        src={
                          product.product.images
                            ? `${process.env.REACT_APP_PRODUCT_URL}/` +
                              image.objName
                            : imgSrc
                        }
                        style={{
                          maxHeight: matches ? 100 : 100,
                          maxWidth: matches ? 100 : 100,
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  ))}
              </div>
            </div>

            <div
              style={{
                width: matches ? "30%" : "100%",
                marginTop: matches ? 0 : 24,
                boxSizing: "border-box",
                fontWeight: matches ? "bold" : "normal",
                paddingBottom: matches ? 50 : 0,
              }}
              className={classes.detailsInfo}
            >
              {matches && (
                <div>
                  <div className={classes.detailsName}>
                    {product.product.name}
                  </div>
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: 24,
                      marginBottom: 32,
                      marginTop: 16,
                    }}
                  >
                    {product.product.brandName}
                  </div>
                  {/* <div className={classes.detailsSKU}>SKU: {product.product.sku}</div> */}
                  {product.product.special && (
                    <div className={classes.specialStrikeout}>
                      {formatPTS(
                        product.product.points ? product.product.points : ""
                      )}{" "}
                      pts
                    </div>
                  )}
                  <div className={classes.detailsPoints}>
                    {formatPTS(
                      product.product.special
                        ? product.product.specialPoints
                        : product.product.points
                        ? product.product.points
                        : ""
                    )}{" "}
                    pts
                  </div>
                </div>
              )}
              {/* <div className={classes.detailsShortDesc}>
                {product.product.shortDescription}
              </div> */}
              {productGroupList.data.length > 1 && (
                <div>
                  {productGroupList.data.some(
                    (prod) =>
                      prod.groupCriteriaColour !== "" &&
                      prod.groupCriteriaColour !== null
                  ) && (
                    <div
                      className={classes.detailsGroupContainer}
                      style={{
                        display: "flex",
                        width: "100%",
                        padding: 10,
                        alignItems: "baseline",
                      }}
                    >
                      <div
                        style={{ textAlign: matches ? "left" : "center" }}
                        className={classes.detailsQuantityTitle}
                      >
                        Colour:
                      </div>
                      <div
                        style={{
                          justifyContent: matches ? "flex-start" : "center",
                        }}
                        className={classes.detailsGroupSelector}
                      >
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <Select
                            labelId="colour-select-label"
                            id="colour-select"
                            value={product.product.id}
                            onChange={handleChange}
                            classes={{ root: classes.select }}
                            displayEmpty
                          >
                            {/* <MenuItem className={classes.colorItem} value={""}>
                        None
                      </MenuItem> */}
                            {productGroupList.data
                              .filter(
                                (prod) => prod.groupCriteriaColour !== null
                              )
                              .map((product) => (
                                <MenuItem
                                  className={classes.colorItem}
                                  value={product.id}
                                >
                                  {product.groupCriteriaColour}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  )}
                  {productGroupList.data.some(
                    (prod) =>
                      prod.groupCriteriaSize !== "" &&
                      prod.groupCriteriaSize !== null
                  ) && (
                    <div
                      className={classes.detailsGroupContainer}
                      style={{
                        display: "flex",
                        width: "100%",
                        padding: 10,
                        alignItems: "baseline",
                      }}
                    >
                      <div
                        style={{ textAlign: matches ? "left" : "center" }}
                        className={classes.detailsQuantityTitle}
                      >
                        Size:
                      </div>
                      <div
                        style={{
                          justifyContent: matches ? "flex-start" : "center",
                        }}
                        className={classes.detailsGroupSelector}
                      >
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <Select
                            labelId="size-select-label"
                            id="size-select"
                            value={product.product.id}
                            onChange={handleChange}
                            classes={{ root: classes.select }}
                          >
                            {/* <MenuItem className={classes.colorItem} value={""}>
                        None
                      </MenuItem> */}
                            {productGroupList.data
                              .filter((prod) => prod.groupCriteriaSize !== null)
                              .map((product) => (
                                <MenuItem
                                  className={classes.colorItem}
                                  value={product.id}
                                >
                                  {product.groupCriteriaSize}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div
                className={classes.detailsQuantityContainer}
                style={{ justifyContent: matches ? "flex-start" : "center" }}
              >
                <div
                  style={{ textAlign: matches ? "left" : "center" }}
                  className={classes.detailsQuantityTitle}
                >
                  Quantity:
                </div>
                <div
                  style={{ justifyContent: matches ? "flex-start" : "center" }}
                  className={classes.detailsQuantitySelector}
                >
                  <div
                    onClick={decreaseQuantity}
                    className={classes.quantityMinus}
                  >
                    {"<"}
                  </div>
                  <div className={classes.quantity}>{quantity}</div>
                  <div
                    onClick={increaseQuantity}
                    className={classes.quantityPlus}
                  >
                    {">"}
                  </div>
                </div>
              </div>
              <div
                className={classes.detailsButtonsContainer}
                style={{
                  flexFlow: matches ? "row wrap" : "column nowrap",
                }}
              >
                <ColorButton
                  onClick={inCart.isInCart ? updateCartQuantity : addToCart}
                  style={{
                    marginRight: matches ? 24 : 0,
                  }}
                  startIcon={<ShoppingCartOutlinedIcon />}
                >
                  Add to Cart
                </ColorButton>
                <WishlistButton
                  onClick={toggleFavourite}
                  style={{
                    marginRight: matches ? 24 : 0,
                  }}
                  startIcon={<FavoriteBorderOutlinedIcon />}
                >
                  {isFav ? "Remove from Wishlist" : "Add to Wishlist"}
                </WishlistButton>
              </div>
            </div>
          </div>
        </Paper>
      </div>
      <div
        className={classes.productDescriptionContainer}
        style={{
          paddingLeft: matches ? 80 : 24,
          paddingRight: matches ? 80 : 24,
          paddingBottom: matches ? 24 : 0,
        }}
      >
        <div
          style={{
            fontSize: matches ? 48 : 32,
            textAlign: matches ? "left" : "center",
          }}
          className={classes.descriptionTitle}
        >
          Details
        </div>
        <div className={classes.descriptionText}>
          <ReactQuill
            value={product.product.longDescription}
            readOnly={true}
            theme={"bubble"}
            style={{ fontSize: 18, textAlign: matches ? "left" : "center" }}
          />
        </div>
      </div>
      <div className={classes.productRelatedContainer}>
        <div
          style={{ fontSize: matches ? 48 : 32 }}
          className={classes.relatedTitle}
        >
          Related Products
        </div>
        <div
          style={{
            paddingLeft: matches ? 30 : 0,
            paddingRight: matches ? 0 : 0,
          }}
          className={classes.relatedCardsContainer}
        >
          <Grid
            container
            spacing={3}
            direction="row"
            // justifyContent="space-between"
          >
            {relatedProducts &&
              relatedProducts.map((relatedProd) =>
                matches ? (
                  <ProductCard uA={uA} product={relatedProd} />
                ) : (
                  <MobileProductCard uA={uA} product={relatedProd} />
                )
              )}
          </Grid>
        </div>
      </div>
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        flexGrow: 1,
        margin: 60,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </div>
  );
}
