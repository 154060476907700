import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDistributors,
  getFullList as getFullDistributorsList,
  selectDistributors,
} from "../distributors/distributorsSlice";

import { fetchClient, selectClients } from "../clients/clientsSlice";

import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Datepicker from "../../components/Datepicker";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { replaceUrlParam } from "../../app/utils";
import { fetchPrograms, selectPrograms } from "../programs/programsSlice";

let suburbList = require(`./suburbs.json`);

const BackButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0, 2),
    backgroundColor: theme.palette.secondaryButton.main,
    color: theme.palette.secondaryButtonFont.main,
    cursor: "pointer",
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: theme.palette.secondaryButtonFont.main,
    borderRadius: 20,
    // width: 100,
    maxHeight: 38.5,
    "&:hover": {
      backgroundColor: `${theme.palette.secondaryButtonFont.main}2b`,
    },
    fontWeight: "bold",
  },
}))(Button);

const ContinueButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#fff",
    color: theme.palette.primaryFont.main,
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: theme.palette.primaryFont.main,
    borderRadius: 30,
    height: 40,
    fontSize: 20,
    width: 250,
    marginRight: 5,
    marginLeft: 5,
    "&:hover": {
      backgroundColor: "#DDD",
    },
    fontWeight: "bold",
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  stepTwoContainer: {
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  cardContainer: {
    backgroundColor: "#fff",
    borderRadius: 16,
    color: theme.palette.secondaryFont.main,

    padding: 32,
  },
  header: { fontWeight: "bold", marginBottom: 24 },
  title: {
    color: theme.palette.primaryFont.main,
    fontWeight: "bold",
    // marginBottom: 16,
    paddingTop: 8,
    textAlign: "center",
  },
  subTitle: {
    fontWeight: "bold",
    marginBottom: 8,
  },
  text: {
    marginBottom: 16,
  },

  progressContainer: {
    display: "flex",
    justifyContent: "center",
    margin: 40,
    marginTop: 16,
    maxWidth: 650,
    marginLeft: "auto",
    marginRight: "auto",
  },

  fileButton: {
    margin: theme.spacing(1, 0, 2),
    padding: 10,
    backgroundColor: theme.palette.secondaryButton.main,
    color: theme.palette.secondaryButtonFont.main,
    cursor: "pointer",
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: theme.palette.secondaryButtonFont.main,
    borderRadius: 20,
    // width: 100,
    maxHeight: 38.5,
    "&:hover": {
      backgroundColor: `${theme.palette.secondaryButtonFont.main}2b`,
    },
    fontWeight: "bold",
  },

  continueContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 0,
  },
  buttonContainer: {
    marginLeft: 26,
    position: "absolute",
  },
  textLabel: {
    fontSize: 14,
    fontWeight: "bold",
    // marginBottom: 18,
    // marginTop: 28,
  },
  invoiceDetails: {},

  productListItem: {
    display: "flex",
    paddingBottom: 36,
  },
  productName: {
    flexGrow: 1,
  },
  quantity: {
    marginRight: 32,
    fontWeight: "bold",
    // fontSize: 26,
    fontSize: 18,
  },
  quantityBox: {
    display: "flex",
    flexFlow: "row nowrap",
    border: "2px solid #DDD",
    borderRadius: 16,
    height: 50,
    width: 75,
    padding: 4,
  },
  numberInput: {
    display: "flex",
    flexFlow: "row nowrap",
    border: "2px solid #DDD",
    borderRadius: 16,
    height: 50,
    width: 75,
    padding: 4,
  },
  quantityAmount: {
    color: theme.palette.primaryFont.main,
    height: 50,
    width: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    textAlign: "center",
    padding: 0,
  },
  quantityControls: {
    borderLeft: "2px solid #DDD",
    height: 50,
    width: 25,
  },
  quantityIncrease: {
    borderBottom: "2px solid #DDD",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 25,
    width: 25,
  },
  quantityDecrease: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 25,
    width: 25,
  },

  unitPrice: {
    width: 200,
    marginRight: 32,
  },
  unitGST: { width: 150, marginRight: 16 },
  productSubTotal: {
    marginRight: 32,
  },
  remove: {
    // marginRight: 32,
    display: "flex",
    flexFlow: "column nowrap",
  },
  removeIcon: {
    alignItems: "center",
    display: "flex",
    paddingTop: 12,
  },

  textItem: {
    color: theme.palette.primaryFont.main,
  },

  addAndPointsContainer: {
    display: "flex",
    paddingBottom: 32,
    paddingTop: 32,
  },
  addContainer: {
    display: "flex",
    flexFlow: "row wrap",
    width: "50%",
  },
  pointsContainer: {
    boxSizing: "border-box",
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-between",
    paddingRight: 187,
    width: "50%",
  },
  values: {
    color: theme.palette.primaryFont.main,
    fontWeight: "bold",
    fontSize: 18,
    paddingTop: 4,
  },
  progressBackground: {
    backgroundColor: theme.palette.highlight.main,
    borderRadius: 20,
  },
  icon: {
    fontSize: 50,
    color: theme.palette.highlight.main,
    cursor: "pointer",
  },
  asterisk: {
    color: "red",
  },
}));

export default function ClaimStepTwo(props) {
  const {
    setStep,
    clientProductsList,
    claimProducts,
    invoiceTotal,
    town,
    handleUpload,
    fileInputRef,
    claims,
    setClaims,
    files,
    setFiles,
    // setUpdatedPoints,
  } = props;

  const { programsList } = useSelector(selectPrograms);
  const { client } = useSelector(selectClients);

  const firstUpdate = useRef(true);

  useEffect(() => {
    if (!programsList.length) {
      dispatch(
        fetchPrograms({
          token: jwtToken,
          query: `?limit=200&statuses=1`,
        })
      );
    }
    if (!distributorsList.length) {
      dispatch(
        fetchDistributors({
          token: jwtToken,
          query: `?limit=100`,
        })
      );
    }
    if (!client.id) {
      dispatch(
        fetchClient({
          token: jwtToken,
        })
      );
    }
  }, []);

  const dispatch = useDispatch();
  // const [productList, setProductList] = useState(
  //   claimProducts.length
  //     ? claimProducts
  //     : [
  //         {
  //           id: 0,
  //           name: "",
  //           price: "",
  //           priceExGst: "",
  //           quantity: 1,
  //           gst: Number(process.env.REACT_APP_GST).toFixed(2),
  //           subTotal: (0.0).toFixed(2),
  //         },
  //       ]
  // );
  const classes = useStyles();
  let jwtToken = localStorage.getItem("clientToken");

  const [suburbs, setSuburbs] = useState([]);
  const [showStore, setShowStore] = useState(
    process.env.REACT_APP_SHOW_STORE_DISTRIBUTOR === "true"
  );

  const [claimIndex, setClaimIndex] = useState(0);

  const { distributorsList, distributorsAmount } =
    useSelector(selectDistributors);

  const matches = useMediaQuery("(min-width:850px)");

  function removeItemWithSlice(index, items) {
    return [...items.slice(0, index), ...items.slice(index + 1)];
  }

  const handleRemove = (e, index) => {
    if (claims[claimIndex].claimProducts.length > 1) {
      const newArr = removeItemWithSlice(
        index,
        claims[claimIndex].claimProducts
      );
      setClaims((prevClaims) =>
        prevClaims.map((claim, index) => {
          if (index === claimIndex) {
            return {
              ...claim,
              claimProducts: newArr,
            };
          } else {
            return claim;
          }
        })
      );
    } else {
      const newArr = [
        {
          id: 0,
          name: "",
          price: "",
          priceExGst: "",
          quantity: 1,
          gst: Number(process.env.REACT_APP_GST).toFixed(2),
          subTotal: (0.0).toFixed(2),
        },
      ];
      setClaims((prevClaims) =>
        prevClaims.map((claim, index) => {
          if (index === claimIndex) {
            return {
              ...claim,
              claimProducts: newArr,
            };
          } else {
            return claim;
          }
        })
      );
    }
  };

  const handleAddProduct = () => {
    setClaims((prevClaims) =>
      prevClaims.map((claim, index) => {
        if (index === claimIndex) {
          return {
            ...claim,
            claimProducts: [
              ...claim.claimProducts,
              {
                idx: claims[claimIndex].claimProducts.length + 1,
                name: "",
                price: "",
                quantity: 1,
                gst: Number(process.env.REACT_APP_GST).toFixed(2),
                subTotal: (0.0).toFixed(2),
              },
            ],
          };
        } else {
          return claim;
        }
      })
    );
    // setProductList(
    //   productList.concat([
    //     {
    //       idx: productList.length + 1,
    //       name: "",
    //       price: "",
    //       quantity: 1,
    //       gst: Number(process.env.REACT_APP_GST).toFixed(2),
    //       subTotal: (0.0).toFixed(2),
    //     },
    //   ])
    // );
  };

  const updateName = (prod, index) => {
    if (prod) {
      let newArr = [...claims[claimIndex].claimProducts]; // copying the old array
      newArr[index].name = prod.name;
      newArr[index].id = prod.id;
      newArr[index].points = prod.points;
      // setProductList(newArr);
      setClaims((prevClaims) =>
        prevClaims.map((claim, index) => {
          if (index === claimIndex) {
            return {
              ...claim,
              claimProducts: newArr,
            };
          } else {
            return claim;
          }
        })
      );
    }
  };

  const incQuantity = (e, index) => {
    let newArr = [...claims[claimIndex].claimProducts]; // copying the old array
    newArr[index].quantity = newArr[index].quantity + 1;
    newArr[index].subTotal =
      Number(newArr[index].price).toFixed(2) * newArr[index].quantity;
    setClaims((prevClaims) =>
      prevClaims.map((claim, index) => {
        if (index === claimIndex) {
          return {
            ...claim,
            claimProducts: newArr,
          };
        } else {
          return claim;
        }
      })
    );
  };

  // newArr[index].subTotal =
  //     (Number(newArr[index].price) + Number(newArr[index].gst)).toFixed(2) *
  //     newArr[index].quantity;

  const decQuantity = (e, index) => {
    let newArr = [...claims[claimIndex].claimProducts]; // copying the old array
    if (newArr[index].quantity === 1) {
      handleRemove(e, index);
    } else {
      newArr[index].quantity = newArr[index].quantity - 1;
      newArr[index].subTotal =
        Number(newArr[index].price).toFixed(2) * newArr[index].quantity;
      setClaims((prevClaims) =>
        prevClaims.map((claim, index) => {
          if (index === claimIndex) {
            return {
              ...claim,
              claimProducts: newArr,
            };
          } else {
            return claim;
          }
        })
      );
    }
  };

  const updateQuantity = (e, index) => {
    let newArr = [...claims[claimIndex].claimProducts]; // copying the old array
    newArr[index].quantity = Number(e.target.value);
    newArr[index].subTotal =
      Number(newArr[index].price).toFixed(2) * e.target.value;
    setClaims((prevClaims) =>
      prevClaims.map((claim, index) => {
        if (index === claimIndex) {
          return {
            ...claim,
            claimProducts: newArr,
          };
        } else {
          return claim;
        }
      })
    );
  };

  const updatePrice = (e, index, value, gst) => {
    let newArr = [...claims[claimIndex].claimProducts]; // copying the old array
    newArr[index].gst = process.env.REACT_APP_GST;
    if (gst) {
      newArr[index].price = value;
      newArr[index].priceExGst = Number(
        Number(value) - Number(value) * Number(process.env.REACT_APP_GST)
      )
        .toFixed(2)
        .toString();
    } else {
      newArr[index].price = Number(
        Number(value) + Number(value) * Number(process.env.REACT_APP_GST)
      ).toFixed(2);
      newArr[index].priceExGst = value;
    }
    newArr[index].subTotal = newArr[index].price * newArr[index].quantity;
    setClaims((prevClaims) =>
      prevClaims.map((claim, index) => {
        if (index === claimIndex) {
          return {
            ...claim,
            claimProducts: newArr,
          };
        } else {
          return claim;
        }
      })
    );
  };

  const handleContinue = () => {
    setStep(4);
  };

  const getTotal = () => {
    return claims[claimIndex].claimProducts.reduce(
      (accum, item) => Number(accum) + Number(item.subTotal),
      0
    );
  };

  const handleChange = (distributor) => {
    // setStore(distributor ? distributor.id : "");
    setClaims((prevClaims) =>
      prevClaims.map((claim, index) => {
        if (index === claimIndex) {
          return {
            ...claim,
            store: distributor,
          };
        } else {
          return claim;
        }
      })
    );
  };

  // const handleTownSelect = (value) => {
  //   // setTown(value ? value.name : "");
  //   setClaims((prevClaims) =>
  //     prevClaims.map((claim, index) => {
  //       if (index === claimIndex) {
  //         return {
  //           ...claim,
  //           town: value,
  //         };
  //       } else {
  //         return claim;
  //       }
  //     })
  //   );
  // };

  useEffect(() => {
    if (claims[claimIndex].claimProducts) {
      if (claims[claimIndex].claimProducts[0].points > 0) {
        setClaims((prevClaims) =>
          prevClaims.map((claim, index) => {
            if (index === claimIndex) {
              return {
                ...claim,
                invoiceTotal: claims[claimIndex].claimProducts
                  .map((item) => item.points * item.quantity)
                  .reduce((prev, next) => prev + next),
              };
            } else {
              return claim;
            }
          })
        );
      } else {
        setClaims((prevClaims) =>
          prevClaims.map((claim, index) => {
            if (index === claimIndex) {
              return {
                ...claim,
                invoiceTotal: claims[claimIndex].claimProducts
                  .map((item) => Math.floor(item.price * item.quantity))
                  .reduce((prev, next) => prev + next),
              };
            } else {
              return claim;
            }
          })
        );
      }
    }
  }, [claims[claimIndex].claimProducts]);

  useEffect(() => {
    if (
      distributorsList.length &&
      distributorsList.length != distributorsAmount &&
      firstUpdate.current
    ) {
      let searchQuery = "?limit=100";
      searchQuery = replaceUrlParam(searchQuery, "limit", 100);
      dispatch(
        getFullDistributorsList({
          token: jwtToken,
          offset: distributorsList.length,
          query: searchQuery,
          targetLength: distributorsAmount,
        })
      );
      firstUpdate.current = false;
    }
  }, [distributorsList.length]);

  // useEffect(() => {
  //   let newsuburbs = []; // copying the old array
  //   suburbList.data.forEach((suburb) => {
  //     if (
  //       distributorsList.some(
  //         (dist) =>
  //           dist.streetAddress.suburb.toLowerCase() ===
  //           suburb.name.toLowerCase()
  //       )
  //     ) {
  //       newsuburbs.push(suburb);
  //     }
  //   });
  //   setSuburbs(newsuburbs);
  // }, [distributorsList]);

  const filterOptions = (options, { inputValue }) => {
    let inputArr = inputValue.split(" ");

    let filterResult = [];
    inputArr.map((inputTerm, index) => {
      if (index === 0) {
        let results = options.filter((item) =>
          item.name.toLowerCase().includes(inputTerm.toLowerCase())
        );
        filterResult = results;
      } else {
        let results = filterResult.filter((item) =>
          item.name.toLowerCase().includes(inputTerm.toLowerCase())
        );
        filterResult = results;
      }
    });
    var result = [];
    filterResult.forEach(function (item) {
      if (result.indexOf(item) < 0) {
        result.push(item);
      }
    });

    return result;
  };

  return (
    <div
      // style={{width: matches ? '50%' : '100%'}}
      className={classes.stepTwoContainer}
    >
      <Paper className={classes.cardContainer} elevation={4}>
        <form onSubmit={handleContinue}>
          {matches && (
            <div className={classes.buttonContainer}>
              <BackButton
                onClick={() => {
                  setStep(0);
                }}
              >
                Go Back
              </BackButton>
            </div>
          )}
          <Typography className={classes.title} variant="h5">
            Purchase Info
          </Typography>

          <div className={classes.progressContainer}>
            <img
              src={matches ? "/steptwo.png" : "/mobiletwo.png"}
              width={matches ? "50%" : "90%"}
              alt="empty progress bar"
              className={classes.progressBackground}
            />
          </div>
          <Divider style={{ height: 2, marginBottom: 10 }} />
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 10,
                marginRight: 30,
              }}
            >
              <ChevronLeftIcon
                style={{
                  borderWidth: 1,
                  borderRadius: 20,
                  borderStyle: "solid",
                  margin: 5,
                  cursor: "pointer",
                }}
                onClick={() =>
                  setClaimIndex((prevIndex) => {
                    if (prevIndex - 1 >= 0) {
                      return prevIndex - 1;
                    } else {
                      return prevIndex;
                    }
                  })
                }
              />
              <span>
                Claim {claimIndex + 1} of {claims.length}
              </span>
              <ChevronRightIcon
                style={{
                  borderWidth: 1,
                  borderRadius: 20,
                  borderStyle: "solid",
                  margin: 5,
                  cursor: "pointer",
                }}
                onClick={() =>
                  setClaimIndex((prevIndex) => {
                    if (prevIndex + 2 <= claims.length) {
                      return prevIndex + 1;
                    } else {
                      return prevIndex;
                    }
                  })
                }
              />
            </div>

            <CloseOutlinedIcon
              style={{
                borderWidth: 1,
                borderRadius: 20,
                borderStyle: "solid",
                margin: 5,
                cursor: "pointer",
              }}
              onClick={() => {
                if (claims.length > 1) {
                  setClaims((prevClaims) => {
                    let newArr = removeItemWithSlice(claimIndex, prevClaims);
                    return newArr;
                  });
                  setFiles((prevFiles) => {
                    let newArr = removeItemWithSlice(claimIndex, prevFiles);
                    return newArr;
                  });
                  setClaimIndex((prevIndex) => prevIndex - 1);
                }
              }}
            />
          </div>
          <Divider style={{ height: 2, marginBottom: 10 }} />
          {showStore && (
            <Typography className={classes.title} variant="h5">
              Store and Location
            </Typography>
          )}
          {showStore && (
            <Divider style={{ height: 2, marginBottom: 10, marginTop: 10 }} />
          )}
          {/* {showStore && (
            <div style={{ padding: 10 }}>
              <div className={classes.textLabel}>Store Town/Suburb* </div>

              <Autocomplete
                onChange={(event, town) => {
                  handleTownSelect(town);
                }}
                id={`combo-box-suburb`}
                options={suburbs}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name ? option.name + " - " + option.state : ""}
                value={claims[claimIndex].town}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} margin="dense" variant="outlined" />
                )}
              />
              <Typography
                // style={{marginTop: 24}}
                className={classes.text}
                variant="body1"
              >
                Type the name of the suburb where you purchased the product(s).
                If you do not see your suburb listed, please contact us on 1800
                855 326.{" "}
              </Typography>
            </div>
          )} */}
          {showStore && (
            <div style={{ padding: 10 }}>
              <div className={classes.textLabel}>Store Name* </div>
              <div>
                <Autocomplete
                  onChange={(event, store) => {
                    handleChange(store);
                  }}
                  id={`combo-box-distributor`}
                  options={distributorsList}
                  // options={
                  //   claims[claimIndex].town
                  //     ? distributorsList.filter(
                  //         (store) =>
                  //           store.streetAddress.suburb.toLowerCase() ===
                  //           claims[claimIndex].town.name.toLowerCase()
                  //       )
                  //     : distributorsList
                  // }
                  getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option.name}
                  value={claims[claimIndex].store}
                  style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      variant="outlined"
                      required
                    />
                  )}
                />
              </div>
              <Typography
                style={{ marginTop: 24, marginBottom: 0 }}
                className={classes.text}
                variant="body1"
              >
                Select from the list of stores in that suburb.
              </Typography>
              <Typography
                style={{ marginTop: 8 }}
                className={classes.text}
                variant="body1"
              >
                If you do not see your store listed, please contact us on 1800
                855 326.
              </Typography>
            </div>
          )}
          {showStore && <Divider style={{ height: 2 }} />}
          <div
            style={{
              width: matches ? "50%" : "100%",
            }}
            className={classes.invoiceDetails}
          >
            <div>
              <div
                style={{
                  marginTop: matches ? 28 : 16,
                  marginBottom: matches ? 8 : 0,
                }}
                className={classes.textLabel}
              >
                Invoice Date*{" "}
              </div>
              <Datepicker
                invoiceDate={claims[claimIndex].invoiceDate}
                setInvoiceDate={(e) =>
                  setClaims((prevClaims) =>
                    prevClaims.map((claim, index) => {
                      if (index === claimIndex) {
                        return { ...claim, invoiceDate: e };
                      } else {
                        return claim;
                      }
                    })
                  )
                }
              />
            </div>

            <div>
              <div
                style={{
                  marginTop: matches ? 28 : 16,
                  marginBottom: matches ? 8 : 0,
                }}
                className={classes.textLabel}
              >
                Invoice Number*{" "}
              </div>
              <TextField
                className={classes.textItem}
                variant="outlined"
                margin="dense"
                id="invoice-number"
                type="text"
                fullWidth
                required
                value={claims[claimIndex].invoiceNumber}
                onChange={(e) =>
                  setClaims((prevClaims) =>
                    prevClaims.map((claim, index) => {
                      if (index === claimIndex) {
                        return { ...claim, invoiceNumber: e.target.value };
                      } else {
                        return claim;
                      }
                    })
                  )
                }
              />
              <Typography
                style={{ marginTop: 24 }}
                className={classes.text}
                variant="body1"
              >
                Please enter details of the product on your invoice.
              </Typography>
            </div>
          </div>
          <Divider style={{ height: 2 }} />
          {claims[claimIndex].claimProducts.map((product, index) => (
            <div
              style={{ flexDirection: matches ? "row" : "column" }}
              className={classes.productListItem}
              key={product.idx}
            >
              <div
                style={{
                  marginRight: matches ? 32 : 0,
                }}
                className={classes.productName}
              >
                <div
                  style={{
                    marginTop: matches ? 28 : 16,
                    marginBottom: matches ? 8 : 0,
                  }}
                  className={classes.textLabel}
                >
                  Product<span className={classes.asterisk}>*</span>{" "}
                </div>
                {clientProductsList.length && (
                  <Autocomplete
                    onChange={(event, product) => {
                      updateName(product, index);
                    }}
                    id={`combo-box-products`}
                    options={clientProductsList.length && clientProductsList}
                    getOptionSelected={(option, value) =>
                      option.name === value.name
                    }
                    filterOptions={filterOptions}
                    getOptionLabel={(option) => option.name}
                    value={claims[claimIndex].claimProducts[index]}
                    style={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        margin="dense"
                        variant="outlined"
                      />
                    )}
                  />
                )}
              </div>
              <div className={classes.quantity}>
                <div
                  style={{
                    marginTop: matches ? 28 : 16,
                    marginBottom: matches ? 8 : 0,
                  }}
                  className={classes.textLabel}
                >
                  Quantity<span className={classes.asterisk}>*</span>{" "}
                </div>
                <div className={classes.quantityBox}>
                  <input
                    type="text"
                    onChange={(e) => {
                      updateQuantity(e, index);
                    }}
                    required
                    value={product.quantity}
                    className={classes.quantityAmount}
                  />
                  <div className={classes.quantityControls}>
                    <div className={classes.quantityIncrease}>
                      {" "}
                      <img
                        // onClick={increaseQuantity}
                        onClick={(e) => incQuantity(e, index)}
                        src={"/upCaret.png"}
                        alt="increase quantity"
                      />
                    </div>
                    <div className={classes.quantityDecrease}>
                      {" "}
                      <img
                        // onClick={decreaseQuantity}
                        onClick={(e) => decQuantity(e, index)}
                        src={"/downCaret.png"}
                        alt="decrease quantity"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {product.points <= 0 && (
                <div className={classes.unitPrice}>
                  <div
                    style={{
                      marginTop: matches ? 28 : 16,
                      marginBottom: matches ? 8 : 0,
                    }}
                    className={classes.textLabel}
                  >
                    Unit Price (incl GST)
                    <span className={classes.asterisk}>*</span>{" "}
                  </div>
                  {/* <input
                  key={product.id}
                  className={classes.numberInput}
                  id="invoice-number"
                  type="number"
                  pattern="^\d*(\.\d{0,2})?$"
                  fullWidth
                  // value={productList[index].price}
                  value={Number(productList[index].price).toFixed(2)}
                  onChange={(e) => updatePrice(e, index, e.target.value, true)}
                /> */}
                  <TextField
                    value={
                      claims[claimIndex].claimProducts[index].price &&
                      claims[claimIndex].claimProducts[index].price
                    }
                    variant="outlined"
                    margin="dense"
                    id="unit-price"
                    required
                    // label="($) Unit Price"
                    name="unit-price"
                    onChange={(e) =>
                      updatePrice(e, index, e.target.value, true)
                    }
                  />
                </div>
              )}
              {product.points <= 0 && (
                <div className={classes.unitPrice}>
                  <div
                    style={{
                      marginTop: matches ? 28 : 16,
                      marginBottom: matches ? 8 : 0,
                    }}
                    className={classes.textLabel}
                  >
                    Unit Price (excl GST)
                    <span className={classes.asterisk}>*</span>{" "}
                  </div>
                  {/* <input
                  key={product.id}
                  className={classes.numberInput}
                  id="invoice-number"
                  type="number"
                  pattern="^\d*(\.\d{0,2})?$"
                  fullWidth
                  value={Number(productList[index].priceExGst).toFixed(2)}
                  onChange={(e) => updatePrice(e, index, e.target.value, false)}
                /> */}
                  <TextField
                    value={
                      claims[claimIndex].claimProducts[index].priceExGst &&
                      claims[claimIndex].claimProducts[index].priceExGst
                    }
                    variant="outlined"
                    margin="dense"
                    id="unit-price"
                    required
                    // label="($) Unit Price (Ex"
                    name="unit-price"
                    onChange={(e) =>
                      updatePrice(e, index, e.target.value, false)
                    }
                  />
                </div>
              )}
              {/* <div className={classes.unitGST}>
              <div
                style={{
                  marginTop: matches ? 28 : 16,
                  marginBottom: matches ? 8 : 0,
                }}
                className={classes.textLabel}
              >
                Unit GST{' '}
              </div>
              <div className={classes.values}>
                ${Number(product.gst).toFixed(2)}
              </div>
            </div> */}
              {product.points <= 0 && (
                <div className={classes.productSubTotal}>
                  <div
                    style={{
                      marginTop: matches ? 28 : 16,
                      marginBottom: matches ? 8 : 0,
                    }}
                    className={classes.textLabel}
                  >
                    Subtotal (inc GST)
                  </div>
                  <div className={classes.values} style={{ lineHeight: 2.5 }}>
                    ${Number(product.subTotal).toFixed(2)}
                  </div>
                </div>
              )}
              <div
                style={{
                  alignItems: "flex-start",
                }}
                className={classes.remove}
              >
                <div
                  style={{
                    marginTop: matches ? 28 : 16,
                    marginBottom: matches ? 8 : 0,
                    visibility: "hidden",
                  }}
                  className={classes.textLabel}
                >
                  Remove Item
                </div>
                <div className={classes.removeIcon}>
                  {/* <img
                    onClick={(e) => handleRemove(e, index)}
                    style={{ cursor: "pointer", marginRight: 16 }}
                    src={`/cross.png`}
                  /> */}
                  <CloseOutlinedIcon
                    onClick={(e) => handleRemove(e, index)}
                    className={classes.icon}
                  />
                </div>
              </div>
            </div>
          ))}
          <Divider style={{ height: 2 }} />
          <div
            style={{
              flexFlow: matches ? "row wrap" : "column wrap",
            }}
            className={classes.addAndPointsContainer}
          >
            <div className={classes.addContainer}>
              <div>
                <BackButton
                  style={{
                    width: matches ? 300 : 240,
                    marginRight: matches ? 16 : 0,
                    marginTop: 0,
                  }}
                  onClick={handleAddProduct}
                >
                  Add Another Product
                </BackButton>
              </div>
            </div>
            <div
              style={{
                paddingRight: matches ? 187 : 0,
                width: matches ? "50%" : "100%",
              }}
              className={classes.pointsContainer}
            >
              <div className={classes.estPoint}>
                <div
                  style={{
                    marginBottom: matches ? 8 : 0,
                    marginTop: 0,
                  }}
                  className={classes.textLabel}
                >
                  Estimated Point Value
                </div>
                <div className={classes.values}>
                  {claims[claimIndex].invoiceTotal &&
                  claims[claimIndex].claimProducts[0].points > 0
                    ? Math.floor(claims[claimIndex].invoiceTotal)
                    : client.monetaryDenominator && Math.floor(
                        (claims[claimIndex].invoiceTotal * client.pointNumerator) / client.monetaryDenominator
                      )}
                </div>
              </div>
              {claims[claimIndex].claimProducts.some(
                (product, index) => product.points <= 0
              ) && (
                <div className={classes.total}>
                  <div
                    style={{
                      marginBottom: matches ? 8 : 0,
                      marginTop: 0,
                    }}
                    className={classes.textLabel}
                  >
                    Total (inc GST)
                  </div>
                  <div className={classes.values}>
                    $
                    {claims[claimIndex].claimProducts &&
                      claims[claimIndex].claimProducts
                        .map((item) => item.price * item.quantity)
                        .reduce((prev, next) => prev + next)
                        .toFixed(2)}
                  </div>
                </div>
              )}
            </div>
          </div>
          <Divider style={{ height: 2 }} />
          <div
            style={{
              marginTop: matches ? 28 : 16,
              marginBottom: matches ? 8 : 0,
            }}
            className={classes.textLabel}
          >
            Upload Supporting Document
            <span className={classes.asterisk}>*</span>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            {/* Hide and disable file input, might show required prompt and can use label to display file name */}

            <input
              ref={fileInputRef}
              style={{
                opacity: 0,
                zIndex: -1,
                width: 0,
                margin: 0,
                padding: 0,
              }}
              accept=".pdf,.jpeg,.jpg,.png"
              className={classes.fileButton}
              required
              id={`contained-button-file-${claimIndex}`}
              // multiple
              onChange={() => {
                handleUpload(claimIndex);
              }}
              type="file"
            />
            <label htmlFor={`contained-button-file-${claimIndex}`}>
              <BackButton
                style={{
                  width: matches ? 300 : 240,
                  marginTop: 5,
                  marginBottom: 5,
                }}
                onClick={() => fileInputRef.current.click()}
              >
                Upload File
              </BackButton>
            </label>
            <span style={{ padding: 10, fontSize: 14 }}>
              {files[claimIndex].name}
            </span>
          </div>
          <div>
            <Typography className={classes.text} variant="body1">
              Upload a copy of the invoice as proof of purchase in pdf, png or
              jpeg format. A statement is not a valid invoice. Maximum file size
              8MB.
            </Typography>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                margin: matches ? 0 : 0,
              }}
              className={classes.continueContainer}
            >
              {programsList[0] && (
                <ContinueButton
                  disabled={
                    claims[claimIndex].invoiceTotal * 100 <
                      programsList[0].minClaimAmount ||
                    claims.length != files.length ||
                    files.some(
                      (obj) =>
                        obj && // 👈 null and undefined check
                        Object.keys(obj).length === 0 &&
                        Object.getPrototypeOf(obj) === Object.prototype
                    )
                  }
                  type="submit"
                >
                  Continue
                </ContinueButton>
              )}
            </div>
            <div
              style={{
                margin: matches ? 0 : 0,
              }}
              className={classes.continueContainer}
            >
              <ContinueButton
                disabled={
                  claims.filter((claim, index) => !files[index].name).length
                }
                onClick={() => {
                  setClaims((prevClaims) => [
                    ...prevClaims,
                    {
                      invoiceTotal: 0,
                      invoiceURL: "",
                      invoiceNumber: "",
                      invoiceDate: "",
                      invoiceType: "",
                      claimProducts: [
                        {
                          id: 0,
                          name: "",
                          price: "",
                          priceExGst: "",
                          quantity: 1,
                          gst: Number(process.env.REACT_APP_GST).toFixed(2),
                          subTotal: (0.0).toFixed(2),
                        },
                      ],
                      town: "",
                      store: "",
                    },
                  ]);
                  setFiles((prevFiles) => [...prevFiles, {}]);
                  setClaimIndex(claims.length);
                  window.scrollTo(0, 0);
                }}
              >
                Add Another Claim
              </ContinueButton>
            </div>
          </div>
          {programsList[0] &&
            claims[claimIndex].invoiceTotal * 100 <
              programsList[0].minClaimAmount &&
            programsList[0].minClaimAmount > 0 && (
              <Typography
                className={classes.text}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "red",
                }}
                variant="body1"
              >
                {`Mininum claim amount of $${
                  programsList[0].minClaimAmount / 100
                } has not been reached.`}
              </Typography>
            )}
        </form>
      </Paper>
    </div>
  );
}
