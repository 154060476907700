import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

import { errorSnack, successSnack } from "../snackbar/snackbarSlice";

import axios from "axios";

import { uuidv4 } from "../../app/utils";
import baseURL from "../../app/utils.js";
axios.interceptors.request.use(
  (req) => {
    req.headers.common["X-Request-ID"] = uuidv4();
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export const fetchDistributors = createAsyncThunk(
  "distributors/fetchDistributors",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/distributors${args.query}`,
        config
      );
      thunkAPI.dispatch(
        setDistributorsAmount(response.headers["x-total-count"])
      );
      thunkAPI.dispatch(setLastQuery(args.query));
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching Distributors list"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchMoreDistributors = createAsyncThunk(
  "distributors/fetchMoreDistributors",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/distributors${args.query}&offset=${args.offset}`,
        config
      );
      thunkAPI.dispatch(
        setDistributorsAmount(response.headers["x-total-count"])
      );
      return await response.data;
    } catch (error) {
      if (error.message.includes("401")) {
        localStorage.removeItem("clientUser");
        localStorage.removeItem("clientToken");
      }
      if (error.response && error.response.data) {
        // thunkAPI.dispatch(
        //   errorSnack(
        //     error.response.data.error_description ||
        //       'Error fetching products list'
        //   )
        // );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchDistributorByID = createAsyncThunk(
  "distributors/fetchDistributorByID",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/admin/distributors/${args.id}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.message.includes("401")) {
        localStorage.removeItem("clientUser");
        localStorage.removeItem("clientToken");
      }
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching distributor data"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const createDistributor = createAsyncThunk(
  "distributors/createDistributor",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //full options
      const response = await axios.post(
        `${baseURL}/v1/admin/distributors`,
        args.distributor,
        config
      );
      thunkAPI.dispatch(successSnack("Distributor created successfully"));
      thunkAPI.dispatch(
        fetchDistributors({ token: args.token, query: "?limit=25" })
      );

      return await response;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error creating distributor"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const updateDistributor = createAsyncThunk(
  "distributors/updateDistributor",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //full options
      const response = await axios.patch(
        `${baseURL}/v1/admin/distributors/${args.id}`,
        args.distributor,
        config
      );
      thunkAPI.dispatch(
        fetchDistributors({ token: args.token, query: "?limit=25" })
      );
      // thunkAPI.dispatch(fetchDistributorByID({ token: args.token, id: args.id }));

      // thunkAPI.dispatch(successSnack('Distributor updated'));
      thunkAPI.dispatch(
        successSnack("Distributor details updated successfully")
      );

      return await response;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error updating distributor"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const deleteDistributor = createAsyncThunk(
  "distributors/deleteDistributor",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //full options
      const response = await axios.delete(
        `${baseURL}/v1/admin/distributors/${args.id}`,
        config
      );
      thunkAPI.dispatch(successSnack("Distributor deleted successfully"));
      return await response;
    } catch (error) {
      if (error.message.includes("401")) {
        localStorage.removeItem("clientUser");
        localStorage.removeItem("clientToken");
      }
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error deleting distributor"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const preSignedURL = createAsyncThunk(
  "distributors/preSignedURL",
  async (args, thunkAPI) => {
    const config = {
      headers: {
        Authorization: `Bearer ${args.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      //full options
      const response = await axios.post(
        `${baseURL}/v1/admin/pre_signed_url`,

        {
          objectName: args.objectName,
          objectType: args.objectType,
          objectContentType: args.contentType,
          download: args.download,
        },
        config
      );
      if (response.data.preSignedURL && !args.download) {
        thunkAPI.dispatch(
          uploadCSV({
            token: args.token,
            csvFile: args.csvFile,
            url: response.data.preSignedURL,
            contentType: args.contentType,
            localization: args.localization,
          })
        );
      }
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error creating pre-signed url for AWS upload"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const uploadCSV = createAsyncThunk(
  "distributors/uploadCSV",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: {
          // Authorization: `Bearer ${args.token}`,
          "Content-Type": args.contentType,
          "Access-Control-Allow-Origin": "*",
        },
        //
      };
      const response = await axios.put(`${args.url}`, args.csvFile, config);
      if (response.status === 200) {
        thunkAPI.dispatch(
          bulkUpload({
            token: args.token,
            csvObj: args.csvFile.name,
            localization: args.localization,
          })
        );
      }

      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error uploading CSV to AWS"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const bulkUpload = createAsyncThunk(
  "distributors/bulkUpload",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${args.token}`,
          "Accept-Language": args.localization,
        },
        //
      };
      const response = await axios.post(
        `${baseURL}/v1/admin/distributors/bulk`,
        { csvObj: args.csvObj },
        config
      );
      if (response.data.statusToken) {
        thunkAPI.dispatch(
          bulkUploadStatusPoll({
            token: args.token,
            statusToken: response.data.statusToken,
            localization: args.localization,
          })
        );
      }

      thunkAPI.dispatch(setDistributorFeedback({ failures: [] }));
      thunkAPI.dispatch(setDistributorBulkDetails({ name: args.csvObj }));
      thunkAPI.dispatch(setDistributorBulkStatus("uploading"));

      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "bulk upload error"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const bulkUploadStatusPoll = createAsyncThunk(
  "distributors/bulkUploadStatusPoll",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${args.token}`,
          "Accept-Language": args.localization,
          "Access-Control-Allow-Origin": "*",
        },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/distributors/bulk/check/${args.statusToken}`,
        config
      );
      if (response.data && response.data.status === "completed") {
        // thunkAPI.dispatch(
        //   successSnack('Bulk CSV upload completed successfully')
        // );
        thunkAPI.dispatch(setDistributorBulkStatus("success"));
        return await response.data;
      } else if (
        response.data &&
        response.data.status === "completed with error(s)"
      ) {
        thunkAPI.dispatch(setDistributorFeedback(response.data));
        thunkAPI.dispatch(setDistributorBulkStatus("success with errors"));
      } else if (response.data && response.data.status === "incomplete") {
        setTimeout(() => {
          thunkAPI.dispatch(
            bulkUploadStatusPoll({
              token: args.token,
              statusToken: args.statusToken,
              localization: args.localization,
            })
          );
        }, 5000);
      } else {
        thunkAPI.rejectWithValue({
          error: "An error occured with the bulk upload",
        });
        thunkAPI.dispatch(
          setDistributorFeedback({
            failures: [],
            errorMessage: "An error occured with the bulk upload",
          })
        );
        thunkAPI.dispatch(setDistributorBulkStatus("error"));
      }
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "bulk upload error"
          )
        );

        thunkAPI.dispatch(
          setDistributorFeedback({
            failures: [],
            errorMessage:
              error.response.data.error_description || "bulk upload error",
          })
        );
        thunkAPI.dispatch(setDistributorBulkStatus("error"));
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getFullList = createAsyncThunk(
  "distributors/getFullList",
  async (args, thunkAPI) => {
    let objToMap = [];
    for (let i = 0; i < (args.targetLength - args.offset) / 100; i++) {
      objToMap.push({
        token: args.token,
        query: args.query,
        offset: args.offset + 100 * i,
      });
    }

    return new Promise((resolve) => {
      Promise.all(
        objToMap.map((requestData) =>
          thunkAPI.dispatch(fetchMoreDistributors(requestData))
        )
      )
        .then(() => resolve())
        .catch((err) => {
          return thunkAPI.rejectWithValue(
            "Error fetching full distributor list"
          );
        });
    });
  }
);

const distributorsSlice = createSlice({
  name: "distributors",
  initialState: {
    distributor: {},
    distributorsList: [],
    loading: "idle",
    message: "",
    error: "",
    distributorFeedback: { failures: [] },
    distributorBulkDetails: { name: "" },
    distributorBulkStatus: "",
    distributorsAmount: "",
    lastQuery: "",
  },
  reducers: {
    resetDistributor(state, action) {
      state.distributorsList = { data: [] };
      state.loading = "idle";
    },
    setDistributor(state, action) {
      state.distributor = action.payload;
    },
    setDistributorFeedback: (state, action) => {
      state.distributorFeedback = action.payload;
    },
    setDistributorBulkDetails: (state, action) => {
      state.distributorBulkDetails = action.payload;
    },
    setDistributorBulkStatus: (state, action) => {
      state.distributorBulkStatus = action.payload;
    },
    resetBulk: (state, action) => {
      state.distributorBulkStatus = "";
      state.distributorBulkDetails = { name: "" };
      state.distributorFeedback = { failures: [] };
    },
    setLastQuery: (state, action) => {
      state.lastQuery = action.payload;
    },
    setDistributorsAmount: (state, action) => {
      state.distributorsAmount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDistributors.pending, (state) => {
      state.distributorsList = [];
      state.loading = "loading";
    });
    builder.addCase(fetchDistributors.fulfilled, (state, { payload }) => {
      state.distributorsList = payload;
      state.loading = "loaded";
    });
    builder.addCase(fetchDistributors.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error;
    });
    builder.addCase(fetchMoreDistributors.fulfilled, (state, { payload }) => {
      state.error = "";
      state.distributorsList = state.distributorsList.concat(payload);
      state.loading = "loaded";
    });
    builder.addCase(fetchMoreDistributors.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(getFullList.fulfilled, (state, { payload }) => {
      state.error = "";
      state.distributorsList = state.distributorsList.sort(function (a, b) {
        return a.id - b.id;
      });
      state.loading = "loaded";
    });
    builder.addCase(getFullList.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchDistributorByID.pending, (state) => {
      // state.distributor = {};
      state.loading = "loading";
    });
    builder.addCase(fetchDistributorByID.fulfilled, (state, { payload }) => {
      state.error = "";
      state.distributor = payload;
      state.loading = "loaded";
    });
    builder.addCase(fetchDistributorByID.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(createDistributor.pending, (state) => {
      state.message = "";
      // state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(createDistributor.fulfilled, (state, { payload }) => {
      // state.message = payload;
      // state.loading = "loaded";
    });
    builder.addCase(createDistributor.rejected, (state, action) => {
      // state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(updateDistributor.pending, (state) => {
      state.message = "";
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(updateDistributor.fulfilled, (state, { payload }) => {
      // state.message = payload;
      state.loading = "loaded";
    });
    builder.addCase(updateDistributor.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(preSignedURL.pending, (state) => {
      state.url = "";
      // state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(preSignedURL.fulfilled, (state, { payload }) => {
      state.url = payload.preSignedURL;
      // state.loading = "loaded";
      state.editing = false;
    });
    builder.addCase(preSignedURL.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
  },
});

export const {
  setDistributor,
  setDistributorFeedback,
  setDistributorBulkDetails,
  setDistributorBulkStatus,
  resetBulk,
  setLastQuery,
  setDistributorsAmount,
} = distributorsSlice.actions;

export const selectDistributors = createSelector(
  (state) => ({
    distributors: state.distributors,
    distributorsList: state.distributors.distributorsList,
    loading: state.distributors.loading,
    error: state.distributors.error,
    distributor: state.distributors.distributor,
    distributorBulkStatus: state.distributors.distributorBulkStatus,
    distributorBulkDetails: state.distributors.distributorBulkDetails,
    distributorFeedback: state.distributors.distributorFeedback,
    distributorsAmount: state.distributors.distributorsAmount,
    lastQuery: state.distributors.lastQuery,
  }),
  (state) => state
);
export default distributorsSlice.reducer;
