import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

import { errorSnack, successSnack } from "../snackbar/snackbarSlice";

import axios from "axios";

import { uuidv4 } from "../../app/utils";
import baseURL, { setXClientHost } from "../../app/utils.js";
import { fetchThemeJSON } from "../customisation/customisationSlice";
axios.interceptors.request.use(
  (req) => {
    req.headers.common["X-Request-ID"] = uuidv4();
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export const invalidateContents = createAsyncThunk(
  "contents/invalidateContents",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };

      const response = await axios.patch(
        `${baseURL}/v1/admin/reset/cf_cache`,
        {
          path: args.path,
        },
        config
      );
      if (response.status === 200) {
        thunkAPI.dispatch(
          fetchThemeJSON({
            token: args.token,
            url: `${process.env.REACT_APP_ASSETS_URL}/theme/theme.json`,
          })
        );
      }

      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching contents list"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchContents = createAsyncThunk(
  "contents/fetchContents",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/contents?limit=100${args.query}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching contents list"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchMoreContents = createAsyncThunk(
  "contents/fetchMoreContents",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/contents${args.query}&offset=${args.offset}`,
        config
      );
      thunkAPI.dispatch(setContentsAmount(response.headers["x-total-count"]));
      return await response.data;
    } catch (error) {
      if (error.message.includes("401")) {
        localStorage.removeItem("clientUser");
        localStorage.removeItem("clientToken");
      }
      if (error.response && error.response.data) {
        // thunkAPI.dispatch(
        //   errorSnack(
        //     error.response.data.error_description ||
        //       'Error fetching products list'
        //   )
        // );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchContentByID = createAsyncThunk(
  "contents/fetchContentByID",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/admin/contents/${args.id}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching content data"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchContentByName = createAsyncThunk(
  "contents/fetchContentByName",
  async (args, thunkAPI) => {
    const config = {
      headers: {
        Authorization: `Bearer ${args.token}`,
        "X-Client-Host": args.host,
      },
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/rt/contents/name/${args.name}`,
        config
      );

      if (args.name == "logo") {
        thunkAPI.dispatch(setLogo(response.data));
      } else if (args.name == "social_links") {
        thunkAPI.dispatch(setSocial(response.data));
      } else if (args.name == "secondary_logo") {
        thunkAPI.dispatch(setSecondaryLogo(response.data));
      } else if (args.name == "alertMessage") {
      } else if (args.name == "secondary_logo_link") {
        thunkAPI.dispatch(setSecondaryLogoLink(response.data));
      } else if (args.name == "alertMessage") {
        thunkAPI.dispatch(setAlert(response.data));

        thunkAPI.dispatch(
          fetchShowAlert({ token: args.token, host: args.host })
        );
      } else {
        thunkAPI.dispatch(setContent(response.data));
      }
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        if (
          error.response.data.error_description == "content not found" &&
          args.name == "alertMessage"
        ) {
          thunkAPI.dispatch(
            createContent({
              token: args.token,
              name: "alertMessage",
              value: "",
              classification: "data",
              storageKey: null,
            })
          );
        }
        if (
          args.name != "secondary_logo_link" &&
          args.name != "secondary_logo"
        ) {
          thunkAPI.dispatch(
            errorSnack(
              error.response.data.error_description ||
                "Error fetching Content data"
            )
          );
        }
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchLogo = createAsyncThunk(
  "contents/fetchLogo",
  async (args, thunkAPI) => {
    const config = {
      headers: {
        Authorization: `Bearer ${args.token}`,
        "X-Client-Host": args.host,
      },
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/rt/contents/name/${args.name}`,
        config
      );

      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        if (
          error.response.data.error_description == "content not found" &&
          args.name == "alertMessage"
        ) {
          thunkAPI.dispatch(
            createContent({
              token: args.token,
              name: "alertMessage",
              value: "",
            })
          );
        }
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching Content data"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchShowAlert = createAsyncThunk(
  "contents/fetchShowAlert",
  async (args, thunkAPI) => {
    const config = {
      headers: {
        Authorization: `Bearer ${args.token}`,
        "X-Client-Host": args.host,
      },
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/rt/contents/name/showAlert`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        if (error.response.data.error_description == "content not found") {
          thunkAPI.dispatch(
            createContent({
              token: args.token,
              name: "showAlert",
              value: "false",
              classification: "data",
              storageKey: null,
            })
          );
        }
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching Content data"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const deleteContent = createAsyncThunk(
  "contents/deleteContent",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //full options
      const response = await axios.delete(
        `${baseURL}/v1/admin/contents/${args.id}`,
        config
      );
      thunkAPI.dispatch(successSnack("Content deleted"));
      return await response;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error deleting Content"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const createContent = createAsyncThunk(
  "contents/createContent",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //full options
      const response = await axios.post(
        `${baseURL}/v1/admin/contents`,
        args.content,
        config
      );
      thunkAPI.dispatch(successSnack("Content submitted successfully"));

      return await response;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error creating Content"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const updateContent = createAsyncThunk(
  "contents/updateContent",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //full options
      const response = await axios.patch(
        `${baseURL}/v1/admin/contents/${args.id}`,
        args.content,
        config
      );
      if (args.name == "social_links") {
        thunkAPI.dispatch(
          fetchContentByName({
            host: setXClientHost(),
            token: args.token,
            name: "social_links",
          })
        );
      } else if (args.name == "secondary_logo") {
        thunkAPI.dispatch(
          fetchContentByName({
            host: setXClientHost(),
            token: args.token,
            name: "secondary_logo",
          })
        );
      }
      thunkAPI.dispatch(successSnack("Content updated successfully"));

      return await response;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error creating Content"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const preSignedURLcontents = createAsyncThunk(
  "contents/preSignedURL",
  async (args, thunkAPI) => {
    const config = {
      headers: {
        Authorization: `Bearer ${args.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      //full options
      const response = await axios.post(
        `${baseURL}/v1/admin/pre_signed_url`,

        {
          objectName: `theme/${args.objectName}`,
          objectType: "asset",
          // objectType: args.objectType,
          objectContentType: args.contentType,
        },
        config
      );
      if (response.data.preSignedURL) {
        thunkAPI.dispatch(
          uploadThemeData({
            token: args.token,
            url: response.data.preSignedURL,
            contentType: args.contentType,
            file: args.file,
          })
        );
      }
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error creating pre-signed url for AWS upload"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const uploadThemeData = createAsyncThunk(
  "contents/uploadThemeData",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: {
          // Authorization: `Bearer ${args.token}`,
          "Content-Type": args.contentType,
          "Access-Control-Allow-Origin": "*",
        },
        //
      };
      const response = await axios.put(`${args.url}`, args.file, config);

      if (response.status === 200) {
        thunkAPI.dispatch(
          invalidateContents({
            token: args.token,
            path: `/theme/theme.json`,
          })
        );
      }
      // thunkAPI.dispatch(successSnack(`File uploaded to s3`));

      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error uploading image to AWS"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const preSignedURLLogo = createAsyncThunk(
  "contents/preSignedURLLogo",
  async (args, thunkAPI) => {
    const config = {
      headers: {
        Authorization: `Bearer ${args.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      //full options
      const response = await axios.post(
        `${baseURL}/v1/admin/pre_signed_url`,

        {
          // objectName: `theme/${args.objectName}`,
          objectName: `theme/logo/${args.objectName}`,
          objectType: "asset",
          objectContentType: args.contentType,
        },
        config
      );
      if (response.data.preSignedURL) {
        thunkAPI.dispatch(
          uploadCustomLogo({
            token: args.token,
            url: response.data.preSignedURL,
            contentType: args.contentType,
            file: args.file,
            logoContent: args.logoContent,
          })
        );
      }
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error creating pre-signed url for AWS upload"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const uploadCustomLogo = createAsyncThunk(
  "contents/uploadCustomLogo",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: {
          // Authorization: `Bearer ${args.token}`,
          "Content-Type": args.contentType,
          "Access-Control-Allow-Origin": "*",
        },
        //
      };
      const response = await axios.put(`${args.url}`, args.file, config);
      if (response.status === 200) {
        if (args.logoContent.length) {
          thunkAPI.dispatch(
            updateContent({
              token: args.token,
              content: {
                value: args.file.name,
              },
              id: args.logoContent[0].id,
            })
          );
        } else {
          thunkAPI.dispatch(
            createContent({
              token: args.token,
              content: {
                name: "logo",
                classification: "data",
                value: args.file.name,
                storageKey: null,
              },
            })
          );
        }
      }

      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error uploading image to AWS"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const preSignedURLBanner = createAsyncThunk(
  "contents/preSignedURLBanner",
  async (args, thunkAPI) => {
    const config = {
      headers: {
        Authorization: `Bearer ${args.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      //full options
      const response = await axios.post(
        `${baseURL}/v1/admin/pre_signed_url`,

        {
          // objectName: `theme/${args.objectName}`,
          objectName: `theme/banner/${args.imgData.objectName}`,
          objectType: "asset",
          objectContentType: args.imgData.contentType,
        },
        config
      );
      if (response.data.preSignedURL) {
        thunkAPI.dispatch(
          uploadCustomBanner({
            token: args.token,
            url: response.data.preSignedURL,
            contentType: args.imgData.contentType,
            file: args.imgData.file,
          })
        );
      }
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error creating pre-signed url for AWS upload"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const uploadCustomBanner = createAsyncThunk(
  "contents/uploadCustomBanner",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: {
          // Authorization: `Bearer ${args.token}`,
          "Content-Type": args.contentType,
          "Access-Control-Allow-Origin": "*",
        },
        //
      };
      const response = await axios.put(`${args.url}`, args.file, config);
      if (response.status === 200) {
      }

      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error uploading image to AWS"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const updateBannerImages = createAsyncThunk(
  "contents/updateBannerImages",
  async (args, thunkAPI) => {
    Promise.all(
      args.addedImages.map((imgData) =>
        thunkAPI.dispatch(preSignedURLBanner({ imgData, token: args.token }))
      )
    )
      .then((results) => {
        if (args.bannerContent.length) {
          thunkAPI.dispatch(
            updateContent({
              token: args.token,
              content: {
                value: args.fileNames.join(","),
              },
              id: args.bannerContent[0].id,
            })
          );
        } else {
          thunkAPI.dispatch(
            createContent({
              token: args.token,
              content: {
                name: "banner",
                classification: "data",
                value: args.fileNames.join(","),
                storageKey: null,
              },
            })
          );
        }
      })
      .catch((err) => {
        return thunkAPI.rejectWithValue("Error updating banner images");
      });
  }
);

export const updateBannerLinks = createAsyncThunk(
  "contents/updateBannerLinks",
  async (args, thunkAPI) => {
    try {
      if (args.bannerContent.length) {
        thunkAPI.dispatch(
          updateContent({
            token: args.token,
            content: {
              value: args.bannerLinks.join(","),
            },
            id: args.bannerContent[0].id,
          })
        );
      } else {
        thunkAPI.dispatch(
          createContent({
            token: args.token,
            content: {
              name: "bannerLink",
              classification: "data",
              value: args.bannerLinks.join(","),
              storageKey: null,
            },
          })
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("Error updating banner images");
    }
  }
);

export const preSignedURLSecondaryLogo = createAsyncThunk(
  "contents/preSignedURLSecondaryLogo",
  async (args, thunkAPI) => {
    const config = {
      headers: {
        Authorization: `Bearer ${args.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      console.log("here3");

      const response = await axios.post(
        `${baseURL}/v1/admin/pre_signed_url`,

        {
          // objectName: `theme/${args.objectName}`,
          objectName: `theme/secondary_logo/${args.objectName}`,
          objectType: "asset",
          objectContentType: args.contentType,
        },
        config
      );

      console.log("here4");

      if (response.data.preSignedURL) {
        thunkAPI.dispatch(
          uploadCustomSecondaryLogo({
            token: args.token,
            url: response.data.preSignedURL,
            contentType: args.contentType,
            file: args.file,
            logoContent: args.logoContent,
            secondaryLogoLink: args.secondaryLogoLink,
            linkContent: args.linkContent,
          })
        );
      }
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error creating pre-signed url for AWS upload"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const uploadCustomSecondaryLogo = createAsyncThunk(
  "contents/uploadCustomSecondaryLogo",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: {
          // Authorization: `Bearer ${args.token}`,
          "Content-Type": args.contentType,
          "Access-Control-Allow-Origin": "*",
        },
        //
      };
      const response = await axios.put(`${args.url}`, args.file, config);
      if (response.status === 200) {
        if (args.logoContent.length) {
          thunkAPI.dispatch(
            updateContent({
              token: args.token,
              content: {
                value: args.file.name,
              },
              id: args.logoContent[0].id,
            })
          );
        } else {
          thunkAPI.dispatch(
            createContent({
              token: args.token,
              content: {
                name: "secondary_logo",
                classification: "data",
                value: args.file.name,
                storageKey: null,
              },
            })
          );
        }
        if (args.linkContent.length) {
          thunkAPI.dispatch(
            updateContent({
              token: args.token,
              content: {
                value: args.secondaryLogoLink,
              },
              id: args.linkContent[0].id,
            })
          );
        } else {
          thunkAPI.dispatch(
            createContent({
              token: args.token,
              content: {
                name: "secondary_logo_link",
                classification: "data",
                value: args.secondaryLogoLink,
                storageKey: null,
              },
            })
          );
        }
      }

      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error uploading image to AWS"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getFullList = createAsyncThunk(
  "claims/getFullList",
  async (args, thunkAPI) => {
    let objToMap = [];
    for (let i = 0; i < (args.targetLength - args.offset) / 100; i++) {
      objToMap.push({
        token: args.token,
        query: args.query,
        offset: args.offset + 100 * i,
      });
    }

    Promise.all(
      objToMap.map((requestData) =>
        thunkAPI.dispatch(fetchMoreContents(requestData))
      )
    )
      .then((results) => {})
      .catch((err) => {
        return thunkAPI.rejectWithValue("Error fetching full contents list");
      });
  }
);

const contentsSlice = createSlice({
  name: "contents",
  initialState: {
    content: {},
    contentsList: [],
    contentsFilter: [],
    showAlert: false,
    alertShown: false,
    loading: "idle",
    bannerLoading: false,
    bannerError: false,
    message: "",
    error: "",
    alert: {},
    logo: "",
    social: "",
    secondaryLogo: "",
    secondaryLogoLink: "",
    contentsAmount: "",
    lastQuery: "",
  },
  reducers: {
    setContent(state, action) {
      state.content = action.payload;
    },
    setLogo(state, action) {
      state.logo = action.payload;
    },
    setSocial(state, action) {
      state.social = action.payload;
    },
    setSecondaryLogo(state, action) {
      state.secondaryLogo = action.payload;
    },
    setSecondaryLogoLink(state, action) {
      state.secondaryLogoLink = action.payload;
    },
    setShowAlert(state, action) {
      state.showAlert = action.payload;
    },
    setAlert(state, action) {
      state.alert = action.payload;
    },
    setAlertShown(state, action) {
      state.alertShown = action.payload;
    },
    setLastQuery: (state, action) => {
      state.lastQuery = action.payload;
    },
    setContentsAmount: (state, action) => {
      state.contentsAmount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchContents.pending, (state) => {
      state.contentsList = [];
      state.contentsFilter = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(fetchContents.fulfilled, (state, { payload }) => {
      state.contentsList = payload;
      state.contentsFilter = payload;
      state.loading = "loaded";
    });
    builder.addCase(fetchContents.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchMoreContents.fulfilled, (state, { payload }) => {
      state.error = "";
      state.contentsList = state.contentsList.concat(payload);
      state.loading = "loaded";
    });
    builder.addCase(fetchMoreContents.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(getFullList.fulfilled, (state, { payload }) => {
      state.error = "";
      state.contentsList = state.contentsList.sort(function (a, b) {
        return a.id - b.id;
      });
      state.loading = "loaded";
    });
    builder.addCase(getFullList.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchContentByName.pending, (state) => {
      state.loading = "loading";
    });
    builder.addCase(fetchContentByName.fulfilled, (state, { payload }) => {
      // state.content = payload;
      state.loading = "loaded";
    });
    builder.addCase(fetchContentByName.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
      state.content = {};
    });
    builder.addCase(fetchLogo.pending, (state) => {
      state.loading = "loading";
    });
    builder.addCase(fetchLogo.fulfilled, (state, { payload }) => {
      state.logo = payload;
      state.loading = "loaded";
    });
    builder.addCase(fetchLogo.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchShowAlert.pending, (state) => {
      state.loading = "loading";
    });
    builder.addCase(fetchShowAlert.fulfilled, (state, { payload }) => {
      state.showAlert = payload;
      state.loading = "loaded";
    });
    builder.addCase(fetchShowAlert.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchContentByID.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchContentByID.pending, (state) => {
      state.content = {};
      state.loading = "loading";
    });
    builder.addCase(fetchContentByID.fulfilled, (state, { payload }) => {
      state.content = payload;
      state.loading = "loaded";
    });

    builder.addCase(deleteContent.pending, (state) => {
      state.message = "";
      state.loading = "loading";
    });
    builder.addCase(deleteContent.fulfilled, (state, { payload }) => {
      // state.message = payload;
      state.loading = "loaded";
    });
    builder.addCase(deleteContent.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(createContent.pending, (state) => {
      state.message = "";
      state.loading = "loading";
    });
    builder.addCase(createContent.fulfilled, (state, { payload }) => {
      // state.message = payload;
      state.loading = "loaded";
    });
    builder.addCase(createContent.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(updateContent.pending, (state) => {
      state.loading = "loading";
    });
    builder.addCase(updateContent.fulfilled, (state, { payload }) => {
      state.loading = "loaded";
    });
    builder.addCase(updateContent.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(preSignedURLcontents.pending, (state) => {
      state.message = "";
      state.bannerError = false;
      state.loading = true;
    });
    builder.addCase(preSignedURLcontents.fulfilled, (state, { payload }) => {
      // state.message = payload;
    });
    builder.addCase(preSignedURLcontents.rejected, (state, action) => {
      state.bannerLoading = false;
      state.bannerError = true;
    });
    builder.addCase(uploadThemeData.pending, (state) => {
      state.bannerError = false;
      state.bannerLoading = true;
    });
    builder.addCase(uploadThemeData.fulfilled, (state, { payload }) => {
      // state.message = payload;
      state.bannerLoading = false;
    });
    builder.addCase(uploadThemeData.rejected, (state, action) => {
      state.bannerLoading = false;
      state.bannerError = true;
      state.error = action.error.message;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  setAlert,
  setContent,
  setLogo,
  setSocial,
  setSecondaryLogo,
  setSecondaryLogoLink,
  setAlertShown,
  setLastQuery,
  setContentsAmount,
} = contentsSlice.actions;

export const selectContents = createSelector(
  (state) => ({
    contents: state.contents,
    contentsList: state.contents.contentsList,
    contentsFilter: state.contents.contentsFilter,
    showAlert: state.contents.showAlert,
    alertShown: state.contents.alertShown,
    loading: state.contents.loading,
    error: state.contents.error,
    content: state.contents.content,
    alert: state.contents.alert,
    logo: state.contents.logo,
    social: state.contents.social,
    secondaryLogo: state.contents.secondaryLogo,
    secondaryLogoLink: state.contents.secondaryLogoLink,
    contentsAmount: state.contents.contentsAmount,
    lastQuery: state.contents.lastQuery,
  }),
  (state) => state
);

export default contentsSlice.reducer;
