import {
  createAsyncThunk,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";

import { errorSnack } from "../snackbar/snackbarSlice";
import { fetchUserPoints } from "../userPoints/userPointsSlice";
import { fetchMyself } from "../users/usersSlice";

import axios from "axios";

import { uuidv4 } from "../../app/utils";
import baseURL from "../../app/utils.js";
axios.interceptors.request.use(
  (req) => {
    req.headers.common["X-Request-ID"] = uuidv4();
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export const fetchStripeConfig = createAsyncThunk(
  "payments/fetchStripeConfig",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/payments/config`,
        config
      );

      return await response.data;
    } catch (error) {
      if (error.message.includes("401")) {
        localStorage.removeItem("clientUser");
        localStorage.removeItem("clientToken");
      }
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching Stripe config"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const createPaymentIntent = createAsyncThunk(
  "payments/createPaymentIntent",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      const response = await axios.post(
        `${baseURL}/v1/admin/users/${args.userID}/payments`,
        args.amount,
        config
      );

      return await response.data;
    } catch (error) {
      if (error.message.includes("401")) {
        localStorage.removeItem("clientUser");
        localStorage.removeItem("clientToken");
      }
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching product data"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const updatePaymentSuccess = createAsyncThunk(
  "payments/updatePaymentSuccess",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      const response = await axios.patch(
        `${baseURL}/v1/admin/users/${args.userID}/payments/${args.paymentID}/success`,
        null,
        config
      );
      thunkAPI.dispatch(
        fetchUserPoints({
          token: args.token,
          userID: args.userID,
        })
      );
      thunkAPI.dispatch(
        fetchMyself({
          token: args.token,
        })
      );
      return await response.data;
    } catch (error) {
      if (error.message.includes("401")) {
        localStorage.removeItem("clientUser");
        localStorage.removeItem("clientToken");
      }
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error updating payment success"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const initial = {
  clientSecret: "",
  publishableKey: "",
  payment: {
    id: "",
  },
  orderAmount: 0,
  dialogOpen: false,
  loading: "idle",
  error: "",
};

const paymentsSlice = createSlice({
  name: "payments",
  initialState: initial,
  reducers: {
    openDialog(state, action) {
      state.dialogOpen = action.payload;
    },
    setOrderAmount(state, action) {
      state.orderAmount = action.payload;
    },
    resetPayment(state, action) {
      state.clientSecret = "";
      state.payment = {
        id: "",
      };
      state.orderAmount = 0;
      state.dialogOpen = false;
      state.loading = "idle";
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStripeConfig.pending, (state) => {
      state.publishableKey = "";
      state.loading = "loading";
    });
    builder.addCase(fetchStripeConfig.fulfilled, (state, { payload }) => {
      state.error = "";
      state.publishableKey = payload.publishableKey;
      state.loading = "loaded";
    });
    builder.addCase(fetchStripeConfig.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });

    builder.addCase(createPaymentIntent.pending, (state) => {
      state.clientSecret = "";
      state.payment = {};
      state.loading = "loading";
    });
    builder.addCase(createPaymentIntent.fulfilled, (state, { payload }) => {
      state.error = "";
      state.clientSecret = payload.clientSecret;
      state.payment = { id: payload.paymentID };
      state.loading = "loaded";
    });
    builder.addCase(createPaymentIntent.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });

    builder.addCase(updatePaymentSuccess.pending, (state) => {
      state.clientSecret = "";
      state.loading = "loading";
    });
    builder.addCase(updatePaymentSuccess.fulfilled, (state, { payload }) => {
      state.error = "";
      state.loading = "loaded";
    });
    builder.addCase(updatePaymentSuccess.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
  },
});

export const { openDialog, setOrderAmount, resetPayment } =
  paymentsSlice.actions;

export const selectPayments = createSelector(
  (state) => ({
    clientSecret: state.payments.clientSecret,
    publishableKey: state.payments.publishableKey,
    payment: state.payments.payment,
    dialogOpen: state.payments.dialogOpen,
    orderAmount: state.payments.orderAmount,
    loading: state.payments.loading,
    error: state.payments.error,
  }),
  (state) => state
);
export default paymentsSlice.reducer;
