import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// or
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import {
  removeProductFromCart,
  selectCarts,
  updateProductQuantity,
} from "../carts/cartsSlice";

import { fetchMyself, selectUsers } from "../users/usersSlice";

import StatesDropDown from "../../common/StatesDropDown";
import { selectCustomisation } from "../customisation/customisationSlice";
import { setProduct } from "../products/coreProductsSlice";
import { selectWishlist } from "../wishlist/wishlistSlice";
import { resetComplete, setOrderDetails } from "./ordersSlice";

import { openDialog, setOrderAmount } from "../payments/paymentsSlice";

import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

var sortBy = require("lodash/sortBy");

const SecondaryButton = withStyles((theme) => ({
  root: {
    // margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.primaryButton.main,
    color: "#fff",
    width: 250,
    fontWeight: "bold",
    borderRadius: 16,
    "&:hover": {
      backgroundColor: theme.palette.primaryButton.main,
    },
    // fontWeight: 'bold',
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  cartContainer: {
    margin: 60,
    marginTop: 24,
  },
  cartTitle: {
    textAlign: "left",
    // marginTop: 60,
    marginBottom: 24,

    fontWeight: "bold",
    fontSize: 32,
    color: theme.palette.primaryFont.main,
  },
  cartProducts: {
    // margin: 60
    borderRadius: 16,
    padding: 32,
    marginBottom: 60,
    color: theme.palette.secondaryFont.main,
    fontSize: 18,
    fontWeight: "bold",
  },
  productsHeader: {
    marginRight: 50,
    paddingBottom: 0,
  },
  header: {
    color: theme.palette.primaryFont.main,
  },
  productsList: {
    borderTop: "1px solid #DDD",
    paddingBottom: 4,
    paddingTop: 4,
  },
  productsActions: {
    paddingTop: 8,
  },
  cartDetailsContainer: {
    display: "flex",
    // flexFlow: 'row wrap',
    justifyContent: "space-between",
    color: theme.palette.secondaryFont.main,
    fontSize: 18,
  },
  deliveryDetailsContainer: { width: "50%", marginRight: 48 },
  deliveryDetails: {
    padding: 32,
    // paddingBottom: 48,
    // paddingTop: 48,
    color: theme.palette.secondaryFont.main,
    fontSize: 18,
    borderRadius: 16,
  },
  additionalPapersContainer: {
    display: "flex",
    flexDirection: "column",
    width: "45%",
  },
  comments: {
    borderRadius: 16,
    padding: 32,
    // paddingBottom: 56,
    // paddingTop: 48,
    marginBottom: 71,
    color: theme.palette.secondaryFont.main,
    fontSize: 18,
  },
  assistance: {
    borderRadius: 16,
    padding: 32,
    // paddingBottom: 56,
    // paddingTop: 48,
    marginBottom: 82,
    color: theme.palette.secondaryFont.main,
    fontSize: 18,
    textAlign: "center",
  },
  assistanceText: {
    fontWeight: "bold",
    marginBottom: 8,
  },
  warningText: {
    color: theme.palette.highlight.main,
    fontWeight: "bold",
    marginBottom: 8,
  },
  review: {
    borderRadius: 16,
    padding: 32,
    // paddingBottom: 56,
    // paddingTop: 48,
    marginBottom: 82,
    color: theme.palette.secondaryFont.main,
    fontSize: 18,
  },
  textLabel: {
    marginBottom: 4,
    marginTop: 16,
  },
  colorSpan: {
    color: theme.palette.secondaryFont.main,
    fontWeight: "bold",
    width: "40%",
  },
  cartColor: {
    color: theme.palette.primaryFont.main,
  },
  skuText: {
    color: "#3e3e3e",
  },

  quantityInput: {
    color: theme.palette.primaryFont.main,
    fontSize: 18,
    fontWeight: "bold",
    width: 100,
    padding: 8,
    textAlign: "center",
  },
  icon: {
    fontSize: 50,
    color: theme.palette.highlight.main,
    cursor: "pointer",
  },
  asterisk: {
    color: "red",
  },
  warning: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    border: "red 4px solid",
    borderRadius: 10,
    padding: 10,
    color: "red",
    flexDirection: "column",
    alignItems: "center",
  },
  purchaseText: {
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: theme.palette.highlight.main,
    borderRadius: "24px",
    color: theme.palette.primaryFont.main,
    fontWeight: "bold",
    padding: 4,
    paddingLeft: 12,
    paddingRight: 12,
    textTransform: "capitalize",
    cursor: "pointer",
    marginRight: 32,
    marginTop: 10,
    fontSize: "16px",
  },
  detailsQuantitySelector: {
    display: "flex",
    // flexFlow: "row nowrap",
    justifyContent: "center",
    alignItems: "center",
  },
  quantityMinus: {
    border: "1px solid #DDD",
    borderRadius: 4,
    paddingLeft: 12,
    paddingRight: 12,
    paddingBottom: 3,
    lineHeight: "24px",
    cursor: "pointer",
    outline: "none",
  },
  quantity: {
    marginLeft: 16,
    marginRight: 16,
    minWidth: 32,
    textAlign: "center",
  },
  quantityPlus: {
    border: "1px solid #DDD",
    borderRadius: 4,
    paddingLeft: 12,
    paddingRight: 12,
    paddingBottom: 3,
    lineHeight: "24px",
    cursor: "pointer",
    outline: "none",
  },
}));

export default function Cart() {
  const matches = useMediaQuery("(min-width:950px)");
  const midpoint = useMediaQuery("(max-width:1450px)");
  const { customisation } = useSelector(selectCustomisation);

  const classes = useStyles();
  const history = useHistory();
  let jwtToken = localStorage.getItem("clientToken");

  const dispatch = useDispatch();
  const { userAccount } = useSelector(selectUsers);
  const { wishlist } = useSelector(selectWishlist);
  const { cart, cartList } = useSelector(selectCarts);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");

  // const [streetNumber, setStreetNumber] = useState("");
  const [streetName, setStreetName] = useState("");
  const [companyName, setCompanyName] = useState("");
  // const [streetNumberError, setStreetNumberError] = useState("");
  const [streetNameError, setStreetNameError] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");

  const [suburb, setSuburb] = useState("");
  const [postCode, setPostCode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [totalPoints, setTotalPoints] = useState(0);
  const [notes, setNotes] = useState("");

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setTotalPoints(cartList.totalPoints);
  }, [cart, dispatch]);

  useEffect(() => {
    dispatch(resetComplete());
    // if (userAccount.id) {
    //   dispatch(
    //     fetchCart({
    //       token: jwtToken,
    //       id: userAccount.id,
    //     })
    //   );
    // }
    dispatch(
      fetchMyself({
        token: jwtToken,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    setFirstName(userAccount.firstName);
    setLastName(userAccount.lastName);
    setMobile(userAccount.mobile);
    if (userAccount.postalAddress) {
      // setStreetNumber(userAccount.postalAddress.streetNumber);
      setStreetName(userAccount.postalAddress.streetName);
      setCompanyName(userAccount.postalAddress.companyName);
      setSuburb(userAccount.postalAddress.suburb);
      setPostCode(userAccount.postalAddress.postCode);
      setCity(userAccount.postalAddress.city);
      setState(userAccount.postalAddress.state.toUpperCase());
    }
  }, [userAccount]);
  const isPOBox = (str) => {
    const regTest =
      /\b(p\.?\s*o\.?|post\s+office|post)(\s+)?(?:box|[0-9]*)?\b/i;
    return regTest.test(str);
  };

  // const handleProdClick = (prod) => {
  //   dispatch(setProduct({product: prod}))
  // }

  const handleReview = (event) => {
    // if (isPOBox(streetNumber)) {
    //   setStreetNumberError("We are unable to accept a PO Box address");
    // } else
    event.preventDefault();
    if (isPOBox(streetName)) {
      // setStreetNumberError("");

      setStreetNameError("We are unable to accept a PO Box address");
    } else {
      // setStreetNumberError("");
      setStreetNameError("");

      const order = {
        userDetails: {
          firstName: firstName,
          lastName: lastName,
          mobile: mobile,
        },
        billingAddress: {
          // streetNumber: streetNumber,
          companyName: companyName,
          streetName: streetName,
          suburb: suburb,
          postCode: postCode,
          city: city,
          state: state,
          country: country,
        },
        deliveryAddress: {
          // streetNumber: streetNumber,
          companyName: companyName,
          streetName: streetName,
          suburb: suburb,
          postCode: postCode,
          city: city,
          state: state,
          country: country,
        },
        userPoints: userAccount.redeemablePoints,
        products: cartList,
        notes: notes,
      };
      dispatch(setOrderDetails(order));

      history.push("/review-order");
    }
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleRemove = (e, id) => {
    dispatch(
      removeProductFromCart({ token: jwtToken, id: userAccount.id, prodID: id })
    );
  };

  const formatPTS = (str) => {
    return str ? str.toLocaleString() : "";
  };

  const handleQuantityChange = (value, id) => {
    dispatch(
      updateProductQuantity({
        token: jwtToken,
        id: userAccount.id,
        productID: id,
        product: { quantity: Number(value) },
        showMessage: false,
      })
    );
  };

  const increaseQuantity = (quantity, id) => {
    // setQuantity(quantity + 1);
    dispatch(
      updateProductQuantity({
        token: jwtToken,
        id: userAccount.id,
        productID: id,
        product: { quantity: Number(quantity + 1) },
        showMessage: false,
      })
    );
  };
  const decreaseQuantity = (quantity, id) => {
    // setQuantity(quantity === 1 ? 1 : quantity - 1);
    dispatch(
      updateProductQuantity({
        token: jwtToken,
        id: userAccount.id,
        productID: id,
        product: {
          quantity: quantity === 1 ? Number(1) : Number(quantity - 1),
        },
        showMessage: false,
      })
    );
  };

  const sortCart = (products) => {
    let sortedProds = sortBy(products, [
      function (o) {
        return o["productName"];
      },
    ]);
    return sortedProds;
  };

  const isWishlist = (id) => {
    const wishlistIDs = wishlist.length
      ? wishlist.map((prod) => prod.productID)
      : [];
    return wishlistIDs.includes(id);
  };

  const handleProdClick = (e, product) => {
    let isFav = isWishlist(product.productID);
    dispatch(setProduct({ product: product.productDetails, fav: isFav }));
    history.push(`/products/${product.productName}`);
  };

  return (
    <div
      className={classes.cartContainer}
      style={{ margin: matches ? 60 : 10 }}
    >
      <div
        style={{
          fontSize: matches ? 40 : 30,
          textAlign: "left",
          padding: matches ? 0 : 4,
          marginBottom: matches ? 24 : 4,
          marginLeft: matches ? 0 : 18,
        }}
        className={classes.cartTitle}
      >
        Your Cart
      </div>
      <Paper
        elevation={3}
        style={{ fontSize: matches ? 18 : 18 }}
        className={classes.cartProducts}
      >
        <Grid
          container
          spacing={3}
          direction="column"
          // justifyContent="space-between"
          className={classes.productsHeader}
        >
          {matches && (
            <Grid item sm style={{ marginRight: 50 }}>
              <Grid
                container
                spacing={3}
                direction="row"
                // justifyContent="space-between"
                className={classes.productsHeader}
              >
                <Grid item sm>
                  Product:
                </Grid>
                <Grid item sm style={{ maxWidth: midpoint ? 160 : 200 }}>
                  Quantity:
                </Grid>
                <Grid item sm style={{ maxWidth: midpoint ? 160 : 200 }}>
                  Price (pts)
                </Grid>
                <Grid item sm style={{ maxWidth: midpoint ? 160 : 200 }}>
                  Subtotal (pts)
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item sm>
            {matches
              ? cartList.items &&
                cartList.items.length &&
                sortCart(cartList.items).map((prod) => (
                  <Grid
                    container
                    spacing={3}
                    direction="row"
                    kye={prod.productID}
                    // justifyContent="space-between"
                    alignItems="center"
                    className={classes.productsList}
                  >
                    <Grid
                      item
                      sm
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        cursor: "pointer",
                      }}
                      onClick={(e) => handleProdClick(e, prod)}
                    >
                      {matches && (
                        <div>
                          <img
                            style={{
                              width: 150,
                              // height: 150,
                              obectFit: "contain",
                            }}
                            src={
                              prod.productImg != "/placeholder.png"
                                ? `${process.env.REACT_APP_PRODUCT_URL}/${prod.productImg}`
                                : prod.productImg
                            }
                          />
                        </div>
                      )}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginLeft: 32,
                          marginBottom: 12,
                        }}
                      >
                        <div> {prod.productName}</div>
                        <div className={classes.skuText}>
                          {/* {prod.product ? prod.product.sku : ''} */}
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      sm
                      className={classes.cartColor}
                      style={{ maxWidth: midpoint ? 160 : 200 }}
                    >
                      <div
                        style={{
                          justifyContent: matches ? "flex-start" : "center",
                        }}
                        className={classes.detailsQuantitySelector}
                      >
                        <div
                          onClick={(e) => {
                            decreaseQuantity(prod.quantity, prod.productID);
                          }}
                          className={classes.quantityMinus}
                        >
                          {"<"}
                        </div>
                        <div className={classes.quantity}>{prod.quantity}</div>
                        <div
                          onClick={(e) => {
                            increaseQuantity(prod.quantity, prod.productID);
                          }}
                          className={classes.quantityPlus}
                        >
                          {">"}
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      sm
                      className={classes.cartColor}
                      style={{
                        maxWidth: midpoint ? 160 : 200,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <span
                        style={{
                          color: prod.productDetails && prod.productDetails.special
                            ? "grey"
                            : customisation.theme.pointsColor,
                          textDecoration: prod.productDetails && prod.productDetails.special
                            ? "line-through"
                            : "none",
                        }}
                      >
                        {formatPTS(
                          prod.productDetails && prod.productDetails.special
                            ? prod.productDetails.points
                            : prod.productPoints
                            ? prod.productPoints
                            : ""
                        )}
                      </span>
                      {prod.productDetails && prod.productDetails.special && (
                        <span
                          style={{ color: customisation.theme.highlightColor }}
                        >
                          {formatPTS(
                            prod.productPoints ? prod.productPoints : ""
                          )}
                        </span>
                      )}
                    </Grid>
                    <Grid
                      item
                      sm
                      className={classes.cartColor}
                      style={{ maxWidth: midpoint ? 160 : 200 }}
                    >
                      {formatPTS(
                        prod.subTotalPoints ? prod.subTotalPoints : ""
                      )}
                    </Grid>
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        marginRight: matches ? 16 : 0,
                        width: matches ? 31 : 24,
                      }}
                    >
                      <CloseOutlinedIcon
                        onClick={(e) => handleRemove(e, prod.productID)}
                        className={classes.icon}
                      />
                    </div>
                    {/* </Grid> */}
                  </Grid>
                ))
              : cartList.items &&
                cartList.items.length &&
                sortCart(cartList.items).map((prod, index) => (
                  <Grid
                    container
                    spacing={3}
                    direction={"row"}
                    key={prod.productID}
                    justifyContent={"space-between"}
                    alignItems={matches ? "center" : "flex-start"}
                    style={{
                      borderTop: matches ? "1px solid #DDD" : "none",
                      borderBottom: !matches ? "1px solid #DDD" : "none",
                      fontSize: midpoint ? 16 : 18,
                      marginTop: index === 0 ? 0 : 8,
                      marginBottom: index === cartList.items.length - 1 ? 0 : 8,
                      flexWrap: "nowrap",
                    }}
                    className={classes.productsList}
                  >
                    <Grid
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flexGrow: 1,
                        justifyContent: "center",
                        // alignItems: "center",
                      }}
                    >
                      <Grid
                        item
                        sm
                        className={classes.cartColor}
                        style={{
                          paddingTop: matches ? 12 : 0,
                          marginBottom: 8,

                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={(e) => handleProdClick(e, prod)}
                        >
                          <img
                            style={{
                              width: 100,
                              // height: 150,
                              obectFit: "contain",
                            }}
                            src={
                              prod.productImg != "/placeholder.png"
                                ? `${process.env.REACT_APP_PRODUCT_URL}/${prod.productImg}`
                                : prod.productImg
                            }
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = "/placeholder.png";
                            }}
                          />
                        </div>
                        <div
                          style={{
                            marginLeft: 20,
                            fontWeight: "normal",
                          }}
                        >
                          <div
                            style={{
                              fontWeight: !matches ? "bold" : "normal",
                              paddingBottom: 0,
                              marginBottom: 8,
                            }}
                          >
                            {" "}
                            {prod.productName}
                          </div>
                          <div>
                            {" "}
                            {!matches && (
                              <span
                                className={classes.colorSpan}
                                style={{
                                  paddingTop: 4,
                                }}
                              >
                                {/* SKU:{' '} */}
                              </span>
                            )}{" "}
                            {/* {prod.product.sku} */}
                          </div>
                        </div>
                      </Grid>
                      <Grid
                        item
                        sm
                        className={classes.cartColor}
                        style={{
                          textAlign: "left",
                          display: "flex",
                          alignItems: "center",
                          paddingTop: matches ? 12 : 0,
                          marginBottom: 8,
                        }}
                      >
                        {!matches && (
                          <span className={classes.colorSpan}>Quantity: </span>
                        )}{" "}
                        {/* <input
                          min={1}
                          style={{
                            padding: 0,
                            fontSize: 16,
                            marginBottom: 8,
                            height: "2rem",
                            width: "9rem",
                          }}
                          className={classes.quantityInput}
                          type="number"
                          key={prod.productID}
                          onChange={(e) =>
                            handleQuantityChange(e.target.value, prod.productID)
                          }
                          defaultValue={prod.quantity}
                        /> */}
                        <div
                          style={{
                            justifyContent: matches ? "flex-start" : "center",
                            display: "flex",
                          }}
                        >
                          <div
                            onClick={(e) => {
                              decreaseQuantity(e.target.value, prod.productID);
                            }}
                            className={classes.quantityMinus}
                          >
                            {"<"}
                          </div>
                          <div className={classes.quantity}>
                            {prod.quantity}
                          </div>
                          <div
                            onClick={(e) => {
                              increaseQuantity(e.target.value, prod.productID);
                            }}
                            className={classes.quantityPlus}
                          >
                            {">"}
                          </div>
                        </div>
                      </Grid>
                      <Grid
                        item
                        sm
                        className={classes.cartColor}
                        style={{
                          paddingTop: matches ? 12 : 0,
                          marginBottom: 8,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {!matches && (
                          <span className={classes.colorSpan}>
                            Price (pts):{" "}
                          </span>
                        )}{" "}
                        {formatPTS(
                          prod.productPoints ? prod.productPoints : ""
                        )}
                      </Grid>
                      <Grid
                        item
                        sm
                        className={classes.cartColor}
                        style={{
                          paddingTop: matches ? 12 : 0,
                          marginBottom: 8,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {!matches && (
                          <span className={classes.colorSpan}>
                            Subtotal (pts):{" "}
                          </span>
                        )}{" "}
                        {formatPTS(
                          prod.subTotalPoints ? prod.subTotalPoints : ""
                        )}
                      </Grid>
                      <Grid
                        item
                        sm
                        style={{
                          maxWidth: 50,
                          alignSelf: "center",
                        }}
                      >
                        <div
                          className={classes.icon}
                          style={{
                            alignItems: "center",
                            display: "flex",
                            fontSize: 14,
                            justifyContent: "center",

                            // maxWidth: 50,
                          }}
                        >
                          {"Remove"}
                          <CloseOutlinedIcon
                            onClick={(e) => handleRemove(e, prod.productID)}
                            className={classes.icon}
                            style={{ fontSize: 26 }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
          </Grid>
          <Grid
            item
            sm
            style={{ marginRight: matches ? 50 : 0, display: "flex", alignItems: "center" }}
          >
            <Grid
              container
              spacing={3}
              direction="row"
              // justifyContent="space-between"
              className={classes.productsActions}
              style={{
                borderTop: matches ? "1px solid #DDD" : "none",
              }}
            >
              <Grid style={{ display: matches ? "flex" : "flex" }} item sm>
                {" "}
                {totalPoints > userAccount.redeemablePoints && (
                  <div className={classes.warning}>
                    You do not currently have enough points to complete this
                    order.
                    {process.env.REACT_APP_ENABLE_STRIPE == "true" && (
                      <div
                        onClick={() => {
                          dispatch(
                            setOrderAmount(
                              totalPoints - userAccount.redeemablePoints
                            )
                          );
                          dispatch(openDialog(true));
                        }}
                        className={classes.purchaseText}
                      >
                        Click here to purchase more points.
                      </div>
                    )}
                  </div>
                )}
              </Grid>
              <Grid
                style={{
                  display: matches ? "flex" : "none",
                  maxWidth: 350,
                  paddingBottom: 0,
                }}
                item
                sm
              ></Grid>
              <Grid item sm style={{ maxWidth: midpoint ? 160 : 200 }}>
                <div>Total</div>
                <div
                  className={classes.cartColor}
                  style={{
                    marginTop: matches ? 8 : 4,
                  }}
                >
                  {formatPTS(totalPoints)} pts
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <div
        style={{
          fontSize: matches ? 40 : 30,
          textAlign: "left",
          padding: matches ? 0 : 4,
          marginBottom: matches ? 24 : 4,
          marginLeft: matches ? 0 : 18,
        }}
        className={classes.cartTitle}
      >
        Checkout
      </div>
      <div
        style={{
          fontSize: matches ? 20 : 16,
          textAlign: matches ? "left" : "center",
          padding: 0,
        }}
        className={classes.cartProducts}
      >
        Please supply your details below before selecting 'Review Order' to
        continue
      </div>
      <div
        style={{ flexDirection: matches ? "row" : "column" }}
        className={classes.cartDetailsContainer}
      >
        <form
          style={{
            width: "100%",
            display: "flex",
            flexDirection: matches ? "row" : "column",
          }}
          onSubmit={
            userAccount.redeemablePoints < totalPoints || !cartList.items
              ? null
              : handleReview
          }
        >
          <div
            style={{
              width: matches ? "50%" : "100%",
              marginRight: matches ? 48 : 0,
            }}
            className={classes.deliveryDetailsContainer}
          >
            <Paper elevation={3} className={classes.deliveryDetails}>
              <div
                style={{
                  fontSize: matches ? 32 : 24,
                  fontWeight: "bold",
                  marginBottom: 32,
                }}
                className={classes.header}
              >
                Delivery Details
              </div>
              <div
                className={classes.textLabel}
                style={{
                  marginTop: matches ? 16 : 8,
                  fontSize: matches ? 18 : 18,
                }}
              >
                First Name<span className={classes.asterisk}>*</span>{" "}
              </div>
              <TextField
                className={classes.textInput}
                variant="outlined"
                margin="dense"
                required
                disabled
                fullWidth
                id="firstName"
                name="firstName"
                autoComplete="firstName"
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
              />
              <div
                className={classes.textLabel}
                style={{
                  marginTop: matches ? 16 : 8,
                  fontSize: matches ? 18 : 18,
                }}
              >
                Surname<span className={classes.asterisk}>*</span>{" "}
              </div>
              <TextField
                className={classes.textInput}
                variant="outlined"
                margin="dense"
                required
                disabled
                fullWidth
                id="lastName"
                name="lastName"
                autoComplete="lastName"
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
              />

              <div
                className={classes.textLabel}
                style={{
                  marginTop: matches ? 16 : 8,
                  fontSize: matches ? 18 : 18,
                }}
              >
                Mobile<span className={classes.asterisk}>*</span>{" "}
                <br/>
                <span style={{ fontSize: 12 }}>
                Number must start with the country code +61 or +64                  
              </span>
              </div>
              <div className={classes.textInput}>
                <TextField
                  value={mobile}
                  disabled
                  // key={mobile}
                  className={classes.textInput}
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  id="mobile"
                  name="mobile"
                  autoComplete="mobile"
                  onChange={(e) => setMobile(e.target.value)}
                />
              </div>

              <div
                style={{
                  fontSize: matches ? 32 : 24,
                  fontWeight: "bold",
                  marginBottom: 32,
                  marginTop: 32,
                }}
                className={classes.header}
              >
                Delivery Address
              </div>
              <Grid container direction="column">
                {/* <Grid item sm>
                <div
                  className={classes.textLabel}
                  style={{
                    marginTop: matches ? 16 : 8,
                    fontSize: matches ? 18 : 18,
                  }}
                >
                  Street Number:<span className={classes.asterisk}>*</span>{" "}
                </div>
                <TextField
                  className={classes.textItem}
                  variant="outlined"
                  margin="dense"
                  id="street-number-street"
                  type="text"
                  fullWidth
                  onChange={(e) => setStreetNumber(e.target.value)}
                  value={streetNumber}
                  error={streetNumberError}
                  helperText={streetNumberError ? streetNumberError : ""}
                />
              </Grid> */}
                <Grid item sm>
                  <div
                    className={classes.textLabel}
                    style={{
                      marginTop: matches ? 16 : 8,
                      fontSize: matches ? 18 : 18,
                    }}
                  >
                    Company Name{" "}
                    <span style={{ fontSize: 12 }}>(if applicable)</span>
                  </div>
                  <TextField
                    className={classes.textItem}
                    variant="outlined"
                    margin="dense"
                    id="company-name-street"
                    type="text"
                    fullWidth
                    onChange={(e) => setCompanyName(e.target.value)}
                    value={companyName}
                    error={companyNameError}
                    helperText={companyNameError ? companyNameError : ""}
                  />
                </Grid>
                <Grid item sm>
                  <div
                    className={classes.textLabel}
                    style={{
                      marginTop: matches ? 16 : 8,
                      fontSize: matches ? 18 : 18,
                    }}
                  >
                    Street Address<span className={classes.asterisk}>*</span>{" "}
                  </div>
                  <div
                    className={classes.textLabel}
                    style={{
                      marginTop: 0,
                      fontSize: matches ? 18 : 18,
                    }}
                  >
                    <span style={{ fontSize: 12 }} className={classes.asterisk}>
                      We cannot accept P.O. Box addresses
                    </span>{" "}
                  </div>
                  <TextField
                    required
                    className={classes.textItem}
                    variant="outlined"
                    margin="dense"
                    id="street-name-street"
                    type="text"
                    fullWidth
                    onChange={(e) => setStreetName(e.target.value)}
                    value={streetName}
                    error={streetNameError}
                    helperText={streetNameError ? streetNameError : ""}
                  />
                </Grid>

                <Grid item sm>
                  <div
                    className={classes.textLabel}
                    style={{
                      marginTop: matches ? 16 : 8,
                      fontSize: matches ? 18 : 18,
                    }}
                  >
                    Suburb<span className={classes.asterisk}>*</span>{" "}
                  </div>
                  <TextField
                    required
                    className={classes.textItem}
                    variant="outlined"
                    margin="dense"
                    id="subarb-street"
                    type="text"
                    fullWidth
                    onChange={(e) => setSuburb(e.target.value)}
                    value={suburb}
                  />
                </Grid>
                <Grid item sm>
                  <div
                    className={classes.textLabel}
                    style={{
                      marginTop: matches ? 16 : 8,
                      fontSize: matches ? 18 : 18,
                    }}
                  >
                    Post Code<span className={classes.asterisk}>*</span>{" "}
                  </div>
                  <TextField
                    required
                    className={classes.textItem}
                    variant="outlined"
                    margin="dense"
                    id="post-code-street"
                    type="text"
                    inputProps={{ maxLength: 4 }}
                    fullWidth
                    onChange={(e) => setPostCode(e.target.value)}
                    value={postCode}
                  />
                </Grid>
                
                {(process.env.REACT_APP_CLIENT_ID != "11" && process.env.REACT_APP_CLIENT_ID != "12")
                && (
                <Grid item sm>
                  <div
                    className={classes.textLabel}
                    style={{
                      marginTop: matches ? 16 : 8,
                      fontSize: matches ? 18 : 18,
                    }}
                  >
                    City <span style={{ fontSize: 12 }}>(if applicable)</span>
                  </div>
                  <TextField
                    className={classes.textItem}
                    variant="outlined"
                    margin="dense"
                    id="city-street"
                    type="text"
                    fullWidth
                    onChange={(e) => setCity(e.target.value)}
                    value={city}
                  />
                </Grid>)}
                <Grid item sm>
                  <div
                    className={classes.textLabel}
                    style={{
                      marginTop: matches ? 16 : 8,
                      fontSize: matches ? 18 : 18,
                    }}
                  >
                    State<span className={classes.asterisk}>*</span>{" "}
                  </div>
                  {/* <TextField
                  className={classes.textItem}
                  variant="outlined"
                  margin="dense"
                  id="state-street"
                  type="text"
                  fullWidth
                  onChange={(e) => setState(e.target.value)}
                  value={state}
                /> */}
                  <StatesDropDown
                    placeholder={"State/Territory"}
                    setSelectedState={setState}
                    state={state}
                  />
                </Grid>
              </Grid>

              {/* <div
              style={{
                fontSize: matches ? 18 : 18,
                marginTop: matches ? 48 : 24,
              }}
              className={classes.assistanceText}
            >
              Do you want to update your delivery address?
            </div> */}
              {/* <FormControlLabel
              style={{marginTop: 16}}
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  name="terms"
                  color="primary"
                />
              }
              label={`Update my delivery information`}
            /> */}
            </Paper>
          </div>
          <div
            style={{
              marginTop: matches ? 0 : 16,
              width: matches ? "45%" : "100%",
            }}
            className={classes.additionalPapersContainer}
          >
            <Paper
              style={{ marginBottom: matches ? 82 : 16 }}
              elevation={3}
              className={classes.comments}
            >
              <div
                style={{
                  fontSize: matches ? 32 : 24,
                  fontWeight: "bold",
                  marginBottom: matches ? 32 : 16,
                }}
                className={classes.header}
              >
                Order Comments
              </div>
              <div
                style={{
                  fontWeight: "bold",
                  marginBottom: 24,
                  fontSize: matches ? 18 : 18,
                }}
              >
                Use this space for special instructions regarding your order
              </div>

              <TextField
                id="standard-multiline-static"
                // label="Multiline"
                fullWidth
                style={{
                  border: "1px solid rgba(0, 0, 0, 0.23)",
                  borderRadius: 4,
                  width: "100%",
                  marginRight: 24,
                  paddingLeft: 8,
                }}
                value={notes}
                onChange={(e) => {
                  setNotes(e.target.value);
                }}
                multiline
                rows={4}
                placeholder="Start typing..."
              />
            </Paper>
            <Paper
              style={{ marginBottom: matches ? 82 : 16 }}
              elevation={3}
              className={classes.assistance}
            >
              <div
                style={{
                  fontSize: matches ? 32 : 24,
                  fontWeight: "bold",
                  marginBottom: matches ? 32 : 16,
                }}
                className={classes.header}
              >
                Need Assistance?
              </div>
              <div
                style={{ fontSize: matches ? 18 : 18 }}
                className={classes.assistanceText}
              >
                Contact our team at
              </div>
              <div
                style={{ fontSize: matches ? 18 : 18 }}
                className={classes.assistanceText}
              >
                {customisation.theme.phone}
              </div>
              <div
                style={{ fontSize: matches ? 18 : 18 }}
                className={classes.assistanceText}
              >
                {customisation.theme.email}
              </div>
              <div
                style={{ fontSize: matches ? 18 : 18 }}
                className={classes.assistanceText}
              >
                Mon - Fri, 9am - 5pm.
              </div>
              <div
                style={{ fontSize: matches ? 18 : 18 }}
                className={classes.assistanceText}
              >
                We'll get you back on track
              </div>
            </Paper>
            <Paper
              style={{ marginBottom: matches ? 82 : 16 }}
              elevation={3}
              className={classes.assistance}
            >
              <div>
                Be sure to check that your deliver details and delivery address
                are correct
              </div>

              {userAccount.redeemablePoints < totalPoints && (
                <div
                  style={{ fontSize: matches ? 18 : 18 }}
                  className={classes.warningText}
                >
                  Not enough points for order.
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: 24,
                  marginBottom: matches ? 40 : 8,
                }}
              >
                {/* <Link
                to={{   <div
              style={{ fontSize: matches ? 18 : 18 }}
              className={classes.assistanceText}
            >
              Be sure to check that your deliver details and delivery address
              are correct
            </div>: streetAddress,
              
            <Paper
              style={{ marginBottom: matches ? 82 : 16 }}
              elevation={3}
              className={classes.review}
            >
              <div
                style={{
                  fontSize: matches ? 32 : 24,
                  fontWeight: "bold",
                  marginBottom: matches ? 32 : 16,
                }}
                className={classes.header}
              >
                Continue Order Review
              </div>
              <div
                style={{ fontSize: matches ? 18 : 18 }}
                className={classes.assistanceText}
              >
                Be sure to check that your deliver details and delivery address
                are correct
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: 24,
                  marginBottom: matches ? 40 : 8,
                }}
              >
                {/* <Link
                to={{
                  pathname: `/review-order`,
                  state: {
                    firstName: firstName,
                    lastName: lastName,
                    mobile: mobile,
                    streetAddress: streetAddress,
                    suburb: suburb,
                    postCode: postCode,
                    city: city,
                    state: state,
                    country: country,
                    totalPoints: totalPoints,
                  },
                }}
              > */}
                <SecondaryButton
                  style={{
                    opacity:
                      userAccount.redeemablePoints < totalPoints ||
                      !cartList.items
                        ? 0.5
                        : 1,
                  }}
                  type="submit"
                  disabled={
                    userAccount.redeemablePoints < totalPoints ||
                    !cartList.items
                  }
                >
                  Review Order
                </SecondaryButton>
                {/* </Link> */}
              </div>
            </Paper>
          </div>
        </form>
      </div>
    </div>
  );
}
