import {
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { errorSnack } from '../snackbar/snackbarSlice';

import axios from 'axios';

import { uuidv4 } from '../../app/utils';
import baseURL from '../../app/utils.js';
axios.interceptors.request.use(
   (req) => {
      req.headers.common['X-Request-ID'] = uuidv4();
      return req;
   },
   (err) => {
      return Promise.reject(err);
   }
);

export const addProductToCart = createAction(
  'wishlists/addProductToCart',
  function (product) {
    return {payload: product};
  }
);

export const removeProductFromCart = createAction(
  'wishlists/removeProductFromCart',
  function (id) {
    return {payload: id};
  }
);

export const fetchWishlist = createAsyncThunk(
  'wishlists/fetchWishlist',
  async (args, thunkAPI) => {
    const config = {
      headers: {Authorization: `Bearer ${args.token}`},
    };
    try {
      //  const response = await axios.get(`${baseURL}/v1/admin/users/wishlist?statuses=1,2&name=Sed&order_by=id&sort=asc&offset=0&limit=10`);
      const response = await axios.get(
        `${baseURL}/v1/admin/users/${args.id}/wishlists`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.message.includes('401')) {
        localStorage.removeItem('clientUser');
        localStorage.removeItem('clientToken');
      }
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              'Error fetching Wishlist list'
          )
        );
      }
      return thunkAPI.rejectWithValue({error: error.message});
    }
  }
);

export const addProductToWishlist = createAsyncThunk(
  'wishlists/addProductToWishlist',
  async (args, thunkAPI) => {
    const config = {
      headers: {Authorization: `Bearer ${args.token}`},
    };
    try {
      const response = await axios.post(
        `${baseURL}/v1/admin/users/${args.id}/wishlists`,
        args.product,
        config
      );

      thunkAPI.dispatch(
        fetchWishlist({
          token: args.token,
          id: args.id,
        })
      );
      return await response.data;
    } catch (error) {
      if (error.message.includes('401')) {
        localStorage.removeItem('clientUser');
        localStorage.removeItem('clientToken');
      }
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || 'Error creating Wishlist'
          )
        );
      }
      return thunkAPI.rejectWithValue({error: error.message});
    }
  }
);

export const removeProductFromWishlist = createAsyncThunk(
  'wishlists/removeProductFromWishlist',
  async (args, thunkAPI) => {
    const config = {
      headers: {Authorization: `Bearer ${args.token}`},
    };
    try {
      const response = await axios.delete(
        `${baseURL}/v1/admin/users/${args.id}/wishlists/product/${args.prodID}`,
        config
      );
      thunkAPI.dispatch(
        fetchWishlist({
          token: args.token,
          id: args.id,
        })
      );

      return await response.data;
    } catch (error) {
      if (error.message.includes('401')) {
        localStorage.removeItem('clientUser');
        localStorage.removeItem('clientToken');
      }
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || 'Error deleting Wishlist'
          )
        );
      }
      return thunkAPI.rejectWithValue({error: error.message});
    }
  }
);

const wishlistSlice = createSlice({
  name: 'wishlists',
  initialState: {
    wishlist: [],
    loading: 'idle',
    message: '',
    error: '',
    cart: [],
  },
  reducers: {
    addProductToCart(state, action) {
      state.cart = [...state.cart, action.payload];
      state.loading = 'a';
    },
    removeProductFromCart(state, action) {
      state.cart = state.cart.filter(
        (prod) => prod.product.id !== action.payload
      );
      state.loading = 'run';
    },
    resetWishlistCart(state, action) {
      state.wishlist =  [];
      state.loading =  'idle';
      state.message =  '';
      state.error =  '';
      state.cart =  [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWishlist.pending, (state) => {
      // state.wishlist = [];
      state.loading = 'loading';
    });
    builder.addCase(fetchWishlist.fulfilled, (state, {payload}) => {
      state.wishlist = payload;
      state.loading = 'loaded';
    });
    builder.addCase(fetchWishlist.rejected, (state, action) => {
      state.loading = 'error';
      state.error = action.error.message;
    });

    builder.addCase(removeProductFromWishlist.pending, (state) => {
      state.message = '';
      state.loading = 'loading';
    });
    builder.addCase(removeProductFromWishlist.fulfilled, (state, {payload}) => {
      // state.message = payload;
      state.loading = 'loaded';
    });
    builder.addCase(removeProductFromWishlist.rejected, (state, action) => {
      state.loading = 'error';
      state.error = action.error.message;
    });
    builder.addCase(addProductToWishlist.pending, (state) => {
      state.message = '';
      state.loading = 'loading';
    });
    builder.addCase(addProductToWishlist.fulfilled, (state, {payload}) => {
      // state.message = payload;
      state.loading = 'loaded';
    });
    builder.addCase(addProductToWishlist.rejected, (state, action) => {
      state.loading = 'error';
      state.error = action.error.message;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  resetWishlistCart
} = wishlistSlice.actions;

export const selectWishlist = createSelector(
  (state) => ({
    wishlists: state.wishlists,
    loading: state.wishlists.loading,
    error: state.wishlists.error,
    wishlist: state.wishlists.wishlist,
    cart: state.wishlists.cart,
  }),
  (state) => state
);
export default wishlistSlice.reducer;
