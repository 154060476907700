import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import { useHistory } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    display: 'flex',
    flexWrap: 'wrap',
  },
  card: {
    borderRadius: 16,
    padding: 24,
    margin: 24,
  },
  helpColor: {
    color: theme.palette.primaryFont.main,
  },
}));

export default function HelpCard(props) {
  const matches = useMediaQuery('(min-width:850px)');
  const { alt, link, icon, text } = props;
  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    history.push(link);
  };

  return (
    <div className={classes.root}>
      <Paper
        style={{ height: matches ? 425 : 350, width: matches ? 375 : 300 }}
        className={classes.card}
        elevation={4}
        onClick={handleClick}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: matches ? 275 : 200,
          }}
        >
          {icon}
        </div>
        <div
          className={classes.helpColor}
          style={{
            fontSize: matches ? 32 : 28,
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          {text}
        </div>
      </Paper>
    </div>
  );
}
