import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

import axios from "axios";
import { errorSnack, infoSnack, successSnack } from "../snackbar/snackbarSlice";

import { uuidv4 } from "../../app/utils";
import baseURL from "../../app/utils.js";
axios.interceptors.request.use(
  (req) => {
    req.headers.common["X-Request-ID"] = uuidv4();
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export const fetchMorePrograms = createAsyncThunk(
  "programs/fetchMorePrograms",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/programs${args.query}&offset=${args.offset}`,
        config
      );
      // thunkAPI.dispatch(setProgramsAmount(response.headers["x-total-count"]));
      return await response.data;
    } catch (error) {
      if (error.message.includes("401")) {
        localStorage.removeItem("clientUser");
        localStorage.removeItem("clientToken");
      }
      if (error.response && error.response.data) {
        // thunkAPI.dispatch(
        //   errorSnack(
        //     error.response.data.error_description ||
        //       'Error fetching products list'
        //   )
        // );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchPrograms = createAsyncThunk(
  "programs/fetchPrograms",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/programs${args.query}`,
        config
      );
      thunkAPI.dispatch(setProgramsAmount(response.headers["x-total-count"]));
      thunkAPI.dispatch(setLastQuery(args.query));
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching Programs list"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const filterPrograms = createAsyncThunk(
  "programs/filterPrograms",
  async (args, thunkAPI) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${args.token}` },
      };
      const response = await axios.get(
        `${baseURL}/v1/admin/programs${args.query}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching Programs list"
          )
        );
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchProgramByID = createAsyncThunk(
  "programs/fetchProgramByID",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      const response = await axios.get(
        `${baseURL}/v1/admin/programs/${args.id}`,
        config
      );
      return await response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description ||
              "Error fetching Program data"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const deleteProgram = createAsyncThunk(
  "programs/deleteProgram",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //full options
      const response = await axios.delete(
        `${baseURL}/v1/admin/programs/${args.id}`,
        config
      );
      thunkAPI.dispatch(successSnack("Program deleted"));
      return await response;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error deleting Program"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const updateProgram = createAsyncThunk(
  "programs/updateProgram",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //full options
      const response = await axios.patch(
        `${baseURL}/v1/admin/programs/${args.id}`,
        args.program,
        config
      );
      // thunkAPI.dispatch(fetchPrograms({token: args.token, query: '?limit=25'}));
      thunkAPI.dispatch(fetchProgramByID({ token: args.token, id: args.id }));

      // thunkAPI.dispatch(successSnack('Program updated'));
      thunkAPI.dispatch(infoSnack("Account details updated successfully"));

      return await response;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error updating Program"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const createProgram = createAsyncThunk(
  "programs/createProgram",
  async (args, thunkAPI) => {
    const config = {
      headers: { Authorization: `Bearer ${args.token}` },
    };
    try {
      //full options
      const response = await axios.post(
        `${baseURL}/v1/admin/programs`,
        args.program,
        config
      );
      thunkAPI.dispatch(successSnack("Program created successfully"));
      thunkAPI.dispatch(
        fetchPrograms({ token: args.token, query: "?limit=25" })
      );

      return await response;
    } catch (error) {
      if (error.response && error.response.data) {
        thunkAPI.dispatch(
          errorSnack(
            error.response.data.error_description || "Error creating Program"
          )
        );
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getFullList = createAsyncThunk(
  "programs/getFullList",
  async (args, thunkAPI) => {
    let objToMap = [];
    for (let i = 0; i < (args.targetLength - args.offset) / 100; i++) {
      objToMap.push({
        token: args.token,
        query: args.query,
        offset: args.offset + 100 * i,
      });
    }

    Promise.all(
      objToMap.map((requestData) =>
        thunkAPI.dispatch(fetchMorePrograms(requestData))
      )
    )
      .then((results) => {})
      .catch((err) => {
        return thunkAPI.rejectWithValue("Error fetching full claims list");
      });
  }
);

const programsSlice = createSlice({
  name: "programs",
  initialState: {
    program: {},
    programsList: [],
    programsFilter: [],
    loading: "idle",
    message: "",
    error: "",
    editing: false,
    success: false,
    programsAmount: "",
    lastQuery: "",
  },
  reducers: {
    setProgram(state, action) {
      state.program = action.payload;
      state.editing = true;
    },
    setLastQuery: (state, action) => {
      state.lastQuery = action.payload;
    },
    setProgramsAmount: (state, action) => {
      state.programsAmount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPrograms.pending, (state) => {
      state.programsList = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(fetchPrograms.fulfilled, (state, { payload }) => {
      state.programsList = payload;
      state.programsFilter = payload;
      state.loading = "loaded";
      state.editing = false;
    });
    builder.addCase(fetchPrograms.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchMorePrograms.fulfilled, (state, { payload }) => {
      state.error = "";
      state.programsList = state.programsList.concat(payload);
      state.loading = "loaded";
    });
    builder.addCase(fetchMorePrograms.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(getFullList.fulfilled, (state, { payload }) => {
      state.error = "";
      state.programsList = state.programsList.sort(function (a, b) {
        return a.id - b.id;
      });
      state.loading = "loaded";
    });
    builder.addCase(getFullList.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(fetchProgramByID.pending, (state) => {
      state.program = {};
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(fetchProgramByID.fulfilled, (state, { payload }) => {
      state.program = payload;
      state.loading = "loaded";
      state.editing = true;
    });
    builder.addCase(fetchProgramByID.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(deleteProgram.pending, (state) => {
      state.message = "";
      state.loading = "loading";
    });
    builder.addCase(deleteProgram.fulfilled, (state, { payload }) => {
      // state.message = payload;
      state.loading = "loaded";
    });
    builder.addCase(deleteProgram.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(updateProgram.pending, (state) => {
      state.message = "";
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(updateProgram.fulfilled, (state, { payload }) => {
      // state.message = payload;
      state.loading = "loaded";
    });
    builder.addCase(updateProgram.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(filterPrograms.pending, (state) => {
      state.programsFilter = [];
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(filterPrograms.fulfilled, (state, { payload }) => {
      state.programsFilter = payload;
      state.loading = "loaded";
    });
    builder.addCase(filterPrograms.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
    builder.addCase(createProgram.pending, (state) => {
      state.message = "";
      state.loading = "loading";
      state.editing = false;
    });
    builder.addCase(createProgram.fulfilled, (state, { payload }) => {
      // state.message = payload;
      state.loading = "loaded";
    });
    builder.addCase(createProgram.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
  },
});
// Action creators are generated for each case reducer function
export const { setProgram, setLastQuery, setProgramsAmount } =
  programsSlice.actions;

export const selectPrograms = createSelector(
  (state) => ({
    programs: state.programs,
    programsList: state.programs.programsList,
    programsFilter: state.programs.programsFilter,
    loading: state.programs.loading,
    error: state.programs.error,
    editing: state.programs.editing,
    success: state.programs.success,
    program: state.programs.program,
    programsAmount: state.programs.programsAmount,
    lastQuery: state.programs.lastQuery,
  }),
  (state) => state
);
export default programsSlice.reducer;
