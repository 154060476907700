import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { setXClientHost } from "../../app/utils.js";
import { selectCustomisation } from "../customisation/customisationSlice";
import AuthDialog from "./AuthDialog";
import { resetPassword, selectAuth } from "./authSlice.js";

const ColorButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.secondaryButton.main,
    color: theme.palette.secondaryButtonFont.main,
    border: `2px solid ${theme.palette.secondaryButtonFont.main}`,
    borderRadius: 24,
    "&:hover": {
      backgroundColor: `${theme.palette.secondaryButtonFont.main}2b`,
    },
    // fontWeight: 'bold',
  },
}))(Button);

const SecondaryButton = withStyles((theme) => ({
  root: {
    // margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.secondaryButton.main,
    color: theme.palette.secondaryButtonFont.main,
    border: `2px solid ${theme.palette.secondaryButtonFont.main}`,
    borderRadius: 24,

    "&:hover": {
      backgroundColor: `${theme.palette.secondaryButtonFont.main}2b`,
    },
    // fontWeight: 'bold',
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "10px",
    backgroundColor: "#fff",
    padding: "45px",
    marginTop: 32,
    maxWidth: 800,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  linkText: {
    fontSize: 16,
    display: "flex",
    alignItems: "center",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
}));

export default function ResetPassword(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  // const resetSuccess = useSelector((state) => state.signin.reset);
  // const error = useSelector((state) => state.signin.error);
  const { customisation } = useSelector(selectCustomisation);

  const { auth, error, loading, signedIn, success } = useSelector(selectAuth);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmError, setConfirmError] = useState("");
  const [resetSuccess, setResetSuccess] = useState(false);
  const [dialogError, setDialogError] = useState(false);

  const submitReset = (event) => {
    event.preventDefault();
    const credentials = {
      username: username,
      password: password,
      resetToken: window.location.search.split("=")[1],
    };

    if (!validatePassword()) {
      setPasswordError("Invalid password");
    } else if (password !== confirmPassword) {
      setConfirmError("Passwords don't match");
    } else {
      setPasswordError("");
      setConfirmError("");
      dispatch(
        resetPassword({
          host: setXClientHost(),
          userDetails: credentials,
        })
      );
      setResetSuccess(success);
      setDialogError(error);
    }
  };

  const validatePassword = () => {
    var conditions = /^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{8,99}$/;
    if (password.match(conditions)) {
      return true;
    } else {
      return false;
    }
  };
  // resetpassword?token=Sm3rctzWHa135kNnsenfc3wPXqahBcFBfAcV2qMge5OjXhY2CVPbzUQbfkNWH5jl
  // if (resetSuccess !== null) {
  //   history.push('/signin');
  // }

  return (
    <Card style={{ width: "100%" }} elevation={0}>
      <Container className={classes.paper}>
        <div>
          <Typography
            variant="h2"
            style={{
              marginBottom: 32,

              fontWeight: "bold",
            }}
          >
            Reset Password
          </Typography>
        </div>
        <Grid>
          <Typography>
            New password must be at least 8 characters and contain at least one
            number.
          </Typography>
        </Grid>
        <form className={classes.form} onSubmit={submitReset}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username/Email"
            name="username"
            autoComplete="username"            
            onChange={(e) => setUsername(e.target.value)}
            error={error}
            helperText={error ? error.message : ""}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="new-password"
            label="New Password"
            type="password"
            id="new-password"
            autoComplete="new-password"
            onChange={(e) => setPassword(e.target.value)}
            error={passwordError}
            helperText={passwordError ? passwordError : ""}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirm-password"
            label="Confirm Password"
            type="password"
            id="confirm-password"
            autoComplete="new-password"
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={confirmError}
            helperText={confirmError ? confirmError : ""}
          />
          <ColorButton type="submit" fullWidth variant="contained">
            Reset Password
          </ColorButton>
          <Link to="/signin">
            <SecondaryButton type="link" fullWidth variant="contained">
              Return to login
            </SecondaryButton>
          </Link>
        </form>
      </Container>
      <AuthDialog
        title={success ? "Success" : error ? "Something went wrong" : ""}
        message={
          success
            ? "Your password has been reset"
            : error
            ? error.error_description
            : ""
        }
        openDialog={success === true || dialogError != false}
      />
    </Card>
  );
}
