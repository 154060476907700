import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  selectCategories
} from "../features/categories/categoriesSlice";
import {
  fetchProducts,
  selectCoreProducts,
} from "../features/products/coreProductsSlice";

const useStyles = makeStyles((theme) => ({
  headMenuContainer: {
    color: theme.palette.secondaryFont.main,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    fontWeight: "bold",
    fontSize: 18,
    // border: '2px solid #F4F4F4',
    marginTop: 8,
    paddingLeft: 24,
    paddingRight: 24,
  },
  headMenuItem: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  subMenuContainer: {
    borderBottom: "2px solid #F4F4F4",
    color: theme.palette.secondaryFont.main,
    padding: 8,
    // paddingLeft: 24,
    // paddingRight: 24,
    width: "100%",

    flexDirection: "column",
  },
  subMenuItemContainer: {
    marginRight: 32,
    flexDirection: "column",
  },
  subMenuItem: {
    cursor: "pointer",
    fontWeight: "bold",
    fontSize: 16,
    marginBottom: 8,
    marginRight: 8,
    paddingLeft: 8,
    color: theme.palette.highlight.main,
  },
  childMenuItem: {
    cursor: "pointer",
    fontSize: 14,
    marginBottom: 4,
    marginRight: 8,
    paddingLeft: 24,
    color: theme.palette.primary.main,
  },
}));

export default function MobileMenu(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState("");
  const [selectedIndex, setSelectedIndex] = useState("");
  const [selectedCat, setSelectedCat] = useState({ id: 1, name: "" });
  const { drawerOpen, setDrawerOpen } = props;

  //categories
  const { categories, categoriesList, categoriesTree } =
    useSelector(selectCategories);
  let jwtToken = localStorage.getItem("clientToken");
  const { productList, brands } = useSelector(selectCoreProducts);

  const handleClick = (e, id, index) => {
    setSelected(id);
    setSelectedIndex(index);
    setOpen(selected === id ? !open : true);
  };

  const handleClickCategory = (e, id, name) => {
    // setSelected(id);
    // setOpen(selected === id ? !open : true);
    let urlName = name.split(" ").join("-").toLowerCase();
    dispatch(
      fetchProducts({
        token: jwtToken,
        query: `?limit=24&categories=${id}`,
      })
    );
    // props.onClick("left", false);
    history.push(`/${urlName}`);
    setDrawerOpen(false);
  };

  const handleBrandsClick = (e, id, brandID, name) => {
    // setSelected(id);
    // setOpen(selected === id ? !open : true);
    let urlName = name.split(" ").join("-").toLowerCase();
    dispatch(
      fetchProducts({
        token: jwtToken,
        query: `?limit=24&categories=${id}&brands=${brandID}`,
      })
    );
    history.push(`/${urlName}`);
  };

  useEffect(() => {
    let selection = categoriesTree.filter(
      (category) => category.id === selected
    );
    selection.length && setSelectedCat(selection[0]);
  }, [window.location.pathname, selected]);

  useEffect(() => {
    setOpen(false);
  }, [productList]);

  return (
    <div className={classes.menuContainer}>
      <div elevation={0}>
        <div className={classes.headMenuContainer}>
          {categoriesTree.map((category, index) => (
            <div>
              <div
                key={category.id}
                onClick={(e) => handleClick(e, category.id, category.id)}
                className={classes.headMenuItem}
              >
                {category.name}
                <div style={{ alignSelf: "flex-end" }}>
                  <KeyboardArrowDownIcon />
                </div>
              </div>
              <div
                className={classes.subMenuContainer}
                style={{
                  display:
                    open && selectedIndex === category.id ? "flex" : "none",
                }}
              >
                {categoriesTree
                  .filter((category) => category.id === selected)
                  .map((cat) => (
                    <div key={"all"}>
                      <div
                        onClick={(e) =>
                          handleClickCategory(e, cat.id, `${cat.name}`)
                        }
                        className={classes.subMenuItem}
                      >
                        All {cat.name}
                      </div>
                    </div>
                  ))}
                {categoriesTree
                  .filter((category) => category.id === selected)
                  .map(
                    (cat, index) =>
                      cat.subCategories &&
                      cat.subCategories.map(
                        (subCat) =>
                          subCat.depth === 2 && (
                            <div
                              style={{
                                display:
                                  selectedIndex !== cat.id ? "none" : "flex",
                              }}
                              key={subCat.id}
                              className={classes.subMenuItemContainer}
                            >
                              <div
                                onClick={(e) =>
                                  handleClickCategory(
                                    e,
                                    subCat.id,
                                    `${cat.name}/${subCat.name}`
                                  )
                                }
                                className={classes.subMenuItem}
                              >
                                {subCat.name}
                              </div>
                              {subCat.subCategories &&
                                subCat.subCategories.map((childCat) => (
                                  <div
                                    key={childCat.id}
                                    onClick={(e) =>
                                      handleClickCategory(
                                        e,
                                        childCat.id,
                                        `${cat.name}/${subCat.name}/${childCat.name}`
                                      )
                                    }
                                    className={classes.childMenuItem}
                                  >
                                    {childCat.name}
                                  </div>
                                ))}
                            </div>
                          )
                      )
                  )}

                <div
                  // style={{marginLeft: 'auto', marginRight: 0}}
                  key={"brands"}
                  className={classes.subMenuItemContainer}
                >
                  <div
                    // onClick={(e) =>
                    //   handleClickCategory(e, selectedCat.id, `${selectedCat.name}`)
                    // }
                    style={{ cursor: "auto" }}
                    className={classes.subMenuItem}
                  >
                    Search by Brand
                  </div>
                  {brands[selectedCat.id] &&
                    brands[selectedCat.id].slice(0, 5).map((brand) => (
                      <div
                        key={selectedCat.id + brand.id}
                        onClick={(e) =>
                          handleBrandsClick(
                            e,
                            selectedCat.id,
                            brand.id,
                            `${selectedCat.name}`
                          )
                        }
                        className={classes.childMenuItem}
                      >
                        {brand.name}
                      </div>
                    ))}
                  <div
                    key={selectedCat.id + 2}
                    style={{ fontWeight: "bold", fontStyle: "italic" }}
                    onClick={(e) =>
                      handleClickCategory(
                        e,
                        selectedCat.id,
                        `${selectedCat.name}`
                      )
                    }
                    className={classes.childMenuItem}
                  >
                    All Brands
                  </div>
                </div>
              </div>
            </div>
          ))}

          {/* <div className={classes.headMenuItem}>What's New</div> */}
        </div>
        <div elevation={3}>
          {/* <div
            className={classes.subMenuContainer}
            style={{
              display: open ? 'flex' : 'none',
            }}
          >
            {categoriesTree
              .filter((category) => category.id === selected)
              .map(
                (cat) =>
                  cat.subCategories &&
                  cat.subCategories.map(
                    (subCat) =>
                      subCat.depth === 2 && (
                        <div
                          key={subCat.id}
                          className={classes.subMenuItemContainer}
                        >
                          <div
                            onClick={(e) =>
                              handleClickCategory(
                                e,
                                subCat.id,
                                `${cat.name}/${subCat.name}`
                              )
                            }
                            className={classes.subMenuItem}
                          >
                            {subCat.name}
                          </div>
                          {subCat.subCategories &&
                            subCat.subCategories.map((childCat) => (
                              <div
                                key={childCat.id}
                                onClick={(e) =>
                                  handleClickCategory(
                                    e,
                                    childCat.id,
                                    `${cat.name}/${subCat.name}/${childCat.name}`
                                  )
                                }
                                className={classes.childMenuItem}
                              >
                                {childCat.name}
                              </div>
                            ))}
                        </div>
                      )
                  )
              )}
            {categoriesTree
              .filter((category) => category.id === selected)
              .map((cat) => (
                <div>
                  <div
                    onClick={(e) =>
                      handleClickCategory(e, cat.id, `${cat.name}`)
                    }
                    className={classes.subMenuItem}
                  >
                    All {cat.name}
                  </div>
                </div>
              ))}
            <div
              style={{marginLeft: 'auto', marginRight: 0}}
              key={'brands'}
              className={classes.subMenuItemContainer}
            >
              <div
                // onClick={(e) =>
                //   handleClickCategory(e, selectedCat.id, `${selectedCat.name}`)
                // }
                style={{cursor: 'auto'}}
                className={classes.subMenuItem}
              >
                Search by Brand
              </div>
              {brands[selectedCat.id] &&
                brands[selectedCat.id].slice(0, 5).map((brand) => (
                  <div
                    key={selectedCat.id + brand.id}
                    onClick={(e) =>
                      handleBrandsClick(
                        e,
                        selectedCat.id,
                        brand.id,
                        `${selectedCat.name}`
                      )
                    }
                    className={classes.childMenuItem}
                  >
                    {brand.name}
                  </div>
                ))}
              <div
                key={selectedCat.id + 2}
                style={{fontWeight: 'bold', fontStyle: 'italic'}}
                onClick={(e) =>
                  handleClickCategory(e, selectedCat.id, `${selectedCat.name}`)
                }
                className={classes.childMenuItem}
              >
                All Brands
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
