import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import MemberTable from "../../common/MemberTable";
import { fetchUserOrders } from "../orders/ordersSlice";

import {
  selectOrders,
  setOrder
} from "./ordersSlice";

import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  progress: {
    margin: theme.spacing(2),
  },
  root: {
    display: "flex",
    flexFlow: "column",
  },
}));

export default function MemberOrders(props) {
  const { userAccount, user } = props;
  let jwtToken = localStorage.getItem("clientToken");
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [orderNames, setOrderNames] = useState([]);
  const [userIDs, setUserIDs] = useState([]);
  const { orders, error, loading, ordersList, ordersFilter } =
    useSelector(selectOrders);
  const [statusNames, setStatusNames] = useState([]);
  const [statusIDs, setStatusIDs] = useState([]);
  const [statusList, setStatusList] = useState([
    { id: 1, value: "Active", name: "Active" },
    { id: 2, value: "Inactive", name: "Inactive" },
    { id: 3, value: "Deleted", name: "Deleted" },
    { id: 4, value: "Unavailable", name: "Unavailable" },
  ]);

  const headCells = [
    {
      id: "id",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Order #",
      long: false,
    },

    {
      id: "totalProducts",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Products",
      long: false,
    },
    {
      id: "totalPoints",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Point Total",
      long: false,
    },
    {
      id: "notes",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Notes",
      long: false,
    },
    {
      id: "updatedAt",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Updated",
      long: false,
    },
    {
      id: "statusName",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Status",
      long: false,
    },
  ];

  const handleOrdersChange = (event) => {
    let idsArr = [];
    // event.target.value.forEach((selected) => {
    let selectedOrder = ordersList.filter(
      (order) => order.orderNumber === event.target.value
    );
    selectedOrder && idsArr.push(selectedOrder[0].id);
    // });

    setOrderNames(event.target.value);
    setUserIDs(idsArr);
  };

  const handleStatusChange = (event) => {
    let idsArr = [];
    event.target.value.forEach((selected) => {
      let selectedStatus = statusList.filter(
        (status) => status.name === selected
      );
      selectedStatus[0] && idsArr.push(selectedStatus[0].id);
    });

    setStatusNames(event.target.value);
    setStatusIDs(idsArr);
  };

  const [ready, setReady] = useState(false);
  if (loading === "loaded" && !ready) {
    setReady(true);
  }

  const handleEdit = (id) => {
    // dispatch(
    //   fetchUserByID({
    //     id: id,
    //     token: jwtToken,
    //   })
    // );
  };

  const handleEditClick = (id) => {
    console.log("edit clicked");
    const selectedOrder = ordersFilter.filter((order) => order.id === id);
    dispatch(setOrder(selectedOrder[0]));

    history.push(`/admin/orders/${id}`);
  };

  const handleSearch = () => {
    // const searchQuery = `?&limit=200&statuses=${statusIDs.join(
    //   ','
    // )}&name=${orderNames}`;
    // dispatch(
    //   filterUsers({
    //     query: searchQuery,
    //     token: jwtToken,
    //   })
    // );
  };

  useEffect(() => {
    dispatch(
      fetchUserOrders({
        token: jwtToken,
        userID: user.id,
      })
    );
  }, []);

  const handleClear = () => {
    setOrderNames([]);
    setUserIDs([]);
    setStatusNames([]);
    setStatusIDs([]);
  };

  const selectNames = ["order", "status"];  

  const cellList = headCells.map((cell) => {
    return cell.id;
  });
  let content;
  if (loading === "loading") {
    content = (
      <CircularProgress className={classes.progress} color="secondary" />
    );
  } else if (loading === "loaded" || loading === "idle") {
    content = (
      <MemberTable
        data={props.ordersList}
        actionsLists={[props.ordersList, statusList]}
        actionsHandleChange={[handleOrdersChange, handleStatusChange]}
        actionsNames={[orderNames, statusNames]}
        headCells={headCells}
        cellList={cellList}
        handleEditClick={userAccount.roleID <= 2 ? handleEditClick : () => {}}
        handleSearch={handleSearch}
        handleClear={handleClear}
        handleEdit={handleEdit}
        area={"order"}
        selectNames={selectNames}
      />
    );
  }

  return (
    <Grid
      container
      spacing={3}
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      style={{
        height: "100%",
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      {content}
    </Grid>
  );
}
