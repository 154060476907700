import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import Input from '@material-ui/core/Input';

import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  // transformOrigin: {
  //   vertical: 'top',
  //   horizontal: 'left',
  // },
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 'fit-content',
      fontSize: 12,
    },
  },
};

export default function TableSelect(props) {
  const {
    classes,
    filterList,
    filterStatus,
    handleFilterChange,
    name,
    multiple,
    cats,
    update,
  } = props;

  return (
    <FormControl
      className={classes.formControl}
      id={`multiple-checkbox-${name.split(' ').join('-')}-parent`}
    >
      {/* <FormHelperText style={{color: 'white'}}>
        {name.toUpperCase()}
      </FormHelperText> */}
      {/* <InputLabel
        id={`multiple-checkbox-label-${name.split(" ").join("-")}`}
        style={{color: 'white'}}
      >
        {name.toUpperCase()}
      </InputLabel> */}
      <Select
        labelId={`multiple-checkbox-label-${name.split(' ').join('-')}`}
        id={`multiple-checkbox-${name.split(' ').join('-')}`}
        multiple={multiple}
        value={filterStatus}
        onChange={handleFilterChange}
        input={<Input />}
        margin="dense"
        style={{marginLeft: 0, marginRight: 0}}
        displayEmpty
        disabled={cats}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return (
              <em>
                {name === 'assigned to'
                  ? 'Assigned to'
                  : update
                  ? `Update ${name}...`
                  : `Filter by ${name}...`}
              </em>
            );
          }
          if (multiple) {
            return selected.join(', ');
          }
          return selected;
        }}
        MenuProps={MenuProps}
      >
        {filterList.map((filterItem) => (
          <MenuItem
            key={filterItem.id}
            value={filterItem.name ? filterItem.name : filterItem.displayName}
          >
            {multiple && (
              <Checkbox
                checked={
                  filterStatus.indexOf(
                    filterItem.name ? filterItem.name : filterItem.displayName
                  ) > -1
                }
              />
            )}
            <ListItemText
              primary={
                filterItem.name ? filterItem.name : filterItem.displayName
              }
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

// TableActions.propTypes = {
//   classes: PropTypes.object.isRequired,
//   numSelected: PropTypes.number.isRequired,
//   onRequestSort: PropTypes.func.isRequired,
//   onSelectAllClick: PropTypes.func.isRequired,
//   order: PropTypes.oneOf(['asc', 'desc']).isRequired,
//   orderBy: PropTypes.string.isRequired,
//   rowCount: PropTypes.number.isRequired,
//   selectAll: PropTypes.bool.isRequired,
// };
