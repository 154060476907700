import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const BackButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0, 2),
    backgroundColor: theme.palette.secondaryButton.main,
    color: theme.palette.secondaryButtonFont.main,
    cursor: "pointer",
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: theme.palette.secondaryButtonFont.main,
    borderRadius: 20,
    // width: 100,
    maxHeight: 38.5,
    "&:hover": {
      backgroundColor: `${theme.palette.secondaryButtonFont.main}2b`,
    },
    fontWeight: "bold",
  },
}))(Button);

const ContinueButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.secondaryButton.main,
    color: theme.palette.secondaryButtonFont.main,
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: theme.palette.secondaryButtonFont.main,
    borderRadius: 30,
    height: 40,
    fontSize: 20,
    width: 200,
    "&:hover": {
      backgroundColor: `${theme.palette.secondaryButtonFont.main}2b`,
    },
    fontWeight: "bold",
  },
}))(Button);

const ErrorButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.secondaryButton.main,
    color: theme.palette.secondaryButtonFont.main,
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: theme.palette.secondaryButtonFont.main,
    borderRadius: 30,
    height: 40,
    fontSize: 20,
    width: 400,
    "&:hover": {
      backgroundColor: `${theme.palette.secondaryButtonFont.main}2b`,
    },
    fontWeight: "bold",
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  claimContainer: {
    // width: '50%',
  },
  cardContainer: {
    backgroundColor: "#fff",
    borderRadius: 16,
    color: theme.palette.secondaryFont.main,

    padding: 32,
  },
  header: {
    fontWeight: "bold",

    color: theme.palette.primaryFont.main,
    marginTop: 24,
  },
  title: {
    color: theme.palette.primaryFont.main,
    fontWeight: "bold",

    paddingTop: 8,
    textAlign: "center",
  },
  subTitle: {
    fontWeight: "bold",
    marginBottom: 8,
  },
  text: {
    marginBottom: 16,
  },

  progressContainer: {
    display: "flex",
    backgroundColor: "#FFF",
    margin: "auto",
    maxWidth: 800,
    marginTop: 16,
    justifyContent: "center",
  },
  continueContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 0,
  },
  textLabel: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 8,
    whiteSpace: "pre",
    // marginTop: 32,
  },
  progressBackground: {
    backgroundColor: theme.palette.highlight.main,
    borderRadius: 20,
  },
}));

const columns: GridColDef[] = [
  { field: "col1", headerName: "Column 1", width: 150 },
  { field: "col2", headerName: "Column 2", width: 150 },
];

export default function ClaimStepThree(props) {
  const {
    setStep,
    calculatedPoints,
    setCalculatedPoints,
    handleUpload,
    fileInputRef,
    files,
    // loading,
    invoiceError,
    invoiceItems,
    setInvoiceItems,
    invoiceTotal,
    setInvoiceTotal,
    invoiceNumber,
    invoiceDate,
    client,
    admin,
    handleStep,
    step,
    uploadFileToAWS,
    error,
    setError,
    resetProcess,
    manualEntry,
    setManualEntry,
    claims,
    setClaims,
  } = props;
  const matches = useMediaQuery("(min-width:800px)");

  console.log('error', error)

  const classes = useStyles();
  const navigate = useHistory();
  const handleContinue = () => {
    setStep(4);
  };

  //TODO - add reputation check for points override
  // const calculatePts = () => {
  //   setCalculatedPoints(
  //     Math.floor(
  //       invoiceTotal * (client.pointNumerator / client.monetaryDenominator)
  //     )
  //   );
  // };

  useEffect(() => {
    if (invoiceItems) {
      try {
        setInvoiceTotal(
          invoiceItems
            .map((item) => item.price * item.quantity)
            .reduce((prev, next) => prev + next)
        );
        setClaims((prevClaims) =>
          prevClaims.map((claim, index) => {
            if (index === 0) {
              return {
                ...claim,
                invoiceTotal: invoiceItems
                  .map((item) => Math.floor(item.price * item.quantity))
                  .reduce((prev, next) => prev + next),
              };
            } else {
              return claim;
            }
          })
        );
      } catch (error) {
        setError(error);
      }
    }
  }, [invoiceItems]);

  // useEffect(() => {
  //   if (invoiceTotal) {
  //     calculatePts()
  //   }
  // }, [invoiceTotal]);

  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: null,
  });

  const [unitPrice, setUnitPrice] = useState(null);
  const [productName, setProductName] = useState(null);
  const [quantity, setQuantity] = useState(null);
  const [partNo, setPartNo] = useState(null);

  const onEdit = ({
    id,
    currentUnitPrice,
    currentProductName,
    currentQuantity,
    currentPartNo,
  }) => {
    setInEditMode({
      status: true,
      rowKey: id,
    });
    setUnitPrice(currentUnitPrice);
    setProductName(currentProductName);
    setQuantity(currentQuantity);
    setPartNo(currentPartNo);
  };

  const updateInventory = ({
    id,
    newUnitPrice,
    newProductName,
    newQuantity,
    newPartNo,
  }) => {
    var foundIndex = invoiceItems.findIndex((x) => x.id == id);
    let items = [...invoiceItems];
    let item = { ...items[foundIndex] };

    item.price = newUnitPrice;
    item.quantity = newQuantity;
    item.productName = newProductName;
    item.partNo = newPartNo;

    items[foundIndex] = item;

    setInvoiceItems(items);
  };

  const onSave = ({
    id,
    newUnitPrice,
    newProductName,
    newQuantity,
    newPartNo,
  }) => {
    updateInventory({
      id,
      newUnitPrice,
      newProductName,
      newQuantity,
      newPartNo,
    });
    setInEditMode({
      status: false,
      rowKey: null,
    });
  };

  const onCancel = () => {
    // reset the inEditMode state value
    setInEditMode({
      status: false,
      rowKey: null,
    });
    // reset the unit price state value
    setUnitPrice(null);
    setProductName(null);
    setQuantity(null);
    setPartNo(null);
  };

  return (
    <div
      style={{ width: matches ? "85%" : "100%" }}
      className={classes.claimContainer}
    >
      {!error ? (
        <Paper className={classes.cardContainer} elevation={4}>
          <div>
            <BackButton onClick={handleStep}>
              {step === 0 ? "Home" : "Go Back"}
            </BackButton>

            <Typography className={classes.title} variant="h5">
              Provide a copy of your invoice
            </Typography>
          </div>
          <div className={classes.progressContainer}>
            <img
              src={matches ? "/steptwo.png" : "/mobiletwo.png"}
              width={matches ? "50%" : "90%"}
              alt="empty progress bar"
              className={classes.progressBackground}
            />
          </div>
          {/* <Divider style={{ height: 2 }} /> */}

          <div className={classes.textLabel}>Submit Invoice Below: </div>
          <hr />
          {invoiceNumber && (
            <div>
              <div className={classes.textLabel}>
                Invoice Number: {invoiceNumber}
              </div>
              <div className={classes.textLabel}>
                Invoice Date: {invoiceDate.split("T")[0]}
              </div>
              <hr />
            </div>
          )}
          <input
            ref={fileInputRef}
            style={{ display: "none" }}
            accept=".pdf,.jpeg,.jpg,.png"
            className={classes.input}
            id="contained-button-file"
            // multiple
            onChange={() => {
              handleUpload(0);
            }}
            type="file"
          />
          {invoiceError ? (
            <div style={{ fontSize: 16, color: "#f44336", fontWeight: "bold" }}>
              Something went wrong, please try again later.
            </div>
          ) : invoiceItems ? (
            <div
              style={{
                fontSize: 16,
                width: "100%",
                textAlign: "left",
                overflowX: "scroll",
              }}
            >
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>SKU/Part No.</th>
                    <th>Product Name</th>
                    <th>Quantity</th>
                    <th>Unit Price</th>
                    <th>Total Price</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {invoiceItems.map((item, index) => (
                    <tr key={index}>
                      <td>
                        {inEditMode.status &&
                        inEditMode.rowKey &&
                        admin === item.id ? (
                          <input
                            value={partNo}
                            style={{ width: "100%" }}
                            onChange={(event) => setPartNo(event.target.value)}
                          />
                        ) : (
                          item.partNo
                        )}
                      </td>
                      <td>
                        {inEditMode.status &&
                        inEditMode.rowKey &&
                        admin === item.id ? (
                          <input
                            value={productName}
                            style={{ width: "100%" }}
                            onChange={(event) =>
                              setProductName(event.target.value)
                            }
                          />
                        ) : (
                          item.productName
                        )}
                      </td>
                      <td>
                        {inEditMode.status && inEditMode.rowKey === item.id ? (
                          <input
                            value={quantity}
                            type="number"
                            onChange={(event) =>
                              setQuantity(parseInt(event.target.value))
                            }
                          />
                        ) : (
                          item.quantity
                        )}
                      </td>
                      <td>
                        {inEditMode.status &&
                        inEditMode.rowKey &&
                        admin === item.id ? (
                          <input
                            value={unitPrice}
                            onChange={(event) =>
                              setUnitPrice(event.target.value)
                            }
                          />
                        ) : (
                          "$" + item.price
                        )}
                      </td>
                      <td>${(item.quantity * item.price).toFixed(2)}</td>
                      <td style={{ justifyContent: "center" }}>
                        {inEditMode.status && inEditMode.rowKey === item.id ? (
                          <React.Fragment>
                            <button
                              className={"button-tbl save"}
                              onClick={() =>
                                onSave({
                                  id: item.id,
                                  newUnitPrice: unitPrice,
                                  newProductName: productName,
                                  newQuantity: quantity,
                                  newPartNo: partNo,
                                })
                              }
                            >
                              Save
                            </button>

                            <button
                              className={"button-tbl cancel"}
                              onClick={() => onCancel()}
                            >
                              Cancel
                            </button>
                          </React.Fragment>
                        ) : (
                          <button
                            className={"button-tbl"}
                            onClick={() =>
                              onEdit({
                                id: item.id,
                                currentUnitPrice: item.price,
                                currentProductName: item.productName,
                                currentQuantity: item.quantity,
                                currentPartNo: item.partNo,
                              })
                            }
                          >
                            Edit
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <th>Total: </th>
                    <th>${invoiceTotal}</th>
                  </tr>
                </tfoot>
              </table>
            </div>
          ) : files[0].name ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div>
              <label htmlFor="contained-button-file">
                <BackButton
                  variant="contained"
                  color="primary"
                  component="span"
                >
                  Choose File
                </BackButton>
              </label>
              <Typography className={classes.text} variant="body1">
                Upload a copy of the invoice as proof of purchase in pdf format.
                A statement is not a valid invoice. Maximum file size 8MB.
              </Typography>
              <Typography className={classes.text} variant="body1">
                Invoices must be in a clear and unimpaired manner in order to be
                successfully processed.
              </Typography>
            </div>
          )}
          {/* <hr />
        <Typography
          style={{ marginBottom: matches ? 24 : 24 }}
          className={classes.header}
          variant={matches ? "h5" : "h5"}
        >
          Calculated Points Value: {calculatedPoints}
        </Typography> */}
          <hr />
          {files && files[0].name && (
            <div
              style={{
                margin: matches ? 0 : 0,
              }}
              className={classes.continueContainer}
            >
              <ContinueButton onClick={handleContinue}>Continue</ContinueButton>
            </div>
          )}
        </Paper>
      ) : (
        <Paper className={classes.cardContainer} elevation={4}>
          <div>
            <BackButton onClick={handleStep}>
              {step === 0 ? "Home" : "Go Back"}
            </BackButton>

            <Typography className={classes.title} variant="h5">
              Error
            </Typography>
          </div>
          <div
            style={{
              fontSize: 16,
              color: "#f44336",
              fontWeight: "bold",
              textAlign: "center",
              margin: 10,
            }}
          >
            Your Invoice was unable to be processed.
          </div>
          <hr />
          <div
            style={{
              margin: matches ? 0 : 0,
            }}
            className={classes.continueContainer}
          >
            <ErrorButton
              onClick={() => {
                setManualEntry(true);
                setStep(2);
              }}
            >
              Enter Claim Details Manually
            </ErrorButton>
          </div>
          <div
            style={{
              margin: matches ? 0 : 0,
            }}
            className={classes.continueContainer}
          >
            <ErrorButton onClick={resetProcess}>
              Process another Claim
            </ErrorButton>
          </div>
        </Paper>
      )}
    </div>
  );
}
