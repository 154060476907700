// staging
const baseURL = process.env.REACT_APP_API_URL;
// // staging
// const baseURL =
//   "http://ps-client-api-apse2-stg-alb-981093349.ap-southeast-2.elb.amazonaws.com";
// staging test
// const baseURL =
//   'http://ps-client-test-api-apse2-stg-alb-1377972316.ap-southeast-2.elb.amazonaws.com';

export function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export function isInCart(id, cartList) {
  if (cartList && cartList.length) {
    const prodInCart = cartList.length
      ? cartList.filter((prod) => prod.productID === id)
      : [];
    const inCart = prodInCart.length > 0;
    const qty = inCart ? prodInCart[0].quantity : 0;
    return {
      isInCart: inCart,
      quantity: qty,
    };
  } else {
    return {
      isInCart: false,
      quantity: 0,
    };
  }
}

export function setXClientHost() {
  return window.location.origin === "http://localhost:3000"
    ? process.env.REACT_APP_LOCAL_URL
    : window.location.origin;
}

export function replaceUrlParam(url, paramName, paramValue) {
  if (paramValue == null) {
      paramValue = '';
  }
  var pattern = new RegExp('([?&])' + paramName + '=[^&]*');
  if (url.search(pattern) >= 0) {
      return url.replace(pattern, '$1' + paramName + '=' + paramValue);
  }
  url += (url.indexOf('?') >= 0 ? '&' : '?') + paramName + '=' + paramValue;
  return url;
}

export default baseURL;
