import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputBase from "@material-ui/core/InputBase";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import SearchIcon from "@material-ui/icons/Search";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { selectCarts } from "../features/carts/cartsSlice";
import { selectCustomisation } from "../features/customisation/customisationSlice";
import CheckoutDialog from "../features/payments/CheckoutDialog";
import {
  createPaymentIntent,
  fetchStripeConfig,
  resetPayment,
  selectPayments,
} from "../features/payments/paymentsSlice";
import {
  fetchProducts,
  setQueryParam,
} from "../features/products/coreProductsSlice";
import { fetchClient, selectClients } from "../features/clients/clientsSlice";
import { fetchMyself, selectUsers } from "../features/users/usersSlice";
import { selectWishlist } from "../features/wishlist/wishlistSlice";

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: "center",
    backgroundColor: theme.palette.appbarColor.main,
    display: "flex",
    flexDirection: "row",
    fontWeight: "bold",
    height: "50px",
    paddingLeft: 24,
    paddingRight: 24,
    fontSize: 24,
  },
  itemContainer: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    cursor: "pointer",
    color: theme.palette.appbarFontColor.main,
  },
  itemIcon: {
    paddingtop: 2,
    paddingLeft: 8,
    paddingRight: 8,
    display: "flex",
    alignItems: "center",
  },
  pointsText: {
    color: theme.palette.appbarFontColor.main,
  },
  pointsNumber: {
    color: theme.palette.highlight.main,
  },
  search: {
    position: "relative",
    color: theme.palette.appbarFontColor.main,
    fontWeight: "bold",
    borderRadius: 16,
    fontSize: 20,
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.appbarFontColor.main,
    cursor: "pointer",
    zIndex: 100,
  },
  inputRoot: {
    color: theme.palette.appbarFontColor.main,
    fontWeight: "bold",
    fontSize: 20,
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    color: theme.palette.appbarFontColor.main,
    paddingLeft: 16,
    paddingBottom: 4,
    paddingTop: 4,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  recently: {
    marginLeft: "auto",
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-end",
    flexFlow: "row nowrap",
    alignItems: "center",
    marginLeft: 50,
    color: theme.palette.appbarFontColor.main,
  },
  favourite: {
    color: theme.palette.highlight.main,
  },
  headerItem: {
    backgroundColor: theme.palette.appbarColor.main,
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: theme.palette.headerButton.main,
    borderRadius: "24px",
    color: theme.palette.headerButton.main,
    fontWeight: "bold",
    padding: 4,
    paddingLeft: 12,
    paddingRight: 12,
    textTransform: "capitalize",
    // width: 120,
    cursor: "pointer",
    fontSize: "0.5em",
  },
  quantityBox: {
    display: "flex",
    flexFlow: "row nowrap",
    border: "3px solid",
    borderColor: theme.palette.secondaryFont.main,
    borderRadius: 24,
    height: 25,
    width: 92,
    padding: 4,
  },
  quantityAmount: {
    color: theme.palette.secondaryFont.main,
    height: 25,
    width: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    textAlign: "center",
    marginLeft: 5,
  },
  quantityControls: {
    borderLeft: "2px solid #DDD",
    height: 25,
    width: 25,
  },
  quantityIncrease: {
    borderBottom: "2px solid #DDD",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 12.5,
    width: 25,
    paddingBottom: 4,
  },
  quantityDecrease: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 12.5,
    width: 25,
  },
}));

export default function AppBar(props) {
  const matches = useMediaQuery("(min-width:850px)");
  const midpoint = useMediaQuery("(max-width:1450px)");
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { cartList } = useSelector(selectCarts);
  const { wishlist } = useSelector(selectWishlist);
  const { userAccount } = useSelector(selectUsers);
  const { customisation } = useSelector(selectCustomisation);
  const { client } = useSelector(selectClients);

  let jwtToken = localStorage.getItem("clientToken");

  const [search, setSearchTerm] = useState("");

  const { publishableKey, clientSecret, payment, dialogOpen, orderAmount } =
    useSelector(selectPayments);

  const [totalPoints, setTotalPoints] = useState(null);

  const [stripePromise, setStripePromise] = useState(null);
  const prUpdate = useRef(true);

  const URLCSecret = new URLSearchParams(window.location.search).get(
    "payment_intent_client_secret"
  );

  const [cSecret, setCSecret] = useState(null);

  const [hideAmountPanel, setHideAmountPanel] = useState(false);

  const [purchaseAmount, setPurchaseAmount] = useState(null);
  const [price, setPrice] = useState(null);
  const [priceGST, setPriceGST] = useState(null);

  const [userPoints, setUserPoints] = useState(null);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret: cSecret,
    appearance,
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (purchaseAmount) {
      setPrice((purchaseAmount / client.pointRatio) * 100);
      setPriceGST(Math.floor(((purchaseAmount / client.pointRatio) * 100) * (1 + client.gstRate)));
    }
  }, [purchaseAmount]);

  useEffect(() => {
    dispatch(
      fetchMyself({
        token: jwtToken,
      })
    );
    if (!client.id) {
      dispatch(
        fetchClient({
          token: jwtToken,
        })
      );
    }
  }, [history.location.pathname]);

  const handleClose = () => {
    dispatch(resetPayment());
    setHideAmountPanel(false);
    setPurchaseAmount(orderAmount);
    setOpen(false);
    setPrice(null);
    setPriceGST(null);
  };

  const submitPaymentAmount = () => {
    if (userAccount.id) {
      dispatch(
        createPaymentIntent({
          token: jwtToken,
          userID: userAccount.id,
          amount: { amount: Number(priceGST)},
        })
      );
    }
    setHideAmountPanel(true);
  };

  useEffect(() => {
    dispatch(
      fetchStripeConfig({
        token: jwtToken,
      })
    );
  }, [dispatch]);

  //Check here for multiple firings
  useEffect(() => {
    if (publishableKey) {
      setStripePromise(loadStripe(publishableKey));
    }
    if (URLCSecret && cSecret) {
      setOpen(true);
    }
  }, [publishableKey, URLCSecret]);

  useEffect(() => {
    setCSecret(URLCSecret ? URLCSecret : clientSecret);
  }, [clientSecret, URLCSecret]);

  useEffect(() => {
    setUserPoints(userAccount.redeemablePoints && userAccount.redeemablePoints);
    setTotalPoints(cartList.totalPoints && cartList.totalPoints);
  }, [userAccount.redeemablePoints, cartList.totalPoints]);

  useEffect(() => {
    setOpen(dialogOpen);
  }, [dialogOpen]);

  useEffect(() => {
    setPurchaseAmount(orderAmount);
  }, [orderAmount]);

  const incPurchasePrice = (event) => {
    setPurchaseAmount((pPrice) => pPrice + 1);
  };

  const decPurchasePrice = (event) => {
    if (purchaseAmount - 1 >= 0) {
      setPurchaseAmount((pPrice) => pPrice - 1);
    } else {
      setPurchaseAmount(0);
    }
  };

  const handlePurchasePriceChange = (event) => {
    prUpdate.current = false;

    setPurchaseAmount(event.target.value);
  };

  const handleSearch = () => {
    dispatch(setQueryParam(search));

    dispatch(
      fetchProducts({
        token: jwtToken,
        query: `?query=${search}&limit=24`,
      })
    );
    history.push(`/products/search`);
  };

  const handleEnter = (e) => {
    if (e.which == 13) {
      handleSearch();
    }
  };

  const handleWhatsNew = () => {
    dispatch(
      fetchProducts({
        token: jwtToken,
        query: `?order_by=created_at&sort=desc&limit=24`,
      })
    );
    history.push(`/products`);
  };

  const formatPTS = (str) => {
    return str.toLocaleString();
  };
  return (
    <div
      style={{
        fontSize: midpoint ? 16 : matches ? 20 : 18,
        paddingRight: matches ? 20 : 10,
        flexWrap: 'wrap',
        height: matches ? 50 : 75
      }}
      className={classes.container}
    >
      {/* {matches && ( */}
        <div style={{ display: "flex", margin: matches ? 0 : "10px 0px" }}>
          <div style={{ display: "flex", alignItems: "center", }}>
            <div className={classes.pointsText}>Points Balance: </div>
            <div
              className={classes.pointsNumber}
              style={{
                marginLeft: midpoint ? 8 : 16,
                marginRight: midpoint ? 8 : 48,
              }}
            >
              {userAccount.redeemablePoints
                ? formatPTS(userAccount.redeemablePoints)
                : "0"}
            </div>
          </div>
          <div>
            {process.env.REACT_APP_ENABLE_STRIPE == "true" && (
              <div className={classes.headerItem} onClick={handleClickOpen}>
                Purchase Points
              </div>
            )}
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>Purchase Points</DialogTitle>
              {/* {totalPoints > userAccount.redeemablePoints && (
            <div style={{ width: "90%", textAlign: "center" }}>
              You need ${totalPoints - userAccount.redeemablePoints} to complete
              this redemption{" "}
            </div>
          )} */}
              {!URLCSecret && (
                <div>
                  <div
                    style={{
                      width: "90%",
                      textAlign: "center",
                      padding: 16,
                      paddingBottom: 0,
                    }}
                  >
                    {totalPoints > userAccount.redeemablePoints &&
                      `You need ${
                        totalPoints - userAccount.redeemablePoints
                      } points to complete
              this redemption.`}
                  </div>
                  <div
                    style={{
                      width: "90%",
                      textAlign: "center",
                      padding: 16,
                      paddingTop: 0,
                    }}
                  >
                    Please enter the amount of points you would like to
                    purchase:
                  </div>
                </div>
              )}
              <div>
                {!URLCSecret && !hideAmountPanel && (
                  <div
                    style={{
                      display: "flex",
                      width: "90%",
                      justifyContent: "space-around",
                      padding: 16,
                    }}
                  >
                    <div className={classes.quantityBox}>
                      <input
                        type="text"
                        onChange={(e) => {
                          handlePurchasePriceChange(e);
                        }}
                        value={purchaseAmount && purchaseAmount}
                        className={classes.quantityAmount}
                      />
                      <div className={classes.quantityControls}>
                        <div className={classes.quantityIncrease}>
                          <KeyboardArrowUpIcon
                            onClick={(e) => incPurchasePrice(e)}
                          />
                        </div>
                        <div className={classes.quantityDecrease}>
                          <KeyboardArrowDownIcon
                            onClick={(e) => decPurchasePrice(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <Button
                        variant="outlined"
                        disabled={!purchaseAmount}
                        onClick={submitPaymentAmount}
                      >
                        Proceed
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              {!URLCSecret && (
                <div style={{ width: "90%", textAlign: "center", padding: 16 }}>
                  {purchaseAmount} points = ${priceGST / 100} ({client.currencyCode})
                  <br/><span style={{fontSize: 10}}>Price Includes GST of {(client.gstRate*100)}%</span>
                </div>                
              )}
              {cSecret && (
                <Elements options={options} stripe={stripePromise}>
                  <CheckoutDialog handleClose={handleClose} id={payment.id} />
                </Elements>
              )}
            </Dialog>
          </div>
          {process.env.REACT_APP_ENABLE_REPUTATION == "true" && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: 30,
              }}
            >
              <div className={classes.pointsText}>Tier Level: </div>
              <div
                className={classes.pointsNumber}
                style={{
                  marginLeft: midpoint ? 8 : 16,
                  marginRight: midpoint ? 8 : 48,
                }}
              >
                {userAccount.reputationName && userAccount.reputationName}
              </div>
            </div>
          )}
        </div>
      {/* )} */}
      {/* <div>
        <ColorButton
          style={{
            paddingLeft: matches ? 24 : 12,
            paddingRight: matches ? 24 : 12,
          }}
        >
          Buy Points
        </ColorButton>
      </div> */}

      <div
        style={{ marginLeft: "auto", width: 550, justifyContent: "flex-end" }}
        className={classes.itemContainer}
      >
        <div
          style={{
            border: "1px solid",
            borderRadius: 16,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: midpoint ? 24 : 50,
            paddingLeft: 8,
          }}
        >
          <div className={classes.searchIcon}>
            <SearchIcon onClick={handleSearch} />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <InputBase
              id="search-input"
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              style={{ fontSize: midpoint ? 16 : matches ? 20 : 18 }}
              value={search}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyPress={(e) => handleEnter(e)}
              inputProps={{ "aria-label": "search" }}
            />
          </div>
        </div>
        {matches && (
          <div
            className={classes.itemContainer}
            style={{ marginLeft: 0, whiteSpace: "nowrap" }}
            onClick={handleWhatsNew}
          >
            Recently Added
          </div>
        )}

        <Link to="/wishlist">
          <div
            className={classes.itemContainer}
            style={{ marginLeft: !matches ? 6 : midpoint ? 24 : 50 }}
          >
            <div className={classes.itemIcon}>
              {wishlist.length ? (
                <FavoriteIcon
                  className={classes.favourite}
                  style={{ fontSize: 26, paddingtop: 2 }}
                />
              ) : (
                <FavoriteBorderOutlinedIcon
                  style={{ fontSize: 26, paddingtop: 2 }}
                />
              )}
            </div>
            {matches && <div>Wishlist</div>}
          </div>
        </Link>
        <Link to="/cart">
          <div
            className={classes.itemContainer}
            style={{ marginLeft: !matches ? 6 : midpoint ? 24 : 50 }}
          >
            <Badge
              badgeContent={cartList.items ? cartList.items.length : 0}
              color="primary"
            >
              <div className={classes.itemIcon}>
                <ShoppingCartOutlinedIcon
                  style={{ fontSize: 26, paddingtop: 2 }}
                />
              </div>
              {matches && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  Cart
                </div>
              )}
            </Badge>
          </div>
        </Link>
      </div>
    </div>
  );
}
