import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import WishlistCard from "./WishlistCard";
import WishlistMobileCard from "./WishlistMobileCard";


import { selectUsers } from "../users/usersSlice";
import {
  removeProductFromWishlist,
  selectWishlist,
} from "./wishlistSlice";

const useStyles = makeStyles((theme) => ({
  wishlistContainer: {
    color: theme.palette.secondaryFont.main,
    padding: 24,
  },
  productsLink: {
    textAlign: "right",
    color: `${theme.palette.secondary.main} !important`,
    fontSize: 24,
    fontWeight: "bold",
    marginTop: 48,
  },
  wishlistTitle: {
    textAlign: "left",
    // marginTop: 60,
    marginBottom: 24,
    fontWeight: "bold",
    fontSize: 54,
  },
  wishlistSubTitle: {
    fontSize: 18,
  },
  wishlistNoBox: {
    border: "3px solid #ed5e9d",
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: theme.palette.secondaryButton.main,
    borderRadius: 16,
    marginTop: 48,
    fontWeight: "bold",
    fontSize: 24,
    padding: 16,
    paddingLeft: 24,
    paddingRight: 24,
    maxWidth: 500,
  },
  viewMore: { color: theme.palette.secondary.main },
}));

export default function Wishlist() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const matches = useMediaQuery("(min-width:850px)");

  const { users, user, userAccount, signedIn } = useSelector(selectUsers);
  const { wishlists, wishlist } = useSelector(selectWishlist);
  const [open, setOpen] = useState(false);
  let jwtToken = localStorage.getItem("clientToken");

  const [uA, setUA] = useState("");

  // const [wishlistArr, setWishlistArr] = useState("");

  // useEffect(() => {
  //   if (wishlists.loading === "loaded" && !wishlistArr.length) {
  //     setWishlistArr(wishlist);
  //   }
  // }, [wishlists.loading]);

  useEffect(() => {
    if (users.loading === "loaded") {
      setUA(userAccount);
    }
  }, [users.loading]);

  // useEffect(() => {
  //   if (uA.id != undefined) {
  //   dispatch(
  //     fetchWishlist({
  //       token: jwtToken,
  //       id: uA.id,
  //     })
  //   );
  //   }
  // }, [dispatch]);

  const handleRemove = (event, product) => {
    if (uA.id != undefined) {
      // setWishlistArr(wishlistArr.filter((item) => 
      //   item.productID != product.productID
      //   ));
      dispatch(
        removeProductFromWishlist({
          token: jwtToken,
          prodID: product.productID,
          id: uA.id,
        })
      );
    }
  };

  return (
    <div className={classes.wishlistContainer}>
      {/* <div className={classes.productsLink}>
        <Link to="/products/search">
          <div className={classes.viewMore}>View Products</div>
        </Link>
      </div> */}
      <div className={classes.wishlistTitle}>Wishlist</div>

      {wishlist.length ? (
        <Grid
          container
          spacing={3}
          direction="row"
          style={{ marginTop: 24, marginBottom: 48 }}
          justifyContent={matches ? "flex-start" : "center"}
        >
          {wishlist.map((prod) => (
            matches ? (
            <WishlistCard
              uA={uA}
              handleRemove={handleRemove}
              product={prod}
              // product={prod.productDetails}
            />
            ) : (
            <WishlistMobileCard
              uA={uA}
              handleRemove={handleRemove}
              product={prod}
              // product={prod.productDetails}
            />
            )
          ))}
        </Grid>
      ) : (
        <div>
          <div className={classes.wishlistNoItems}>
            <div className={classes.wishlistSubTitle}>
              Save the items you like here, so you can find them easily later
            </div>
            <div className={classes.wishlistNoBox}>
              You have no items in your wishlist
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
