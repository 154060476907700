// export default formatPoints = (points) => {};

// (1, 'active'),
// 	(2, 'inactive'),
// 	(3, 'unavailable'),
// 	(4, 'deleted'),
// 	(5, 'pending'),
// 	(6, 'confirmed'),
// 	(7, 'cancelled'),
// 	(8, 'outstanding'),
// 	(9, 'completed'),
// 	(10, 'done'),
// 	(11, 'paid'),
// 	(12, 'sent'),
// 	(13, 'draft'),
// 	(14, 'failed'),
// 	(15, 'temporary'),
// 	(16, 'dispatched'),
// 	(17, 'blocked'),
// 	(18, 'notapplicable'),
// 	(19, 'expired'),
// 	(20, 'redeemed'),
// 	(21, 'declined'),
// 	(22, 'complimentary'),
// 	(23, 'refunded'),
// 	(24, 'processing'),
// 	(25, 'invoiced'),
// 	(26, 'purchaseordered'),
// 	(27, 'approved')
// 	(28, 'purchased')

export const checkFieldUpdate = (fieldList, existingObj) => {
  let updatedObj = {};
  fieldList.forEach((field) => {
    if (field.val !== existingObj[field.name]) {
      updatedObj[field.name] = field.val;
    }
  });
  return updatedObj;
};
