import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Carousel from "react-img-carousel";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { Carousel } from "react-responsive-carousel";
import { useHistory } from "react-router-dom";
import { replaceUrlParam } from "../app/utils";
import { selectUsers } from "../features/users/usersSlice";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import {
  fetchProducts,
  fetchProductsMaxPoints,
  selectCoreProducts,
} from "../features/products/coreProductsSlice";
import MobileProductCard from "./MobileProductCard";
import ProductCard from "./ProductCard";

import Grid from "@material-ui/core/Grid";
import { setXClientHost } from "../app/utils";
import { selectCategories } from "../features/categories/categoriesSlice";
import { fetchClient, selectClients } from "../features/clients/clientsSlice";
import {
  fetchContentByName,
  fetchContents,
  getFullList,
  selectContents,
  setAlertShown,
} from "../features/content/contentsSlice";
import { selectCustomisation } from "../features/customisation/customisationSlice";
require("react-responsive-carousel/lib/styles/carousel.min.css"); // requires a loader

var sortBy = require("lodash/sortBy");

const useStyles = makeStyles((theme) => ({
  landingContainer: {
    color: theme.palette.secondaryFont.main,
    fontSize: 20,
    fontWeight: "bold",
  },
  bannerContainer: {},
  cardsContainer: {
    padding: theme.spacing(3),
  },
  sortContainer: {
    marginBottom: 16,
    display: "flex",
    flexDirection: "column",
    width: 150,
  },
  priceContainer: {
    minWidth: 250,
  },
  sortItemsContainer: {
    display: "flex",
  },

  filterContainer: {
    display: "flex",
    // justifyContent: 'space-between',
    alignItems: "center",
    marginBottom: 24,
  },
  filterItem: {
    marginRight: 48,
  },

  categoriesContainer: {
    padding: 80,
    paddingBottom: 0,
    paddingTop: 0,
    maxWidth: 1400,
    margin: "auto",
  },
  categoryTitle: {
    textAlign: "center",
    margin: 60,
    fontWeight: "bold",
    fontSize: 54,
  },
  categoryCardsContainer: {
    display: "flex",
    justifyContent: "center",
  },
  featuredContainer: {
    padding: "8vw",
    paddingBottom: 0,
    paddingTop: 0,
    marginTop: 100,
    marginBottom: 100,
    // marginBottom: 26,
  },
  featuredTitle: {
    textAlign: "center",
    margin: 60,
    fontWeight: "bold",
    fontSize: 54,
  },
  icon: {
    fontSize: 50,
    color: theme.palette.highlight.main,
    cursor: "pointer",
  },
  landingTileTitle: { fontSize: "2.5vw", marginBottom: "1vw" },
  landingTileSubtitle: {
    fontSize: "1vw",
    marginBottom: "1.5vw",
    fontWeight: 400,
  },
  landingTileLink: {
    fontSize: "0.8vw",
    color: theme.palette.landingTileButtonFontColor.main,
    backgroundColor: theme.palette.highlight.main,
    width: "8vw",
    padding: "1vw",
    textAlign: "center",
    cursor: "pointer",
    borderRadius: '10px',
  },
  landingTileTextContainer: {
    width: "40%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft: "3vw",
    paddingTop: "4vw",
    color: theme.palette.landingTileFontColor.main,
  },
  landingTileImageContainer: {
    width: "60%",
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    borderRadius: '10px'
  },
  landingTile: {
    display: "flex",
    width: "100%",
    height: "20vw",
    // boxShadow: "3px 2px 4px 4px rgba(194,194,194,0.8)",
  },
  landingTileImage: { objectFit: "cover", width: "100%" },
  brandImage: {
    width: "7em",
    objectFit: "contain",
    padding: "2vw",
    cursor: "pointer",
  },
  alertMessage: {
    color: theme.palette.primaryFont.main,
  },
}));

function valuetext(value) {
  return `${value}`;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

export default function CategoryContainer(props) {
  const { customisation } = useSelector(selectCustomisation);
  const { categoriesTree } = useSelector(selectCategories);
  const matches = useMediaQuery("(min-width:850px)");
  const matchesSmall = useMediaQuery("(min-width:950px)");
  const matchesMedium = useMediaQuery("(min-width:1100px)");
  const matchesWide = useMediaQuery("(min-width:1400px)");
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [categoryList, setCategeryList] = React.useState([]);

  const {
    content,
    showAlert,
    alertShown,
    contentsList,
    contentsAmount,
    alert,
  } = useSelector(selectContents);
  const { users, user, userAccount, signedIn } = useSelector(selectUsers);
  const { client } = useSelector(selectClients);
  const [uA, setUA] = useState("");
  const isStaging = Number(process.env.REACT_APP_CLIENT_ID) === 1;
  useEffect(() => {
    if (users.loading === "loaded") {
      setUA(userAccount);
    }
  }, [users.loading]);

  useEffect(() => {
    setCategeryList(categoriesTree);
  }, [categoriesTree]);

  const [numberShown, setNumberShown] = useState(10);

  const [imgSrc, setImgSrc] = useState("/landing.png");

  useEffect(() => {
    dispatch(
      fetchProductsMaxPoints({
        token: jwtToken,
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      fetchProducts({
        token: jwtToken,
        query: "?featured=true&limit=10",
      })
    );
    dispatch(
      fetchContentByName({
        host: setXClientHost(),
        token: jwtToken,
        name: "alertMessage",
      })
    );
    dispatch(
      fetchContents({
        token: jwtToken,
        query: "",
      })
    );
    if (!client.id) {
      dispatch(
        fetchClient({
          token: jwtToken,
        })
      );
    }
  }, [dispatch]);

  const [drawerOpen, setDrawerOpen] = useState(
    showAlert.value === "true" && !alertShown
  );

  useEffect(() => {
    setDrawerOpen(showAlert.value === "true" && !alertShown);
  }, [alertShown, showAlert]);

  //categories
  const { coreproducts, productList } = useSelector(selectCoreProducts);
  let jwtToken = localStorage.getItem("clientToken");

  const iconComponent = (props) => {
    return <ExpandMoreIcon className={props.className + " " + classes.icon} />;
  };

  useEffect(() => {
    let searchQuery = "";
    searchQuery = replaceUrlParam(searchQuery, "limit", 100);
    dispatch(
      getFullList({
        token: jwtToken,
        offset: contentsList.length,
        query: searchQuery,
        targetLength: contentsAmount,
      })
    );
  }, []);

  const [imageList, setImageList] = useState([]);
  const [linkList, setLinkList] = useState([]);
  const [mobileImageList, setMobileImageList] = useState([
    "/abb-mob-one.jpeg",
    "/abb-mob-two.jpeg",
  ]);

  useEffect(() => {
    if (contentsList) {
      let filtered = contentsList.filter(
        (content) => content.name === "banner"
      );
      setImageList(filtered.length ? filtered[0].value.split(",") : []);

      let linkFiltered = contentsList.filter(
        (content) => content.name === "bannerLink"
      );

      let noLinkArray = [];
      filtered.length &&
        filtered[0].value.split(",").forEach(() => {
          noLinkArray.push("");
        });

      setLinkList(
        linkFiltered.length ? linkFiltered[0].value.split(",") : noLinkArray
      );
    }
  }, [contentsList]);

  // useEffect(() => {
  //   let src = props.location.pathname.split('/')[2];
  //   if (src) {
  //     src = `/${src.toLowerCase()}.png`;
  //   }
  //   setImgSrc(src);
  //   setSearchQuery('');
  //   setNumberShown(10);
  // }, [props.location]);

  const sortProduct = (products, sortCategory) => {
    let sort = sortCategory;
    if (sortCategory.includes("reverse")) {
      sort = sortCategory.split("-")[0];
    }
    let sortedProds = sortBy(products, [
      function (o) {
        return o[sort];
      },
    ]);
    return sortCategory.includes("reverse")
      ? sortedProds.reverse()
      : sortedProds;
  };
  const [priceRange, setPriceRange] = useState([0, 10000]);

  const handleSliderChange = (event, newValue) => {
    setPriceRange(newValue);
  };

  const [brandName, setBrand] = React.useState([]);

  const handleSelect = (event) => {
    setBrand(event.target.value);
  };

  const handleMore = () => {
    setNumberShown(numberShown + 10);
  };

  const checkPets = (obj) => obj.name.toUpperCase() === "PETS";
  const catLists = categoryList.some(checkPets)
    ? categoryList
    : categoryList.concat({
        name: "Pets",
        empty: true,
        id: "soon",
      });

  const arrows = {
    left: (
      <div class="arrow-container">
        <ChevronLeftIcon
          style={{ fontSize: 50, color: customisation.theme.secondaryColor }}
        />
      </div>
    ),
    right: (
      <div class="arrow-container">
        <ChevronRightIcon
          style={{ fontSize: 50, color: customisation.theme.secondaryColor }}
        />
      </div>
    ),
  };

  const brandClick = (brandID) => {
    dispatch(
      fetchProducts({
        token: jwtToken,
        query: `?brands=${brandID}&limit=24`,
      })
    );
    history.push(`/products`);
  };

  return (
    <div className={classes.landingContainer}>
      <Drawer open={drawerOpen} anchor={"top"}>
        <div
          style={{
            height: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className={classes.alertMessage}
            style={{ padding: 10, fontSize: 20 }}
          >
            {alert.value}
          </div>
          <div style={{ padding: 10 }}>
            <CloseOutlinedIcon
              onClick={(e) => {
                setDrawerOpen(false);
                dispatch(setAlertShown(true));
              }}
              className={classes.icon}
            />
          </div>
        </div>
      </Drawer>
      {imageList && imageList.length ? (
        <Carousel
          swipeable={false}
          showStatus={false}
          infiniteLoop={true}
          autoPlay={true}
          interval={6000}
          showThumbs={false}
          dynamicHeight={true}
          onClickItem={(index) => {
            if (linkList && linkList[index]) {
              window.location = linkList[index];
            }
          }}
        >
          {imageList &&
            linkList &&
            imageList.map((img) => (
              <img
                src={
                  img.includes("blob")
                    ? img
                    : `${process.env.REACT_APP_ASSETS_URL}/theme/banner/${img}`
                }
              />
            ))}
        </Carousel>
      ) : (
        <div></div>
      )}

      {/* <div
        style={{
          paddingLeft: matches ? 80 : 12,
          paddingRight: matches ? 80 : 12,
          paddingTop: 20,
          paddingBottom: matches ? 0 : 16,
        }}
        className={classes.categoriesContainer}
      > */}
      {/* <div
          style={{
            margin: matches ? 40 : 8,
            marginBottom: matches ? 60 : 16,
            fontSize: matches ? 40 : 24,
          }}
          className={classes.categoryTitle}
        >
          Check out what's new at {customisation.theme.clientName}
        </div> */}
      {/* <div
          style={{
            marginBottom: matches ? 0 : 24,
            paddingTop: 20,
            marginLeft: matchesWide ? "6rem" : "1rem",
            marginRight: matchesWide ? "6rem" : "1rem",
          }}
        >
          <Grid
            container
            spacing={3}
            direction="row"
            // justifyContent="space-between"
            className={classes.categoryCardsContainer}
          >
            {catLists.map((category) => (
              <div
                style={{
                  flex: "1 0 31%",
                  justifyContent: "center",
                  display: "flex",
                  marginBottom: 40,
                  marginRight: 10,
                }}
              >
                <CategoryCard
                  category={category}
                  empty={category.empty ? true : false}
                  imageURL={`/${category.name
                    .split(" ")
                    .join("-")
                    .toLowerCase()}.png`}
                />
              </div>
            ))}
          </Grid>
        </div> */}
      {/* </div> */}

      <div
        style={{ paddingBottom: "3vw", paddingTop: "1vw", marginTop: 50 }}
        className={classes.featuredContainer}
      >
        <div
          className={classes.landingTile}
          style={{
            flexDirection: matches ? "row" : "column",
            height: matches ? "20vw" : "auto",
          }}
        >
          {/* {matches && ( */}
          <div
            className={classes.landingTileImageContainer}
            style={{ width: matches ? "60%" : "100%" }}
          >
            <img
              className={classes.landingTileImage}
              src={
                process.env.REACT_APP_CLIENT_ID == "6"
                  ? "/br-all-products.jpg"
                  : process.env.REACT_APP_CLIENT_ID == "12"
                  ? "/tw-all-products.jpg"
                  : "/all-products.jpg"
              }
            />
          </div>
          {/* )} */}
          <div
            className={classes.landingTileTextContainer}
            style={{
              width: matches ? "40%" : "100%",
              paddingLeft: matches ? "2.5vw" : "0.6rem",
              paddingTop: matches ? "2.5vw" : "0.6rem",
              paddingBottom: matches ? 0 : "0.6rem",
            }}
          >
            <div className={classes.landingTileTitle}>All Products</div>
            <div
              className={classes.landingTileSubtitle}
              style={{ fontSize: matches ? "1vw" : "0.8rem" }}
            >
              Browse our entire range of products
            </div>
            <div
              className={classes.landingTileLink}
              style={{
                fontSize: matches ? "1vw" : "0.6rem",
                width: matches ? "8vw" : "4rem",
              }}
              onClick={() => {
                dispatch(
                  fetchProducts({
                    token: jwtToken,
                    query: `?order_by=name&sort=asc&limit=24`,
                  })
                );
                history.push(`/products`);
              }}
            >
              {"Shop Now >>"}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ paddingBottom: "3vw" }}
        className={classes.featuredContainer}
      >
        <div
          className={classes.landingTile}
          style={{
            flexDirection: matches ? "row" : "column",
            height: matches ? "20vw" : "auto",
          }}
        >
          {!matches && (
            <div
              className={classes.landingTileImageContainer}
              style={{ width: matches ? "60%" : "100%" }}
            >
              <img
                className={classes.landingTileImage}
                src={
                  process.env.REACT_APP_CLIENT_ID == "6"
                    ? "/br-recently-added.jpg"
                    : process.env.REACT_APP_CLIENT_ID == "12"
                    ? "/tw-recently-added.jpg"
                    : "/recently-added.jpg"
                }
              />
            </div>
          )}
          <div
            className={classes.landingTileTextContainer}
            style={{
              width: matches ? "40%" : "100%",
              paddingLeft: matches ? "2.5vw" : "0.6rem",
              paddingTop: matches ? "2.5vw" : "0.6rem",
              paddingBottom: matches ? 0 : "0.6rem",
            }}
          >
            <div className={classes.landingTileTitle}>Recently Added</div>
            <div
              className={classes.landingTileSubtitle}
              style={{ fontSize: matches ? "1vw" : "0.8rem" }}
            >
              Check out what's been recently added to our range
            </div>
            <div
              className={classes.landingTileLink}
              style={{
                fontSize: matches ? "1vw" : "0.6rem",
                width: matches ? "8vw" : "4rem",
              }}
              onClick={() => {
                dispatch(
                  fetchProducts({
                    token: jwtToken,
                    query: `?order_by=created_at&sort=desc&limit=24`,
                  })
                );
                history.push(`/products`);
              }}
            >
              {"Shop Now >>"}
            </div>
          </div>
          {matches && (
            <div
              className={classes.landingTileImageContainer}
              style={{ width: matches ? "60%" : "100%" }}
            >
              <img
                className={classes.landingTileImage}
                src={
                  process.env.REACT_APP_CLIENT_ID == "6"
                    ? "/br-recently-added.jpg"
                    : process.env.REACT_APP_CLIENT_ID == "12"
                    ? "/tw-recently-added.jpg"
                    : "/recently-added.jpg"
                }
              />
            </div>
          )}
        </div>
      </div>

      {process.env.REACT_APP_ENABLE_SALE_PRODUCTS == "true" && (
        <div
          // style={{ paddingBottom: matches ? 80 : 24 }}
          className={classes.featuredContainer}
        >
          <div
            className={classes.landingTile}
            style={{
              flexDirection: matches ? "row" : "column",
              height: matches ? "20vw" : "auto",
            }}
          >
            {/* {matches && ( */}
            <div
              className={classes.landingTileImageContainer}
              style={{ width: matches ? "60%" : "100%" }}
            >
              <img
                className={classes.landingTileImage}
                src={"/sale-products.jpg"}
              />
            </div>
            {/* )} */}
            <div
              className={classes.landingTileTextContainer}
              style={{
                width: matches ? "40%" : "100%",
                paddingLeft: matches ? "2.5vw" : "0.6rem",
                paddingTop: matches ? "2.5vw" : "0.6rem",
                paddingBottom: matches ? 0 : "0.6rem",
              }}
            >
              <div className={classes.landingTileTitle}>
                All Products On Sale
              </div>
              <div
                className={classes.landingTileSubtitle}
                style={{ fontSize: matches ? "1vw" : "0.8rem" }}
              >
                See our limited sale items
              </div>
              <div
                className={classes.landingTileLink}
                style={{
                  fontSize: matches ? "1vw" : "0.6rem",
                  width: matches ? "8vw" : "4rem",
                }}
                onClick={() => {
                  dispatch(
                    fetchProducts({
                      token: jwtToken,
                      query: `?special=true&order_by=name&sort=asc&limit=24`,
                    })
                  );
                  history.push(`/products`);
                }}
              >
                {"Shop Now >>"}
              </div>
            </div>
          </div>
        </div>
      )}

      {process.env.REACT_APP_ENABLE_FEATURED_BRANDS == "true" && (
        <div
          style={{
            paddingTop: matches ? 0 : 30,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          className={classes.featuredContainer}
        >
          <div
            style={{ margin: matches ? 40 : 8, fontSize: matches ? 40 : 24 }}
            className={classes.featuredTitle}
          >
            Our most popular brands
          </div>
          <div
            className={classes.featuredCardsContainer}
            style={{
              // overflow: "auto",
              width: "100%",
            }}
          >
            <Grid
              container
              // spacing={5}
              direction="row"
              // column={{ xs: 3, m: 6 }}
              // justifyContent="space-between"
              className={classes.categoryCardsContainer}
            >
              <img
                src={"/dyson.png"}
                className={classes.brandImage}
                onClick={() => {
                  brandClick(isStaging ? "438" : "55");
                }}
              />
              <img
                src={"/fitbit.png"}
                className={classes.brandImage}
                onClick={() => {
                  brandClick(isStaging ? "452" : "69");
                }}
              />
              <img
                src={"/delonghi.png"}
                className={classes.brandImage}
                onClick={() => {
                  brandClick(isStaging ? "432" : "49");
                }}
              />
              <img
                src={process.env.REACT_APP_CLIENT_ID == "20" ? "/oztrail.png" : "/samsung.png"}
                className={classes.brandImage}
                onClick={() => {
                  brandClick(isStaging ? "562" : "180");
                }}
              />
              <img
                src={process.env.REACT_APP_CLIENT_ID == "20" ? "/taylormade.png" : "/google.png"}
                className={classes.brandImage}
                onClick={() => {
                  brandClick(isStaging ? "466" : "83");
                }}
              />
              <img
                src={"/bose.png"}
                className={classes.brandImage}
                onClick={() => {
                  brandClick(isStaging ? "411" : "28");
                }}
              />
              <img
                src={"/garmin.png"}
                className={classes.brandImage}
                onClick={() => {
                  brandClick(isStaging ? "457" : "74");
                }}
              />
              <img
                src={"/bosch.png"}
                className={classes.brandImage}
                onClick={() => {
                  brandClick(isStaging ? "410" : "27");
                }}
              />
              <img
                src={"/kitchenaid.png"}
                className={classes.brandImage}
                onClick={() => {
                  brandClick(isStaging ? "496" : "113");
                }}
              />
              <img
                src={"/nespresso.png"}
                className={classes.brandImage}
                onClick={() => {
                  brandClick(isStaging ? "523" : "140");
                }}
              />
              <img
                src={"/rayban.png"}
                className={classes.brandImage}
                onClick={() => {
                  brandClick(isStaging ? "546" : "164");
                }}
              />
              <img
                src={"/weber.png"}
                className={classes.brandImage}
                onClick={() => {
                  brandClick(isStaging ? "610" : "229");
                }}
              />
            </Grid>
          </div>
        </div>
      )}

      {process.env.REACT_APP_ENABLE_FEATURED_PRODUCTS == "true" && (
        <div
          style={{ padding: matches ? 0 : 24 }}
          className={classes.featuredContainer}
        >
          <div
            style={{ margin: matches ? 40 : 8, fontSize: matches ? 40 : 24 }}
            className={classes.featuredTitle}
          >
            What's Hot & Our Picks
          </div>

          <div
            className={classes.featuredCardsContainer}
            className="featuredCarousel"
          >
            {productList.data && productList.data.length ? (
              <Carousel
                swipeable={false}
                centerSlidePercentage={
                  matchesWide
                    ? 25
                    : matchesMedium
                    ? 33
                    : matchesSmall
                    ? 37
                    : matches
                    ? 42
                    : 100
                }
                selectedItem={1}
                centerMode={true}
                showStatus={false}
                infiniteLoop={true}
                showThumbs={false}
              >
                {productList &&
                  productList.data.length &&
                  productList.data
                    // .filter((prod) => prod.featured === true)
                    // .splice(0, 4)
                    .map((prod, index) =>
                      matches ? (
                        <div style={{ margin: 10 }}>
                          <ProductCard
                            uA={uA}
                            product={prod}
                            path={props.location.pathname}
                          />
                        </div>
                      ) : (
                        <MobileProductCard
                          uA={uA}
                          product={prod}
                          path={props.location.pathname}
                        />
                      )
                    )}
              </Carousel>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
