import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCustomisation } from '../customisation/customisationSlice';
import {
  fetchSnapshot,
  selectReports,
} from './reportsSlice';

import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.secondaryFont.main,

        fontWeight: 'bold',
        fontSize: 30,
        marginBottom: 32,
        textAlign: 'center'
    },
    rowContainer: {
    display: 'flex',
    // justifyContent: 'space-between'
    },
    label: {
        color: theme.palette.secondaryFont.main,
        fontWeight: 'bold',
        marginRight: 16,
        minWidth: 150,
        paddingBottom: 4,
        fontSize: 18

    },
    vals: {
      fontSize: 18

    } 
}));

export default function ProgramSnapshot(props) {
  let jwtToken = localStorage.getItem('clientToken');
  const { customisation } = useSelector(selectCustomisation);

  const dispatch = useDispatch();
  const classes = useStyles();

    const {error, snapshot, loading, reportList} =
    useSelector(selectReports);

  useEffect(() => {
    dispatch(
      fetchSnapshot({
        token: jwtToken,
      })
    );
  }, [dispatch]);


  const [ready, setReady] = useState(false);
  if (loading === 'loaded' && !ready) {
    setReady(true);
  }


  let content;
  if (loading === 'loading') {
    content = (
      <CircularProgress className={classes.progress} color="secondary" />
    );
  } else if (loading === 'loaded' || loading === 'idle') {
    content = (
        <Paper style={{padding: 40, borderRadius: 16, width: 500}}>
            <div className={classes.title}>Program Snapshot</div>
    <div>
        <div className={classes.rowContainer}>
            <div className={classes.label}>Participants:</div>
            <div className={classes.vals}>{snapshot.userCount ?  snapshot.userCount.toLocaleString() : '' }</div>
        </div>
        <div className={classes.rowContainer}>
            <div className={classes.label}>Total Logins:</div>
            <div className={classes.vals}>{snapshot.loginCount ?  snapshot.loginCount .toLocaleString() : '' }</div>
        </div>
        <div className={classes.rowContainer}>
            <div className={classes.label}>Unspent Points:</div>
            <div className={classes.vals}>{snapshot.redeemable ?  snapshot.redeemable .toLocaleString() : '' }</div>
        </div>
        <div className={classes.rowContainer}>
            <div className={classes.label}>Spent Points:</div>
            <div className={classes.vals}>{snapshot.redeemed ?  snapshot.redeemed.toLocaleString() : '' }</div>
        </div>
        <div className={classes.rowContainer}>
            <div className={classes.label}>Total Points:</div>
            <div className={classes.vals}>{snapshot.totalPoints ?  snapshot.totalPoints.toLocaleString() : '' }</div>
        </div>
    </div>
    </Paper>
    );
  }

  return (
    <Grid
      container
      spacing={3}
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      style={{height: '100%'}}
    >
      {content}
    </Grid>
  );
}
