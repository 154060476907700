import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import AuthDialog from "./AuthDialog";

import { setXClientHost } from "../../app/utils.js";
import { selectCustomisation } from "../customisation/customisationSlice";
import { forgotPassword, selectAuth } from "./authSlice.js";

const ColorButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 4),
    backgroundColor: theme.palette.secondaryButton.main,
    color: theme.palette.secondaryButtonFont.main,
    border: `2px solid ${theme.palette.secondaryButtonFont.main}`,
    borderRadius: "24px",
    "&:hover": {
      backgroundColor: `${theme.palette.secondaryButtonFont.main}2b`,
    },
    fontWeight: "bold",
    fontSize: 14,
    padding: 12,
  },
}))(Button);

const SecondaryButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(4, 0, 5),
    backgroundColor: theme.palette.secondaryButton.main,
    border: `2px solid ${theme.palette.secondaryButtonFont.main}`,
    borderRadius: "24px",
    color: theme.palette.secondaryButtonFont.main,
    "&:hover": {
      backgroundColor: `${theme.palette.secondaryButtonFont.main}2b`,
    },
    fontWeight: "bold",
    minWidth: 175,
    fontSize: 14,
    padding: 12,
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  form: {
    borderBottom: "1px solid rgb(221, 221, 221)",
    width: "100%", // Fix IE 11 issue.
    color: theme.palette.secondaryFont.main,
  },
  heading: {
    color: theme.palette.secondaryFont.main,
    marginBottom: 16,
    fontWeight: "bold",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    backgroundColor: "#fff",
    padding: "45px",
    maxWidth: 800,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  subText: {
    color: theme.palette.secondaryFont.main,
    marginTop: theme.spacing(2),
    textAlign: "left",
  },
  returnButton: {
    color: theme.palette.primaryFont.main,
    textAlign: "center",
    fontSize: 18,
    fontWeight: "bold",
  },
}));

export default function ForgotPassword(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { customisation } = useSelector(selectCustomisation);

  const { auth, error, loading, signedIn, success } = useSelector(selectAuth);
  const [user, setUser] = useState("");
  const [resetSuccess, setResetSuccess] = useState(false);
  const [dialogError, setDialogError] = useState(false);

  const submitResetPassword = (event) => {
    event.preventDefault();
    dispatch(
      forgotPassword({
        username: user,
        host: setXClientHost(),
      })
    );
    setResetSuccess(success);
    setDialogError(error);
  };

  return (
    <Container className={classes.paper} component="main">
      <Grid container>
        <Typography variant="h3" className={classes.heading}>
          Forgot your password?
        </Typography>
      </Grid>
      <Grid container>
        <Typography variant="body1" className={classes.subText}>
          Enter your username below and we'll send you an email to your
          registered email address with instructions on how you can create a new
          password.
        </Typography>
      </Grid>
      <form className={classes.form} onSubmit={submitResetPassword}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="username"
          label="Username/Email"
          name="username"
          autoComplete="username"          
          onChange={(e) => setUser(e.target.value)}
          style={{ marginBottom: 32 }}
        />
        <ColorButton type="submit" fullWidth variant="contained">
          Send Reset Password Link
        </ColorButton>
      </form>
      <Grid container>
        <Grid item xs>
          <Link to="/signin/register">
            <SecondaryButton fullWidth variant="contained">
              Register
            </SecondaryButton>
          </Link>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs style={{ textAlign: "center" }}>
          <Link to="/signin">
            <div className={classes.returnButton}>Back to Sign In</div>
          </Link>
        </Grid>
      </Grid>
      <AuthDialog
        title={success ? "Success" : error ? "Something went wrong" : ""}
        message={
          success
            ? "If the email address you specified exists in our system, we’ve sent a password reset link to it. Please also check your spam folder for this email."
            : error
            ? error.error_description
            : ""
        }
        openDialog={success === true || dialogError}
      />
    </Container>
  );
}
