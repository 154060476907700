import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import { setXClientHost } from "../../app/utils";
import Editor from "../../common/Editor";
import {
  createContent,
  fetchContentByName,
  selectContents,
  updateContent
} from "./contentsSlice";

const ColorButton = withStyles((theme) => ({
  root: {
    // margin: theme.spacing(3, 0, 2),
    margin: theme.spacing(0, 0, 0),
    backgroundColor: theme.palette.primaryButton.main,
    color: theme.palette.primaryButtonFont.main,
    "&:hover": {
      backgroundColor: theme.palette.primaryButton.main,
      opacity: 0.7,
    },
    width: "100%",
    maxWidth: 300,
    // fontWeight: 'bold',
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  adminContentContainer: {
    padding: 48,
    borderRadius: 16,
    color: theme.palette.primaryFont.main,
    fontSize: 16,
    width: "75%",
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 16,
    color: theme.palette.secondaryFont.main,
  },
  subTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 16,
    marginTop: 16,
    color: theme.palette.secondaryFont.main,
  },
  contentHeader: {
    fontWeight: "bold",
    fontSize: 48,
    marginBottom: 24,
  },
  formControl: { marginLeft: 24 },
  selectContainer: {
    fontSize: 32,
    marginBottom: 24,
    display: "flex",
    alignItems: "center",
  },
}));

export default function AdminContentContainer() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { content } = useSelector(selectContents);
  let jwtToken = localStorage.getItem("clientToken");

  const contentPagesList = [
    { name: "Privacy", value: "privacy" },
    { name: "Terms & Conditions", value: "terms-conditions" },
    { name: "Contact Us", value: "contact-us" },
    { name: "How It Works", value: "how-it-works" },
    { name: "How To Claim", value: "how-to-claim" },
    { name: "Change of Delivery Address", value: "address" },
    { name: "Wishlist", value: "wishlist" },
    { name: "Delivery & Receipt Of Your Order", value: "delivery" },
    { name: "In Transit Damage & Product Warranty Claims", value: "damaged" },
    { name: "Superseded & Discontinued Products ", value: "discontinued" },
  ];

  const [contentPage, setContentPage] = useState("privacy");

  const [editorValue, setEditorValue] = useState("");

  const handleSave = () => {
    if (content.id) {
      dispatch(
        updateContent({
          token: jwtToken,
          content: {
            value: editorValue,
          },
          id: content.id,
        })
      );
    } else {
      dispatch(
        createContent({
          token: jwtToken,
          content: {
            name: contentPage,
            classification: "data",
            value: editorValue,
            storageKey: null,
          },
        })
      );
    }
  };

  useEffect(() => {
    dispatch(
      fetchContentByName({
        host: setXClientHost(),
        token: jwtToken,
        name: contentPage,
      })
    );
  }, [contentPage]);

  useEffect(() => {
    setEditorValue(content.value ? content.value : "");
  }, [content]);

  return (
    <Paper elevation={3} className={classes.adminContentContainer}>
      <div className={classes.title}>Update page content</div>
      <div className={classes.selectContainer}>
        <div className={classes.subTitle}>Select Page to Edit: </div>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            margin="dense"
            labelId="content-page-simple-select-outlined-label"
            id="content-page-simple-select-outlined"
            value={contentPage}
            onChange={(e) => setContentPage(e.target.value)}
            required
            InputLabelProps={{ shrink: false }}
          >
            {contentPagesList.map((page) => (
              <MenuItem key={page.value} value={page.value}>
                {page.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div
          onClick={handleSave}
          style={{ marginLeft: "auto", cursor: "pointer" }}
        >
          <ColorButton>Save Changes</ColorButton>
        </div>
      </div>
      <div style={{}}>
        <Editor
          readonly={false}
          placeholder={"Page content..."}
          editorValue={editorValue}
          setEditorValue={setEditorValue}
        />
      </div>
    </Paper>
  );
}
