import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";

const useStyles = makeStyles((theme) => ({
  state: {
    backgroundColor: theme.palette.secondaryButton.main,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: theme.palette.secondaryButtonFont.main,
    borderRadius: "24px",
    color: theme.palette.secondaryButtonFont.main,
    fontSize: 14,
    padding: 0,
    paddingLeft: 2,
    paddingRight: 24,
    textTransform: "capitalize",
    height: 40.5,
  },
  colorItem: { color: theme.palette.primary.main },
}));

const usePlaceholderStyles = makeStyles((theme) => ({
  placeholder: {
    color: theme.palette.primaryFont.main,
  },
}));

const Placeholder = ({ children }) => {
  const classes = usePlaceholderStyles();
  return <div className={classes.placeholder}>{children}</div>;
};

export const StatesDropDown = (props) => {
  const { placeholder, setSelectedState, state } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const iconComponent = (props) => {
    return <ExpandMoreIcon className={props.className + " " + classes.icon} />;
  };
    
  const MenuProps = {
    classes: {
      paper: classes.paper,
      list: classes.list,
    },
    anchorOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  };

  const stateList = [
    { value: "N/A", name: "Not Applicable" },
    { value: "ACT", name: "Australian Capital Territory" },
    { value: "NSW", name: "New South Wales" },
    { value: "NT", name: "Northern Territory" },
    { value: "QLD", name: "Queensland" },
    { value: "SA", name: "South Australia" },
    { value: "TAS", name: "Tasmania" },
    { value: "VIC", name: "Victoria" },
    { value: "WA", name: "Western Australia" },
  ];

  return (
    <div style={{ marginTop: 8, marginBottom: 8 }}>
      <FormControl style={{ width: "100%" }} className={classes.formControl}>
        <Select
          labelId="state-select-label"
          fullWidth
          id="state-select"
          open={open}          
          onClose={handleClose}
          onOpen={handleOpen}
          value={state}
          onChange={(e) => setSelectedState(e.target.value)}
          IconComponent={iconComponent}
          classes={{ root: classes.select }}
          input={<Input className={classes.state} />}
          MenuProps={MenuProps}
          displayEmpty
          required
          renderValue={
            state !== ""
              ? undefined
              : () => <Placeholder>{placeholder}</Placeholder>
          }
        >
          {stateList.map((stateItem, index) => (
            <MenuItem className={classes.colorItem} value={stateItem.value} key={index}>
              {stateItem.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default StatesDropDown;
