import { MuiThemeProvider, createTheme, makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import ScrollToTop from "./ScrollToTop";
import AdminContainer from "./components/AdminContainer";
import ContentContainer from "./components/ContentContainer";
import Footer from "./components/Footer";
import Header from "./components/Header";
import MobileHeader from "./components/MobileHeader";
import ForgotPassword from "./features/auth/ForgotPassword";
import Register from "./features/auth/Register";
import ResetPassword from "./features/auth/ResetPassword";
import SignIn from "./features/auth/SignIn";
import {
  selectCustomisation,
  setCustomisation
} from "./features/customisation/customisationSlice";
import CustomSnackbar from "./features/snackbar/CustomSnackbar";

let testTheme = require(`./themes/${process.env.REACT_APP_THEME}`);

const useStyles = makeStyles((theme) => ({
  appContainer: {},
}));

function App(props) {
  const { themeData } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const { customisation, initialThemeLoaded } =
    useSelector(selectCustomisation);

  useEffect(() => {
    dispatch(setCustomisation(themeData));
  }, []);

  let theme = createTheme({
    palette: {
      primary: {
        main: initialThemeLoaded
          ? customisation.theme.primaryColor
          : themeData.primaryColor,
      },
      secondary: {
        main: initialThemeLoaded
          ? customisation.theme.secondaryColor
          : themeData.secondaryColor,
      },
      alternative: {
        main: initialThemeLoaded
          ? customisation.theme.alternativeColor
          : themeData.alternativeColor,
      },
      highlight: {
        main: initialThemeLoaded
          ? customisation.theme.highlightColor
          : themeData.highlightColor,
      },
      primaryFont: {
        main: initialThemeLoaded
          ? customisation.theme.primaryFontColor
          : themeData.primaryFontColor,
      },
      secondaryFont: {
        main: initialThemeLoaded
          ? customisation.theme.secondaryFontColor
          : themeData.secondaryFontColor,
      },
      alternativeFont: {
        main: initialThemeLoaded
          ? customisation.theme.alternativeFontColor
          : themeData.alternativeFontColor,
      },
      primaryButton: {
        main: initialThemeLoaded
          ? customisation.theme.primaryButtonColor
          : themeData.primaryButtonColor,
      },
      secondaryButton: {
        main: initialThemeLoaded
          ? customisation.theme.secondaryButtonColor
          : themeData.secondaryButtonColor,
      },
      headerButton: {
        main: initialThemeLoaded
          ? customisation.theme.headerButtonColor
          : themeData.headerButtonColor,
      },
      primaryButtonFont: {
        main: initialThemeLoaded
          ? customisation.theme.primaryButtonFontColor
          : themeData.primaryButtonFontColor,
      },
      secondaryButtonFont: {
        main: initialThemeLoaded
          ? customisation.theme.secondaryButtonFontColor
          : themeData.secondaryButtonFontColor,
      },
      headerButtonFont: {
        main: initialThemeLoaded
          ? customisation.theme.headerButtonFontColor
          : themeData.headerButtonFontColor,
      },
      headerButtonBorder: {
        main: initialThemeLoaded
          ? customisation.theme.headerButtonFontColor
          : themeData.headerButtonFontColor,
      },
      headerColor: {
        main: initialThemeLoaded
          ? customisation.theme.headerColor
          : themeData.headerColor,
      },
      headerFontColor: {
        main: initialThemeLoaded
          ? customisation.theme.headerFontColor
          : themeData.headerFontColor,
      },
      appbarColor: {
        main: initialThemeLoaded
          ? customisation.theme.appbarColor
          : themeData.appbarColor,
      },
      appbarFontColor: {
        main: initialThemeLoaded
          ? customisation.theme.appbarFontColor
          : themeData.appbarFontColor,
      },
      menuColor: {
        main: initialThemeLoaded
          ? customisation.theme.menuColor
          : themeData.menuColor,
      },

      menuFontColor: {
        main: initialThemeLoaded
          ? customisation.theme.menuFontColor
          : themeData.menuFontColor,
      },
      subMenuColor: {
        main: initialThemeLoaded
          ? customisation.theme.subMenuColor
          : themeData.subMenuColor,
      },
      subMenuFontColor: {
        main: initialThemeLoaded
          ? customisation.theme.subMenuFontColor
          : themeData.subMenuFontColor,
      },
      landingTileFontColor: {
        main: initialThemeLoaded
          ? customisation.theme.landingTileFontColor
          : themeData.landingTileFontColor,
      },
      landingTileButtonFontColor: {
        main: initialThemeLoaded
          ? customisation.theme.landingTileButtonFontColor
          : themeData.landingTileButtonFontColor,
      },
      footerColor: {
        main: initialThemeLoaded
          ? customisation.theme.footerColor
          : themeData.footerColor,
      },
      footerFontColor: {
        main: initialThemeLoaded
          ? customisation.theme.footerFontColor
          : themeData.footerFontColor,
      },
      pointsColor: {
        main: initialThemeLoaded
          ? customisation.theme.pointsColor
          : themeData.pointsColor,
      },
    },

    typography: {
      fontFamily: initialThemeLoaded
        ? customisation.theme.fontFamily.join(",")
        : themeData.fontFamily.join(","),
    },
  });

  const matches = useMediaQuery("(min-width:850px)");
  document.title = testTheme.title;
  return (
    <ScrollToTop>
      <MuiThemeProvider theme={theme}>
        <div
          className={classes.appContainer}
          style={{ fontFamily: theme.typography.fontFamily }}
        >
          <header className="App-header">
            {matches ? <Header /> : <MobileHeader />}
            <div style={{ display: "flex", flexGrow: "1" }}>
              <Switch>
                <Route path="/signin" component={SignIn} exact />
                <Route path="/signin/register" component={Register} />
                <Route path="/resetpassword" component={ResetPassword} />
                <Route
                  path="/signin/forgot-password"
                  component={ForgotPassword}
                />
                <Route
                  path="/admin"
                  render={(props) => (
                    <AdminContainer {...props} customisation={customisation} />
                  )}
                />
                <Route
                  path="/"
                  render={(props) => (
                    <ContentContainer
                      {...props}
                      customisation={customisation}
                    />
                  )}
                />
              </Switch>
            </div>
            <Footer />
          </header>{" "}
          <CustomSnackbar />
        </div>
      </MuiThemeProvider>
    </ScrollToTop>
  );
}

export default App;
