import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import { Facebook, LinkedIn, YouTube } from "@material-ui/icons";
import { setXClientHost } from "../../app/utils";
import {
  createContent,
  fetchContentByName,
  selectContents,
  updateContent,
} from "../content/contentsSlice";

const ColorButton = withStyles((theme) => ({
  root: {
    // margin: theme.spacing(3, 0, 2),
    margin: theme.spacing(0, 0, 0),
    backgroundColor: theme.palette.primaryButton.main,
    color: theme.palette.primaryButtonFont.main,
    "&:hover": {
      backgroundColor: theme.palette.primaryButton.main,
      opacity: 0.7,
    },
    width: 200,
    // fontWeight: 'bold',
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  customisationContainer: {
    padding: 24,
    borderRadius: 16,
    color: theme.palette.primaryFont.main,
    fontSize: 16,
    // fontWeight: 'bold',
    width: "75%",
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 16,
    color: theme.palette.secondaryFont.main,
  },
  subTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 16,
    marginTop: 16,
    color: theme.palette.secondaryFont.main,
  },
  customUnit: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 8,
    marginTop: 8,
  },
  customInputContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  inputLabel: { width: 100 },
  picker: { marginLeft: 16, marginRight: 16 },
  pickerInput: { width: "100%" },
  customDisplay: {},
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export default function CustomVariables() {
  const classes = useStyles();
  const dispatch = useDispatch();
  let jwtToken = localStorage.getItem("clientToken");

  const { content, showAlert, social, alert } = useSelector(selectContents);

  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [youtube, setYouTube] = useState("");
  const [linkedIn, setLinkedIn] = useState("");

  const [showAlertState, setShowAlertState] = useState(
    showAlert.value === "true"
  );
  const [alertMessage, setAlertMessage] = useState(
    content ? content.value : ""
  );
  useEffect(() => {
    dispatch(
      fetchContentByName({
        host: setXClientHost(),
        token: jwtToken,
        name: "alertMessage",
      })
    );
    dispatch(
      fetchContentByName({
        host: setXClientHost(),
        token: jwtToken,
        name: "social_links",
      })
    );
  }, []);

  useEffect(() => {
    if (alert.id && showAlert.id) {
      setAlertMessage(alert.value);
      setShowAlertState(showAlert.value === "true");
    }
  }, [alert, showAlert]);

  useEffect(() => {
    if (social.value) {
      try {
        let socialObj = JSON.parse(JSON.parse(social.value));
        setFacebook(socialObj.facebook);
        setInstagram(socialObj.instagram);
        setYouTube(socialObj.youtube);
        setLinkedIn(socialObj.linkedIn);
      } catch (error) {
        console.log("error");
      }
    }
  }, [social]);

  const handleUpdateSocial = () => {
    let socialObj = {
      facebook: facebook,
      instagram: instagram,
      youtube: youtube,
      linkedIn: linkedIn,
    };

    let stringSocial = JSON.stringify(JSON.stringify(socialObj));
    // dispatch(
    //   createContent({
    //     token: jwtToken,
    //     content: {
    //       classification: "data",
    //       storageKey: null,
    //       name: "alertMessage",
    //       value: "",
    //     },
    //   })
    // );
    // dispatch(
    //   createContent({
    //     token: jwtToken,
    //     content: {
    //       classification: "data",
    //       storageKey: null,
    //       name: "showAlert",
    //       value: "false",
    //     },
    //   })
    // );
    if (social.value) {
      dispatch(
        updateContent({
          token: jwtToken,
          id: social.id,
          content: { value: stringSocial },
          name: "social_links",
        })
      );
    } else {
      dispatch(
        createContent({
          token: jwtToken,
          content: {
            name: "social_links",
            value: stringSocial,
            classification: "data",
            storageKey: null,
          },
        })
      );
    }
  };

  const handleUpdateAlert = () => {
    if (showAlert.value) {
      dispatch(
        updateContent({
          token: jwtToken,
          id: alert.id,
          content: { value: alertMessage },
        })
      );
      dispatch(
        updateContent({
          token: jwtToken,
          id: showAlert.id,
          content: { value: showAlertState.toString() },
        })
      );
    } else {
      dispatch(
        createContent({
          token: jwtToken,
          content: {
            name: "alertMessage",
            value: "",
            classification: "data",
            storageKey: null,
          },
        })
      );
      dispatch(
        createContent({
          token: jwtToken,
          content: {
            name: "showAlert",
            value: "false",
            classification: "data",
            storageKey: null,
          },
        })
      );
    }
  };

  return (
    <Paper elevation={3} className={classes.customisationContainer}>
      <div className={classes.title}>Site Variables</div>
      <div className={classes.subTitle}>Social Media Links</div>
      <Divider style={{ marginTop: 16, marginBottom: 16 }} />
      <div style={{ fontWeight: "bold", fontStyle: "italic", fontSize: 18 }}>
        If no value is supplied corresponding icon will not appear in the footer
      </div>
      <div className={classes.customUnit}>
        <div className={classes.customInputContainer}>
          <label className={classes.inputLabel} for='facebook'>
            Facebook:
          </label>
          <div style={{ display: "flex", marginRight: 8, color: "#4267B2" }}>
            <Facebook />
          </div>
          <TextField
            className={classes.pickerInput}
            variant='outlined'
            margin='dense'
            id='facebook'
            name='facebook'
            autoComplete='text'
            value={facebook}
            onChange={(e) => setFacebook(e.target.value)}
          />
        </div>
      </div>
      <div className={classes.customUnit}>
        <div className={classes.customInputContainer}>
          <label className={classes.inputLabel} for='instagram'>
            Instagram:
          </label>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              height: 24,
              justifyContent: "center",
              marginRight: 8,
              width: 24,
            }}
          >
            <img
              height='18'
              width='18'
              src='/instagram.png'
              alt='instagram logo'
            />
          </div>
          <TextField
            className={classes.pickerInput}
            variant='outlined'
            margin='dense'
            id='instagram'
            name='instagram'
            autoComplete='text'
            value={instagram}
            onChange={(e) => setInstagram(e.target.value)}
          />
        </div>
      </div>
      <div className={classes.customUnit}>
        <div className={classes.customInputContainer}>
          <label className={classes.inputLabel} for='youtube'>
            YouTube:
          </label>
          <div style={{ display: "flex", marginRight: 8, color: "#FF0000" }}>
            <YouTube />
          </div>
          <TextField
            className={classes.pickerInput}
            variant='outlined'
            margin='dense'
            id='youtube'
            name='youtube'
            autoComplete='text'
            value={youtube}
            onChange={(e) => setYouTube(e.target.value)}
          />
        </div>
      </div>
      <div className={classes.customUnit}>
        <div className={classes.customInputContainer}>
          <label className={classes.inputLabel} for='linkedIn'>
            LinkedIn:
          </label>
          <div style={{ display: "flex", marginRight: 8, color: "#2867b2" }}>
            <LinkedIn />
          </div>
          <TextField
            className={classes.pickerInput}
            variant='outlined'
            margin='dense'
            id='linkedIn'
            name='linkedIn'
            autoComplete='text'
            value={linkedIn}
            onChange={(e) => setLinkedIn(e.target.value)}
          />
        </div>
      </div>
      <div style={{ marginBottom: 16, marginTop: 16 }}>
        <ColorButton onClick={handleUpdateSocial}>
          Update Social Media
        </ColorButton>
      </div>
      {/*  colors */}
      <Divider style={{ marginTop: 16, marginBottom: 16 }} />
      <div className={classes.subTitle}>Landing Page Alert Message</div>
      <div className={classes.customUnit}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            flexGrow: 1,
          }}
        >
          <div style={{ marginBottom: 16 }}>
            <label>
              Show Alert:
              <input
                className={classes.picker}
                style={{ marginLeft: 16, marginBottom: 10 }}
                type='checkbox'
                id='headerButtonFontColor'
                name='headerButtonFontColor'
                checked={showAlertState}
                onChange={(e) => {
                  setShowAlertState(e.target.checked);
                }}
              />
            </label>
          </div>
          <div style={{ marginBottom: 16 }}>
            <label>
              Alert Message:
              <TextField
                id='outlined-textarea'
                // label="Multiline Placeholder"
                minRows={4}
                placeholder='Set alert message...'
                multiline
                variant='outlined'
                value={alertMessage}
                style={{ marginLeft: 16, width: 400 }}
                onChange={(e) => setAlertMessage(e.target.value)}
              />
            </label>
          </div>
          <div style={{ marginBottom: 16 }}>
            <ColorButton onClick={handleUpdateAlert}>
              Update Alert Settings
            </ColorButton>
          </div>
        </div>
        <div className={classes.customDisplay}></div>
      </div>
    </Paper>
  );
}
