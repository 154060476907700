import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { selectCarts } from "../carts/cartsSlice";
import { selectCustomisation } from "../customisation/customisationSlice";
import { setProduct } from "../products/coreProductsSlice";
import { fetchMyself, selectUsers } from "../users/usersSlice";
import { selectWishlist } from "../wishlist/wishlistSlice";
import { createOrder, selectOrders } from "./ordersSlice";

const BackButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "transparent",
    color: theme.palette.primaryFont.main,
    cursor: "pointer",
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: theme.palette.primaryFont.main,
    borderRadius: 20,
    width: 250,
    maxHeight: 38.5,
    "&:hover": {
      backgroundColor: "#0000004f",
    },
    fontWeight: "bold",
  },
}))(Button);

const SecondaryButton = withStyles((theme) => ({
  root: {
    // margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.primaryButton.main,
    color: "#fff",
    width: 250,
    fontWeight: "bold",
    borderRadius: 16,
    "&:hover": {
      backgroundColor: theme.palette.primaryButton.main,
    },
    // fontWeight: 'bold',
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  reviewOrderContainer: {
    margin: 60,
    marginTop: 24,
    // width: "95%"
  },
  reviewTitle: {
    textAlign: "left",
    // marginTop: 60,
    marginBottom: 24,
    fontWeight: "bold",
    fontSize: 32,
    color: theme.palette.primaryFont.main,
  },
  cartProducts: {
    // margin: 60
    borderRadius: 16,
    padding: 32,
    marginBottom: 60,
    color: theme.palette.secondaryFont.main,
    fontSize: 20,
    fontWeight: "bold",
  },
  productsHeader: {
    paddingBottom: 0,
  },
  productsList: {
    borderTop: "1px solid #DDD",
    paddingBottom: 16,
    paddingTop: 16,
  },
  productsActions: {
    paddingTop: 32,

    borderTop: "1px solid #DDD",
  },
  cartDetailsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    color: theme.palette.secondaryFont.main,
    fontSize: 20,
  },
  deliveryDetailsContainer: { width: "75%", marginRight: 48 },
  deliveryDetails: {
    padding: 32,
    paddingBottom: 48,
    paddingTop: 48,
    color: theme.palette.secondaryFont.main,
    fontSize: 20,
    borderRadius: 16,
  },
  additionalPapersContainer: {
    display: "flex",
    flexDirection: "column",
  },
  comments: {
    borderRadius: 16,
    padding: 32,
    paddingBottom: 56,
    paddingTop: 48,
    marginBottom: 82,
    color: theme.palette.secondaryFont.main,
    fontSize: 20,
  },
  assistance: {
    borderRadius: 16,
    padding: 32,
    paddingBottom: 56,
    paddingTop: 48,
    marginBottom: 82,
    color: theme.palette.secondaryFont.main,
    fontSize: 20,
    textAlign: "center",
  },
  assistanceText: {
    fontWeight: "bold",
    marginBottom: 8,
  },
  review: {
    borderRadius: 16,
    padding: 32,
    paddingBottom: 56,
    paddingTop: 48,
    marginBottom: 82,
    color: theme.palette.secondaryFont.main,
    fontSize: 20,
  },
  textLabel: {
    marginBottom: 4,
    marginTop: 16,
  },

  buttonsContainer: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  reviewHeader: {
    color: theme.palette.primaryFont.main,
    fontSize: 24,
    marginBottom: 24,
  },
  reviewDetail: {
    color: theme.palette.primaryFont.main,
    fontWeight: "normal",
    marginBottom: 16,
  },
  reviewSubhead: {
    fontWeight: "bold",
    marginBottom: 4,
  },
  colorSpan: { color: theme.palette.secondaryFont.main, fontWeight: "bold" },
  reviewColor: {
    color: theme.palette.primaryFont.main,
  },
}));

export default function ReviewOrder(props) {
  const matches = useMediaQuery("(min-width:850px)");
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { userAccount } = useSelector(selectUsers);
  const { orderDetails, orderComplete } = useSelector(selectOrders);
  const { cart, cartList } = useSelector(selectCarts);
  const { wishlist } = useSelector(selectWishlist);
  const { customisation } = useSelector(selectCustomisation);

  const [totalPoints, setTotalPoints] = useState(0);
  let jwtToken = localStorage.getItem("clientToken");
  useEffect(() => {
    setTotalPoints(cartList.totalPoints);
    // dispatch(
    //   fetchMyself({
    //     token: jwtToken,
    //   })
    // );
  }, [cart, dispatch]);

  useEffect(() => {
    if (orderComplete) {
      history.push("/submit-order");
    }
  }, [orderComplete]);

  const handleBack = () => {
    history.goBack();
  };
  const handleSubmit = () => {
    dispatch(
      fetchMyself({
        token: jwtToken,
      })
    ).then(() => {
      if (orderDetails.userPoints >= totalPoints) {
        const order = {
          billingAddress: orderDetails.billingAddress,
          deliveryAddress: orderDetails.deliveryAddress,
          // userPoints: orderDetails.userPoints,
          products: cartList.items.map((prod) => {
            return {
              productID: prod.productID,
              quantity: prod.quantity,
              totalPoints: prod.subTotalPoints,
            };
          }),
          notes: orderDetails.notes,
        };
        dispatch(
          createOrder({ userID: userAccount.id, token: jwtToken, order: order })
        );
      }
    });
  };

  const formatPTS = (str) => {
    return str.toLocaleString();
  };

  const isWishlist = (id) => {
    const wishlistIDs = wishlist.length
      ? wishlist.map((prod) => prod.productID)
      : [];
    return wishlistIDs.includes(id);
  };

  const handleProdClick = (e, product) => {
    let isFav = isWishlist(product.productID);
    dispatch(setProduct({ product: product.productDetails, fav: isFav }));
    history.push(`/products/${product.productName}`);
  };

  return (
    <div className={classes.reviewOrderContainer}>
      <div className={classes.reviewTitle}>Order Review</div>

      <Paper elevation={3} className={classes.cartProducts}>
        <Grid
          container
          spacing={3}
          direction="column"
          // justifyContent="space-between"
          className={classes.productsHeader}
        >
          <Grid item sm>
            {matches && (
              <Grid
                container
                spacing={3}
                direction="row"
                // justifyContent="space-between"
                className={classes.productsHeader}
              >
                <Grid item sm>
                  Product:
                </Grid>
                <Grid item sm style={{ textAlign: "center" }}>
                  Quantity:
                </Grid>
                <Grid item sm style={{}}>
                  Price (pts)
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item sm>
            {cartList.items &&
              cartList.items.length &&
              cartList.items.map((prod) => (
                <Grid
                  container
                  spacing={3}
                  direction={matches ? "row" : "column"}
                  key={prod.productID}
                  alignItems={matches ? "center" : "flex-start"}
                  style={{
                    borderTop: matches ? "1px solid #DDD" : "none",
                    borderBottom: !matches ? "1px solid #DDD" : "none",
                    alignItems: "center",
                  }}
                  className={classes.productsList}
                >
                  <Grid
                    item
                    sm
                    style={{
                      display: "flex",
                      flexDirection: matches ? "row" : "column",
                      cursor: "pointer",
                    }}
                    onClick={(e) => handleProdClick(e, prod)}
                  >
                    <Grid
                      item
                      sm
                      style={{
                        display: "flex",
                        flexDirection: matches ? "row" : "column",
                      }}
                    >
                      <div
                        style={{ justifyContent: "center", display: "flex" }}
                      >
                        <img
                          style={{
                            width: 150,
                            // height: 150,
                            obectFit: "contain",
                          }}
                          src={
                            prod.productImg != "/placeholder.png"
                              ? `${process.env.REACT_APP_PRODUCT_URL}/${prod.productImg}`
                              : prod.productImg
                          }
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginLeft: matches ? 32 : 0,
                          marginBottom: matches ? 12 : 0,
                        }}
                      >
                        <div
                          style={{
                            textAlign: matches ? "left" : "center",
                            paddingTop: matches ? 0 : 16,
                          }}
                        >
                          {" "}
                          {prod.productName}
                        </div>
                        <div className={classes.skuText}>
                          {/* {' '}
                          {prod.product.sku} */}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    className={classes.reviewColor}
                    item
                    sm
                    style={{ textAlign: "center" }}
                  >
                    {!matches && (
                      <span className={classes.colorSpan}>Quantity: </span>
                    )}{" "}
                    {prod.quantity}
                  </Grid>
                  <Grid className={classes.reviewColor} item sm>
                    {!matches && (
                      <span className={classes.colorSpan}>Price (pts): </span>
                    )}
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          color: prod.productDetails.special
                            ? "grey"
                            : customisation.theme.pointsColor,
                          textDecoration: prod.productDetails.special
                            ? "line-through"
                            : "none",
                        }}
                      >
                        {formatPTS(
                          prod.productDetails.special
                            ? prod.productDetails.points
                            : prod.productPoints
                            ? prod.productPoints
                            : ""
                        )}
                      </span>
                      {prod.productDetails.special && (
                        <span
                          style={{ color: customisation.theme.highlightColor }}
                        >
                          {formatPTS(
                            prod.productPoints ? prod.productPoints : ""
                          )}
                        </span>
                      )}
                    </div>
                  </Grid>
                </Grid>
              ))}
          </Grid>
          <Grid item sm style={{}}>
            <Grid
              container
              spacing={3}
              direction="row"
              // justifyContent="space-between"
              className={classes.productsActions}
            >
              <Grid item sm></Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm>
          <div className={classes.reviewHeader}>
            Submitted Delivery Information
          </div>
          <div
            style={{
              display: "flex",
              flexFlow: matches ? "row wrap" : "column",
              justifyContent: "flex-start",
            }}
          >
            <div style={{ width: matches ? "50%" : "100%" }}>
              <div className={classes.reviewSubhead}>First Name:</div>
              <div className={classes.reviewDetail}>
                {orderDetails.userDetails.firstName}
              </div>
              <div className={classes.reviewSubhead}>Last Name:</div>
              <div className={classes.reviewDetail}>
                {orderDetails.userDetails.lastName}
              </div>
              <div className={classes.reviewSubhead}>Email</div>
              <div className={classes.reviewDetail}>{userAccount.email}</div>
              <div className={classes.reviewSubhead}>Contact Number:</div>
              <div className={classes.reviewDetail}>
                {orderDetails.userDetails.mobile}
              </div>
            </div>
            <div style={{ width: matches ? "50%" : "100%" }}>
              {orderDetails.deliveryAddress.companyName && (
                <div>
                  <div className={classes.reviewSubhead}>
                    Company Name{" "}
                    <span style={{ fontSize: 12 }}>(if applicable)</span>:
                  </div>
                  <div className={classes.reviewDetail}>
                    {orderDetails.deliveryAddress.companyName}
                  </div>
                </div>
              )}
              <div className={classes.reviewSubhead}>Address:</div>
              <div className={classes.reviewDetail}>
                {`${orderDetails.deliveryAddress.streetName}, ${orderDetails.deliveryAddress.suburb}, ${orderDetails.deliveryAddress.city}`}
              </div>
              <div className={classes.reviewSubhead}>Post Code:</div>
              <div className={classes.reviewDetail}>
                {orderDetails.deliveryAddress.postCode}
              </div>
              <div className={classes.reviewSubhead}>Order Comments:</div>
              <div className={classes.reviewDetail}>{orderDetails.notes}</div>
            </div>
          </div>
        </Grid>
        <Grid item sm style={{ marginTop: 24 }}>
          <div className={classes.reviewHeader}>Payment Method</div>
          <div className={classes.reviewSubhead}>Total:</div>
          <div className={classes.reviewDetail}>
            {" "}
            {formatPTS(totalPoints)} pts
          </div>
          <div className={classes.reviewSubhead}>Payment Type:</div>
          <div className={classes.reviewDetail}>Points</div>
        </Grid>
      </Paper>
      <div className={classes.buttonsContainer}>
        <BackButton onClick={handleBack}>Back to Cart</BackButton>

        <div onClick={handleSubmit}>
          <SecondaryButton
            style={{ opacity: orderDetails.userPoints < totalPoints ? 0.5 : 1 }}
            disabled={orderDetails.userPoints < totalPoints}
          >
            Submit Order
          </SecondaryButton>
        </div>
      </div>
    </div>
  );
}
