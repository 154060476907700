import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CSVFeedbackTable from "./CSVFeedbackTable";

import {
  fetchDistributors,
  resetBulk as resetDistributorBulk,
  selectDistributors,
} from "../distributors/distributorsSlice";
import {
  fetchProducts,
  resetBulk as resetProductBulk,
  selectClientProducts,
} from "../products/productsSlice";
import { fetchUsers, resetBulk, selectUsers } from "../users/usersSlice";

const ColorButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#64B2CB",
    color: "#FFF",
    "&:hover": {
      backgroundColor: "#2171ce",
    },
    // fontWeight: 'bold',
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  textColor: {
    color: theme.palette.alternativeFont.main,
    borderColor: theme.palette.alternativeFont.main,
  }
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },

});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CSVFeedback() {
  const classes = useStyles();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  let jwtToken = localStorage.getItem("clientToken");

  const { feedback, bulkDetails, bulkStatus, lastQuery } =
    useSelector(selectUsers);

  const { productFeedback, productBulkDetails, productBulkStatus } =
    useSelector(selectClientProducts);

  const { distributorFeedback, distributorBulkDetails, distributorBulkStatus } =
    useSelector(selectDistributors);

  const [currentFeedback, setCurrentFeedback] = useState(feedback);

  const [currentBulkStatus, setCurrentBulkStatus] = useState(bulkStatus);

  const [currentBulkDetails, setCurrentBulkDetails] = useState(bulkDetails);

  useEffect(() => {
    setCurrentFeedback(feedback);
    setCurrentBulkStatus(bulkStatus);
    setCurrentBulkDetails(bulkDetails);
  }, [feedback, bulkStatus, bulkDetails]);

  useEffect(() => {
    setCurrentFeedback(productFeedback);
    setCurrentBulkStatus(productBulkStatus);
    setCurrentBulkDetails(productBulkDetails);
  }, [productFeedback, productBulkStatus, productBulkDetails]);

  useEffect(() => {
    setCurrentFeedback(distributorFeedback);
    setCurrentBulkStatus(distributorBulkStatus);
    setCurrentBulkDetails(distributorBulkDetails);
  }, [distributorFeedback, distributorBulkStatus, distributorBulkDetails]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleUpload = () => {};

  const handleClear = () => {
    if (pathname.includes("users")) {
      dispatch(fetchUsers({ token: jwtToken, query: lastQuery }));
      dispatch(resetBulk());
    } else if (pathname.includes("distributors")) {
      dispatch(fetchDistributors({ token: jwtToken, query: "?limit=25" }));
      dispatch(resetProductBulk());
    } else {
      dispatch(fetchProducts({ token: jwtToken, query: "?limit=25" }));
      dispatch(resetDistributorBulk());
    }
    setOpen(false);
  };

  return (
    <div style={{ display: "flex" }} className={classes.textColor}>
      <div style={{ display: "flex", fontSize: 18, alignItems: "center" }}>
        {currentBulkStatus === "uploading" ? (
          <div style={{ marginRight: 24 }}>
            {currentBulkDetails.name} upload in progress
          </div>
        ) : currentBulkStatus === "success" ? (
          <div style={{ marginRight: 24 }}>
            {currentBulkDetails.name} upload completed successfully
          </div>
        ) : currentBulkStatus === "success with errors" ? (
          <div style={{ marginRight: 24 }}>
            {currentBulkDetails.name} upload completed with errors
          </div>
        ) : currentBulkStatus === "errors" ? (
          <div style={{ marginRight: 24 }}>
            {currentBulkDetails.name} upload failed
          </div>
        ) : (
          <div></div>
        )}
        {currentBulkStatus === "success with errors" ||
        currentBulkStatus === "error" ? (
          <Button
            style={{ marginRight: 24 }}
            variant="outlined"
            onClick={handleClickOpen}
            className={classes.textColor}
          >
            Error Report{" "}
          </Button>
        ) : currentBulkStatus === "success" ? (
          <Button
            style={{ marginRight: 24 }}
            variant="outlined"
            onClick={handleClear}
            className={classes.textColor}
          >
            Clear Message{" "}
          </Button>
        ) : (
          <div></div>
        )}
      </div>

      <Dialog
        id="feedback-dialog"
        fullWidth={true}
        maxWidth={"lg"}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <div style={{ fontWeight: "bold", fontSize: 24 }}>
            Error report for {currentBulkDetails.name}
          </div>
        </DialogTitle>
        <DialogContent style={{ padding: 24, paddingBottom: 48 }} dividers>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 24,
            }}
          >
            <div>
              <div style={{ fontWeight: "bold", fontSize: 18 }}>
                Status:{" "}
                <span style={{ fontWeight: "normal" }}>
                  {currentFeedback.status}
                </span>
              </div>
              <div style={{ fontWeight: "bold", fontSize: 18 }}>
                Success Count:{" "}
                <span style={{ fontWeight: "normal" }}>
                  {currentFeedback.successCount}
                </span>
              </div>
              <div style={{ fontWeight: "bold", fontSize: 18 }}>
                Error Count:{" "}
                <span style={{ fontWeight: "normal" }}>
                  {currentFeedback.failures
                    ? currentFeedback.failures.length
                    : ""}
                </span>
              </div>
            </div>
            <div>
              {/* <ColorButton onClick={handleUpload}>New Upload</ColorButton> */}
            </div>
          </div>

          <CSVFeedbackTable
            data={currentFeedback.failures ? currentFeedback.failures : []}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClear} color="primary">
            Clear Report
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
