// Importing helper modules
import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import { render } from "react-dom";

import EmailEditor from "react-email-editor";
import {
  fetchEmailTemplates,
  selectEmails,
  updateEmailTemplate,
  sendTestEmail,
} from "./emailsSlice";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  topBackground: {
    backgroundColor: theme.palette.secondary.main,
  },
  cardContainer: {
    backgroundColor: "#fff",
    borderRadius: 16,
    color: theme.palette.secondaryFont.main,
    padding: 32,
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.primaryButton.main,
    color: theme.palette.primaryButtonFont.main,
    marginRight: "10px",

    borderRadius: "24px",
    "&:hover": {
      // backgroundColor: theme.palette.secondaryButton.main,
      opacity: 0.9,
    },
  },
}))(Button);

const Editor = () => {
  const classes = useStyles();
  let jwtToken = localStorage.getItem("clientToken");
  const { emailTemplates } = useSelector(selectEmails);
  const dispatch = useDispatch();
  // Editor state
  const [editorReady, setEditorReady] = useState(false);
  const [templateLoaded, setTemplateLoaded] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [subject, setSubject] = useState("");
  const [recipient, setRecipient] = useState("");
  const [mergeTags, setMergeTags] = useState(null);
  const [loadingTemplate, setLoadingTemplate] = useState(false);

  const emailEditorRef = useRef(null);

  useEffect(() => {
    dispatch(
      fetchEmailTemplates({
        token: jwtToken,
      })
    );
  }, []);

  const exportHtml = async () => {
    let currentText = "";

    // Extract text from HTML content
    const extractTextFromHtml = (html) => {
      const div = document.createElement("div");
      div.innerHTML = html;
      return div.textContent || div.innerText || "";
    };

    let currentHTML = await new Promise((resolve) => {
      emailEditorRef.current.editor.exportHtml((data) => {
        const { design, html } = data;

        // Remove style tags and their contents
        let cleanedHtml = html.replace(/<style([\s\S]*?)<\/style>/gi, "");
        currentText = extractTextFromHtml(cleanedHtml);

        let wrappedHtml = cleanedHtml;
        if (
          !cleanedHtml.includes("<!DOCTYPE html>") ||
          !cleanedHtml.includes("<html>") ||
          !cleanedHtml.includes("<body>")
        ) {
          wrappedHtml = `<!DOCTYPE html><html><body>${cleanedHtml}</body></html>`;
        }
        resolve(
          wrappedHtml.replace(/\r?\n|\r/g, "").replace(/>\s+</g, "><")
          // .replace(/"/g, '\\"')
        );
      });
    });

    dispatch(
      updateEmailTemplate({
        token: jwtToken,
        templateId: selectedTemplate.id,
        templateData: {
          tmpl: {
            Template: {
              TemplateName: selectedTemplate.name,
              SubjectPart: subject,
              TextPart: currentText,
              HtmlPart: currentHTML,
            },
          },
        },
      })
    );
  };

  const sendTest = async () => {
    let currentText = "";

    // Extract text from HTML content
    const extractTextFromHtml = (html) => {
      const div = document.createElement("div");
      div.innerHTML = html;
      return div.textContent || div.innerText || "";
    };

    let currentHTML = await new Promise((resolve) => {
      emailEditorRef.current.editor.exportHtml((data) => {
        const { design, html } = data;

        // Remove style tags and their contents
        let cleanedHtml = html.replace(/<style([\s\S]*?)<\/style>/gi, "");
        currentText = extractTextFromHtml(cleanedHtml);

        let wrappedHtml = cleanedHtml;
        if (
          !cleanedHtml.includes("<!DOCTYPE html>") ||
          !cleanedHtml.includes("<html>") ||
          !cleanedHtml.includes("<body>")
        ) {
          wrappedHtml = `<!DOCTYPE html><html><body>${cleanedHtml}</body></html>`;
        }
        resolve(
          wrappedHtml.replace(/\r?\n|\r/g, "").replace(/>\s+</g, "><")
          // .replace(/"/g, '\\"')
        );
      });
    });

    console.log("recipient", recipient);

    dispatch(
      sendTestEmail({
        token: jwtToken,
        emailData: {
          recipient: recipient,
          subject: subject,
          textBody: currentText,
          htmlBody: currentHTML,
        },
      })
    );
  };

  const onLoad = () => {
    // editor instance is created
    // you can load your template here;
    // const templateJson = {};
    // emailEditorRef.current.editor.loadDesign(templateJson);
  };

  // const onReady = () => {
  //   // editor is ready
  //   setEditorReady(true);
  //   if (selectedTemplate) {
  //     emailEditorRef.current.editor.loadDesign({
  //       html: selectedTemplate.tmpl.Template.HtmlPart,
  //       classic: true,
  //     });
  //     emailEditorRef.current.editor.addEventListener(
  //       "design:loaded",
  //       function (data) {
  //         // Design is loaded
  //         console.log("design loaded"); // design json
  //         setTimeout(() => {
  //           setTemplateLoaded(true);
  //         }, [1000]);
  //       }
  //     );
  //   }
  // };

  const onReady = () => {
    // editor is ready
    setEditorReady(true);
    if (selectedTemplate) {
      emailEditorRef.current.editor.loadDesign({
        html: selectedTemplate.tmpl.Template.HtmlPart.replace(
          "{{clientLogo}}",
          process.env.REACT_APP_ASSETS_URL + "/email_templates/images/logo.png"
        ),
        classic: true,
      });
      emailEditorRef.current.editor.addEventListener(
        "design:loaded",
        function (data) {
          // Design is loaded
          console.log("design loaded"); // design json
          setTimeout(() => {
            setTemplateLoaded(true);
            setLoadingTemplate(false); // Stop displaying the spinner
          }, [1000]);
        }
      );
    }
  };

  const customToolbarOptions = useMemo(() => {
    return [
      {
        category: "text",
        items: [
          {
            type: "font-size",
          },
        ],
      },
    ];
  }, []);

  const handleTemplateChange = (event) => {
    setTemplateLoaded(false);
    setLoadingTemplate(true);
    const newMergeTags = {};
    emailTemplates
      .find((template) => template.id == event.target.value)
      .dataPoints.forEach((dataPoint) => {
        newMergeTags[dataPoint] = {
          name: dataPoint.replace(/([A-Z])/g, "$1").trim(),
          value: `{{${dataPoint}}}`,
        };
      });
    setMergeTags(newMergeTags);

    setSelectedTemplate(
      emailTemplates.find((template) => template.id == event.target.value)
    );

    setSubject(
      emailTemplates.find((template) => template.id == event.target.value).tmpl
        .Template.SubjectPart
    );

    // Load selected template into the editor
  };

  return (
    <div>
      <Paper className={classes.cardContainer} elevation={4}>
        <div
          style={{
            fontSize: 24,
          }}
        >
          Select Email Template
        </div>
        <select
          value={selectedTemplate ? selectedTemplate.id : ""}
          onChange={handleTemplateChange}
          className={classes.storeSelect}
          style={{
            padding: "8px 16px", // Adjust padding as needed
            fontSize: "16px", // Adjust font size as needed
            borderRadius: "999px", // Use a large value for a rounded look
            border: "1px solid #ccc", // Add a border for visual separation
            appearance: "none", // Remove default styling
            background: "white", // Set background color
            backgroundImage:
              'url(\'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23353b48"><path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"/></svg>\')', // Add a custom dropdown arrow
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right center",
            paddingRight: "30px", // Adjust padding to accommodate the dropdown arrow
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Add a shadow for depth
            width: "100%", // Ensure the select input takes up full width
            boxSizing: "border-box", // Ensure padding is included in width
            margin: "10px 0px",
          }}
        >
          <option value="">Select Template</option>
          {emailTemplates.map((template) => (
            <option key={template.id} value={template.id}>
              {template.name}
            </option>
          ))}
        </select>
        {!templateLoaded && loadingTemplate && (
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
          >
            <CircularProgress />
          </div>
        )}
        <div style={{ display: templateLoaded ? "block" : "none" }}>
          <hr /> 
          <div
            style={{
              fontSize: 18,
              marginBottom: 10,
            }}
          >
            Subject:
          </div>
          <input
            type="text"
            placeholder="Enter email subject..."
            value={subject}
            onChange={(event) => {
              setSubject(event.target.value);
            }}
            style={{
              padding: "8px 12px", // Adjust padding as needed
              fontSize: "16px", // Adjust font size as needed
              borderRadius: "4px", // Use a smaller value for less rounded corners
              border: "1px solid #ccc", // Add a border for visual separation
              width: "100%", // Ensure the input takes up full width
              boxSizing: "border-box", // Ensure padding is included in width
              marginBottom: "16px", // Add some spacing below the input
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Add a shadow for depth
            }}
          />
          <div
            style={{
              fontSize: 18,
              marginBottom: 10,
            }}
          >
            Email Template:
          </div>
          <div
            style={{
              fontSize: 12,
              marginBottom: 10,
            }}
          >{`Client logo images can be added using the image button and providing the following link in the source input: 
          ${
            process.env.REACT_APP_ASSETS_URL +
            "/email_templates/images/logo.png"
          }`}</div>
          <EmailEditor
            ref={emailEditorRef}
            onLoad={onLoad}
            onReady={onReady}
            style={{ border: "1px solid" }}
            options={{
              mergeTags: mergeTags,
              features: {
                smartMergeTags: false,
                textEditor: {
                  emojis: false,
                  fontSizes: [
                    "10px",
                    "12px",
                    "14px",
                    "16px",
                    "18px",
                    "20px",
                    "24px",
                    "30px",
                  ],
                  inlineFontControls: true,
                },
              },
              toolbarOptions: {
                fontSize: true,
              },
              tools: {
                ...EmailEditor.defaultTools,
                ...customToolbarOptions,
              },
            }}
          />
          <div style={{ fontSize: 18, marginBottom: 10, marginTop: 10 }}>
            Test Email Recipient:
          </div>
          <input
            type="email"
            placeholder="Enter recipient email..."
            value={recipient}
            onChange={(event) => setRecipient(event.target.value)}
            style={{
              padding: "8px 12px",
              fontSize: "16px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              width: "100%",
              boxSizing: "border-box",
              marginBottom: "16px",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          />
        </div>
        <div>
          <ColorButton onClick={exportHtml} variant="contained">
            Save Changes
          </ColorButton>
          <ColorButton onClick={sendTest} variant="contained">
            Send Test Email
          </ColorButton>
        </div>
      </Paper>
    </div>
  );
};

export default Editor;
