import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import 'date-fns';
import React from 'react';

import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

let testTheme = require(`../themes/${process.env.REACT_APP_THEME}`);
const materialTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: testTheme.primaryColor,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        // color: lightBlue.A700,
      },
      daySelected: {
        backgroundColor: testTheme.primaryColor,
      },
      dayDisabled: {
        // color: lightBlue['100'],
      },
      current: {
        // color: lightBlue['900'],
      },
    },
    MuiPickersModal: {
      dialogAction: {
        // color: lightBlue['400'],
      },
    },
  },
});

export default function MaterialUIPickers(props) {
  // The first commit of Material-UI
  const { invoiceDate, setInvoiceDate, before, after, report, label, required } = props;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <MuiThemeProvider theme={materialTheme}>
        <KeyboardDatePicker
          className
          style={{
            width: '100%',
            backgroundColor: 'transparent',
            color: report ? '#FFF' : 'black',
            // backgroundColor: after ? '#fff' : before ? '#fff' : 'transparent',
          }}
          margin="dense"
          id="date-picker-dialog"
          // label={
          //   before
          //     ? 'Updated Before'
          //     : after
          //     ? 'Updated After'
          //     : label
          //     ? label
          //     : 'Select Invoice Date'
          // }
          format="dd/MM/yyyy"
          shrink={true}
          value={invoiceDate ? invoiceDate : null}
          // required
          // value={selectedDate}
          onChange={(e) => setInvoiceDate(e)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </MuiThemeProvider>
    </MuiPickersUtilsProvider>
  );
}
