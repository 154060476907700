import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";

import Tooltip from "@material-ui/core/Tooltip";
import { isInCart } from "../../app/utils";
import {
  addProductToCart,
  selectCarts,
  updateProductQuantity,
} from "../carts/cartsSlice";
import { setProduct } from "../products/coreProductsSlice";
import { selectUsers } from "../users/usersSlice";
import {
  addProductToWishlist,
  removeProductFromWishlist,
  selectWishlist,
} from "./wishlistSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 16,
    maxWidth: 345,
    maxHeight: 525,

    // paddingBottom: 70,
    // paddingTop: 35,
  },
  prodCard: {
    minWidth: 350,
    color: theme.palette.primaryFont.main,
  },

  cardAction: {
    padding: 14,
    paddingTop: 35,
  },
  CardContentContainer: {
    // minheight:
  },
  cardImagecontainer: {
    minHeight: 195,
    maxHeight: 195,
  },

  cardName: {
    color: theme.palette.primaryFont.main,
    textAlign: "left",
    fontWeight: "bold",
    fontSize: 18,
  },

  cardDescription: {
    color: theme.palette.secondaryFont.main,
    textAlign: "left",
    whiteSpace: "break-spaces",
    fontSize: 14,
    marginBottom: 4,
    marginTop: 4,
  },
  points: {
    // margin: theme.spacing(3, 0, 2),
    position: "absolute",
    zIndex: 900,

    backgroundColor: "#FFFFFF",
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: theme.palette.pointsColor.main,
    borderRadius: "24px",
    color: theme.palette.pointsColor.main,
    right: 16,
    fontSize: 24,
    fontWeight: "bold",
    padding: 4,
    paddingLeft: 24,
    paddingRight: 24,
    top: 30,
  },
  cardButtons: {
    height: 30,
  },
  special: {
    position: "absolute",
    padding: 16,
    paddingLeft: 24,
    paddingRight: 24,
    fontSize: 16,
    fontWeight: "bold",
    backgroundColor: theme.palette.highlight.main,
    borderRadius: "16px 0px 16px 0px",
    color: "#FFFFFF",
    textalign: "center",
  },
}));

export default function WishlistCard(props) {
  const matches = useMediaQuery("(min-width:850px)");
  const classes = useStyles();
  const { product, uA, handleRemove } = props;
  const dispatch = useDispatch();
  const { wishlist } = useSelector(selectWishlist);
  const { cartList } = useSelector(selectCarts);
  const { userAccount } = useSelector(selectUsers);

  let jwtToken = localStorage.getItem("clientToken");
  const [selectedImage, setSelectedImage] = useState(product.productImg);
  const [isFav, setIsFav] = useState(
    wishlist.filter((prod) => prod.productID === product.id).length
  );
  const [showFave, setShowFave] = useState(
    wishlist.filter((prod) => prod.productID === product.id).length
  );

  const [inCart, setIsInCart] = useState(
    isInCart(product.productID, cartList.items)
  );

  useEffect(() => {
    setIsInCart(isInCart(product.productID, cartList.items));
  }, [cartList]);

  useEffect(() => {
    let fav = wishlist.filter((prod) => prod.productID === product.id);
    setIsFav(fav.length);
    let prodImage = product.productImg
      ? product.productImg
      : "/placeholder.png";

    setSelectedImage(prodImage);
  }, [product, wishlist]);

  const toggleFavourite = () => {
    if (uA.id != undefined) {
      if (isFav) {
        dispatch(
          removeProductFromWishlist({
            token: jwtToken,
            prodID: product.id,
            id: uA.id,
          })
        );
      } else {
        const wishProd = {
          productID: product.id,
          productName: product.name,
          productPoints: product.points,
          productImg: product.images
            ? product.images[3].objName
            : "/placeholder.png",
          statusID: 1,
        };
        dispatch(
          addProductToWishlist({
            token: jwtToken,
            id: uA.id,
            product: wishProd,
          })
        );
      }
      setShowFave(!showFave);
    }
  };

  const addToCart = () => {
    const cartProd = {
      productID: product.productID,
      productName: product.productName,
      productPoints: product.productPoints,
      productImg: product.productImg ? selectedImage : "/placeholder.png",
      statusID: product.statusID,
      quantity: 1,
    };

    dispatch(
      addProductToCart({ token: jwtToken, id: uA.id, product: cartProd })
    );
  };

  const updateCartQuantity = () => {
    dispatch(
      updateProductQuantity({
        token: jwtToken,
        id: userAccount.id,
        productID: product.productID,
        product: { quantity: Number(inCart.quantity + 1) },
        showMessage: true,
      })
    );
  };

  const formatPTS = (str) => {
    return str.toLocaleString();
  };

  const handleProdClick = () => {
    dispatch(setProduct({ product: product, fav: isFav }));
  };

  return (
    <Grid item xs={3} className={classes.prodCard}>
      <Card
        style={{
          minHeight: matches ? 325 : 0,
          maxHeight: matches ? 525 : 365,
        }}
        className={classes.root}
        elevation={3}
        onClick={handleProdClick}
      >
        <Link
          to={{
            pathname: `products/${product.productName}`,
          }}
        >
          <CardActionArea
            style={{
              paddingBottom: matches ? 0 : 0,
            }}
            className={classes.cardAction}
          >
            <div className={classes.cardImagecontainer}>
              <div className={classes.points}>
                {product.productPoints && formatPTS(product.productPoints)} pts
              </div>
              {selectedImage && (
                <CardMedia
                  component="img"
                  alt={product.productName}
                  // height="140"
                  style={{ marginTop: 16, maxHeight: 195 }}
                  image={
                    product.productImg != "/placeholder.png"
                      ? `${process.env.REACT_APP_PRODUCT_URL}/` + selectedImage
                      : selectedImage
                  }
                  title={product.productName}
                />
              )}
            </div>
            <CardContent
              style={{ height: 58 }}
              className={classes.CardContentContainer}
            >
              <Typography
                className={classes.cardDescription}
                variant="body1"
                color="textSecondary"
                component="p"
              >
                {product.brandName}
              </Typography>
              <Typography
                className={classes.cardName}
                gutterBottom
                variant="h4"
                component="h2"
              >
                {product.productName.length > 80
                  ? product.productName.substring(0, 80) + "..."
                  : product.productName}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Link>

        <CardActions className={classes.cardButtons}>
          <Tooltip title="Remove from Wishlist">
            <Button size="small" color="primary">
              <DeleteOutlineIcon
                onClick={(event) => handleRemove(event, product)}
              />
            </Button>
          </Tooltip>
          <Tooltip title="Add to Cart">
            <Button size="small" color="primary">
              <ShoppingCartOutlinedIcon
                onClick={inCart.isInCart ? updateCartQuantity : addToCart}
              />
            </Button>
          </Tooltip>
        </CardActions>
      </Card>
    </Grid>
  );
}
