import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Card from "@material-ui/core/Card";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import AddStore from "./AddStore";
import { fetchStoreByID, selectStores } from "./storesSlice";

const useStyles = makeStyles((theme) => ({
  tabBar: {
    // backgroundColor: ' rgba(0, 0, 0, 0.08)',
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    // alignItems: 'center',
    borderRadius: "10px",
    backgroundColor: "#fff",
    padding: 0,
    height: "100%",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    padding: theme.spacing(1, 3, 2),
  },
  linkText: {
    fontSize: 16,
    display: "flex",
    alignItems: "center",
  },
  headerContainer: {
    margin: theme.spacing(2),
  },
  subHeader: { margin: theme.spacing(2), marginBottom: 0 },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  tabContent: {
    display: "flex",
    flexFlow: "column nowrap",
    flexGrow: 1,
  },
  root: {
    display: "flex",
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function StoreContainer(props) {
  // const {userAccount, error, loading} = props;
  const classes = useStyles();
  const { store, stores, error, loading, storesList, storesAmount, lastQuery } =
    useSelector(selectStores);  
  const location = useLocation();
  let jwtToken = localStorage.getItem("clientToken");

  const dispatch = useDispatch();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!store.id) {
      let storeID = location.pathname.split("/")[3];
      dispatch(
        fetchStoreByID({
          id: storeID,
          token: jwtToken,
        })
      );
    }
  }, []);

  return (
    <Container className={classes.paper} component="main" maxWidth="lg">
      {/* // <Container className={classes.paper} fixed> */}
      {loading === "loaded" && store && (
        <Card style={{ height: "100%" }} elevation={4}>
          <Grid
            container
            role="tabpanel"
            hidden={value !== 0}
            id={`simple-tabpanel-${0}`}
            aria-labelledby={`simple-tab-${0}`}
          >
            {value === 0 && (
              <div className={classes.tabContent}>
                <AddStore storeAccount editItem setEdit store={store} />
              </div>
            )}
          </Grid>
        </Card>
      )}
    </Container>
  );
}
