import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { updatePaymentSuccess } from "../payments/paymentsSlice";
import { selectUsers } from "../users/usersSlice";

export default function CheckoutDialog(props) {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const location = useLocation();

  let jwtToken = localStorage.getItem("clientToken");

  const { id, handleClose } = props;

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hideElement, setHideElement] = useState(false);

  const { userAccount } = useSelector(selectUsers);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    } else {
      setHideElement(true);
    }

    const paymentID = new URLSearchParams(window.location.search).get(
      "paymentID"
    );

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      setIsLoading(true);

      switch (paymentIntent.status) {
        case "succeeded":
          setHideElement(true);
          dispatch(
            updatePaymentSuccess({
              token: jwtToken,
              userID: userAccount.id,
              paymentID,
            })
          );
          setMessage("Payment succeeded!");
          setIsLoading(false);
          break;
        case "processing":
          setMessage("Your payment is processing.");
          setIsLoading(false);
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          setIsLoading(false);
          break;
        default:
          setMessage("Something went wrong.");
          setIsLoading(false);
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${process.env.REACT_APP_LOCAL_URL}/cart?paymentID=${id}`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit} style={{ padding: 20 }}>
      {!hideElement && <PaymentElement id="payment-element" />}
      {!hideElement && (
        <button
          disabled={isLoading || !stripe || !elements}
          id="submit"
          style={{
            padding: 5,
            marginTop: 10,
            background: "#5469d4",
            color: "#ffffff",
            borderRadius: 4,
            border: 0,
            padding: "12px 16px",
            cursor: "pointer",
            display: "block",
            transition: "all 0.2s ease",
            boxShadow: "0px 4px 5.5px 0px rgba(0, 0, 0, 0.07)",
            width: "100%",
          }}
        >
          <span id="button-text">
            {isLoading ? (
              <div className="spinner" id="spinner"></div>
            ) : (
              "Pay now"
            )}
          </span>
        </button>
      )}
      {/* Show any error or success messages */}
      {message && (
        <div style={{ padding: 16 }}>
          <div id="payment-message" style={{ marginBottom: 10 }}>
            {message}
          </div>
          <button
            onClick={() => {
              history.push(location.pathname);
              handleClose();
            }}
            style={{
              padding: 5,
              marginTop: 10,
              background: "#5469d4",
              color: "#ffffff",
              borderRadius: 4,
              border: 0,
              padding: "12px 16px",
              cursor: "pointer",
              display: "block",
              transition: "all 0.2s ease",
              boxShadow: "0px 4px 5.5px 0px rgba(0, 0, 0, 0.07)",
              width: "100%",
            }}
          >
            Continue Shopping
          </button>
        </div>
      )}
    </form>
  );
}
