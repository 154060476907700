import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { selectCustomisation } from "../customisation/customisationSlice";
import {
  createDistributor,
  selectDistributors,
  updateDistributor,
} from "../distributors/distributorsSlice";

import "react-phone-number-input/style.css";

const BackButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "transparent",
    color: "#fff",
    cursor: "pointer",
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: "#fff",
    borderRadius: 20,
    // width: 100,
    maxHeight: 38.5,
    "&:hover": {
      backgroundColor: "#0000004f",
    },
    fontWeight: "bold",
  },
}))(Button);

const ColorButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.secondaryButton.main,
    color: theme.palette.secondaryButtonFont.main,
    width: "100%",
    borderRadius: "24px",
    "&:hover": {
      backgroundColor: theme.palette.secondaryButton.main,
      opacity: 0.7,
    },
  },
}))(Button);
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};
const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "45px",
    // backgroundColor: theme.palette.secondary.main,
    width: "100%",
  },
  accountContainer: {
    height: "100%",
    color: theme.palette.primaryFont.main,
  },
  topBackground: {
    // backgroundColor: theme.palette.secondary.main,
    // height: '100%',
  },
  form: {
    // borderBottom: '1px solid rgb(221, 221, 221)',

    marginTop: theme.spacing(1),
    width: "100%", // Fix IE 11 issue.
    color: theme.palette.secondaryFont.main,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  registerContainer: {
    borderRadius: 16,
    backgroundColor: "#fff",
    color: theme.palette.secondaryFont.main,
    padding: "50px !important",
  },
  textLabel: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 4,
    marginTop: 8,
  },
  textInput: {},
  distributorDetailsContainer: {
    padding: 24,
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-between",
  },
  distributorDetails: {
    display: "flex",
    justifyContent: "space-between",
    // color: "#fff",
    fontSize: 40,
  },
  infoContainerEdit: {
    display: "flex",
    flexFlow: "column wrap",
    justifyContent: "space-between",
    marginLeft: 80,
  },

  distributorCardsContainerEdit: {
    display: "flex",
    flexFlow: "row wrap",
    padding: 24,
    boxSizing: "border-box",
    justifyContent: "space-between",
    flexGrow: 1,
    width: "100%",
  },
  historyCardContainerEdit: {
    display: "flex",
    flexGrow: 1,
    marginRight: 80,

    // padding: 24,
    // boxSizing: 'border-box',
  },
  historyCardEdit: {
    flexGrow: 1,
  },
  cardHeader: {
    color: theme.palette.primaryFont.main,
    fontWeight: "bold",
    fontSize: 40,
  },
  formControl: {
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: theme.palette.primaryFont.main,
    borderRadius: 16,
    display: "flex",
    marginTop: 12,
  },
  addCircle: { marginRight: 0, color: "#FFF" },
  title: {
    color: "#fff",
    padding: 16,
    backgroundColor: theme.palette.secondary.main,
    textAlign: "center",
    fontSize: 24,
    fontWeight: "bold",
  },
}));

const usePlaceholderStyles = makeStyles((theme) => ({
  placeholder: {
    color: theme.palette.primaryFont.main,
  },
}));

const Placeholder = ({ children }) => {
  const classes = usePlaceholderStyles();
  return <div className={classes.placeholder}>{children}</div>;
};

export default function AddDistributor(props) {
  const { distributorAccount, editItem, setEdit } = props;
  const classes = useStyles();
  const history = useHistory();
  const { customisation } = useSelector(selectCustomisation);

  const dispatch = useDispatch();
  const { distributor } = useSelector(selectDistributors);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [externalID, setExternalID] = useState("");
  const [externalIDType, setExternalIDType] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [websiteURL, setWebsiteURL] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [faxNumber, setFaxNumber] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");

  const [streetName, setStreetName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [suburb, setSuburb] = useState("");
  const [postCode, setPostCode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");

  const [statusList, setStatusList] = useState([
    { id: 1, value: "Active", name: "Active" },
    { id: 2, value: "Inactive", name: "Inactive" },
    { id: 5, value: "Pending", name: "Pending" },
  ]);

  const [statusOpen, setStatusOpen] = useState(false);
  const [status, setStatus] = useState(1);

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleStatusClose = () => {
    setStatusOpen(false);
  };

  const handleStatusOpen = () => {
    setStatusOpen(true);
  };

  let jwtToken = localStorage.getItem("clientToken");

  const handleSubmit = (event) => {
    event.preventDefault();
    const distributorDetails = {
      statusID: status,
      clientID: Number(process.env.REACT_APP_CLIENT_ID),
      name,
      description,
      externalID,
      externalIDType,
      emailAddress,
      websiteURL,
      phoneNumber,
      faxNumber,
      mobileNumber,
      steetAddress: {
        streetName,
        companyName,
        suburb,
        postCode,
        city,
        state,
        country,
      },
    };
    if (editItem) {
      dispatch(
        updateDistributor({
          token: jwtToken,
          id: distributor.id,
          distributor: distributorDetails,
        })
      );
    } else {
      dispatch(
        createDistributor({ token: jwtToken, distributor: distributorDetails })
      );
    }
  };

  const iconComponent = (props) => {
    return <ExpandMoreIcon className={props.className + " " + classes.icon} />;
  };

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (editItem) {
      setOpen(true);
      setStatus(distributor.statusID);
      setName(distributor.name);
      setDescription(distributor.description);
      setExternalID(distributor.externalID);
      setExternalIDType(distributor.externalIDType);
      setEmailAddress(distributor.emailAddress);
      setWebsiteURL(distributor.websiteURL);
      setPhoneNumber(distributor.phoneNumber);
      setFaxNumber(distributor.faxNumber);
      setMobileNumber(distributor.mobileNumber);
      setStreetName(distributor.streetName);
      setCompanyName(distributor.companyName);
      setSuburb(distributor.suburb);
      setPostCode(distributor.postCode);
      setCity(distributor.city);
      setState(distributor.state);
      setCountry(distributor.country);
    }
  }, [editItem]);

  const handleClickOpen = () => {
    setStatus(1);
    setName("");
    setDescription("");
    setExternalID("");
    setExternalIDType("");
    setEmailAddress("");
    setWebsiteURL("");
    setOpen(true);
  };

  const handleClose = () => {
    setStatus(1);
    setName("");
    setDescription("");
    setExternalID("");
    setExternalIDType("");
    setEmailAddress("");
    setWebsiteURL("");
    setOpen(false);
    setEdit(false);
  };

  return (
    <div>
      <IconButton aria-label={"add distributor"} style={{ marginLeft: 0 }}>
        <AddCircleIcon
          className={classes.addCircle}
          onClick={handleClickOpen}
        />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle
          disableTypography
          className={classes.title}
          id="form-dialog-title"
        >
          {editItem ? "Update Distributor" : "Add New Distributor"}
        </DialogTitle>
        <form className={classes.form} onSubmit={handleSubmit}>
          <DialogContent>
            <DialogContentText style={{ paddingTop: 8, textAlign: "center" }}>
              <div className={classes.distributorDetails}>
                <div className={classes.actionsItem}>
                  <div
                    style={{
                      fontSize: 24,
                    }}
                  >
                    Set Distributor Status
                  </div>
                  <FormControl className={classes.formControl}>
                    <Select
                      labelId="store-select-label"
                      id="store-select"
                      open={statusOpen}
                      onClose={handleStatusClose}
                      onOpen={handleStatusOpen}
                      value={status}
                      displayEmpty
                      onChange={handleStatusChange}
                      IconComponent={iconComponent}
                      classes={{ root: classes.select }}
                      input={<Input className={classes.storeSelect} />}
                      // input={<Input className={classes.brandSelect} />}
                      // renderValue={(selected) => selected.join(', ')}
                      // renderValue={(selected) => sortCategory}
                      MenuProps={MenuProps}
                      // displayEmpty
                    >
                      {statusList.map((status) => (
                        <MenuItem
                          // style={{color: theme.palette.primary.main}}
                          value={status.id}
                        >
                          {status.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </DialogContentText>
            <Grid className={classes.historyCardEdit}>
              <div
                style={{
                  fontSize: 28,
                  marginBottom: 18,
                  fontWeight: "bold",
                  marginTop: 24,
                }}
              >
                Distributor Details
              </div>
              <div className={classes.textLabel}>Distributor Name: </div>
              <TextField
                className={classes.textInput}
                variant="outlined"
                margin="dense"
                defaultValue={name}
                required
                fullWidth
                id="distributorName"
                name="distributorName"                
                onChange={(e) => setName(e.target.value)}
              />
              <div className={classes.textLabel}>Description: </div>
              <TextField
                className={classes.textInput}
                variant="outlined"
                margin="dense"
                defaultValue={description}
                fullWidth
                id="description"
                name="description"                
                onChange={(e) => setDescription(e.target.value)}
              />

              <div className={classes.textLabel}>External ID: </div>
              <TextField
                className={classes.textInput}
                variant="outlined"
                margin="dense"
                defaultValue={externalID}
                fullWidth
                id="externalID"
                name="externalID"                
                onChange={(e) => setExternalID(e.target.value)}
              />
              <div className={classes.textLabel}>External ID Type: </div>
              <TextField
                className={classes.textInput}
                variant="outlined"
                margin="dense"
                defaultValue={externalIDType}
                fullWidth
                id="externalIDType"
                name="externalIDType"                                
                onChange={(e) => setExternalIDType(e.target.value)}
              />

              <div className={classes.textLabel}>Email Address: </div>
              <TextField
                className={classes.textInput}
                variant="outlined"
                margin="dense"
                defaultValue={emailAddress}
                fullWidth
                id="email"
                name="email"
                autoComplete="email"                
                onChange={(e) => setEmailAddress(e.target.value)}
              />
              <div className={classes.textLabel}>Website URL: </div>
              <TextField
                className={classes.textInput}
                variant="outlined"
                margin="dense"
                defaultValue={websiteURL}
                fullWidth
                id="url"
                name="url"                
                onChange={(e) => setWebsiteURL(e.target.value)}
              />
              <div className={classes.textLabel}>Phone Number: </div>
              <TextField
                className={classes.textInput}
                variant="outlined"
                margin="dense"
                defaultValue={phoneNumber}
                fullWidth
                id="phoneNumber"
                name="phoneNumber"                
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              <div className={classes.textLabel}>Fax Number: </div>
              <TextField
                className={classes.textInput}
                variant="outlined"
                margin="dense"
                defaultValue={faxNumber}
                fullWidth
                id="fax"
                name="fax"                
                onChange={(e) => setFaxNumber(e.target.value)}
              />
              <div className={classes.textLabel}>Mobile Number: </div>
              <TextField
                className={classes.textInput}
                variant="outlined"
                margin="dense"
                defaultValue={mobileNumber}
                fullWidth
                id="mobileNumber"
                name="mobileNumber"                
                onChange={(e) => setMobileNumber(e.target.value)}
              />
              <div className={classes.textLabel}>Street Name: </div>
              <TextField
                className={classes.textInput}
                variant="outlined"
                margin="dense"
                defaultValue={streetName}
                fullWidth
                id="streetName"
                name="streetName"                
                onChange={(e) => setStreetName(e.target.value)}
              />
              <div className={classes.textLabel}>Company Name: </div>
              <TextField
                className={classes.textInput}
                variant="outlined"
                margin="dense"
                defaultValue={companyName}
                fullWidth
                id="companyName"
                name="companyName"                
                onChange={(e) => setCompanyName(e.target.value)}
              />
              <div className={classes.textLabel}>Suburb: </div>
              <TextField
                className={classes.textInput}
                variant="outlined"
                margin="dense"
                defaultValue={suburb}
                fullWidth
                id="suburb"
                name="suburb"                
                onChange={(e) => setSuburb(e.target.value)}
              />
              <div className={classes.textLabel}>Post Code: </div>
              <TextField
                className={classes.textInput}
                variant="outlined"
                margin="dense"
                defaultValue={postCode}
                inputProps={{ maxLength: 4 }}
                fullWidth
                id="postCode"
                name="postCode"                
                onChange={(e) => setPostCode(e.target.value)}
              />
              <div className={classes.textLabel}>City: </div>
              <TextField
                className={classes.textInput}
                variant="outlined"
                margin="dense"
                defaultValue={city}
                fullWidth
                id="city"
                name="city"                
                onChange={(e) => setCity(e.target.value)}
              />
              <div className={classes.textLabel}>State: </div>
              <TextField
                className={classes.textInput}
                variant="outlined"
                margin="dense"
                defaultValue={state}
                fullWidth
                id="state"
                name="state"                
                onChange={(e) => setState(e.target.value)}
              />
              <div className={classes.textLabel}>Country: </div>
              <TextField
                className={classes.textInput}
                variant="outlined"
                margin="dense"
                defaultValue={country}
                fullWidth
                id="country"
                name="country"                
                onChange={(e) => setCountry(e.target.value)}
              />
            </Grid>
          </DialogContent>
          <Divider />
          <DialogActions
            style={{ paddingLeft: 24, paddingRight: 24, paddingBottom: 16 }}
          >
            <Grid container spacing={2} direction="row">
              <Grid item sm>
                <ColorButton onClick={handleClose} color="primary">
                  Cancel
                </ColorButton>
              </Grid>
              <Grid item sm>
                <ColorButton type="submit" color="secondary">
                  {editItem ? "Update Distributor" : "Create Distributor"}
                </ColorButton>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
