import { makeStyles, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';


import { resetCart, selectCarts } from '../carts/cartsSlice';
import { selectCustomisation } from '../customisation/customisationSlice';
import { selectOrders } from './ordersSlice';

const SecondaryButton = withStyles((theme) => ({
  root: {
    // margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.secondaryButton.main,
    color: "#fff",
    width: 250,
    fontWeight: 'bold',
    borderRadius: 16,
    '&:hover': {
      backgroundColor: ' #ce5087',
    },
    // fontWeight: 'bold',
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  completeContainer: {
    margin: 60,
    marginTop: 24,
  },
  completeTitle: {
    textAlign: 'left',
    // marginTop: 60,
    marginBottom: 24,
    fontWeight: 'bold',
    fontSize: 32,
  },
  cartProducts: {
    // margin: 60
    borderRadius: 16,
    padding: 32,
    marginBottom: 60,
    color: theme.palette.secondaryFont.main,
    fontSize: 20,
    fontWeight: 'bold',
  },
  productsHeader: {
    marginRight: 50,
    paddingBottom: 0,
  },
  productsList: {
    borderTop: '1px solid #DDD',
    paddingBottom: 16,
    paddingTop: 16,
  },
  productsActions: {
    paddingTop: 32,

    borderTop: '1px solid #DDD',
  },
  cartDetailsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: theme.palette.secondaryFont.main,
    fontSize: 24,
  },
  deliveryDetailsContainer: {width: '75%', marginRight: 48},
  deliveryDetails: {
    padding: 32,
    paddingBottom: 48,
    paddingTop: 48,
    color: theme.palette.secondaryFont.main,
    fontSize: 24,
    borderRadius: 16,
  },
  additionalPapersContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  comments: {
    borderRadius: 16,
    padding: 32,
    paddingBottom: 56,
    paddingTop: 48,
    marginBottom: 82,
    color: theme.palette.secondaryFont.main,
    fontSize: 24,
  },
  assistance: {
    borderRadius: 16,
    padding: 32,
    paddingBottom: 56,
    paddingTop: 48,
    marginBottom: 82,
    color: theme.palette.secondaryFont.main,
    fontSize: 24,
    textAlign: 'center',
  },
  assistanceText: {
    fontWeight: 'bold',
    marginBottom: 8,
  },
  review: {
    borderRadius: 16,
    padding: 32,
    paddingBottom: 56,
    paddingTop: 48,
    marginBottom: 82,
    color: theme.palette.secondaryFont.main,
    fontSize: 24,
  },
  reviewTitle: {
    textAlign: 'left',
    marginTop: 60,
    marginBottom: 24,
    fontWeight: 'bold',
    fontSize: 40,
    color: theme.palette.primaryFont.main,
  },
  textLabel: {
    marginBottom: 4,
    marginTop: 16,
  },
  reviewHeader: {
    color: theme.palette.primaryFont.main,
    fontSize: 24,
    marginBottom: 24,
  },
  reviewDetail: {
    color: theme.palette.primaryFont.main,
    fontWeight: 'normal',
    marginBottom: 16,
  },
  reviewSubhead: {
    fontWeight: 'bold',
    marginBottom: 4,
  },
  completeInfo: {
    fontWeight: 'normal',
  },

  buttonsContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  colorSpan: {color: theme.palette.secondaryFont.main, fontWeight: 'bold'},
  submitColor: {
    color: theme.palette.primaryFont.main,
  },
}));

export default function SubmitOrder() {
  const {customisation} = useSelector(selectCustomisation);
  const {cart, cartList} = useSelector(selectCarts);
  const {orderNumber, orderDetails} = useSelector(selectOrders);

  const matches = useMediaQuery('(min-width:850px)');
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();


  const handleHome = () => {
    dispatch(resetCart())
    history.push('/');
  };
  const formatPTS = (str) => {
    return str.toLocaleString() 
  }

  return (
    <div className={classes.completeContainer}>
      <div
        style={{
          textAlign: matches ? 'left' : 'center',
        }}
        className={classes.completeTitle}
      >
        Order Complete
      </div>

      <Paper
        elevation={3}
        style={{fontSize: matches ? 24 : 18}}
        className={classes.cartProducts}
      >
        <Grid item sm>
          <div className={classes.completeHeader}>
            <div
              style={{
                display: 'flex',
                flexFlow: matches ? 'row nowrap' : 'column-reverse',
                justifyContent: 'space-between',
              }}
            >
              <div className={classes.reviewHeader}>Your order is complete</div>
              <div>Order # {orderNumber.id}</div>
            </div>
            <div>
              Thank you. Your order is complete.
            </div>
          </div>

          <Grid
            container
            spacing={3}
            direction="column"
            // justifyContent="space-between"
            className={classes.productsHeader}
          >
            <Grid item sm>
              {matches && (
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  // justifyContent="space-between"
                  className={classes.productsHeader}
                >
                  <Grid item sm>
                    Product:
                  </Grid>
                  <Grid item sm style={{textAlign: 'center'}}>
                    Quantity:
                  </Grid>
                  <Grid item sm style={{}}>
                    Price (pts)
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item sm>
              {orderDetails.products.items && orderDetails.products.items.length &&
                orderDetails.products.items.map((prod) => (
                  <Grid
                    container
                    spacing={3}
                    direction={matches ? 'row' : 'column'}
                    key={prod.productID}
                    alignItems={matches ? 'center' : 'flex-start'}
                    style={{
                      borderTop: matches ? '1px solid #DDD' : 'none',
                      borderBottom: !matches ? '1px solid #DDD' : 'none',
                    }}
                    className={classes.productsList}
                  >
                    <Grid
                      item
                      sm
                      style={{
                        display: 'flex',
                        flexDirection: matches ? 'row' : 'column',
                      }}
                    >
                      <div
                        style={{
                          marginLeft: 0,
                          fontWeight: 'normal',
                        }}
                      >
                        <div
                          style={{
                            fontWeight: !matches ? 'bold' : 'normal',
                            paddingBottom: !matches ? 12 : 0,
                          }}
                        >
                          {' '}
                          {prod.productName}
                        </div>
                        <div>
                          {' '}
                          {!matches && (
                            <span
                              className={classes.colorSpan}
                              style={{
                                paddingTop: 4,
                              }}
                            >
                              {/* SKU:{' '} */}
                            </span>
                          )}{' '}
                          {/* {prod.product.sku} */}
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      className={classes.submitColor}
                      item
                      sm
                      style={{textAlign: 'center'}}
                    >
                      {!matches && (
                        <span className={classes.colorSpan}>Quantity: </span>
                      )}{' '}
                      {prod.quantity}
                    </Grid>
                    <Grid className={classes.submitColor} item sm>
                      {!matches && (
                        <span className={classes.colorSpan}>Price (pts): </span>
                      )}{' '}
                      <div style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          color: prod.productDetails.special
                            ? "grey"
                            : customisation.theme.pointsColor,
                          textDecoration: prod.productDetails.special
                            ? "line-through"
                            : "none",
                        }}
                      >
                        {formatPTS(
                          prod.productDetails.special
                            ? prod.productDetails.points
                            : prod.productPoints
                            ? prod.productPoints
                            : ""
                        )}
                      </span>
                      {prod.productDetails.special && (
                        <span
                          style={{ color: customisation.theme.highlightColor }}
                        >
                          {formatPTS(
                            prod.productPoints ? prod.productPoints : ""
                          )}
                        </span>
                      )}
                    </div>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
            <Grid item sm style={{}}>
              <Grid
                container
                spacing={3}
                direction="row"
                // justifyContent="space-between"
                className={classes.productsActions}
              >
                <Grid item sm></Grid>
              </Grid>
            </Grid>
          </Grid>

          <div className={classes.completeInfo}>
            While logged in, you may continue shopping or view your current
            order status and order history via 'My Account'
          </div>
        </Grid>
      </Paper>
      <div className={classes.buttonsContainer}>
        <SecondaryButton onClick={handleHome}>Home</SecondaryButton>
      </div>
    </div>
  );
}
