import { configureStore } from "@reduxjs/toolkit";

import { combineReducers } from "redux";

import authReducer from "../features/auth/authSlice";
import brandsReducer from "../features/brands/brandsSlice";
import cartsReducer from "../features/carts/cartsSlice";
import categoriesReducer from "../features/categories/categoriesSlice";
import claimsReducer from "../features/claims/claimsSlice";
import clientsReducer from "../features/clients/clientsSlice";
import contentsReducer from "../features/content/contentsSlice";
import customisationReducer from "../features/customisation/customisationSlice";
import distributorsReducter from "../features/distributors/distributorsSlice";
import emailsReducer from "../features/emails/emailsSlice";
import leaderboardReducer from "../features/leaderboard/leaderboardSlice";
import membersReducer from "../features/members/membersSlice"
import ordersReducer from "../features/orders/ordersSlice";
import paymentsReducer from "../features/payments/paymentsSlice";
import coreProductsReducer from "../features/products/coreProductsSlice";
import clientProductsReducer from "../features/products/productsSlice";
import programsReducer from "../features/programs/programsSlice.js";
import reportsReducer from "../features/reports/reportsSlice";
import snackbarReducer from "../features/snackbar/snackbarSlice";
import storesReducer from "../features/stores/storesSlice";
import userPointsSlice from "../features/userPoints/userPointsSlice";
import usersReducer from "../features/users/usersSlice";
import wishlistReducer from "../features/wishlist/wishlistSlice";

const reducers = combineReducers({
  auth: authReducer,
  brands: brandsReducer,
  categories: categoriesReducer,
  cart: cartsReducer,
  claims: claimsReducer,
  clients: clientsReducer,
  clientProducts: clientProductsReducer,
  contents: contentsReducer,
  coreproducts: coreProductsReducer,  
  customisation: customisationReducer,
  distributors: distributorsReducter,
  emails: emailsReducer,
  leaderboards: leaderboardReducer,
  members: membersReducer,
  orders: ordersReducer,
  programs: programsReducer,
  payments: paymentsReducer,
  reports: reportsReducer,
  snackbar: snackbarReducer,
  stores: storesReducer,
  users: usersReducer,
  userPoints: userPointsSlice,
  wishlists: wishlistReducer,
});

const store = configureStore({
  reducer: reducers,
});

export default store;
