import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import MuiAlert from '@material-ui/lab/Alert';
import { resetSnack, selectSnackbar } from './snackbarSlice';

function Alert(props) {
  return (
    <MuiAlert style={{width: 500}} elevation={6} variant="filled" {...props} />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },

  close: {
    padding: theme.spacing(0.5),
  },
  snack: {width: 500},
  error: {
    color: ' "#fff"',
    fontWeight: 500,
    backgroundColor: '#f44336',
    width: 500,
  },
  info: {width: 500},
  success: {
    color: ' "#fff"',
    fontWeight: 500,
    // backgroundColor:  theme.palette.primary.main,
    width: 500,
  },
}));

export default function CustomSnackbar() {
  const {type, message, snackbar} = useSelector(selectSnackbar);
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (type === 'error' && message === 'Invalid or expired JWT' || message === 'invalid token') {
      localStorage.removeItem('clientUser');
      localStorage.removeItem('clientToken');
      history.push('/signin');
      setOpen(false);
    }
  }, [type, message]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(resetSnack())
    setOpen(false);
  };

  useEffect(() => {
    // setSnackPack((prev) => [
    //   ...prev,
    //   {message: snackbar.message, key: new Date().getTime()},
    // ]);
    if (type !== '') {
      setOpen(true);
    }
  }, [snackbar]);

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Snackbar
        open={open}
        autoHideDuration={30000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className={classes.snack}
      >
        <Alert onClose={handleClose} severity={snackbar.type}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
