import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CircularProgress from "@material-ui/core/CircularProgress";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Link, useHistory } from "react-router-dom";
import {
  fetchProducts,
  getFullList as getFullProductsList,
  selectClientProducts,
} from "../products/productsSlice";

import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Datepicker from "../../components/Datepicker";

import { fetchClient, selectClients } from "../clients/clientsSlice";
import {
  fetchDistributors,
  getFullList as getFullDistributorsList,
  selectDistributors,
} from "../distributors/distributorsSlice";

import { replaceUrlParam } from "../../app/utils";
import {
  preSignedURLClaims,
  selectClaims,
  updateClaim,
} from "../claims/claimsSlice";
import { approvePoints, declinePoints } from "../userPoints/userPointsSlice";

import { selectUsers } from "../users/usersSlice";

const BackButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 2),
    marginBottom: 10,
    backgroundColor: "transparent",
    color: theme.palette.primaryFont.main,
    cursor: "pointer",
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: theme.palette.primaryFont.main,
    borderRadius: 20,
    paddingLeft: 20,
    paddingRight: 20,
    maxHeight: 38.5,
    "&:hover": {
      backgroundColor: "#DDD",
    },
    fontWeight: "bold",
  },
}))(Button);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

const ContinueButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#fff",
    color: theme.palette.primaryFont.main,
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: theme.palette.primaryFont.main,
    borderRadius: 30,
    height: 60,
    fontSize: 20,
    width: 200,
    "&:hover": {
      backgroundColor: "#DDD",
    },
    fontWeight: "bold",
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  // marginRight: 8,
  // },
  stepOneContainer: {
    // minWidth: 1280,
    width: "95%",
  },
  goBack: {
    marginLeft: "-1rem",
    marginTop: "-0.5rem",
    marginBottom: 20,
  },
  cardContainer: {
    backgroundColor: "#fff",
    borderRadius: 16,
    color: theme.palette.secondaryFont.main,
    padding: "2rem",
    color: theme.palette.secondaryFont.main,
    fontSize: 16,
    fontWeight: "bold",
  },
  header: { fontWeight: "bold", marginBottom: 8 },
  title: {
    color: theme.palette.primaryFont.main,
    fontWeight: "bold",
    // marginBottom: 16,
    paddingTop: 8,
    textAlign: "center",
  },
  subTitle: {
    fontWeight: "bold",
    marginBottom: 8,
  },
  text: {
    marginBottom: 8,
  },

  progressContainer: {
    display: "flex",
    justifyContent: "center",
    margin: 62,
    marginTop: 16,
  },
  progress: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },

  continueContainer: {
    display: "flex",
    justifyContent: "center",
    margin: 62,
    marginBottom: 0,
  },

  textLabel: {
    // fontSize: 20,
    // fontWeight: 'bold',
    // marginBottom: 8,
    // marginTop: 12,
  },
  values: { fontWeight: "normal" },

  storeSelect: {
    backgroundColor: "#fff",
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: theme.palette.primaryFont.main,
    borderRadius: "24px",
    color: theme.palette.primaryFont.main,
    fontSize: 24,
    fontWeight: "bold",
    padding: 0,
    paddingLeft: 2,
    paddingRight: 24,
    textTransform: "capitalize",
    width: 250,
  },
  textInput: {
    backgroundColor: "#fff",
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: theme.palette.primaryFont.main,
    borderRadius: "24px",
    color: theme.palette.primaryFont.main,
    fontSize: 24,
    fontWeight: "bold",
    padding: 0,
    paddingLeft: 24,
    paddingRight: 24,
    textTransform: "capitalize",
    width: 250,
    height: 41.5,
  },
  textLabel: {
    marginBottom: 4,
    // marginTop: 16,
  },
  invoiceDetails: {
    // display: 'flex',
    // justifyContent: 'space-between',
  },

  productListItem: {
    display: "flex",
    paddingBottom: 24,
    paddingTop: 24,
  },
  productName: {
    flexGrow: 1,
    marginRight: 32,
  },
  quantity: {
    marginRight: 32,
    fontWeight: "bold",
    // fontSize: 26,
  },
  quantityBox: {
    display: "flex",
    flexFlow: "row nowrap",
    border: "2px solid #DDD",
    borderRadius: 16,
    height: 50,
    width: 75,
    padding: 4,
  },
  numberInput: {
    display: "flex",
    flexFlow: "row nowrap",
    border: "2px solid #DDD",
    borderRadius: 16,
    height: 50,
    width: 75,
    padding: 4,
  },
  quantityAmount: {
    color: theme.palette.primaryFont.main,
    height: 50,
    width: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    textAlign: "center",
    padding: 0,
  },
  quantityControls: {
    borderLeft: "2px solid #DDD",
    height: 50,
    width: 25,
  },
  quantityIncrease: {
    borderBottom: "2px solid #DDD",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 25,
    width: 25,
  },
  quantityDecrease: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 25,
    width: 25,
  },

  unitPrice: {
    width: 200,
    marginRight: 32,
  },
  unitGST: { width: 150, marginRight: 16 },
  productSubTotal: {
    marginRight: 32,
  },
  remove: {
    marginRight: 32,
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "flex-end",
  },
  removeIcon: {
    alignItems: "center",
    display: "flex",
    paddingTop: 12,
  },

  textItem: {
    color: theme.palette.primaryFont.main,
  },

  addAndPointsContainer: {
    display: "flex",
    flexFlow: "row wrap",
    paddingBottom: 32,
    paddingTop: 32,
  },
  addContainer: {
    display: "flex",
    flexFlow: "row wrap",
    width: "50%",
  },
  pointsContainer: {
    boxSizing: "border-box",
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-between",
    paddingRight: 187,
    width: "50%",
  },
  asterisk: {
    color: "red",
  },
}));

const usePlaceholderStyles = makeStyles((theme) => ({
  placeholder: {
    color: theme.palette.primaryFont.main,
  },
}));

const Placeholder = ({ children }) => {
  const classes = usePlaceholderStyles();
  return <div className={classes.placeholder}>{children}</div>;
};

export default function Claim(props) {
  const dispatch = useDispatch();
  const matches = useMediaQuery("(min-width:850px)");
  const theme = useTheme();
  const { claim } = useSelector(selectClaims);
  const { clientProductsList, loading, error, productsAmount } =
    useSelector(selectClientProducts);
  const { user, userAccount } = useSelector(selectUsers);
  const { client } = useSelector(selectClients);
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    if (!claim.id) {
      history.push(`/admin/claims`);
    }
  }, []);

  const [showStore, setShowStore] = useState(
    process.env.REACT_APP_SHOW_STORE_DISTRIBUTOR === "true"
  );

  const [step, setStep] = useState(0);

  const [admin, setAdmin] = useState(userAccount.roleID == 2 ? true : false);

  const firstUpdate = useRef(true);
  const firstUpdateDist = useRef(true);

  const [notes, setNotes] = useState(
    claim.points && claim.points.notes ? claim.points.notes : ""
  );

  //step 1
  const { distributorsList, distributorsAmount } =
    useSelector(selectDistributors);

  const [town, setTown] = useState(claim.town);
  const [store, setStore] = useState(null);

  const handleTownSelect = (event, value, index) => {
    setTown(value ? value.name : "");
  };

  //step 2
  const [invoiceDate, setInvoiceDate] = useState(claim.invoiceDate);
  const [invoiceNumber, setInvoiceNumber] = useState(claim.invoiceNumber);
  const [monetaryValue, setMonetaryValue] = useState(claim.monetaryValue);
  const [userID, setUserID] = useState(claim.userID);
  const [claimID, setClaimID] = useState(claim.id);
  const [file, setFile] = useState(null);
  const [invoiceType, setInvoiceType] = useState(null);
  const [pointsValue, setPointsValue] = useState(
    claim.points ? claim.points.points : ""
  );
  const [productQuantities, setProductQuantities] = useState([
    claim.product ? claim.product.quantity : "",
  ]);

  const fileInputRef = useRef();

  const [productList, setProductList] = useState([]);

  let jwtToken = localStorage.getItem("clientToken");

  useEffect(() => {
    if (!distributorsList.length) {
      dispatch(
        fetchDistributors({
          token: jwtToken,
          query: `?limit=100`,
        })
      );
    }
    if (!clientProductsList.length) {
      dispatch(fetchProducts({ token: jwtToken, query: "" }));
    }
    if (!client.id) {
      dispatch(
        fetchClient({
          token: jwtToken,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (
      claim &&
      Object.keys(claim).length !== 0 &&
      clientProductsList.length == productsAmount
    ) {
      setStep(0);
      setTown(claim.town);
      setInvoiceDate(claim.invoiceDate);
      setInvoiceNumber(claim.invoiceNumber);
      setClaimID(claim.id);
      setUserID(claim.userID);

      if (claim.products) {
        let newArr = JSON.parse(JSON.stringify(claim.products));
        newArr.forEach((product, index) => {
          let newProd = JSON.parse(JSON.stringify(product));
          let price = product.totalAmount / 100 / product.quantity;
          newProd["price"] = price;
          newProd["priceExGst"] = Number(
            Number(price) - Number(price) * Number(process.env.REACT_APP_GST)
          );

          let cProduct = clientProductsList.find(
            (element) => element.id == product.productID
          );

          if (cProduct && cProduct.points) {
            newProd["points"] = cProduct.points;
          }

          newArr[index] = newProd;
        });

        setProductList(newArr);
      }
    }
  }, [claim, clientProductsList.length]);

  useEffect(() => {
    if (
      clientProductsList.length &&
      clientProductsList.length != productsAmount &&
      firstUpdate.current
    ) {
      let searchQuery = "?limit=100";
      searchQuery = replaceUrlParam(searchQuery, "limit", 100);
      dispatch(
        getFullProductsList({
          token: jwtToken,
          offset: clientProductsList.length,
          query: searchQuery,
          targetLength: productsAmount,
        })
      );
      firstUpdate.current = false;
    }
  }, [clientProductsList.length]);

  useEffect(() => {
    if (
      distributorsList.length &&
      distributorsList.length != distributorsAmount &&
      firstUpdateDist.current
    ) {
      let searchQuery = "?limit=100";
      searchQuery = replaceUrlParam(searchQuery, "limit", 100);
      dispatch(
        getFullDistributorsList({
          token: jwtToken,
          offset: distributorsList.length,
          query: searchQuery,
          targetLength: distributorsAmount,
        })
      );
      firstUpdateDist.current = false;
    }
  }, [distributorsList.length]);

  useEffect(() => {
    setStore(
      distributorsList.filter(
        (distributor) => distributor.id === claim.distributorID
      )[0]
    );
  }, [distributorsList]);

  function removeItemWithSlice(index, items) {
    return [...items.slice(0, index), ...items.slice(index + 1)];
  }

  const handleRemove = (e, index) => {
    const newArr = removeItemWithSlice(index, productList);
    setProductList(newArr);
  };

  const handleAddProduct = () => {
    setProductList(
      productList.concat([
        {
          id: null,
          name: "",
          price: 0,
          points: 0,
          quantity: 1,
          priceExGst: 0,
          totalAmount: 0,
        },
      ])
    );
  };

  const filterOptions = (options, { inputValue }) => {
    let inputArr = inputValue.split(" ");

    let filterResult = [];
    inputArr.map((inputTerm, index) => {
      if (index === 0) {
        let results = options.filter((item) =>
          item.name.toLowerCase().includes(inputTerm.toLowerCase())
        );
        filterResult = results;
      } else {
        let results = filterResult.filter((item) =>
          item.name.toLowerCase().includes(inputTerm.toLowerCase())
        );
        filterResult = results;
      }
    });
    var result = [];
    filterResult.forEach(function (item) {
      if (result.indexOf(item) < 0) {
        result.push(item);
      }
    });

    return result;
  };

  const handleUpload = () => {
    if (fileInputRef.current.files.length) {
      setFile(Object.assign(fileInputRef.current.files[0]));
    }
  };

  const updateName = (e, index, value) => {
    let newArr = [...productList]; // copying the old array
    newArr[index].productID = value;
    setProductList(newArr);
  };

  const updateProduct = (e, index, value) => {
    let newArr = [...productList]; // copying the old array
    let newProd = { ...newArr[index] };

    newProd.productID = value;

    newArr[index] = newProd;
    setProductList(newArr);
  };

  const incQuantity = (e, index) => {
    let newArr = [...productList]; // copying the old array
    newArr[index].quantity = newArr[index].quantity + 1;
    newArr[index].subTotal =
      (Number(newArr[index].price) + Number(newArr[index].gst)).toFixed(2) *
      newArr[index].quantity;
    setProductList(newArr);
  };

  const decQuantity = (e, index) => {
    let newArr = [...productList]; // copying the old array
    if (newArr[index].quantity === 1) {
      handleRemove(e, index);
    } else {
      newArr[index].quantity = newArr[index].quantity - 1;
      newArr[index].subTotal =
        (Number(newArr[index].price) + Number(newArr[index].gst)).toFixed(2) *
        newArr[index].quantity;
      setProductList(newArr);
    }
  };

  const updateQuantity = (e, index) => {
    let newArr = [...claim.products]; // copying the old array
    newArr[index].quantity = Number(e.target.value);
    newArr[index].subTotal =
      Number(newArr[index].price).toFixed(2) * e.target.value;
    setProductList(newArr);
  };

  // const updatePrice = (e, index, value) => {
  //   let newArr = [...productList]; // copying the old array
  //   newArr[index].price = Number(value);
  //   newArr[index].gst = (Number(value) * 0.1).toFixed(2);
  //   newArr[index].subTotal =
  //     (Number(newArr[index].price) + Number(newArr[index].gst)).toFixed(2) *
  //     newArr[index].quantity;
  //   setProductList(newArr);
  // };

  function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  }

  const updatePrice = (e, index, value, gst) => {
    let newArr = [...productList]; // copying the old array
    newArr[index].gst = process.env.REACT_APP_GST;
    if (gst) {
      newArr[index].price = value;
      newArr[index].priceExGst = Number(
        Number(value) - Number(value) * Number(process.env.REACT_APP_GST)
      )
        .toFixed(2)
        .toString();
    } else {
      newArr[index].price = Number(
        Number(value) + Number(value) * Number(process.env.REACT_APP_GST)
      ).toFixed(2);
      newArr[index].priceExGst = value;
    }
    newArr[index].totalAmount =
      newArr[index].price * newArr[index].quantity * 100;
    setProductList(newArr);
  };

  const handleContinue = () => {
    setStep(3);
  };

  const decreaseQuantity = () => {
    setProductQuantities(productQuantities - 1);
  };

  const increaseQuantity = () => {
    setProductQuantities(productQuantities + 1);
  };

  const getTotal = () => {
    return productList.reduce(
      (accum, item) => Number(accum) + Number(item.subTotal),
      0
    );
  };

  const [open, setOpen] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);
  const [status, setStatus] = useState(claim.statusID);
  const handleChange = (e, distributor, index) => {
    setStore(distributor);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleStatusClose = () => {
    setStatusOpen(false);
  };

  const handleStatusOpen = () => {
    setStatusOpen(true);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const iconComponent = (props) => {
    return <ExpandMoreIcon className={props.className + " " + classes.icon} />;
  };

  const handleUpdate = () => {
    if (file) {
      uploadFileToAWS().then(() => {
        if (status === 27) {
          dispatch(
            approvePoints({
              token: jwtToken,
              userID: claim.userID,
              pointsID: claim.points.id,
              pointsDetails: notes
                ? {
                    notes,
                  }
                : {},
            })
          ).then(() => {
            history.push("/admin/claims");
          });
        } else if (status === 21) {
          dispatch(
            declinePoints({
              token: jwtToken,
              userID: claim.userID,
              pointsID: claim.points.id,
              pointsDetails: notes
                ? {
                    notes,
                  }
                : {},
            })
          ).then(() => {
            history.push("/admin/claims");
          });
        }
      });
    } else {
      handleSubmit().then(() => {
        if (status === 27) {
          dispatch(
            approvePoints({
              token: jwtToken,
              userID: claim.userID,
              pointsID: claim.points.id,
              pointsDetails: notes
                ? {
                    notes,
                  }
                : {},
            })
          ).then(() => {
            history.push("/admin/claims");
          });
        } else if (status === 21) {
          dispatch(
            declinePoints({
              token: jwtToken,
              userID: claim.userID,
              pointsID: claim.points.id,
              pointsDetails: notes
                ? {
                    notes,
                  }
                : {},
            })
          ).then(() => {
            history.push("/admin/claims");
          });
        }
      });
    }
  };

  const uploadFileToAWS = () => {
    return new Promise((resolve, reject) => {
      //Change to map through array of files
      let url;
      let jwtToken = localStorage.getItem("clientToken");
      if (file) {
        return new Promise((resolve, reject) => {
          let filename = uuidv4() + "." + file.name.split(".")[1];

          let data = {
            objectName: filename,
            objectType: file.type.split("/")[1],
            token: jwtToken,
            file: file,
            contentType: file.type,
            id: userID,
          };

          setInvoiceType(file.type.split("/")[1]);

          url = `${process.env.REACT_APP_ASSETS_URL}/users/${userID}/claims/attachments/${filename}`;

          dispatch(preSignedURLClaims(data)).then(() => {
            resolve();
          });
        }).then(() => {
          handleSubmit(url).then(() => {
            resolve();
          });
        });
      }
    });
  };

  const handleSubmit = (url) => {
    return new Promise((resolve, reject) => {
      let products = [];
      productList.map((product) => {
        let newProd = {};
        newProd.claimID = claimID;
        newProd.productID = product.productID;
        newProd.quantity = product.quantity;
        newProd.totalAmount = Math.floor(
          Number(product.price * product.quantity) * 100
        );

        products.push(newProd);
      });

      const payload = {
        invoiceNumber: invoiceNumber,
        invoiceDate: invoiceDate,
        monetaryValue:
          productList &&
          (productList[0].points > 0
            ? Math.floor(
                productList &&
                  productList
                    .map((item) => item.points * item.quantity)
                    .reduce((prev, next) => prev + next) * 100
              )
            : Math.floor(
                productList &&
                  productList
                    .map((item) => item.price * item.quantity)
                    .reduce((prev, next) => prev + next) * 100
              )),
        products: products.length ? products : null,
      };

      if (store && showStore) {
        payload["distributorID"] = store.id;
      }

      //  distributorID: claim.store.id,

      if (url) {
        payload["attachments"] = [
          {
            attmURL: url,
            attmType: claim.invoiceType === "pdf" ? "pdf" : "image",
            claimID,
          },
        ];
      }

      dispatch(
        updateClaim({
          token: jwtToken,
          claim: payload,
          id: userID,
          claimID: claimID,
        })
      ).then(() => {
        resolve();
      });
    });
  };

  return (
    <div className={classes.stepOneContainer}>
      {loading === "loading" ? (
        <div className={classes.progress}>
          {" "}
          <CircularProgress color="secondary" />
        </div>
      ) : error || !claim ? (
        <div>Something went wrong</div>
      ) : (
        <div>
          {claim.statusID != 5 ? (
            <Paper className={classes.cardContainer} elevation={4}>
              <BackButton className={classes.goBack}>
                <Link to="/admin/claims">Go Back</Link>
              </BackButton>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: matches ? "row" : "column",
                  marginBottom: matches ? 0 : 10,
                }}
              >
                <div
                  style={{
                    fontSize: 40,
                    fontWeight: "bold",
                    marginBottom: 24,
                    color: theme.palette.primaryFont.main,
                  }}
                >
                  Claim Number: {claim.id}
                </div>
                <div>
                  <FormControl className={classes.formControl}>
                    <Select
                      labelId="status-select-label"
                      id="status-select"
                      displayEmpty
                      renderValue={
                        status !== ""
                          ? undefined
                          : () => <Placeholder>Select one</Placeholder>
                      }
                      open={statusOpen}
                      onClose={handleStatusClose}
                      onOpen={handleStatusOpen}
                      value={status}
                      onChange={handleStatusChange}
                      IconComponent={iconComponent}
                      classes={{ root: classes.select }}
                      disabled
                      input={<Input className={classes.storeSelect} />}
                      // input={<Input className={classes.brandSelect} />}
                      // renderValue={(selected) => selected.join(', ')}
                      // renderValue={(selected) => sortCategory}
                      MenuProps={MenuProps}
                      // displayEmpty
                    >
                      <MenuItem
                        style={{ color: theme.palette.primary.main }}
                        value={5}
                      >
                        Pending
                      </MenuItem>
                      <MenuItem
                        style={{ color: theme.palette.primary.main }}
                        value={27}
                      >
                        Approved
                      </MenuItem>
                      <MenuItem
                        style={{ color: theme.palette.primary.main }}
                        value={21}
                      >
                        Declined
                      </MenuItem>
                      {/* <MenuItem style={{color: theme.palette.primary.main}} value={7}>
                  Cancelled
                </MenuItem> */}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div>
                <Grid container direction="column">
                  <Grid item sm>
                    <div className={classes.textLabel}>
                      Member Name:{" "}
                      <span style={{ fontWeight: "normal" }}>
                        {user.firstName} {user.lastName}
                      </span>
                    </div>
                    <div className={classes.textLabel}>
                      Email:{" "}
                      <span style={{ fontWeight: "normal" }}>
                        {claim.userEmail}
                      </span>
                    </div>
                    <div className={classes.textLabel}>
                      Contact Number:{" "}
                      <span style={{ fontWeight: "normal" }}>
                        {user.mobile}
                      </span>
                    </div>
                  </Grid>
                  <Grid item sm>
                    <div className={classes.textLabel}>
                      Address:
                      <span style={{ fontWeight: "normal" }}>
                        {user.streetAddress
                          ? // ? user.streetAddress.streetNumber +
                            //   // " " +
                            user.streetAddress.streetName
                          : ""}
                        {user.streetAddress ? user.streetAddress.suburb : ""}{" "}
                        {user.streetAddress
                          ? user.streetAddress.city +
                            " " +
                            user.streetAddress.postCode
                          : ""}
                      </span>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div style={{ marginTop: 24 }} className={classes.invoiceDetails}>
                <div style={{ width: "50%" }}>
                  {store && (
                    <div className={classes.textLabel}>Store Name </div>
                  )}
                  <div className={classes.values}>
                    {store ? store.name : ""}
                  </div>
                </div>
                {/* <AutocompleteInput selectedVal={store} name={'distributor'} list={distributorsList} index={1} setSelectedOption={handleChange} /></div> */}
              </div>
              <div style={{ marginTop: 24 }} className={classes.invoiceDetails}>
                <div>
                  <div className={classes.textLabel}>Invoice Number </div>
                  <div className={classes.values}>{invoiceNumber}</div>
                  {/*
            <TextField
              defaultValue={invoiceNumber}
              className={classes.textItem}
              variant="outlined"
              margin="dense"
              id="invoice-number"
              type="text"
              fullWidth
              onChange={(e) => setInvoiceNumber(e.target.value)}
            /> */}
                  {/* <Typography
              style={{marginTop: 8}}
              className={classes.text}
              variant="body1"
            >
              Please enter details of the product on your invoice.
            </Typography> */}
                </div>
                <div style={{ marginTop: 24 }}>
                  <div className={classes.textLabel}>Invoice Date* </div>
                  <div className={classes.values}>{invoiceDate}</div>
                  {/* <Datepicker
              invoiceDate={invoiceDate}
              setInvoiceDate={setInvoiceDate}
            /> */}
                </div>
              </div>
              <Divider style={{ marginTop: 24, height: 2 }} />
              {clientProductsList.length == productsAmount &&
                productList.map((product, index) => (
                  <div
                    style={{ flexDirection: matches ? "column" : "column" }}
                    className={classes.productListItem}
                    key={product.idx}
                  >
                    <div
                      style={{
                        marginRight: matches ? 32 : 0,
                        display: "flex",
                      }}
                      className={classes.productName}
                    >
                      <div
                        style={{
                          marginRight: matches ? 16 : 16,
                          marginBottom: matches ? 8 : 0,
                        }}
                        className={classes.textLabel}
                      >
                        Product:{" "}
                      </div>
                      <div className={classes.values}>
                        {clientProductsList.length &&
                          clientProductsList.filter(
                            (prod) => prod.id === product.productID
                          )[0].name}
                      </div>
                    </div>
                    {/* <div className={classes.quantity} style={{ display: "flex" }}>
                <div
                  style={{
                    marginRight: matches ? 16 : 16,
                    marginBottom: matches ? 8 : 0,
                  }}
                  className={classes.textLabel}
                >
                  Unit Price (ex GST):{" "}
                </div>
                <div className={classes.values}>{product.unitPriceExGST}</div>
              </div> */}
                    <div
                      className={classes.quantity}
                      style={{ display: "flex" }}
                    >
                      <div
                        style={{
                          marginRight: matches ? 16 : 16,
                          marginBottom: matches ? 8 : 0,
                        }}
                        className={classes.textLabel}
                      >
                        Quantity:{" "}
                      </div>
                      <div className={classes.values}>{product.quantity}</div>
                    </div>
                    {clientProductsList.length &&
                      clientProductsList.filter(
                        (prod) => prod.id === product.productID
                      )[0].points <= 0 && (
                        <div
                          className={classes.quantity}
                          style={{ display: "flex" }}
                        >
                          <div
                            style={{
                              marginRight: matches ? 16 : 16,
                              marginBottom: matches ? 8 : 0,
                            }}
                            className={classes.textLabel}
                          >
                            Total Amount:
                          </div>
                          <div className={classes.values}>
                            ${(product.totalAmount / 100).toFixed(2)}
                          </div>
                        </div>
                      )}
                  </div>
                ))}
              <Divider style={{ height: 2 }} />
              <div style={{ marginBottom: 20 }}>
                {productList.length &&
                  clientProductsList.length &&
                  !clientProductsList
                    .filter((prod) => prod.id === productList[0].productID)
                    .some((product, index) => product.points > 0) && (
                    <div style={{ marginTop: 24 }}>
                      <div className={classes.textLabel}>Monetary Value </div>
                      <div className={classes.values}>
                        ${(monetaryValue / 100).toFixed(2)}
                      </div>
                    </div>
                  )}
                <div style={{ marginTop: 24 }}>
                  <div className={classes.textLabel}>Points Value </div>
                  <div className={classes.values}>{pointsValue}</div>
                </div>
              </div>
              <Divider style={{ height: 2 }} />
              <div style={{ marginTop: 24 }} className={classes.textLabel}>
                Invoice Evidence Below
                <span className={classes.asterisk}>*</span>{" "}
              </div>
              <input
                style={{ display: "none" }}
                accept="application/pdf"
                className={classes.input}
                id="contained-button-file"
                // multiple
                type="file"
              />
              {claim.attachments
                ? claim.attachments.map((claim) => (
                    <div
                      style={{
                        marginBottom: 24,
                        marginTop: 24,
                      }}
                    >
                      <a
                        style={{
                          marginRight: 40,
                          textDecoration: "underline",
                        }}
                        id="uploaded-invoice"
                        href={claim.attmURL}
                        target="_blank"
                      >
                        Invoice
                        {/* {claim.attmURL.slice(claim.attmURL.lastIndexOf("/") + 1)} */}
                      </a>
                    </div>
                  ))
                : ""}

              {(status == 21 || status == 27) && admin && (
                <div>
                  <Divider style={{ height: 2 }} />
                  <div style={{ marginTop: 24 }} className={classes.textLabel}>
                    {status == 21
                      ? "Reason for Claim Decline:"
                      : "Claim Notes:"}
                  </div>
                  <TextField
                    value={notes}
                    variant="outlined"
                    margin="dense"
                    required
                    fullWidth
                    id="notes"
                    name="notes"
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </div>
              )}

              {/* <Typography className={classes.text} variant="body1">
          Upload a copy of the invoice as proof of purachase (pdf, jpg, jpeg,
          png). A statement is not a valid invoice. Maximum file size 8MB.
        </Typography> */}

              {/* <label htmlFor="contained-button-file"> */}
              {/* <BackButton
                onClick={handleUpdate}
                variant="contained"
                color="primary"
                component="span"
              >
                Update Claim
              </BackButton> */}
              {/* </label> */}
            </Paper>
          ) : (
            <Paper className={classes.cardContainer} elevation={4}>
              <BackButton className={classes.goBack}>
                <Link to="/admin/claims">Go Back</Link>
              </BackButton>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: matches ? "row" : "column",
                  marginBottom: matches ? 0 : 10,
                }}
              >
                <div
                  style={{
                    fontSize: 40,
                    fontWeight: "bold",
                    marginBottom: 24,
                    color: theme.palette.primaryFont.main,
                  }}
                >
                  Claim Number: {claim.id}
                </div>
                <div>
                  <FormControl className={classes.formControl}>
                    <Select
                      labelId="status-select-label"
                      id="status-select"
                      displayEmpty
                      renderValue={
                        status !== ""
                          ? undefined
                          : () => <Placeholder>Select one</Placeholder>
                      }
                      open={statusOpen}
                      onClose={handleStatusClose}
                      onOpen={handleStatusOpen}
                      value={status}
                      onChange={handleStatusChange}
                      IconComponent={iconComponent}
                      classes={{ root: classes.select }}
                      input={<Input className={classes.storeSelect} />}
                      // input={<Input className={classes.brandSelect} />}
                      // renderValue={(selected) => selected.join(', ')}
                      // renderValue={(selected) => sortCategory}
                      MenuProps={MenuProps}
                      // displayEmpty
                    >
                      <MenuItem
                        style={{ color: theme.palette.primary.main }}
                        value={5}
                      >
                        Pending
                      </MenuItem>
                      <MenuItem
                        style={{ color: theme.palette.primary.main }}
                        value={27}
                      >
                        Approved
                      </MenuItem>
                      <MenuItem
                        style={{ color: theme.palette.primary.main }}
                        value={21}
                      >
                        Declined
                      </MenuItem>
                      {/* <MenuItem style={{color: theme.palette.primary.main}} value={7}>
                  Cancelled
                </MenuItem> */}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div>
                <Grid container direction="column">
                  <Grid item sm>
                    <div className={classes.textLabel}>
                      Member Name:{" "}
                      <span style={{ fontWeight: "normal" }}>
                        {user.firstName} {user.lastName}
                      </span>
                    </div>
                    <div className={classes.textLabel}>
                      Email:{" "}
                      <span style={{ fontWeight: "normal" }}>
                        {claim.userEmail}
                      </span>
                    </div>
                    <div className={classes.textLabel}>
                      Contact Number:{" "}
                      <span style={{ fontWeight: "normal" }}>
                        {user.mobile}
                      </span>
                    </div>
                  </Grid>
                  <Grid item sm>
                    <div className={classes.textLabel}>
                      Address:
                      <span style={{ fontWeight: "normal" }}>
                        {user.streetAddress
                          ? // ? user.streetAddress.streetNumber +
                            //   // " " +
                            user.streetAddress.streetName
                          : ""}
                        {user.streetAddress ? user.streetAddress.suburb : ""}{" "}
                        {user.streetAddress
                          ? user.streetAddress.city +
                            " " +
                            user.streetAddress.postCode
                          : ""}
                      </span>
                    </div>
                  </Grid>
                </Grid>
              </div>

              {showStore && (
                <Divider
                  style={{ height: 2, marginBottom: 10, marginTop: 10 }}
                />
              )}
              {showStore && (
                <div style={{ padding: 10 }}>
                  <div className={classes.textLabel}>Store Name* </div>
                  {
                    <div>
                      <Autocomplete
                        onChange={(event, store) => {
                          setStore(store);
                        }}
                        id={`combo-box-distributor`}
                        options={distributorsList}
                        getOptionSelected={(option, value) =>
                          option.id === value.id
                        }
                        getOptionLabel={(option) => option.name}
                        value={store}
                        style={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            margin="dense"
                            variant="outlined"
                            required
                          />
                        )}
                      />
                    </div>
                  }
                  <Typography
                    style={{ marginTop: 24, marginBottom: 0 }}
                    className={classes.text}
                    variant="body1"
                  >
                    Select from the list of stores in that suburb.
                  </Typography>
                  <Typography
                    style={{ marginTop: 8 }}
                    className={classes.text}
                    variant="body1"
                  >
                    If you do not see your store listed, please contact us on
                    1800 855 326.
                  </Typography>
                </div>
              )}
              {showStore && <Divider style={{ height: 2 }} />}
              <div style={{ marginTop: 24 }} className={classes.invoiceDetails}>
                <div>
                  <div className={classes.textLabel}>Invoice Number </div>
                  <TextField
                    className={classes.textItem}
                    variant="outlined"
                    margin="dense"
                    id="invoice-number"
                    type="text"
                    fullWidth
                    required
                    value={invoiceNumber}
                    onChange={(e) => setInvoiceNumber(e.target.value)}
                  />
                </div>
                <div style={{ marginTop: 24 }}>
                  <div className={classes.textLabel}>Invoice Date* </div>
                  <Datepicker
                    invoiceDate={invoiceDate}
                    setInvoiceDate={(e) => setInvoiceDate(e)}
                  />
                </div>
              </div>
              <Divider style={{ marginTop: 24, height: 2 }} />
              {productList.map((product, index) => {
                let clientProd = clientProductsList.find(
                  (element) => element.id == product.productID
                );
                return (
                  <div
                    style={{ flexDirection: matches ? "row" : "column" }}
                    className={classes.productListItem}
                    key={product.idx}
                  >
                    <div
                      style={{
                        marginRight: matches ? 32 : 0,
                      }}
                      className={classes.productName}
                    >
                      <div
                        style={{
                          marginTop: matches ? 28 : 16,
                          marginBottom: matches ? 8 : 0,
                        }}
                        className={classes.textLabel}
                      >
                        Product<span className={classes.asterisk}>*</span>{" "}
                      </div>
                      {clientProductsList.length && (
                        <Autocomplete
                          onChange={(event, product) => {
                            updateProduct(event, index, product.id);
                          }}
                          id={`combo-box-products`}
                          options={
                            clientProductsList.length && clientProductsList
                          }
                          getOptionSelected={(option, value) => {
                            return option.id == value.productID;
                          }}
                          filterOptions={filterOptions}
                          getOptionLabel={(option) => {
                            let prod = clientProductsList.find(
                              (element) => element.id == option.productID
                            );
                            let clientProdName;
                            if (prod && prod.name) {
                              clientProdName = prod.name;
                            }
                            return clientProdName
                              ? clientProdName
                              : option.name;
                          }}
                          value={productList[index]}
                          style={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              margin="dense"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                    </div>
                    <div className={classes.quantity}>
                      <div
                        style={{
                          marginTop: matches ? 28 : 16,
                          marginBottom: matches ? 8 : 0,
                        }}
                        className={classes.textLabel}
                      >
                        Quantity<span className={classes.asterisk}>*</span>{" "}
                      </div>
                      <div className={classes.quantityBox}>
                        <input
                          type="text"
                          onChange={(e) => {
                            updateQuantity(e, index);
                          }}
                          required
                          value={product.quantity}
                          className={classes.quantityAmount}
                        />
                        <div className={classes.quantityControls}>
                          <div className={classes.quantityIncrease}>
                            {" "}
                            <img
                              // onClick={increaseQuantity}
                              onClick={(e) => incQuantity(e, index)}
                              src={"/upCaret.png"}
                              alt="increase quantity"
                            />
                          </div>
                          <div className={classes.quantityDecrease}>
                            {" "}
                            <img
                              // onClick={decreaseQuantity}
                              onClick={(e) => decQuantity(e, index)}
                              src={"/downCaret.png"}
                              alt="decrease quantity"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {((clientProd && clientProd.points <= 0) ||
                      clientProd == undefined) && (
                      <div className={classes.unitPrice}>
                        <div
                          style={{
                            marginTop: matches ? 28 : 16,
                            marginBottom: matches ? 8 : 0,
                          }}
                          className={classes.textLabel}
                        >
                          Unit Price (incl GST)
                          <span className={classes.asterisk}>*</span>{" "}
                        </div>
                        <TextField
                          value={product.price && product.price}
                          variant="outlined"
                          margin="dense"
                          id="unit-price"
                          required
                          name="unit-price"
                          onChange={(e) =>
                            updatePrice(e, index, e.target.value, true)
                          }
                        />
                      </div>
                    )}
                    {((clientProd && clientProd.points <= 0) ||
                      clientProd == undefined) && (
                      <div className={classes.unitPrice}>
                        <div
                          style={{
                            marginTop: matches ? 28 : 16,
                            marginBottom: matches ? 8 : 0,
                          }}
                          className={classes.textLabel}
                        >
                          Unit Price (excl GST)
                          <span className={classes.asterisk}>*</span>{" "}
                        </div>
                        <TextField
                          value={product.priceExGst && product.priceExGst}
                          variant="outlined"
                          margin="dense"
                          id="unit-price"
                          required
                          name="unit-price"
                          onChange={(e) =>
                            updatePrice(e, index, e.target.value, false)
                          }
                        />
                      </div>
                    )}
                    {((clientProd && clientProd.points <= 0) ||
                      clientProd == undefined) && (
                      <div className={classes.productSubTotal}>
                        <div
                          style={{
                            marginTop: matches ? 28 : 16,
                            marginBottom: matches ? 8 : 0,
                          }}
                          className={classes.textLabel}
                        >
                          Subtotal (inc GST)
                        </div>
                        <div
                          className={classes.values}
                          style={{ lineHeight: 2.5 }}
                        >
                          ${Number(product.price * product.quantity).toFixed(2)}
                        </div>
                      </div>
                    )}
                    <div
                      style={{
                        alignItems: "flex-start",
                      }}
                      className={classes.remove}
                    >
                      <div
                        style={{
                          marginTop: matches ? 28 : 16,
                          marginBottom: matches ? 8 : 0,
                          visibility: "hidden",
                        }}
                        className={classes.textLabel}
                      >
                        Remove Item
                      </div>
                      <div className={classes.removeIcon}>
                        <CloseOutlinedIcon
                          onClick={(e) => handleRemove(e, index)}
                          className={classes.icon}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
              <Divider style={{ height: 2 }} />
              <div
                style={{
                  flexFlow: matches ? "row wrap" : "column wrap",
                }}
                className={classes.addAndPointsContainer}
              >
                <div className={classes.addContainer}>
                  <div>
                    <BackButton
                      style={{
                        width: matches ? 300 : 240,
                        marginRight: matches ? 16 : 0,
                        marginTop: 0,
                      }}
                      onClick={handleAddProduct}
                    >
                      Add Another Product
                    </BackButton>
                  </div>
                </div>
                {productList && productList.length && (
                  <div
                    style={{
                      paddingRight: matches ? 187 : 0,
                      width: matches ? "50%" : "100%",
                    }}
                    className={classes.pointsContainer}
                  >
                    <div className={classes.estPoint}>
                      <div
                        style={{
                          marginBottom: matches ? 8 : 0,
                          marginTop: 0,
                        }}
                        className={classes.textLabel}
                      >
                        Estimated Point Value
                      </div>
                      <div className={classes.values}>
                        {productList && productList[0].points > 0
                          ? Math.floor(
                              productList
                                .map((item) => item.points * item.quantity)
                                .reduce((prev, next) => prev + next)
                            )
                          : client.monetaryDenominator &&
                            Math.floor(
                              (productList
                                .map((item) => item.price * item.quantity)
                                .reduce((prev, next) => prev + next) *
                                client.pointNumerator) /
                                client.monetaryDenominator
                            )}
                      </div>
                    </div>

                    <div className={classes.total}>
                      <div
                        style={{
                          marginBottom: matches ? 8 : 0,
                          marginTop: 0,
                        }}
                        className={classes.textLabel}
                      >
                        Total (inc GST)
                      </div>
                      <div className={classes.values}>
                        $
                        {productList &&
                          productList
                            .map((item) => item.price * item.quantity)
                            .reduce((prev, next) => prev + next)
                            .toFixed(2)}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <Divider style={{ height: 2 }} />

              {/* <div className={classes.addAndPointsContainer}>
          <div className={classes.addContainer}>
            <div>
              <BackButton
                style={{width: 300, marginRight: 16, marginTop: 0}}
                onClick={handleAddProduct}
              >
                Add Another Product
              </BackButton>
            </div>
            <div>
              <img src={'/information-line.png'} alt={'exclamation icon'} />
            </div>
          </div>
          <div className={classes.pointsContainer}>
            <div className={classes.estPoint}>
              <div style={{marginTop: 0}} className={classes.textLabel}>
                Estimated Point Value
              </div>
              <div
                style={{
                  color: theme.palette.primaryFont.main,
                  fontWeight: 'bold',
                  fontSize: 18,
                  paddingTop: 12,
                }}
              >
                {getTotal().toFixed(2) * 10}
              </div>
            </div>
            <div className={classes.total}>
              <div style={{marginTop: 0}} className={classes.textLabel}>
                Total (inc GST)
              </div>
              <div
                style={{
                  color: theme.palette.primaryFont.main,
                  fontWeight: 'bold',
                  fontSize: 18,
                  paddingTop: 12,
                }}
              >
                ${getTotal().toFixed(2)}
              </div>
            </div>
          </div>
        </div> */}
              <div style={{ marginTop: 24 }} className={classes.textLabel}>
                Invoice Evidence Below
                <span className={classes.asterisk}>*</span>{" "}
              </div>
              <input
                style={{ display: "none" }}
                accept="application/pdf"
                className={classes.input}
                id="contained-button-file"
                // multiple
                type="file"
              />
              {/* <label htmlFor="contained-button-file">
          <BackButton variant="contained" color="primary" component="span">
            Choose File
          </BackButton>
        </label> */}
              {claim.attachments
                ? claim.attachments.map((claim) => (
                    <div
                      style={{
                        marginBottom: 24,
                        marginTop: 24,
                      }}
                    >
                      <a
                        style={{
                          marginRight: 40,
                          textDecoration: "underline",
                        }}
                        id="uploaded-invoice"
                        href={claim.attmURL}
                        target="_blank"
                      >
                        Invoice
                        {/* {claim.attmURL.slice(claim.attmURL.lastIndexOf("/") + 1)} */}
                      </a>
                    </div>
                  ))
                : ""}
              <div>
                <input
                  ref={fileInputRef}
                  style={{
                    opacity: 0,
                    zIndex: -1,
                    width: 0,
                    margin: 0,
                    padding: 0,
                  }}
                  accept=".pdf,.jpeg,.jpg,.png"
                  className={classes.fileButton}
                  required
                  id={`contained-button-file`}
                  // multiple
                  onChange={() => {
                    handleUpload();
                  }}
                  type="file"
                />
                <label htmlFor={`contained-button-file`}>
                  <BackButton
                    style={{
                      width: matches ? 300 : 240,
                      marginTop: 5,
                      marginBottom: 5,
                    }}
                    onClick={() => fileInputRef.current.click()}
                  >
                    Replace Invoice File
                  </BackButton>
                </label>
                {file && (
                  <span style={{ padding: 10, fontSize: 14 }}>{file.name}</span>
                )}
              </div>
              <div>
                <Typography className={classes.text} variant="body1">
                  This file will overwrite the file linked above.
                </Typography>
                <Typography className={classes.text} variant="body1">
                  Upload a copy of the invoice as proof of purchase in pdf, png
                  or jpeg format. A statement is not a valid invoice. Maximum
                  file size 8MB.
                </Typography>
              </div>

              {(status == 21 || status == 27) && admin && (
                <div>
                  <Divider style={{ height: 2 }} />
                  <div style={{ marginTop: 24 }} className={classes.textLabel}>
                    {status == 21
                      ? "Reason for Claim Decline:"
                      : "Claim Notes:"}
                  </div>
                  <TextField
                    value={notes}
                    variant="outlined"
                    margin="dense"
                    required
                    fullWidth
                    id="notes"
                    name="notes"
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </div>
              )}

              {/* <Typography className={classes.text} variant="body1">
          Upload a copy of the invoice as proof of purachase (pdf, jpg, jpeg,
          png). A statement is not a valid invoice. Maximum file size 8MB.
        </Typography> */}

              {/* <label htmlFor="contained-button-file"> */}
              <BackButton
                onClick={handleUpdate}
                variant="contained"
                color="primary"
                component="span"
              >
                Update Claim
              </BackButton>
              {/* </label> */}
            </Paper>
          )}
        </div>
      )}
    </div>
  );
}
