import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Divider,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  ExpandMore as ExpandMoreIcon,
  AddCircle as AddCircleIcon,
  Close as CloseIcon,
  Store,
} from "@material-ui/icons";
import {
  createMemberGroup,
  selectMembers,
  updateMemberGroup,
  deleteMemberGroup,
} from "./membersSlice";
import { selectStores } from "../stores/storesSlice";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
  addCircle: {
    marginRight: 0,
    color: "#FFF",
  },
}));

const AddMemberGroup = (props) => {
  const { editItem, setEdit } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { memberGroup } = useSelector(selectMembers);
  const { storesList, storesAmount } = useSelector(selectStores);

  const [name, setName] = useState("");
  const [storeID, setStoreID] = useState(null);
  let jwtToken = localStorage.getItem("clientToken");

  const handleSubmit = (event) => {
    event.preventDefault();
    const memberGroupDetails = {
      name,
      storeID,
    };
    if (editItem) {
      dispatch(
        updateMemberGroup({
          token: jwtToken,
          id: memberGroup.id,
          member: memberGroupDetails,
        })
      );
    } else {
      dispatch(
        createMemberGroup({ token: jwtToken, member: memberGroupDetails })
      );
    }
  };

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (editItem) {
      setOpen(true);
      setName(memberGroup.name);
      setStoreID(memberGroup.storeID);
    }
  }, [editItem]);

  const handleClickOpen = () => {
    setName("");
    setStoreID(null);
    setOpen(true);
  };

  const handleClose = () => {
    setName("");
    setStoreID(null);
    setOpen(false);
    setEdit(false);
  };

  const handleDelete = () => {
    dispatch(
      deleteMemberGroup({
        token: jwtToken,
        id: memberGroup.id,
      })
    ).then(() => {
      handleClose();
    });
  };

  return (
    <div>
      <IconButton aria-label={"add store"} style={{ marginLeft: 0 }}>
        <AddCircleIcon
          className={classes.addCircle}
          onClick={handleClickOpen}
        />
      </IconButton>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <DialogTitle disableTypography id="form-dialog-title">
            {editItem ? "Update Member Group" : "Add New Member Group"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              variant="outlined"
              type="text"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="storeID-label">Store</InputLabel>
              <Select
                labelId="storeID-label"
                id="storeID"
                value={storeID}
                onChange={(e) => setStoreID(e.target.value)}
                label="Store"
              >
                <MenuItem value={null}>None</MenuItem>
                {storesList.map((store) => (
                  <MenuItem value={store.id}>{store.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Grid
              container
              fullWidth
              justifyContent="space-around"
              direction="row"
            >
              <Button variant="outlined" type="submit" color="secondary">
                {editItem ? "Update Member Group" : "Create Member Group"}
              </Button>

              {editItem && (
                <Button
                  onClick={handleDelete}
                  variant="contained"
                  style={{ backgroundColor: "red", color: "white" }}
                >
                  {"Delete Member Group"}
                </Button>
              )}
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default AddMemberGroup;
