import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { setXClientHost } from "../../app/utils";
import { selectCustomisation } from "../customisation/customisationSlice";
import { fetchContentByName, selectContents } from "./contentsSlice";

const BackButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#fff",
    color: theme.palette.primaryFont.main,
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: theme.palette.primaryFont.main,
    borderRadius: 20,
    width: 200,
    "&:hover": {
      backgroundColor: "#DDD",
    },
    fontWeight: "bold",
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  claimContainer: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondaryFont.main,
    display: "flex",
    flexFlow: "column nowrap",
    // justifyContent: 'center',
    alignItems: "center",
    height: "100%",
    marginBottom: 62,
  },
  cardContainer: {
    backgroundColor: "#fff",
    borderRadius: 16,
    color: theme.palette.secondaryFont.main,
    margin: 48,
    padding: 48,
  },
  header: { fontWeight: "bold", marginBottom: 24 },
  title: {
    color: theme.palette.primaryFont.main,
    fontWeight: "bold",
    marginBottom: 16,
    paddingTop: 8,
  },
  subTitle: {
    fontWeight: "bold",
    marginBottom: 8,
  },
  text: {
    marginBottom: 16,
  },
}));

export default function HowToClaim() {
  const { customisation } = useSelector(selectCustomisation);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  let jwtToken = localStorage.getItem("clientToken");

  const goBack = () => {
    history.goBack();
  };

  const { content, loading } = useSelector(selectContents);

  useEffect(() => {
    dispatch(
      fetchContentByName({
        host: setXClientHost(),
        token: jwtToken,
        name: "how-to-claim",
      })
    );
  }, []);

  return loading !== "loaded" ? (
    <div className={classes.claimContainer}></div>
  ) : content.value && content.value !== "<p><br></p>" ? (
    <div className={classes.claimContainer}>
      <Paper className={classes.cardContainer} elevation={4}>
        <div className={classes.customContentContainer}>
          <ReactQuill
            value={content.value}
            readOnly={true}
            theme={"bubble"}
            // style={{ fontSize: 18 }}
          />
        </div>
      </Paper>
      <BackButton onClick={goBack}>Go Back</BackButton>
    </div>
  ) : (
    <div className={classes.claimContainer}>
      <Paper className={classes.cardContainer} elevation={4}>
        <Typography className={classes.header} variant="h3">
          How To Claim
        </Typography>
        <Typography className={classes.title} variant="h5">
          To claim {customisation.theme.clientName} prizes:
        </Typography>
        <Typography className={classes.subTitle} variant="body1">
          1. Select Your Prize(s)
        </Typography>
        <Typography className={classes.text} variant="body1">
          Browse the prize categories to select your prizes equal to the value
          of your Total Available Points. Add each item to your shopping cart by
          clicking on the 'Add To Cart' button.
        </Typography>
        <Typography className={classes.subTitle} variant="body1">
          2. Check Your List Of Prizes
        </Typography>
        <Typography className={classes.text} variant="body1">
          Check your list of prizes and remove or change any items before
          clicking on the 'Checkout' button.
        </Typography>
        <Typography className={classes.subTitle} variant="body1">
          3. Checkout
        </Typography>
        <Typography className={classes.text} variant="body1">
          On the Checkout page you may enter an alternate Delivery Address if
          desired, then click on 'Review Order' to continue.
        </Typography>
        <Typography className={classes.subTitle} variant="body1">
          4. Review Your Order
        </Typography>
        <Typography className={classes.text} variant="body1">
          On this page you can review your order. If you are happy that all of
          the information is accurate, click on 'Submit Order'. Your claim will
          then be lodged and its total points deducted from your Total Available
          Points balance, appearing at top of screen.
        </Typography>
        <Typography className={classes.title} variant="h5">
          Delivery & Receipt of Your Prizes
        </Typography>
        <Typography className={classes.text} variant="body1">
          Reward prizes can only be delivered to a business address open 9.00am
          - 5.00pm Monday to Friday and NOT to a PO Box or home address. A
          business phone number should also be provided.
        </Typography>
        <Typography
          style={{ fontWeight: "bold" }}
          className={classes.text}
          variant="body1"
        >
          Please allow 30 days from the end of the month of claim for the
          processing, handling and delivery of all prizes.
        </Typography>
        <Typography className={classes.text} variant="body1">
          Freight has been paid for by {customisation.theme.clientName}, so if
          for some reason you are charged freight due to a supplier oversight
          please post the receipt plus your details to{" "}
          {customisation.theme.clientName} and you will be reimbursed the total
          amount.
        </Typography>
        <Typography className={classes.text} variant="body1">
          Upon receipt of your prizes fill in and return any manufacturers
          warranty card in the normal manner.
        </Typography>
        <Typography className={classes.title} variant="h5">
          In Transit Damage or Warranty Claims
        </Typography>
        <Typography className={classes.text} variant="body1">
          Please inspect your prizes immediately upon receipt.
        </Typography>
        <Typography className={classes.text} variant="body1">
          Any items that are damaged in transit will be replaced by{" "}
          {customisation.theme.clientName}. Please notify{" "}
          {customisation.theme.clientName} immediately upon receipt of any such
          prize, including details of the carrier.
        </Typography>
        <Typography
          style={{ fontWeight: "bold", marginBottom: 0 }}
          className={classes.text}
          variant="body1"
        >
          IMPORTANT
        </Typography>
        <Typography className={classes.text} variant="body1">
          {" "}
          Any product returned for a carrier or manufacturer warranty claim
          should be repackaged in its original container.
        </Typography>
        <Typography className={classes.title} variant="h5">
          Superseded or Discontinued Products
        </Typography>
        <Typography className={classes.text} variant="body1">
          {customisation.theme.clientName} reserves the right to discontinue any
          prize and to replace any superseded prize with a new model or prize of
          the nearest possible points value.
        </Typography>
      </Paper>
      <BackButton onClick={goBack}>Go Back</BackButton>
    </div>
  );
}
